import { useParams } from 'react-router-dom'
import { parsePositiveInteger } from 'tokensoft-shared-types'

/**
 * Parses an integer path parameter from current url, returning the value
 * as an integer, or null if the parameter is not present and valid.
 * Rejects numbers with leading zeros and zero itself.
 */
export const usePositiveIntegerParam = (param: string): Maybe<ID> => {
  const params = useParams()
  const paramStr = params[param]

  if (paramStr === undefined || paramStr === '') {
    return null
  }

  return parsePositiveInteger(paramStr)
}
