import { EVENT_USER_FILTER_TYPE } from '@apiServices'
import {
  Button,
  ButtonRow,
  InputGroup,
  MultiSelect,
  Text,
  TextLink,
} from '@components'
import classNames from 'classnames'
import { useState } from 'react'
import { FaFilter, FaSearch, FaTimes } from 'react-icons/fa'
import './event-users-search.css'

export const EventUsersSearch = ({
  setSearchValue,
  updateUserTable,
  filterChange,
  filterList,
  disabled,
}: {
  setSearchValue: Function
  updateUserTable: Function
  filterChange: Function
  filterList: Record<string, any>
  disabled: boolean
}) => {
  const [filterListOpen, setFilterListOpen] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')

  const updateSearchValue = (value: any) => {
    setSearchValue(value)
    setSearchText(value)
  }
  const handleSearchButton = () => {
    updateUserTable(0)
    setFilterListOpen(false)
  }

  const handleEnterPress = (e: any) => {
    if (e.key == 'Enter') {
      handleSearchButton()
    }
  }

  const renderFilter = (key: any, index: any) => {
    switch (filterList[key as keyof typeof filterList].type) {
      case EVENT_USER_FILTER_TYPE.SELECT:
        return (
          <div
            key={index}
            className={classNames(
              'w-full lg:w-1/4 px-0 md:px-2',
              index == 0 && 'pl-0',
              index == Object.entries(filterList).length - 1 && 'pr-0',
            )}
          >
            <MultiSelect
              defaultValue=''
              label={filterList[key].inTableFilter}
              options={filterList[key].data.dataSet}
              value={filterList[key].data.dataSet.find(
                (data: any) => data.value == filterList[key].searchTerm,
              )}
              onChange={(selected: any) => filterChange(key, selected.value)}
              isMulti={false}
            />
          </div>
        )

      case EVENT_USER_FILTER_TYPE.TEXT:
        return (
          <div
            key={index}
            className={classNames(
              'w-full lg:w-1/4 px-0 md:px-2',
              index == 0 && 'pl-0',
              index == Object.entries(filterList).length - 1 && 'pr-0',
            )}
          >
            <InputGroup
              name=''
              value={filterList[key].searchTerm}
              label={filterList[key].inTableFilter}
              onChange={(evt: any) => filterChange(key, evt.target.value)}
              className='my-2'
            />
          </div>
        )
      default:
        return null
    }
  }

  const handleClearFilter = () => {
    Object.entries(filterList).map(([key, value], index) =>
      filterChange(key, null),
    )
  }

  return (
    <div className={classNames('search-container', filterListOpen && 'open')}>
      <div className='search-bar'>
        <InputGroup
          label={''}
          value={searchText}
          name='searchValue'
          onChange={(e: any) => {
            updateSearchValue(e.target.value)
          }}
          className={classNames(
            'search-input',
            disabled && 'cursor-none pointer-events-none',
          )}
          onKeyPress={handleEnterPress}
          placeholder='Search by User Name or Email'
          append={
            <Button
              className='search-button'
              disabled={disabled}
              onClick={() => handleSearchButton()}
            >
              <div>
                <FaSearch />
              </div>
              <Text className='search-text'>Search</Text>
            </Button>
          }
        />
        {!disabled ? (
          <button
            className='btn-outline-primary filter-button'
            onClick={() => setFilterListOpen(!filterListOpen)}
          >
            <FaFilter />
          </button>
        ) : null}
      </div>
      <div className={classNames('filter-container')}>
        <div className='flex'>
          <div className='filter-header-text'>Filter Search</div>
          <div className='relative'>
            <button
              className='filter-close'
              onClick={() => setFilterListOpen(!filterListOpen)}
            >
              <FaTimes />
            </button>
          </div>
        </div>
        <div className='mb-5'>
          Click all that apply to filter your users by category.
        </div>
        <div className='filter-list'>
          {Object.entries(filterList).map(([key, value], index) =>
            renderFilter(key, index),
          )}
        </div>
        <ButtonRow place={'end'}>
          <TextLink
            width={'fit'}
            className='text-sm !font-normal'
            onClick={handleClearFilter}
          >
            Clear Filters
          </TextLink>
        </ButtonRow>
      </div>
    </div>
  )
}
