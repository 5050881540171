export const DangerAlertIcon = ({ className = '' }) => {
  return (
    <svg
      className={className}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM10.6961 6.33244C10.9801 6.04842 10.9801 5.58794 10.6961 5.30392C10.4121 5.01991 9.95158 5.01991 9.66756 5.30392L8 6.97148L6.33244 5.30392C6.04842 5.01991 5.58794 5.01991 5.30392 5.30392C5.01991 5.58794 5.01991 6.04842 5.30392 6.33244L6.97148 8L5.30392 9.66756C5.01991 9.95158 5.01991 10.4121 5.30392 10.6961C5.58794 10.9801 6.04842 10.9801 6.33244 10.6961L8 9.02852L9.66756 10.6961C9.95158 10.9801 10.4121 10.9801 10.6961 10.6961C10.9801 10.4121 10.9801 9.95158 10.6961 9.66756L9.02852 8L10.6961 6.33244Z'
        fill='#EF4444'
      />
    </svg>
  )
}
