import { useWebApiClient } from '@apiClients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const usePostPolkadotWalletVerificationSignature = () => {
  const fetchWithClient = useWebApiClient()

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (variables: {
      polkadotAddress: string
      signature: string
    }) => {
      const result = (await fetchWithClient(
        'wallets/polkadot/verification-signature',
        {
          method: 'POST',
          data: variables,
        },
      )) as { isValid: boolean }
      return result.isValid
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: ['wallets'] })
    },
  })
}
