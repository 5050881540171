import {
  Alert,
  Card,
  Col,
  NetworkIcon,
  Row,
  SectionTitle,
  Text,
} from '@components'
import { convertBaseUnitsToDecimal, formatValue } from '@utils'

interface TokenInfoCardProps {
  tokenInfo: any
  networkId: number
  showSuccess: boolean
}

export const getFormattedAmount = (
  amount: string,
  tokenInfo: any,
  roundedDecimals = 2,
) => {
  return formatValue(
    convertBaseUnitsToDecimal(
      amount,
      tokenInfo?.decimals || 18,
      roundedDecimals,
    ),
    { commas: true },
  )
}

export const getFormattedTotalSupply = (tokenInfo: any) => {
  return getFormattedAmount(tokenInfo?.totalSupply, tokenInfo)
}

export const formatAmountWithSymbol = (amount: string, tokenInfo: any) => {
  if (amount) {
    return `${amount} ${tokenInfo?.symbol}`
  }

  return ''
}

export const TokenInfoCard = ({
  tokenInfo,
  networkId,
  showSuccess,
}: TokenInfoCardProps) => {
  const formattedTotalSupply = getFormattedTotalSupply(tokenInfo)
  const nameFormatted = tokenInfo?.name
    ? `${tokenInfo?.name} (${tokenInfo?.symbol})`
    : ''
  const totalSupplyFormatted = formatAmountWithSymbol(
    formattedTotalSupply,
    tokenInfo,
  )
  const decimals = tokenInfo?.decimals || ''

  return (
    <Card id={'token-info-card'}>
      <Col gap={2.5}>
        <Row place={'between-center'} gap={5}>
          <SectionTitle data-testid={'token-info-card-title'}>
            {nameFormatted}
          </SectionTitle>
          <NetworkIcon
            data-testid={`token-info-card-icon`}
            chainId={networkId}
            logoSize={'sm'}
            width={'fit'}
            place={'end-center'}
          />
        </Row>

        <Row place={'between-center'} gap={5}>
          <Text data-testid={'token-info-token-supply'} breakAnywhere>
            Total Supply: {totalSupplyFormatted}
          </Text>
          <Text data-testid={'token-info-decimals'}>Decimals: {decimals}</Text>
        </Row>

        {showSuccess ? (
          <Alert data-testid='valid-token-address' type='alert-success'>
            Valid Address
          </Alert>
        ) : null}
      </Col>
    </Card>
  )
}
