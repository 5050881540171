import { LoadingIndicator } from '@components'
import { useToast } from '@contexts'
import { useEventDocuments } from '@hooks'
import { useEffect, useState } from 'react'
import { DocumentSigningForm } from './document-signing-form'
import { areDocumentRequirementsMet } from './document-util'
import { EligibilityItemStatus } from './eligibility-item-status'
import { EventDocumentList } from './event-documents-list'
import { EventModal } from './event-modal'

interface DocumentEligibilityStatusProps {
  eventId: ID
  hasMetRequirement: boolean
  onMetRequirement: () => void
}

export const DocumentEligibilityStatus = ({
  eventId,
  onMetRequirement,
  hasMetRequirement,
}: DocumentEligibilityStatusProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { showSuccessToast, showErrorToast } = useToast()

  const progressStatus: ProgressStatus = hasMetRequirement
    ? 'completed'
    : 'incomplete'

  const {
    data: { eventDocuments, eventUserDocumentsStatus },
    isPending: isPendingDocuments,
    error: eventDocumentsError,
  } = useEventDocuments(eventId)

  useEffect(() => {
    if (eventDocuments === null || eventUserDocumentsStatus === null) {
      return
    }

    // No documents to approve
    if (eventDocuments.length === 0) {
      onMetRequirement()
    }

    if (areDocumentRequirementsMet(eventDocuments, eventUserDocumentsStatus)) {
      onMetRequirement()
    }
  }, [eventDocuments, eventUserDocumentsStatus])

  useEffect(() => {
    if (eventDocumentsError) {
      showErrorToast({ description: eventDocumentsError.message })
    }
  }, [eventDocumentsError])

  const actionString = eventDocuments?.some(
    (document) => document.requiresSignature,
  )
    ? 'Sign Documents'
    : 'Agree to Document Terms'

  const handleDocumentRequirementMet = () => {
    setIsModalOpen(false)
    onMetRequirement()
    showSuccessToast({ description: 'Document requirements completed.' })
  }

  if (isPendingDocuments) {
    return <LoadingIndicator />
  }

  if (
    eventDocumentsError ||
    eventDocuments === null ||
    eventUserDocumentsStatus === null
  ) {
    return <div>Error fetching documents</div>
  }

  if (eventDocuments.length === 0) {
    return <></>
  }

  return (
    <>
      <EventModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel='Event Documents'
      >
        {progressStatus === 'completed' ? (
          <EventDocumentList
            documents={eventDocuments}
            documentStatuses={eventUserDocumentsStatus}
          />
        ) : (
          <DocumentSigningForm
            eventId={eventId}
            eventDocuments={eventDocuments}
            eventUserDocumentStatuses={eventUserDocumentsStatus}
            onFinishedReviewingOrSigning={handleDocumentRequirementMet}
            isUserFinishedReviewingOrSigning={hasMetRequirement}
          />
        )}
      </EventModal>
      <EligibilityItemStatus
        title={actionString}
        progressStatus={progressStatus}
        onClick={() => setIsModalOpen(true)}
      />
    </>
  )
}
