import {
  AccreditationCompleted,
  AccreditationFailed,
  AccreditationPending,
  IdentityCompleted,
  IdentityFailed,
  IdentityPending,
  IdentitySubmitted,
  Layout,
  SentryErrorBoundary,
} from '@components'
import { EventProvider, ProfileProvider, ProjectProvider } from '@contexts'
import {
  AccreditationPage,
  AdminPage,
  Dashboard,
  DeployDistributorWizardPage,
  DeploySaleWizardPage,
  EditDistributorFundWizardPage,
  EditDistributorOwnerWizardPage,
  EditDistributorParticipantsWizardPage,
  EditDistributorVestingWizardPage,
  EditEventContentWizardPage,
  EditEventDetailsPage,
  EditEventDocumentsWizardPage,
  EditEventEligibilityWizardPage,
  EditEventGeneralWizardPage,
  EditEventPrivacyWizardPage,
  EditProjectThemeWizardPage,
  EditSaleOwnerWizardPage,
  EditSaleParticipantsWizardPage,
  EditSalePaymentConfigWizardPage,
  EditSaleSetupWizardPage,
  ErrorPage,
  EventCreatePage,
  EventDashboardPage,
  IdentityPage,
  LaunchDistributorEventWizardPage,
  LaunchIdentityEventWizardPage,
  LaunchSaleEventWizardPage,
  MaintenancePage,
  ProfileDashboardPage,
  ProjectDashboardPage,
  ProjectPolicyPage,
  ProjectsPage,
  PurchasesPage,
  RegisterPage,
  UserPage,
  ViewMessagePage,
} from '@pages'
import * as Sentry from '@sentry/react'
import { Route, Routes } from 'react-router-dom'
import { App } from './App'
import { EventLoader } from './pages/event/event-loader'

export const TSRoutes = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)
  return (
    <SentryRoutes>
      <Route
        path='/maintenance'
        element={<MaintenancePage />}
        errorElement={<SentryErrorBoundary />}
      />
      <Route path='/' element={<App />} errorElement={<SentryErrorBoundary />}>
        {/* Default route to render if no custom default_uri provided */}
        <Route path='/' element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path='event/:eventId' element={<EventLoader />} />
          <Route path='policy' element={<ProjectPolicyPage />} />
          <Route path='purchases' element={<PurchasesPage />} />
          <Route path='projects' element={<ProjectsPage />} />
          <Route path='account'>
            <Route
              index
              element={
                <ProfileProvider>
                  <ProfileDashboardPage />
                </ProfileProvider>
              }
            />
            <Route path='identity'>
              <Route index element={<IdentityPage />} />
              <Route path='submitted' element={<IdentitySubmitted />} />
              <Route path='pending' element={<IdentityPending />} />
              <Route path='completed' element={<IdentityCompleted />} />
              <Route path='failed' element={<IdentityFailed />} />
            </Route>
            <Route path='accreditation'>
              <Route index element={<AccreditationPage />} />
              <Route path='pending' element={<AccreditationPending />} />
              <Route path='completed' element={<AccreditationCompleted />} />
              <Route path='failed' element={<AccreditationFailed />} />
            </Route>
            <Route path='message/:messageToken' element={<ViewMessagePage />} />
            <Route
              path='*'
              element={
                <ProfileProvider>
                  <ProfileDashboardPage />
                </ProfileProvider>
              }
            />
          </Route>

          <Route path='admin'>
            <Route index element={<AdminPage />} />
            <Route path='project' element={<ProjectProvider />}>
              <Route path=':id'>
                <Route index element={<ProjectDashboardPage />} />
                <Route path='theme' element={<EditProjectThemeWizardPage />} />
                <Route path='event'>
                  <Route path='launch'>
                    <Route index element={<EventCreatePage />} />
                    <Route
                      path='identity'
                      element={<LaunchIdentityEventWizardPage />}
                    />
                    <Route
                      path='sale'
                      element={<LaunchSaleEventWizardPage />}
                    />
                    <Route
                      path='distributor'
                      element={<LaunchDistributorEventWizardPage />}
                    />
                  </Route>
                  <Route path=':eventId' element={<EventProvider />}>
                    <Route index element={<EventDashboardPage />} />
                    <Route
                      path='fund'
                      element={<EditDistributorFundWizardPage />}
                    />
                    <Route path='user/:authId' element={<UserPage />} />

                    <Route path='edit' element={<EditEventDetailsPage />} />
                    <Route
                      path='general'
                      element={<EditEventGeneralWizardPage />}
                    />
                    <Route
                      path='eligibility'
                      element={<EditEventEligibilityWizardPage />}
                    />
                    <Route
                      path='privacy'
                      element={<EditEventPrivacyWizardPage />}
                    />
                    <Route
                      path='documents'
                      element={<EditEventDocumentsWizardPage />}
                    />
                    <Route
                      path='content'
                      element={<EditEventContentWizardPage />}
                    />
                    <Route path='sale'>
                      <Route
                        path='participants'
                        element={<EditSaleParticipantsWizardPage />}
                      />
                      <Route
                        path='owner'
                        element={<EditSaleOwnerWizardPage />}
                      />
                      <Route
                        path='payment-config'
                        element={<EditSalePaymentConfigWizardPage />}
                      />
                      <Route
                        path='setup'
                        element={<EditSaleSetupWizardPage />}
                      />
                    </Route>
                    <Route path='distributor'>
                      <Route
                        path='owner'
                        element={<EditDistributorOwnerWizardPage />}
                      />
                      <Route
                        path='participants'
                        element={<EditDistributorParticipantsWizardPage />}
                      />
                      <Route
                        path='vesting'
                        element={<EditDistributorVestingWizardPage />}
                      />
                    </Route>
                    <Route path='deploy'>
                      <Route
                        path='distributor'
                        element={<DeployDistributorWizardPage />}
                      />
                      <Route path='sale' element={<DeploySaleWizardPage />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path='register' element={<RegisterPage />} />
          </Route>

          {/* Catch-all */}
          <Route path='*' element={<Dashboard />} />
        </Route>
      </Route>
      {/* Catch-all */}
      <Route
        path='*'
        element={
          <div className='min-h-screen'>
            <Layout />
          </div>
        }
        errorElement={<SentryErrorBoundary />}
      >
        <Route index element={<ErrorPage />} />
      </Route>
    </SentryRoutes>
  )
}
