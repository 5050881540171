import { Col, EventSubtitle, EventTitle, Stretched } from '@components'
import { DEFAULT_EVENT_HEADER_FONT_COLOR } from '@constants'
import { LAYOUT_TYPE } from '@enums'
import { getResourceUri, getValidIPFSHash } from '@utils'

interface EventHeaderProps {
  headerTitle: Maybe<string>
  headerSubtitle: Maybe<string>
  contentHeaderImage: Maybe<string>
  contentHeaderFontColor: Maybe<string>
  themeLayoutVersion: Maybe<string>
}

export const EventHeader = ({
  headerTitle,
  headerSubtitle,
  contentHeaderImage,
  contentHeaderFontColor,
  themeLayoutVersion,
}: EventHeaderProps) => {
  const headerImage = getValidIPFSHash(contentHeaderImage ?? '')
    ? getResourceUri(contentHeaderImage ?? '')
    : contentHeaderImage
  const _contentHeaderFontColor =
    contentHeaderFontColor || DEFAULT_EVENT_HEADER_FONT_COLOR
  let title: any = {}
  let icon: Maybe<{
    width: Pick<React.ComponentProps<typeof Col>, 'width'>['width']
    show: boolean
    className: string
    imgClassName: string
  }> = null

  switch (themeLayoutVersion) {
    case LAYOUT_TYPE.TITLE_ICON_50_50: {
      title = {
        show: true,
        className: 'event-banner-text-container w-full',
        xalign: 'left',
      }
      icon = {
        show: true,
        className: 'event-banner-logo-container max-h-64 w-96',
        width: 'fit',
        imgClassName: 'event-banner-logo',
      }
      break
    }
    case LAYOUT_TYPE.TITLE_ICON_50_50_REVERSED: {
      title = {
        show: true,
        className: 'event-banner-text-container order-2',
        xalign: 'right',
      }
      icon = {
        show: true,
        className: ' max-h-64 w-96 order-1',
        width: 'fit',
        imgClassName: 'event-banner-logo',
      }
      break
    }
    case LAYOUT_TYPE.ICON_100: {
      title = {
        show: false,
        className: '',
        xalign: '',
      }
      icon = {
        show: true,
        className: 'event-banner-logo-container max-h-64',
        width: 'full',
        imgClassName: 'h-full',
      }
      break
    }
    case LAYOUT_TYPE.TITLE_100: {
      title = {
        show: true,
        className: 'event-banner-text-container',
        xalign: 'center',
      }
      icon = {
        show: false,
        className: '',
        width: '0',
        imgClassName: '',
      }
      break
    }
    default: {
      title = {
        show: true,
        className: 'event-banner-text-container w-full',
        xalign: 'left',
      }
      icon = {
        show: true,
        className: 'event-banner-logo-container max-h-64 w-96',
        width: 'fit',
        imgClassName: 'event-banner-logo',
      }
      break
    }
  }

  return (
    <Stretched>
      {title.show && (
        <Col
          gap={2}
          yalign='center'
          className={title.className}
          style={{ color: _contentHeaderFontColor }}
        >
          <EventTitle textAlign={title.xalign}>{headerTitle}</EventTitle>
          <EventSubtitle textAlign={title.xalign}>
            {headerSubtitle}
          </EventSubtitle>
        </Col>
      )}
      {icon.show && headerImage !== null && (
        <Col
          width={`${icon.width ?? 'fit'}`}
          className={icon.className}
          data-testid={'event-banner-logo-container'}
          place={'center'}
        >
          <img className={icon.imgClassName} src={headerImage} />
        </Col>
      )}
    </Stretched>
  )
}
