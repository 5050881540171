import {
  Button,
  ButtonRow,
  Card,
  Col,
  Discord,
  Globe,
  Row,
  Stacked,
  Telegram,
  TokensoftPoweredByIcon,
  Twitter,
} from '@components'
import { useModal } from '@contexts'
import { LAYOUT_TYPE } from '@enums'
import { getIPFSLink, getResourceUri, getValidIPFSHash } from '@utils'
import classNames from 'classnames'

export const PreviewWelcomePage = ({
  context,
  onContinue,
}: {
  context: any
  onContinue?: Function
}) => {
  const { closeModal } = useModal()

  const onContinueHandler = () => {
    onContinue && onContinue()
    closeModal()
  }

  const TwitterIcon = () => {
    return (
      <div>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          viewBox='0 0 512 512'
          style={{ width: '16px', height: '16px' }}
        >
          <path d='M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z' />
        </svg>
      </div>
    )
  }

  let welcomeTitleAlignment: Maybe<string> = null

  switch (context?.welcomeLayoutVersion) {
    case LAYOUT_TYPE.TITLE_ICON_CENTERED:
      welcomeTitleAlignment = 'text-center'
      break
    case LAYOUT_TYPE.TITLE_ONLY_100_CENTERED:
      welcomeTitleAlignment = 'text-center'
      break
    case LAYOUT_TYPE.TITLE_ONLY_100_LEFT:
      welcomeTitleAlignment = 'text-left'
      break
    case LAYOUT_TYPE.TITLE_ONLY_100_RIGHT:
      welcomeTitleAlignment = 'text-right'
      break
  }

  return (
    context && (
      <Stacked className='mt-10'>
        <Card className='relative'>
          <div
            className={classNames(
              `flex flex-col justify-center items-center welcome-color !min-w-full relative`,
            )}
            style={{
              height: '50rem',
              backgroundImage: `url('${
                getValidIPFSHash(context.welcomeBgImage)
                  ? getResourceUri(context.welcomeBgImage)
                  : context.welcomeBgImage
              }')`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundColor: context.welcomeBgColor,
            }}
          >
            <div className={classNames(`flex flex-col w-full px-32`)}>
              <div className={'w-full'}>
                {context.welcomeLayoutVersion ===
                LAYOUT_TYPE.TITLE_ICON_CENTERED ? (
                  <img
                    src={getIPFSLink(context?.welcomeLogo)}
                    className={'w-40 mx-auto'}
                  />
                ) : null}
                <div
                  className={classNames(
                    'mb-5 welcome-title',
                    welcomeTitleAlignment,
                  )}
                  style={{ color: context.welcomeFontColor }}
                >
                  {context.welcomeTitle}
                </div>
                <div
                  className={classNames(
                    'welcome-subtitle',
                    welcomeTitleAlignment,
                  )}
                  style={{ color: context.welcomeFontColor }}
                >
                  {context.welcomeSubtitle}
                </div>
              </div>
              <Col xalign='center' className={'mt-12'}>
                <div className={'mt-4'}>
                  <button
                    className='btn truncate'
                    style={{ backgroundColor: context.primaryColor }}
                    onClick={() => {}}
                  >
                    Connect Wallet
                  </button>
                </div>
                <div
                  className={'mt-5'}
                  style={{ color: context.welcomeFontColor }}
                >
                  <TokensoftPoweredByIcon />
                </div>
              </Col>
            </div>
            <Row gap={4} place='center' className={'mt-5'}>
              <div style={{ color: context.welcomeFontColor }}>
                <Globe />
              </div>
              <div style={{ color: context.welcomeFontColor }}>
                <Twitter />
              </div>
              <div style={{ color: context.welcomeFontColor }}>
                <Telegram />
              </div>
              <div style={{ color: context.welcomeFontColor }}>
                <Discord />
              </div>
            </Row>
            <div
              className='absolute h-2 bottom-0 left-0 right-0 z-50'
              style={{ backgroundColor: context.primaryColor }}
            ></div>
          </div>
        </Card>
        <ButtonRow>
          <Button
            className={'w-full'}
            fill={'outline'}
            onClick={() => closeModal()}
          >
            Continue Editing
          </Button>
          <Button className={'w-full'} onClick={() => onContinueHandler()}>
            Approve & Continue
          </Button>
        </ButtonRow>
      </Stacked>
    )
  )
}
