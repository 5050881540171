import BigNumber from 'bignumber.js'

export class CurrencyRegistry {
  private pairs: Map<string, CurrencyPair> = new Map()
  private graph: Map<
    CurrencySymbol,
    { target: CurrencySymbol; rate: BigNumber }[]
  > = new Map()

  /**
   * Generates a unique key for a currency pair.
   */
  private getKey(base: CurrencySymbol, quote: CurrencySymbol): string {
    return `${base}-${quote}`
  }

  /**
   * Reverses the rate for a currency pair.
   * @param rate - The original exchange rate.
   * @returns The inverse of the original rate.
   */
  private reverseRate(rate: BigNumber): BigNumber {
    return new BigNumber(1).dividedBy(rate)
  }

  /**
   * Registers a currency pair for conversion.
   * Updates both the pairs map and the graph for traversal.
   * @param pair - The currency pair to register, including base, quote, and rate.
   */
  registerPair(pair: CurrencyPair): void {
    const key = this.getKey(pair.base, pair.quote)
    this.pairs.set(key, pair)

    // Update the graph
    if (!this.graph.has(pair.base)) this.graph.set(pair.base, [])
    if (!this.graph.has(pair.quote)) this.graph.set(pair.quote, [])

    // Add forward and reverse edges to the graph
    this.graph.get(pair.base)!.push({ target: pair.quote, rate: pair.rate })
    const inverse = {
      target: pair.base,
      rate: this.reverseRate(pair.rate),
    }

    this.graph.get(pair.quote)!.push(inverse)
  }

  /**
   * Converts an amount from one currency to another.
   * Tries direct pair first, then reverse pair, and finally falls back to graph-based lookup.
   * @param amount - The amount to convert, as a CurrencyValue.
   * @param targetSymbol - The symbol of the target currency.
   * @returns The converted amount as a CurrencyValue.
   * @throws If no conversion path is found.
   */
  convert(amount: CurrencyValue, targetSymbol: CurrencySymbol): CurrencyValue {
    if (amount.symbol === targetSymbol) {
      // No conversion needed
      return { ...amount }
    }

    // Try direct pair
    const directKey = this.getKey(amount.symbol, targetSymbol)
    const directPair = this.pairs.get(directKey)
    if (directPair) {
      return this.applyRate(amount, directPair.rate, targetSymbol, 'forward')
    }

    // Try reverse pair
    const reverseKey = this.getKey(targetSymbol, amount.symbol)
    const reversePair = this.pairs.get(reverseKey)
    if (reversePair) {
      return this.applyRate(amount, reversePair.rate, targetSymbol, 'reverse')
    }

    // Fallback to graph-based pathfinding
    return this.convertAny(amount, targetSymbol)
  }

  /**
   * Retrieves a currency pair based on base and quote symbols.
   * @param base - The base currency symbol.
   * @param quote - The quote currency symbol.
   * @returns The corresponding CurrencyPair or undefined if not found.
   */
  getPair(
    base: CurrencySymbol,
    quote: CurrencySymbol,
  ): CurrencyPair | undefined {
    const key = this.getKey(base, quote)
    return this.pairs.get(key)
  }

  /**
   * Retrieves all currency pairs that involve the specified symbol.
   * @param symbol - The currency symbol to filter by.
   * @returns An array of CurrencyPair objects.
   */
  getPairsForSymbol(symbol: CurrencySymbol): CurrencyPair[] {
    return Array.from(this.pairs.values()).filter(
      (pair) => pair.base === symbol || pair.quote === symbol,
    )
  }

  /**
   * Retrieves all registered currency pairs.
   * @returns An array of all CurrencyPair objects.
   */
  getAllPairs(): CurrencyPair[] {
    return Array.from(this.pairs.values())
  }

  /**
   * Checks if a currency pair exists in the registry, regardless of direction.
   * @param pair - The currency pair to check (excluding rate).
   * @returns True if the pair exists, false otherwise.
   */
  hasPair(pair: Omit<CurrencyPair, 'rate'>): boolean {
    const key = this.getKey(pair.base, pair.quote)
    const reverseKey = this.getKey(pair.quote, pair.base)

    return this.pairs.has(key) || this.pairs.has(reverseKey)
  }

  /**
   * Checks if a currency pair exists at a specific rate.
   * @param pair - The currency pair with the rate to check.
   * @returns True if the pair exists at the specified rate, false otherwise.
   */
  hasPairAtRate(pair: CurrencyPair): boolean {
    const key = this.getKey(pair.base, pair.quote)
    const foundPair = this.pairs.get(key)
    if (!foundPair) return false

    return foundPair.rate.isEqualTo(pair.rate)
  }

  /**
   * Clears all registered currency pairs and graph data.
   */
  clear(): void {
    this.pairs.clear()
    this.graph.clear()
  }

  /**
   * Converts an amount using a graph-based search to find any path between currencies.
   * @param amount - The amount to convert, as a CurrencyValue.
   * @param targetSymbol - The target currency symbol.
   * @returns The converted amount as a CurrencyValue.
   * @throws If no path exists between the currencies.
   */
  private convertAny(
    amount: CurrencyValue,
    targetSymbol: CurrencySymbol,
  ): CurrencyValue {
    const visited = new Set<CurrencySymbol>()
    const queue: Array<{
      currency: CurrencySymbol
      rate: BigNumber
      path: CurrencySymbol[]
    }> = [
      {
        currency: amount.symbol,
        rate: new BigNumber(1),
        path: [amount.symbol],
      },
    ]

    while (queue.length > 0) {
      const { currency, rate, path } = queue.shift()!

      if (currency === targetSymbol) {
        // Apply the cumulative rate to the amount
        const convertedValue = rate.multipliedBy(amount.value)
        const final = {
          symbol: targetSymbol,
          value: convertedValue,
        }

        return final
      }

      if (visited.has(currency)) continue
      visited.add(currency)

      const neighbors = this.graph.get(currency) || []
      for (const neighbor of neighbors) {
        // Ensure we're not creating a cycle
        if (path.includes(neighbor.target)) continue

        // Calculate the new cumulative rate
        const newRate = rate.multipliedBy(neighbor.rate)

        queue.push({
          currency: neighbor.target,
          rate: newRate,
          path: [...path, neighbor.target],
        })
      }
    }

    throw new Error(
      `No conversion path found from ${amount.symbol} to ${targetSymbol}`,
    )
  }

  /**
   * Applies the exchange rate to convert an amount between currencies.
   * @param amount - The amount to convert, as a CurrencyValue.
   * @param rate - The exchange rate as a BigNumber.
   * @param targetSymbol - The symbol of the target currency.
   * @param direction - 'forward' for base -> quote, 'reverse' for quote -> base.
   * @returns The converted amount as a CurrencyValue.
   */
  private applyRate(
    amount: CurrencyValue,
    rate: BigNumber,
    targetSymbol: CurrencySymbol,
    direction: 'forward' | 'reverse',
  ): CurrencyValue {
    let result: BigNumber

    if (direction === 'forward') {
      result = amount.value.multipliedBy(rate)
    } else {
      result = amount.value.dividedBy(rate)
    }

    return { symbol: targetSymbol, value: result }
  }
}
