import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { EventDocument } from 'tokensoft-shared-types'

export const useGetEventDocuments = (eventId: ID) => {
  const client = useWebApiClient()

  return useQuery<EventDocument[]>({
    queryKey: ['event', eventId, 'documents'],
    queryFn: async () => {
      const documentsRaw = await client(`events/${eventId}/documents`)
      return documentsRaw
    },
  })
}
