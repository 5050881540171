import { useTSWagmi } from '@contexts'
import { useTSAddress } from '@hooks'
import { usePrivy } from '@privy-io/react-auth'
import { simulateContract, writeContract } from '@wagmi/core'
import { Abi, Address, encodeFunctionData, Hex } from 'viem'
import { useAccount } from 'wagmi'

type ContractWriteParams = {
  address: Address
  abi: Abi
  functionName: string
  args: unknown[]
  value?: bigint
}

type ContractWriteResponse = Hex

export const useContractWrite = (): {
  executeContractWrite: (
    params: ContractWriteParams,
  ) => Promise<ContractWriteResponse>
} => {
  const { sendTransaction } = usePrivy()
  // TODO: Will need to update chain similarly to useTSAddress
  const { wagmiConfig } = useTSWagmi()
  const { type } = useTSAddress()
  const { chain } = useAccount()

  const executeContractWrite = async ({
    address,
    abi,
    functionName,
    args,
    value = BigInt(0),
  }: ContractWriteParams) => {
    let writeContractResponse: any
    if (type === 'email') {
      const { transactionHash } = await sendTransaction({
        to: address,
        data: encodeFunctionData({
          abi,
          functionName,
          args,
        }),
        value,
        chainId: chain?.id,
      })
      writeContractResponse = transactionHash
    } else {
      const { request } = await simulateContract(wagmiConfig, {
        address,
        abi,
        functionName,
        chainId: chain?.id,
        args,
        value,
      })
      writeContractResponse = await writeContract(wagmiConfig, request)
    }

    return writeContractResponse as Hex
  }

  return { executeContractWrite }
}
