import { useNetworks } from '@contexts'
import { getFlatPriceSaleFactoryABI } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Hex, TransactionReceipt } from 'viem'
import {
  useAccount as useWagmiAccount,
  useWaitForTransactionReceipt,
} from 'wagmi'

export const useDeploySale = () => {
  const { chain } = useWagmiAccount()
  const { supportedNetworks } = useNetworks()
  const { executeContractWrite } = useContractWrite()
  const [transactionHash, setTransactionHash] = useState<Maybe<Hex>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const network = supportedNetworks?.find((n) => n.id === chain?.id)
  const factoryAddress = network?.saleFactoryAddress
  const factoryV3Address = network?.saleFactoryV3Address
  const factoryV4Address = network?.saleFactoryV4Address
  const factoryVersion = factoryV4Address
    ? 'v4.0'
    : factoryV3Address
      ? 'v3.0'
      : 'v2.x'

  // TODO: improve typing here
  const write = async (args: unknown[]) => {
    setSubmitting(true)
    let updatedArgsForSaleVersion = [...args]

    // v2 has unique arguments; v3 and v4 have the same
    if (factoryVersion === 'v2.x') {
      updatedArgsForSaleVersion = [
        ...args.slice(0, 5),
        ...args.slice(6, 8),
        ...args.slice(9),
      ]
    }

    const chosenFactoryAddress =
      factoryV4Address ?? factoryV3Address ?? factoryAddress
    if (!chosenFactoryAddress) throw new Error('No factory address found')

    try {
      const writeContractResponse = await executeContractWrite({
        address: chosenFactoryAddress,
        abi: getFlatPriceSaleFactoryABI(factoryVersion) as Abi,
        functionName: 'newSale',
        args: updatedArgsForSaleVersion,
      })
      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (error) {
      console.error({ error })
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash || undefined,
  })

  return {
    write,
    isSubmitting: submitting,
    response: { ...waitForTransactionResponse },
    data: waitForTransactionResponse?.data || ({} as TransactionReceipt),
    error: waitForTransactionResponse?.error || ({} as Error),
  }
}
