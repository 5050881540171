import { useWebApiClient } from '@apiClients'
import { useGetOraclePrice } from '@apiServices'
import { useNetworks } from '@contexts'
import { DistributorClaimSignature } from '@custom-types/claim-signature'
import { Account, Tranche } from '@customTypes'
import {
  add,
  div,
  exp,
  fix,
  getCrosschainDistributorABI,
  mult,
  sub,
  toBigNumber,
  unixToLocalDateTime,
  utcToLocalDateTime,
} from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import BigNumber from 'bignumber.js'
import { useEffect, useState } from 'react'
import { Distributor, TSEvent } from 'tokensoft-shared-types'
import { Abi, ContractFunctionArgs, getAddress } from 'viem'
import { useReadContract, useWaitForTransactionReceipt } from 'wagmi'
import {
  getAdvancedDistributorABI,
  getClaimABI,
  getExperimentalClaimABI,
} from './abi'

const NATIVE_TOKEN_DECIMALS = 18

export const useSubmitClaim = (options: {
  event: TSEvent
  distributor: Distributor
}) => {
  const { event } = options
  const [transactionHash, setTransactionHash] =
    useState<Maybe<EvmAddress>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const client = useWebApiClient()
  const { supportedNetworks } = useNetworks()
  const { mutate: getOraclePrice } = useGetOraclePrice()
  const { executeContractWrite } = useContractWrite()

  const write = async (
    chainId: number,
    distributorAddress: EvmAddress,
    // [proofIndex, beneficiary, amount, merkleProof]
    args: [number, EvmAddress, BigNumber, SHA256Hash[]],
  ): Promise<EvmAddress> => {
    setSubmitting(true)

    let contractArgs: ContractFunctionArgs = args

    try {
      let value: BigNumber | undefined

      if (event.useExperimentalContractFeatures) {
        // TODO: support custom fee amounts per-distributor
        const distributorFeeInCents = 0
        const network = supportedNetworks?.find((n) => n.id === chainId)

        if (network === undefined) {
          throw new Error(`unsupported network "${chainId}"`)
        }

        const response = (await client(
          `distributors/${distributorAddress}/claim-signature`,
        )) as DistributorClaimSignature

        const result = await new Promise<{ price: string }>(
          (resolve, reject) => {
            getOraclePrice(
              {
                networkId: chainId,
                oracleAddress: network.nativePriceOracleAddress,
              },
              { onSuccess: resolve, onError: reject },
            )
          },
        )

        const nativeOraclePrice = result.price

        const fee = mult(distributorFeeInCents, Math.pow(10, 6))

        if (fee !== '0') {
          value = toBigNumber(
            fix(
              mult(
                div(add(fee, '1'), nativeOraclePrice),
                exp(10, NATIVE_TOKEN_DECIMALS),
              ),
              '0',
            ),
          )
        }

        contractArgs = [
          response.beneficiaryWalletAddress,
          response.totalAmount,
          response.encodedVestingSchedule,
          response.expiresAt,
          response.signature,
          network.platformFeeRecipient,
          fee,
        ]
      }

      const writeContractResponse = await executeContractWrite({
        address: distributorAddress,
        abi: event.useExperimentalContractFeatures
          ? (getExperimentalClaimABI() as Abi)
          : (getClaimABI() as Abi),
        functionName: 'claim',
        args: contractArgs as unknown[],
        value: value ? BigInt(value.toString()) : undefined,
      })

      setTransactionHash(writeContractResponse)
      console.log('claim transaction response', writeContractResponse)
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash ?? undefined,
    query: {
      enabled: !!transactionHash,
    },
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}

export const getTotalUserClaimable = (
  distributor: any,
  walletAddress: string,
): number => {
  if (!distributor || !walletAddress) {
    return 0
  }

  if (distributor?.authorization) {
    const authorization = distributor.authorization
    const amountObj = authorization?.data?.find((d: any) => d.name === 'amount')
    if (amountObj) {
      return Number(amountObj.value)
    }
  }

  // If not, use merkle root amount
  if (distributor.merkleProof && distributor.proofAmount) {
    return Number(distributor.proofAmount)
  }

  return 0
}

export const useNativeOraclePrice = (networkId: any) => {
  const { getNetworkDetails } = useNetworks()
  const { mutate: getOraclePrice } = useGetOraclePrice()
  const [nativeOraclePrice, setNativeOraclePrice] = useState(0)

  useEffect(() => {
    getOraclePrice(
      {
        networkId,
        oracleAddress: getNetworkDetails(networkId).nativePriceOracleAddress,
      },
      {
        onSuccess: (result) => {
          setNativeOraclePrice(result.price)
        },
        onError: (error) => {
          console.error('error:', error)
        },
      },
    )
  }, [])

  return { nativeOraclePrice }
}

export const getVestedFractionForContinuous = (
  start: number,
  cliff: number,
  end: number,
  fractionDenominator: BigNumber,
): BigNumber => {
  const d = new Date()
  // Seconds since epoch
  const time = d.getTime() / 1000

  // no tokens are vested
  if (time <= cliff) {
    return toBigNumber(0)
  }

  // all tokens are vested
  if (time >= end) {
    return fractionDenominator
  }

  // some tokens are vested
  return toBigNumber(
    div(mult(fractionDenominator, sub(time, start)), sub(end, start)),
  )
}

export const getVestedFractionForTranche = (tranches: Tranche[]): BigNumber => {
  const sortedTranches = tranches.sort(function (a, b) {
    return Number(a.index) - Number(b.index)
  })

  const d = new Date()
  // Seconds since epoch
  const time = d.getTime() / 1000
  let largestBips = toBigNumber(0)

  for (let i = 0; i < sortedTranches.length; i++) {
    if (time > sortedTranches[i].time) {
      largestBips = toBigNumber(sortedTranches[i].vestedFraction)
    }
  }

  return largestBips
}

export const getVestedFractionForPriceTier = (
  tierVesting: any[],
  oraclePrice: number,
): BigNumber => {
  if (!tierVesting || !tierVesting.length || !oraclePrice) {
    return toBigNumber(0)
  }
  for (let i = tierVesting.length - 1; i != 0; i--) {
    if (oraclePrice > Number(tierVesting[i].price)) {
      return toBigNumber(tierVesting[i].vestedFraction)
    }
  }
  return toBigNumber(0)
}

export const getClaimsStartDate = (distributor: any, account?: Account) => {
  // if tranche
  if (distributor.trancheVesting && distributor.trancheVesting.length) {
    return unixToLocalDateTime(
      distributor.trancheVesting[0].time,
      account?.timezone,
    )
  }

  // if continuous
  if (distributor.continuousVesting) {
    return unixToLocalDateTime(
      distributor.continuousVesting.start,
      account?.timezone,
    )
  }

  // if sale
  if (distributor.sale?.startTime) {
    return unixToLocalDateTime(distributor.sale.startTime, account?.timezone)
  }

  // fallback to event
  return distributor.startTime ? utcToLocalDateTime(distributor.startTime) : '-'
}

export const useFairDelayTime = (
  distributorId?: any,
  chaindId?: number,
  walletAddress?: string,
) => {
  let delay: Maybe<number> = null

  const config = {
    address: distributorId ? getAddress(distributorId) : null,
    abi: getAdvancedDistributorABI(),
    functionName: 'getFairDelayTime',
    chainId: chaindId ? chaindId : null,
    args: [walletAddress],
  } as any

  const { data, isSuccess, isLoading } = useReadContract(config)

  if (data) {
    delay = Number(data)

    if (delay === 0) {
      delay = null
    }
  }

  return {
    delay,
    isSuccess,
    isLoading,
  }
}

export const useGetTranches = (distributorId?: any, chaindId?: number) => {
  const config = {
    address: distributorId ? getAddress(distributorId) : null,
    abi: getCrosschainDistributorABI(),
    functionName: 'getTranches',
    chainId: chaindId ? chaindId : null,
  } as any

  const { data, isSuccess, isLoading } = useReadContract(config)
  const tranches = data || []

  return {
    tranches,
    isSuccess,
    isLoading,
  }
}
