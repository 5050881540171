import { useCreateProject } from '@apiServices'
import {
  GlobalRestriction,
  KycRestriction,
  RegionRestriction,
  RegisterProjectPage,
  RegisterUserPage,
  WalletAddressRestriction,
  WhitelabelRestriction,
} from '@components'
import { useAccount, useToast } from '@contexts'
import { FEATURE, FEATURE_RESTRICTION } from '@enums'
import { useFeature, useHideSidebar } from '@hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface UserInfo {
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
}

export const RegisterPage = () => {
  const { showErrorToast } = useToast()
  const { getFeatureRestriction } = useFeature()
  const { account } = useAccount()
  const navigate = useNavigate()
  const { isPending, mutate: createProject } = useCreateProject()
  const restriction = getFeatureRestriction(FEATURE.REGISTRATION)

  useHideSidebar()

  const [step, setStep] = useState('project')
  const [projectName, setProjectName] = useState<Maybe<any>>(null)
  const [userRole, setUserRole] = useState({
    label: 'Admin',
    value: 'admin',
  })
  const [userInfo, setUserInfo] = useState<UserInfo>({
    firstName: undefined,
    lastName: undefined,
    email: undefined,
    phoneNumber: undefined,
  })

  useEffect(() => {
    if (account) {
      setUserInfo({
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        phoneNumber: account.phoneNumber,
      })
    }
  }, [account])

  const handleUserInfoChange = (e: any) => {
    setUserInfo((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }))
  }

  const handleProjectNameChange = (e: any) => setProjectName(e.target.value)

  const handleProjectSubmit = () => {
    setStep('user')
  }

  const handleUserBack = () => {
    setStep('project')
  }

  const handleUserSubmit = () => {
    createProject(
      {
        project: {
          name: projectName,
          ownerCompanyRole: userRole.value,
        },
        account: {
          ...userInfo,
          id: account.id,
        },
      },
      {
        onSuccess: (data: { id: number }) =>
          navigate(`/admin/project/${data.id}`),
        onError: (error) => showErrorToast({ description: error.message }),
      },
    )
  }

  if (restriction) {
    switch (restriction) {
      case FEATURE_RESTRICTION.GLOBAL_RESTRICTION:
        return <GlobalRestriction />
      case FEATURE_RESTRICTION.WHITELABEL_RESTRICTION:
        return <WhitelabelRestriction />
      case FEATURE_RESTRICTION.KYC_RESTRICTION:
        return <KycRestriction />
      case FEATURE_RESTRICTION.REGION_RESTRICTION:
        return <RegionRestriction />
      case FEATURE_RESTRICTION.WALLET_ADDRESS_RESTRICTION:
        return <WalletAddressRestriction />
    }
  }

  return (
    <div className='pt-8'>
      <div className={'w-full'}>
        {step === 'project' ? (
          <RegisterProjectPage
            projectName={projectName}
            onProjectNameChange={handleProjectNameChange}
            onSubmit={handleProjectSubmit}
          />
        ) : (
          <RegisterUserPage
            onBack={handleUserBack}
            onSubmit={handleUserSubmit}
            userInfo={userInfo}
            onUserInfoChange={handleUserInfoChange}
            userRole={userRole}
            onUserRoleChange={setUserRole}
            projectCreationPending={isPending}
          />
        )}
      </div>
    </div>
  )
}
