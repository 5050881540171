import { hydrateEventDates } from '../../../util'

export class EventTimeline
  implements EventTimelineValues<Date>, EventTimelineUtilities
{
  registrationStartTime: Date
  registrationEndTime: Maybe<Date>
  startTime: Maybe<Date>
  endTime: Maybe<Date>

  constructor(values: EventTimelineValues<ISO8601String>) {
    const hydrated: EventTimelineValues<Date> = hydrateEventDates(values)
    this.registrationStartTime = hydrated.registrationStartTime
    this.registrationEndTime = hydrated.registrationEndTime
    this.startTime = hydrated.startTime
    this.endTime = hydrated.endTime
  }

  isRegistrationUpcoming(): boolean {
    return new Date() < this.registrationStartTime
  }

  isRegistrationOpen(): Maybe<boolean> {
    const isAfterRegistrationStart = new Date() > this.registrationStartTime
    if (!isAfterRegistrationStart) return false

    const registrationDoesNotEnd = this.registrationEndTime === null
    if (registrationDoesNotEnd) return true

    const now = new Date()
    const isBeforeRegistrationEndTime = now <= this.registrationEndTime!
    return isBeforeRegistrationEndTime
  }

  isRegistrationPast(): Maybe<boolean> {
    return (
      this.isRegistrationUpcoming() === false &&
      this.isRegistrationOpen() === false
    )
  }

  isEventUpcoming(): Maybe<boolean> {
    const isEventStartTimeSet = this.startTime !== null
    if (!isEventStartTimeSet) return null

    const isEventStartTimeInFuture = new Date() < this.startTime!
    return isEventStartTimeInFuture
  }

  isEventOpen(): Maybe<boolean> {
    const isEventStartTimeSet = this.startTime !== null
    if (!isEventStartTimeSet) return null

    const isBeforeEventStart = new Date() <= this.startTime!
    if (isBeforeEventStart) return false

    const eventDoesNotClose = this.endTime === null
    if (eventDoesNotClose) return true

    const isBeforeEventEnd = new Date() <= this.endTime!
    return isBeforeEventEnd
  }

  isEventPast(): Maybe<boolean> {
    const isEventStartTimeSet = this.startTime !== null
    const isEventEndTimeSet = this.endTime !== null

    if (!isEventStartTimeSet || !isEventEndTimeSet) return null

    return this.isEventUpcoming() === false && this.isEventOpen() === false
  }

  private getRegistrationStatus(): Maybe<string> {
    if (this.isRegistrationUpcoming()) return 'Upcoming'
    if (this.isRegistrationOpen()) return 'Registration Open'
    if (this.isRegistrationPast()) return 'Registration Closed'
    return null
  }

  private getEventStatus(): Maybe<string> {
    if (this.isEventUpcoming()) return 'Upcoming'
    if (this.isEventOpen()) return 'Live'
    if (this.isEventPast()) return 'Closed'
    return null
  }

  getRegistrationAndEventStatuses(): string[] {
    const eventStatus = this.getEventStatus()

    // If event closed, no point in showing registration status
    if (eventStatus === 'Closed') {
      return [eventStatus]
    }

    const registrationStatus = this.getRegistrationStatus()

    if (registrationStatus === 'Upcoming') {
      return [registrationStatus]
    }

    return [eventStatus, registrationStatus].filter(Boolean) as string[]
  }
}

export interface EventTimelineValues<T extends Date | ISO8601String> {
  /**
   * Event startTime. For registration events, this value is equal to
   * `registrationStartTime`. For non-registration events, this value is
   * the start time of the event itself, not the registration period (ie
   * the sale or distribution start time).
   */
  startTime: Maybe<T>
  /**
   * Event startTime. For registration events, this value is equal to
   * `registrationStartTime`. For non-registration events, this value is
   * the start time of the event itself, not the registration period (ie
   * the sale or distribution start time).
   */
  endTime: Maybe<T>
  /**
   * Registration start time. This is the time at which the event
   * registration period opens. For registration events, this value
   * is equal to `startTime`. For non-registration events, this value is
   * the start time of the registration period, not the event itself.
   */
  registrationStartTime: T
  /**
   * Registration end time. This is the time at which the event
   * registration period closes. For registration events, this value
   * is equal to `endTime`. For non-registration events, this value is
   * the end time of the registration period, not the event itself. If
   * `null`, the registration period is open indefinitely.
   */
  registrationEndTime: Maybe<T>
}

export interface EventTimelineUtilities {
  isRegistrationUpcoming: () => boolean
  isRegistrationOpen: () => Maybe<boolean>
  isRegistrationPast: () => Maybe<boolean>
  isEventUpcoming: () => Maybe<boolean>
  isEventOpen: () => Maybe<boolean>
  isEventPast: () => Maybe<boolean>
}
