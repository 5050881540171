import { usePublicApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { ProjectWelcomeContent } from 'tokensoft-shared-types'

export const useGetProjectWelcomeContent = () => {
  const fetchWithClient = usePublicApiClient()

  return useQuery<ProjectWelcomeContent>({
    queryKey: ['project/welcome-content'],
    queryFn: async () => {
      return fetchWithClient('project/welcome-content')
    },
  })
}
