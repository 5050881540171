import {
  convertApiKycRestrictionTypeToKycRestrictionType,
  convertLegacyEventTypeToEventType,
} from '../../../util'
import { EventContent } from './event-content'
import { EventTimeline } from './event-timeline'

// Prefixed with TS just because "Event" is too common
export class TSEvent implements IDRecord {
  id: ID
  name: string
  description: Maybe<string>
  longDescription: Maybe<HtmlString>
  kycRestriction: KycRestrictionType
  isPolkadotWalletRequired: boolean
  disclaimerText: Maybe<HtmlString>
  scheduleDescription: Maybe<HtmlString>
  overviewImageUrl: Maybe<Url | IpfsUri>
  iconSmallUrl: Maybe<Url | IpfsUri>
  iconLargeUrl: Maybe<Url | IpfsUri>

  content: EventContent

  type: EventType
  chainId: Maybe<ChainId>

  timeline: EventTimeline
  isPrivateEvent: boolean
  createdAt: Date
  updatedAt: Date

  distributorAddress: Maybe<EvmAddress>
  saleAddress: Maybe<EvmAddress>

  useExperimentalContractFeatures: boolean
  useCustomBeneficiary: boolean

  constructor(rawEvent: any) {
    this.id = rawEvent.id
    this.name = rawEvent.name
    this.description = rawEvent.description
    this.longDescription = rawEvent.longDescription
    this.kycRestriction = convertApiKycRestrictionTypeToKycRestrictionType(
      rawEvent.kycRestriction,
    )
    this.isPolkadotWalletRequired = rawEvent.isPolkadotWalletRequired
    this.disclaimerText = rawEvent.disclaimerText
    this.scheduleDescription = rawEvent.scheduleDescription
    this.overviewImageUrl = rawEvent.overviewImageUrl
    this.iconSmallUrl = rawEvent.iconSmallUrl
    this.iconLargeUrl = rawEvent.iconLargeUrl

    this.content = {
      header: {
        backgroundColor: rawEvent.contentHeaderBackgroundColor,
        backgroundImage: rawEvent.contentHeaderBackgroundImage,
        fontColor: rawEvent.contentHeaderFontColor,
        image: rawEvent.contentHeaderImage,
        title: rawEvent.contentHeaderTitle,
        subtitle: rawEvent.contentHeaderSubtitle,
      },
      body: {
        header: rawEvent.contentBodyHeader,
        upcoming: rawEvent.contentBodyUpcoming,
        open: rawEvent.contentBodyOpen,
        closed: rawEvent.contentBodyClosed,
      },
    }

    this.type = convertLegacyEventTypeToEventType(rawEvent.eventType)
    this.chainId = rawEvent.chainId

    this.timeline = new EventTimeline(rawEvent)

    /**
     * Identity events are a bit awkward because they are stored in the `event` table, but
     * that table itself has both `registration_(start|end)_time` and `(start|end)_time` fields.
     * Someone prior decided to just set `(start|end)_time` equal to `(registration_(start|end)_time`,
     * but this means event timelines show up with two dates when the event type is `identity`. As a
     * quick fix, we just set these to `null` for identity events.
     */
    if (this.type === 'identity') {
      this.timeline.startTime = null
      this.timeline.endTime = null
    }

    this.isPrivateEvent = rawEvent.isPrivateEvent
    this.createdAt = new Date(rawEvent.createdAt)
    this.updatedAt = new Date(rawEvent.updatedAt)

    this.distributorAddress = rawEvent.distributorAddress
    this.saleAddress = rawEvent.saleAddress

    this.useExperimentalContractFeatures =
      rawEvent.useExperimentalContractFeatures

    this.useCustomBeneficiary = rawEvent.useCustomBeneficiary
  }

  getCurrentBodyContent(): Maybe<HtmlString> {
    if (this.timeline.isEventPast()) {
      return this.content.body.closed
    }

    if (this.timeline.isEventOpen()) {
      return this.content.body.open
    }

    return this.content.body.upcoming
  }
}
