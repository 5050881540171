import { IconProps } from '@components'

export const InProgressIcon = ({ color, className, size = 20 }: IconProps) => {
  return (
    <div className={className}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 20 20'
        fill='none'
        stroke={color || '#101828'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='10' cy='10' r='9' stroke={color || '#101828'} />
        <path
          d='M10 1 A9 9 0 0 1 18.09 12.5 L10 10 Z'
          fill={color || '#101828'}
        />
      </svg>
    </div>
  )
}
