import { Text } from '@components'
import classNames from 'classnames'

export type TableDataProps = {
  className?: string
  children: React.ReactNode
} & React.ComponentProps<typeof Text>

export const TableData: React.FC<TableDataProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      className={classNames('text-neutral-medium', className)}
      {...restProps}
    >
      {children}
    </Text>
  )
}
