import { usePublicApiClient } from '@apiClients'
import { ONE_MINUTE } from '@constants'
import { useQuery } from '@tanstack/react-query'
import { hydrateISO8601String, Project } from 'tokensoft-shared-types'

export const useGetProject = () => {
  const fetchWithClient = usePublicApiClient()

  return useQuery<Project>({
    queryKey: ['project'],
    queryFn: async () => {
      const project = await fetchWithClient('project')

      project.createdAt = hydrateISO8601String(project.createdAt)
      project.updatedAt = hydrateISO8601String(project.updatedAt)

      return project
    },
    staleTime: 5 * ONE_MINUTE,
  })
}
