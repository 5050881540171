import { Button, Text } from '@components'
import classNames from 'classnames'
import { useWizard } from 'react-use-wizard'

type BackButtonProps = {
  disabled?: boolean
  label?: string
  icon?: React.ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
} & React.ComponentProps<typeof Button>

export const BackButton: React.FC<BackButtonProps> = ({
  disabled = false,
  label = 'Back',
  icon = <></>,
  className = '',
  onClick,
  ...restProps
}) => {
  const { previousStep, isLoading } = useWizard()

  return (
    <Button
      fill={'outline'}
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => (onClick ? onClick(e) : previousStep())}
      disabled={disabled || isLoading}
      {...restProps}
    >
      {icon}
      <Text>{label}</Text>
    </Button>
  )
}
