import { Text } from '@components'
import classNames from 'classnames'

export type WizardBreadcrumbProps = {
  className?: string
  children: React.ReactNode
} & React.ComponentProps<typeof Text>

export const WizardBreadcrumb: React.FC<WizardBreadcrumbProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames(
        'wizard-breadcrumb text-sm leading-5 text-neutral-extra-dark uppercase',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  )
}
