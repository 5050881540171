import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import BigNumber from 'bignumber.js'
import {
  ONE_HOUR_IN_MS,
  ONE_MINUTE_IN_MS,
  PurchaseSignature,
  PurchaseSignatureRaw,
} from 'tokensoft-shared-types'

export const useGetPurchaseSignature = (saleAddress: EvmAddress) => {
  const client = useWebApiClient()

  return useQuery<PurchaseSignature>({
    queryKey: ['sales', saleAddress, 'purchase-signature'],
    queryFn: async () => {
      const response: PurchaseSignatureRaw = await client(
        `sales/${saleAddress}/purchase-signature`,
      )
      const purchaseSignature: PurchaseSignature = {
        userLimit: new BigNumber(response.userLimit),
        expiresAt: new Date(response.expiresAt),
        signature: response.signature,
      }
      return purchaseSignature
    },
    // Purchase signatures expire after 1 hour; expire cache after 58 minutes
    // and refetch after 59 minutes
    staleTime: 1 * ONE_HOUR_IN_MS - 2 * ONE_MINUTE_IN_MS,
    refetchInterval: 1 * ONE_HOUR_IN_MS - ONE_MINUTE_IN_MS,
  })
}
