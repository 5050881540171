import { FC, ReactNode } from 'react'
import { Link } from 'react-router-dom'

type RouteLinkProps = {
  useRoute?: boolean
  url: string
  children?: ReactNode
}

export const RouteLink: FC<RouteLinkProps> = ({
  useRoute = true,
  url = '/',
  children,
}) => {
  if (useRoute) {
    return (
      <Link className='ai-icon' to={url} aria-expanded='false'>
        {children}
      </Link>
    )
  }

  return (
    <a className='ai-icon' href={url} aria-expanded='false'>
      {children}
    </a>
  )
}
