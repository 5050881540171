import { useMemo, useState } from 'react'
import { CurrencyRegistry } from 'tokensoft-shared-types'
import { CurrencyRegistryContext } from './currency-registry-context'

export const CurrencyRegistryProvider = ({
  children,
}: {
  children: JSX.Element
}): JSX.Element => {
  const [currencyRegistry] = useState(() => new CurrencyRegistry())

  const value = useMemo(
    () => ({
      convert: currencyRegistry.convert.bind(currencyRegistry),
      registerPair: currencyRegistry.registerPair.bind(currencyRegistry),
    }),
    [currencyRegistry],
  )

  return (
    <CurrencyRegistryContext.Provider value={value}>
      {children}
    </CurrencyRegistryContext.Provider>
  )
}
