import { Text } from '@components'
import classNames from 'classnames'

type TextProps = React.ComponentProps<typeof Text>

interface PageTitleProps extends TextProps {
  className?: string
  children: React.ReactNode
}

export const PageTitle: React.FC<PageTitleProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames(
        'page-title text-3xl font-bold high-contrast',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  )
}
