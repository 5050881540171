import { getResourceUri } from '@utils'
import { useEffect } from 'react'

export const useFavicon = (faviconUrl: Maybe<Url | IpfsUri>) => {
  useEffect(() => {
    if (faviconUrl === null) {
      return
    }

    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')

    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'

    const url = faviconUrl.startsWith('ipfs://')
      ? getResourceUri(faviconUrl)
      : faviconUrl

    link.href = url

    document.getElementsByTagName('head')[0].appendChild(link)

    // Optional: Cleanup in case the favicon is changed multiple times
    return () => {
      if (link.parentNode) {
        link.parentNode.removeChild(link)
      }
    }
  }, [faviconUrl])
}
