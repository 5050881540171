import { User } from '@customTypes'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

/**
 * A hook to set or unset Sentry tags based on the user object.
 * @param user - The user object containing walletAddress and authId.
 */
export const useSentryContext = (user: Maybe<User>) => {
  useEffect(() => {
    if (
      import.meta.env.VITE_SENTRY_DSN &&
      import.meta.env.VITE_PROCESS_ENV !== 'development'
    ) {
      if (user) {
        // Set Sentry tags when the user is present
        Sentry.setTag('walletAddress', user.walletAddress)
        Sentry.setTag('authId', user.authId)
      } else {
        // Unset Sentry tags when the user is null
        Sentry.setTag('walletAddress', '')
        Sentry.setTag('authId', '')
      }
    }
  }, [user])
}
