import { Spinner } from '@components'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface LoaderButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading: boolean
  loadingText: string
  text: string
}

export const LoaderButton = ({
  isLoading,
  className,
  text,
  loadingText,
  disabled,
  ...buttonProps
}: LoaderButtonProps) => {
  return (
    <button
      {...buttonProps}
      disabled={disabled || isLoading}
      className={twMerge(
        `px-4 py-3 rounded-md disabled:opacity-40 text-white btn 
        btn-primary font-semibold transition-colors duration-300 
        ease-in-out flex items-center justify-center`,
        className,
      )}
    >
      {isLoading && <Spinner />}
      {isLoading ? loadingText : text}
    </button>
  )
}
