import { useUpdateEvent } from '@apiServices'
import {
  BackButton,
  ButtonRow,
  EventPageSetupContent,
  PageSubtitle,
  PageTitle,
  PreviewPage,
  PreviewPageButton,
  Step,
  StepFooter,
  StepHeader,
} from '@components'
import { useAccount, useEvent, useToast, WizardContext } from '@contexts'
import {
  eventDocumentsToEditEventDocuments,
  formatCreateEventDocumentsPayload,
  getDocumentCategoryForEventType,
  localToUtcDateTime,
  utcToEditableDateTime,
} from '@utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'

export const EditEventContentWizardPage = () => {
  const { account } = useAccount()
  const navigate = useNavigate()
  const { event } = useEvent()
  const { showErrorToast, showSuccessToast } = useToast()

  const { mutate: update, isPending: updateLoading } = useUpdateEvent()

  const [wizardState, setWizardState] = useState<any>({
    eventPageSetupFormValid: false,
    eventId: event?.id,
    projectId: event?.project?.id,
    contentHeaderTitle: event?.contentHeaderTitle,
    contentHeaderSubtitle: event?.contentHeaderSubtitle,
    contentHeaderBgImage: event?.contentHeaderBgImage,
    bodyTitle: event?.contentBodyHeader,
    contentBodyUpcoming: event?.contentBodyUpcoming,
    contentBodyOpen: event?.contentBodyOpen,
    contentBodyClosed: event?.contentBodyClosed,
    contentHeaderBgColor: event?.contentHeaderBgColor,
    contentHeaderFontColor: event?.contentHeaderFontColor,
    contentHeaderImage: event?.contentHeaderImage,
    themeLayoutVersion: event?.themeLayoutVersion,
    overviewImageUrl: event?.overviewImageUrl,
    startTime: event?.startTime
      ? utcToEditableDateTime(event.startTime, account?.timezone)
      : null,
    endTime: event?.endTime
      ? utcToEditableDateTime(event.endTime, account?.timezone)
      : null,
    alwaysOpen: !!event?.endTime,
  })

  const renderPreviewContent = (onContinue: any) => {
    return <PreviewPage context={wizardState} onContinue={onContinue} />
  }

  const goBack = () => {
    navigate(-1)
  }

  const handleSave = () => {
    const allowedRegions = event.allowedRegions
    const editedableDocuments = eventDocumentsToEditEventDocuments(
      event?.documents,
    )
    const documentCategory = getDocumentCategoryForEventType(event?.type)
    const formatedEventDocuemntPayload = formatCreateEventDocumentsPayload(
      documentCategory,
      editedableDocuments,
      allowedRegions,
    )

    const payload = {
      id: event.id,
      kycRestriction: event.kycRestriction,
      documents: formatedEventDocuemntPayload,
      contentHeaderTitle: wizardState.contentHeaderTitle,
      bodyHeader: wizardState.bodyTitle,
      contentHeaderBgColor: wizardState.contentHeaderBgColor,
      contentHeaderFontColor: wizardState.contentHeaderFontColor,
      contentHeaderSubtitle: wizardState.contentHeaderSubtitle,
      contentHeaderBgImage: wizardState.contentHeaderBgImage,
      contentBodyUpcoming: wizardState.contentBodyUpcoming,
      contentBodyOpen: wizardState.contentBodyOpen,
      contentBodyClosed: wizardState.contentBodyClosed,
      longDescription: event.longDescription,
      contentHeaderImage: wizardState.contentHeaderImage,
      overviewImageUrl: wizardState.overviewImageUrl,
      themeLayoutVersion: wizardState.themeLayoutVersion,
      startTime: localToUtcDateTime(wizardState.startTime, account?.timezone),
      endTime: wizardState.endTime
        ? localToUtcDateTime(wizardState.endTime, account?.timezone)
        : null,
    }

    update(payload, {
      onSuccess: (data) => {
        showSuccessToast({ description: 'Successfully updated event.' })
        goBack()
      },
      onError: (error) => {
        showErrorToast({ description: error.message })
      },
    })
  }

  const handleCancel = () => {
    goBack()
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Event Content Setup</PageTitle>
            <PageSubtitle>
              The Event Page is displayed to your users as a preview page for an
              upcoming event. This page is designed to introduce them to the
              process and to provide general context about your project.
            </PageSubtitle>
          </StepHeader>

          <EventPageSetupContent
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateLoading}
              />
              <PreviewPageButton
                previewContent={renderPreviewContent}
                disabled={
                  !wizardState?.eventPageSetupFormValid || updateLoading
                }
                label={'Preview and Continue'}
                onContinue={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
      <button className=''></button>
    </WizardContext.Provider>
  )
}
