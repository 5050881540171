export enum USER_EVENT_TABLE_COLUMNS {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  EMAIL = 'EMAIL',
  LEGACY_EMAIL = 'LEGACY_EMAIL',
  ENTITY_NAME = 'ENTITY_NAME',
  WALLET_ADDRESS = 'WALLET_ADDRESS',
  REFERRAL_CODE = 'REFERRAL_CODE',
  COUNTRY = 'COUNTRY',
  COMPLIANCE = 'COMPLIANCE',
  ACCREDITATION = 'ACCREDITATION',
  IDENTITY = 'IDENTITY',
  ELIGIBLE = 'ELIGIBLE',
}
