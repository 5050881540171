import { Button, FrontArrowIcon, Text } from '@components'
import classNames from 'classnames'
import { useWizard } from 'react-use-wizard'

type ButtonProps = React.ComponentProps<typeof Button>

interface ContinueButtonProps extends ButtonProps {
  disabled?: boolean
  label?: string
  icon?: React.ReactNode
  className?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

export const ContinueButton: React.FC<ContinueButtonProps> = ({
  disabled = false,
  label = 'Continue',
  icon = <FrontArrowIcon />,
  className = '',
  onClick = null,
  ...restProps
}) => {
  const { nextStep, isLoading } = useWizard()

  return (
    <Button
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => (onClick ? onClick(e) : nextStep())}
      disabled={disabled || isLoading}
      {...restProps}
    >
      <Text>{label}</Text>
      {icon}
    </Button>
  )
}
