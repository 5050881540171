import { useGetContractConfig, useGetContractOwner } from '@apiServices'
import {
  Button,
  Col,
  FrontArrowIcon,
  InputGroup,
  Row,
  Section,
  SectionTitle,
  Stacked,
  TableData,
  Text,
} from '@components'
import { GENESIS_ADDRESS } from '@constants'
import { useNetworks, useToast } from '@contexts'
import {
  useClipboard,
  useUpdateSaleOnChainConfig,
  useUpdateSaleOwner,
} from '@hooks'
import { getFlatPriceSaleABI, getTruncatedAddress, getTxUrl } from '@utils'
import { useEffect, useState } from 'react'
import { AiOutlineCopy, AiOutlineLoading3Quarters } from 'react-icons/ai'
import { VscLinkExternal } from 'react-icons/vsc'
import { isAddress } from 'viem'

interface SaleOwnerProps {
  context: any
  setContext: Function
}

export const SaleOwner = ({ context, setContext }: SaleOwnerProps) => {
  const { copyToClipboard } = useClipboard()
  const { showErrorToast, showSuccessToast } = useToast()
  const { getNetworkDetails } = useNetworks()
  const [owner, setOwner] = useState<Maybe<string>>(null)
  const [recipient, setRecipient] = useState<Maybe<string>>(null)
  const [ownerUpdatedAt, setOwnerUpdatedAt] = useState<Maybe<Date>>(null)
  const [recipientUpdatedAt, setRecipientUpdatedAt] =
    useState<Maybe<Date>>(null)

  const [updatedOwnerAddress, setUpdatedOwnerAddress] =
    useState<Maybe<string>>(null)
  const [updatedFeeRecipientAddress, setUpdatedFeeRecipientAddress] = useState<
    string | null
  >(null)

  const validRecipient =
    !recipient || (isAddress(recipient) && recipient !== GENESIS_ADDRESS)
  const recipientError = validRecipient ? null : 'Invalid address'

  const validOwner = !owner || (isAddress(owner) && owner !== GENESIS_ADDRESS)
  const ownerError = validOwner ? null : 'Invalid address'

  const {
    error: updateOwnerError,
    write: updateOwner,
    isLoading: updateOwnerLoading,
    data: updateOwnerReceipt,
  } = useUpdateSaleOwner()

  const {
    error: updateConfigError,
    write: updateConfig,
    isLoading: updateConfigLoading,
    data: updateConfigReceipt,
  } = useUpdateSaleOnChainConfig()

  const saving = updateOwnerLoading || updateConfigLoading

  const key = 'sale-owner'

  useEffect(() => {
    if (!ownerUpdatedAt) {
      return
    }

    setContext({
      ...context,
      owner: owner,
    })
  }, [ownerUpdatedAt])

  useEffect(() => {
    if (!recipientUpdatedAt) {
      return
    }

    setContext({
      ...context,
      recipient: recipient,
    })
  }, [recipientUpdatedAt])

  const saveOwner = async () => {
    try {
      await updateOwner(context.chainId, context.id, [owner])
    } catch (e: any) {
      showErrorToast({ description: e.message })
      console.error(e)
    }
  }

  const saveRecipient = async () => {
    try {
      const newOnChainConfig = {
        recipient: recipient,
        merkleRoot: context.merkleRoot,
        price: context.price,
        saleMaximum: context.saleMaximum,
        userMaximum: context.userMaximum,
        purchaseMinimum: context.purchaseMinimum,
        startTime: context.startTime,
        endTime: context.endTime,
        maxQueueTime: context.maxQueueTime,
        URI: context.uris[0],
      }

      await updateConfig(context.chainId, context.id, [newOnChainConfig])
    } catch (e: any) {
      showErrorToast({ description: e.message })
      console.error(e)
    }
  }

  useEffect(() => {
    if (updateOwnerReceipt) {
      if (updateOwnerReceipt.status === 'success') {
        setUpdatedOwnerAddress(owner)
        setOwnerUpdatedAt(new Date())
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateOwnerReceipt.transactionHash,
                  getNetworkDetails(context.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        })
      }
    }
  }, [updateOwnerReceipt])

  useEffect(() => {
    if (updateOwnerError) {
      showErrorToast({ description: updateOwnerError.toString() })
    }
  }, [updateOwnerError])

  useEffect(() => {
    if (updateConfigReceipt) {
      if (updateConfigReceipt.status === 'success') {
        setUpdatedFeeRecipientAddress(recipient)
        setRecipientUpdatedAt(new Date())
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateConfigReceipt.transactionHash,
                  getNetworkDetails(context.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        })
      }
    }
  }, [updateConfigReceipt])

  useEffect(() => {
    if (updateConfigError) {
      showErrorToast({ description: updateConfigError.toString() })
    }
  }, [updateConfigError])

  const saleContractAbi = getFlatPriceSaleABI()

  const {
    owner: saleContractOwner,
    isPending: loadingContractOwner,
    refetch: refetchContractOwner,
  } = useGetContractOwner(context?.id, context?.chainId, saleContractAbi)

  const {
    config,
    isPending: isConfigPending,
    refetch: refetchConfig,
  } = useGetContractConfig(context?.id, context?.chainId, saleContractAbi)

  const feeRecipientAddress = config ? (config as any)[0] : ''

  return (
    <Stacked data-testid={`${key}`}>
      <Section xgap={5} ygap={5}>
        <Col>
          <Row nowrap xalign={'between'}>
            <SectionTitle width={'1/2'}>Current Owner</SectionTitle>
            <Row width={'1/2'} nowrap gap={2} yalign={'center'} xalign={'end'}>
              {loadingContractOwner ? (
                <span>Loading...</span>
              ) : (
                <>
                  <div
                    className='text-primary-medium cursor-pointer'
                    // If owner is updated, show updated address immediately
                    onClick={() =>
                      copyToClipboard(updatedOwnerAddress ?? saleContractOwner)
                    }
                  >
                    <AiOutlineCopy size={16} />
                  </div>
                  <TableData
                    className='break-anywhere'
                    data-testid={`${key}-owner-data`}
                  >
                    {/* If owner is updated, show updated address immediately */}
                    {getTruncatedAddress(
                      updatedOwnerAddress ?? saleContractOwner,
                    )}
                  </TableData>
                </>
              )}
            </Row>
          </Row>

          <Text>
            Please add wallet address below to select a new owner and overwrite
            the existing one.
          </Text>
        </Col>

        <Col>
          <Row>
            <InputGroup
              name={'owner'}
              placeholder={'Enter Address'}
              required={true}
              value={owner}
              onChange={(changed: any) => {
                setOwner(changed.target.value)
              }}
              valid={validOwner}
              error={ownerError}
            />
          </Row>

          <Button
            className='w-fit'
            size={'md'}
            disabled={!owner || saving}
            onClick={() => saveOwner()}
          >
            {updateConfigLoading ? (
              <>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={18} />
                </div>
                <Text>Saving...</Text>
              </>
            ) : (
              <>
                <Text>Save</Text>
                <FrontArrowIcon />
              </>
            )}
          </Button>
        </Col>
      </Section>

      <Section xgap={5} ygap={5}>
        <Col>
          <Row nowrap xalign={'between'}>
            <SectionTitle width={'1/2'}>Current Recipient</SectionTitle>
            <Row width={'1/2'} nowrap gap={2} yalign={'center'} xalign={'end'}>
              {isConfigPending ? (
                <span>Loading...</span>
              ) : (
                <>
                  <div
                    className='text-primary-medium cursor-pointer'
                    // If recipient is updated, show updated address immediately
                    onClick={() =>
                      copyToClipboard(
                        updatedFeeRecipientAddress ?? feeRecipientAddress,
                      )
                    }
                  >
                    <AiOutlineCopy size={16} />
                  </div>
                  <TableData
                    className='break-anywhere'
                    data-testid={`${key}-recipient-data`}
                  >
                    {/* If recipient is updated, use updated address immediately */}
                    {getTruncatedAddress(
                      updatedFeeRecipientAddress ?? feeRecipientAddress,
                    )}
                  </TableData>
                </>
              )}
            </Row>
          </Row>

          <Text>
            Please add wallet address below to select a new recipient and
            overwrite the existing one.
          </Text>
        </Col>

        <Col>
          <Row>
            <InputGroup
              name={'recipient'}
              placeholder={'Enter Address'}
              required={true}
              value={recipient}
              onChange={(changed: any) => {
                setRecipient(changed.target.value)
              }}
              valid={validRecipient}
              error={recipientError}
            />
          </Row>

          <Button
            className='w-fit'
            size={'md'}
            disabled={!recipient || saving}
            onClick={() => saveRecipient()}
          >
            {updateConfigLoading ? (
              <>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={18} />
                </div>
                <Text>Saving...</Text>
              </>
            ) : (
              <>
                <Text>Save</Text>
                <FrontArrowIcon />
              </>
            )}
          </Button>
        </Col>
      </Section>
    </Stacked>
  )
}
