import { isDefined } from '@utils'
import classNames from 'classnames'
import React from 'react'

/* Coerce tailwind into including classnames are derived dynamically in this component */
export const ALLOWED_CLASSES = {
  flex: ['flex-1', 'flex-auto', 'flex-none'],
  twtextAlign: [
    'text-left',
    'text-center',
    'text-right',
    'text-start',
    'text-end',
    'text-justify',
  ],
  textAlign: ['left', 'center', 'right', 'start', 'end', 'justify'],
  twjustify: [
    'justify-normal',
    'justify-start',
    'justify-end',
    'justify-center',
    'justify-between',
    'justify-around',
    'justify-evenly',
    'justify-stretch',
  ],
  justify: [
    'normal',
    'start',
    'end',
    'center',
    'between',
    'around',
    'evenly',
    'stretch',
  ],
  items: [
    'items-start',
    'items-end',
    'items-center',
    'items-baseline',
    'items-stretch',
  ],
  // Tailwind width classes
  twwidth: [
    'w-0',
    'w-auto',
    'w-1/2',
    'w-1/3',
    'w-2/3',
    'w-1/4',
    'w-2/4',
    'w-3/4',
    'w-1/5',
    'w-2/5',
    'w-3/5',
    'w-4/5',
    'w-full',
    'w-screen',
    'w-min',
    'w-max',
    'w-fit',
  ],
  // Actual prop width classes
  width: [
    '0',
    'auto',
    '1/2',
    '1/3',
    '2/3',
    '1/4',
    '2/4',
    '3/4',
    '1/5',
    '2/5',
    '3/5',
    '4/5',
    'full',
    'screen',
    'min',
    'max',
    'fit',
  ],
  gap: [
    'gap-0',
    'gap-x-0',
    'gap-y-0',
    'gap-2',
    'gap-x-2',
    'gap-y-2',
    'gap-2.5',
    'gap-x-2.5',
    'gap-y-2.5',
    'gap-5',
    'gap-x-5',
    'gap-y-5',
    'gap-10',
    'gap-x-10',
    'gap-y-10',
    'gap-12',
    'gap-x-12',
    'gap-y-12',
  ],
} as const

type AllowedClasses = typeof ALLOWED_CLASSES

type DivProps = React.HTMLAttributes<HTMLDivElement>

type FlexProps = DivProps & {
  id?: string
  className?: string
  direction?: 'col' | 'row'
  textAlign?: AllowedClasses['textAlign'][number]
  gap?: number
  xgap?: number
  ygap?: number
  nowrap?: boolean
  wrap?: boolean
  width?: AllowedClasses['width'][number]
  place?: string
  xalign?: AllowedClasses['justify'][number] | AllowedClasses['items'][number]
  yalign?: AllowedClasses['justify'][number] | AllowedClasses['items'][number]
  children?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>

export const Flex: React.FC<FlexProps> = ({
  id = '',
  className = '',
  direction = 'col',
  textAlign,
  gap = 0,
  xgap,
  ygap,
  nowrap,
  wrap = true,
  width = 'full',
  place,
  xalign,
  yalign,
  onClick = () => {},
  children,
  ...restProps
}) => {
  let appliedPlace = place
  let appliedXAlign = xalign
  let appliedYAlign = yalign
  let appliedGap: number | null = gap

  if (isDefined(xgap) || isDefined(ygap)) {
    appliedGap = null
  }

  // support for place values like between-center so we can
  // control justify and align all in one
  const placeTokens = place?.split('-')
  if (!!placeTokens && placeTokens?.length > 1) {
    appliedXAlign = placeTokens[0] as
      | AllowedClasses['justify'][number]
      | AllowedClasses['items'][number]
    appliedYAlign = placeTokens[1] as
      | AllowedClasses['justify'][number]
      | AllowedClasses['items'][number]
    appliedPlace = undefined
  }

  return (
    <div
      id={id}
      onClick={onClick}
      className={classNames(
        'flex',
        width ? `w-${width}` : '',
        direction === 'col' ? `flex-col` : '',
        direction === 'row' ? `flex-row` : '',
        isDefined(appliedGap) ? `gap-${appliedGap}` : '',
        isDefined(xgap) ? `gap-x-${xgap}` : '',
        isDefined(ygap) ? `gap-y-${ygap}` : '',
        appliedXAlign && direction === 'row' ? `justify-${appliedXAlign}` : '',
        appliedXAlign && direction === 'col' ? `items-${appliedXAlign}` : '',
        appliedYAlign && direction === 'row' ? `items-${appliedYAlign}` : '',
        appliedYAlign && direction === 'col' ? `justify-${appliedYAlign}` : '',
        wrap && !nowrap ? 'flex-wrap' : '',
        nowrap ? 'flex-nowrap' : '',
        textAlign ? `text-${textAlign}` : '',
        appliedPlace ? `items-${appliedPlace} justify-${appliedPlace}` : '',
        className,
      )}
      {...restProps}
    >
      {children}
    </div>
  )
}
