export const OnchainIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
    >
      <rect width='50' height='50' rx='5' fill='#E5E7EB' />
      <path
        d='M29.0395 23.5433L25.9929 22.6882L27.6032 16.9528C27.7007 16.6033 27.5357 16.2351 27.2102 16.0761C26.884 15.9171 26.4917 16.0138 26.2772 16.3056L20.1458 24.642C20.0003 24.8408 19.9605 25.0973 20.0408 25.3305C20.1203 25.5638 20.3101 25.7423 20.5471 25.8091L23.5936 26.6641L21.8011 33.0475C21.7021 33.4 21.8716 33.7712 22.2016 33.928C22.3059 33.9767 22.4154 34 22.5234 34C22.7611 34 22.9929 33.8867 23.1376 33.6812L29.4505 24.6968C29.5908 24.4973 29.6245 24.243 29.5435 24.0135C29.4625 23.784 29.2743 23.6093 29.0395 23.5433Z'
        fill='#F59E0B'
      />
    </svg>
  )
}
