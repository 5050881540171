import { useMutation } from '@tanstack/react-query'
import { useAddAccountDocuments } from './use-add-account-documents'
import { useUploadKycFileToS3 } from './use-upload-kyc-file-to-s3'

export const useUploadKycDocuments = (kycTier: KycTier) => {
  const { mutateAsync: uploadKycFileToS3 } = useUploadKycFileToS3()
  const { mutateAsync: addAccountDocuments } = useAddAccountDocuments()

  return useMutation({
    mutationFn: async (data: {
      files: File[]
      message?: string
    }): Promise<ID[]> => {
      const { files, message } = data
      const fileUploadResults = await Promise.all(
        files.map((file) => uploadKycFileToS3(file)),
      )

      const addAccountDocumentsPayload: Parameters<
        typeof addAccountDocuments
      >[0] = {
        documents: fileUploadResults.map((uploadResult, i) => ({
          fileName: files[i].name,
          mimeType: files[i].type,
          s3Uri: uploadResult.s3Uri,
        })),
        kycTier,
      }

      if (message) {
        addAccountDocumentsPayload.message = message
      }

      const documentIds = await addAccountDocuments(addAccountDocumentsPayload)
      return documentIds
    },
  })
}
