import { convertLegacyEventTypeToEventType } from '../../../util'
import { TSEvent } from './event'
import { EventTimeline } from './event-timeline'

export class EventOverview implements IDRecord {
  id: IDRecord['id']
  chainId: TSEvent['chainId']
  description: TSEvent['description']
  iconSmallUrl: TSEvent['iconSmallUrl']
  iconLargeUrl: TSEvent['iconLargeUrl']
  imageUrl: TSEvent['overviewImageUrl']
  isPrivateEvent: TSEvent['isPrivateEvent']
  name: TSEvent['name']
  timeline: TSEvent['timeline']
  type: TSEvent['type']

  constructor(rawEvent: any) {
    this.id = rawEvent.id
    this.chainId = rawEvent.chainId
    this.description = rawEvent.description
    this.iconSmallUrl = rawEvent.iconSmallUrl
    this.iconLargeUrl = rawEvent.iconLargeUrl
    this.imageUrl = rawEvent.overviewImageUrl
    this.isPrivateEvent = rawEvent.isPrivateEvent
    this.name = rawEvent.name
    this.timeline = new EventTimeline(rawEvent)
    this.type = convertLegacyEventTypeToEventType(rawEvent.eventType)
  }
}
