import axios, { AxiosRequestConfig, Method } from 'axios'
import { useCallback } from 'react'

interface RequestConfig extends AxiosRequestConfig {
  token?: string
  signOut?: () => void
  shouldSignOutOnForbidden?: boolean
}

const publicApiClient = (
  endpoint: string,
  {
    data,
    signOut,
    headers: customHeaders,
    shouldSignOutOnForbidden = true,
    ...customConfig
  }: RequestConfig,
) => {
  const method: Method = data ? 'post' : 'get'
  const config: AxiosRequestConfig = {
    method,
    url: `${import.meta.env.VITE_API_URL}/public/v1/${endpoint}`,
    data: data || undefined,
    headers: {
      ...(data ? { 'Content-Type': 'application/json' } : {}),
      ...customHeaders,
    },
    ...customConfig,
  }

  return axios.request(config).then(
    (res) => res.data,
    (error) => {
      console.log(error.toJSON())
      const { status: statusCode } = error.toJSON()
      if (statusCode === 403 && shouldSignOutOnForbidden) {
        if (signOut) {
          signOut()
        }
      } else if (statusCode === 500) {
        return Promise.reject({
          message: error.response.data.message,
        })
      }

      return Promise.reject(error)
    },
  )
}

const usePublicApiClient = () => {
  return useCallback(
    (endpoint: string, config: Omit<RequestConfig, 'token' | 'signOut'> = {}) =>
      publicApiClient(endpoint, { ...config }),
    [],
  )
}

export { publicApiClient, usePublicApiClient }
