import { CollapseIcon, ExpandIcon, Row, Text } from '@components'
import classNames from 'classnames'
import { ReactNode, useState } from 'react'

type TextProps = React.ComponentProps<typeof Text>

type CardTitleProps = TextProps & {
  className?: string
  open?: boolean
  onOpen?: (open: boolean) => void
  children?: ReactNode
}

export const CardTitle = ({
  className = '',
  open,
  onOpen,
  children,
  ...restProps
}: CardTitleProps) => {
  const [isOpen, setIsOpen] = useState(open)

  const toggleOpen = () => {
    const openClose = !isOpen
    setIsOpen(openClose)
    if (onOpen) {
      onOpen(openClose)
    }
  }

  if (onOpen) {
    return (
      <Row
        nowrap
        gap={2}
        yalign={'center'}
        className='cursor-pointer'
        onClick={toggleOpen}
      >
        {isOpen ? <ExpandIcon /> : <CollapseIcon />}
        <Text
          className={classNames('text-lg font-bold high-contrast', className)}
          {...restProps}
        >
          {children}
        </Text>
      </Row>
    )
  }

  return (
    <Text
      className={classNames('text-lg font-bold high-contrast', className)}
      {...restProps}
    >
      {children}
    </Text>
  )
}
