import { Account, AccountDetails } from '@customTypes'

// Returns an address formatted in an array of lines, suitable for rendering with map.
// If lastName is null, it returns nothing (in the case of new user accounts).
export const formatAddress = (
  account?: Account,
  accountType = 'myself',
  countryList?: Function,
) => {
  const address = account?.addresses?.find(
    (address) => address.addressType === accountType,
  )
  if (!address) return []

  return account?.lastName
    ? [
        address.buildingName,
        address.buildingNumber || address.streetLine1
          ? `${address.buildingNumber || ''} ${address.streetLine1 || ''}`
          : null,
        address.streetLine2,
        address.city || address.state || address.postcode
          ? `${address.city ? address.city + ',' : ''} ${address.state || ''} ${
              address.postcode || ''
            }`
          : null,
        countryList ? countryList().getLabel(address.country) : address.country,
      ].filter((l) => l)
    : []
}

export const getTruncatedAddress = (address?: string): string => {
  if (!address) {
    return ''
  }
  return address.substring(0, 5) + '...' + address.slice(-4)
}

export const formatAccountDetailsAddress = (
  a: AccountDetails,
  type: AccountInvestorType,
) => {
  if (type === 'myself') {
    return [
      a.buildingName,
      a.buildingNumber || a.streetLineOne
        ? `${a.buildingNumber || ''} ${a.streetLineOne || ''}`
        : null,
      a.streetLineTwo,
      a.city || a.state || a.zipCode
        ? `${a.city ? a.city + ',' : ''} ${a.state || ''} ${a.zipCode || ''}`
        : null,
      a.country,
    ].filter((l) => l)
  }

  return [
    a.entityBuildingName,
    a.entityBuildingNumber || a.entityStreetLineOne
      ? `${a.entityBuildingNumber || ''} ${a.entityStreetLineOne || ''}`
      : null,
    a.entityStreetLineTwo,
    a.entityCity || a.entityState || a.entityZipCode
      ? `${a.entityCity ? a.entityCity + ',' : ''} ${a.entityState || ''} ${
          a.entityZipCode || ''
        }`
      : null,
    a.country,
  ].filter((l) => l)
}

export const getCountryForAccountAddresses = (
  accountAddresses: any[],
  investorType: AccountInvestorType,
): string | null => {
  if (!accountAddresses || !accountAddresses.length) {
    return null
  }

  const found = accountAddresses?.find(
    ({ addressType }) => addressType === investorType,
  )
  if (found) {
    return found?.country
  }

  return null
}
