export const TITLE_ICON_50_50 = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 342 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='176.538'
        y='13.0769'
        width='150.385'
        height='104.615'
        rx='5'
        fill='#E5E7EB'
      />
      <path
        d='M263.147 54.9231H240.314C239.397 54.9231 238.653 55.6666 238.653 56.5836V73.6044C238.653 74.5214 239.397 75.2649 240.314 75.2649H263.147C264.064 75.2649 264.807 74.5214 264.807 73.6044V56.5836C264.807 55.6666 264.064 54.9231 263.147 54.9231ZM263.147 56.5836V68.6604L259.874 65.6851C259.382 65.238 258.626 65.2559 258.156 65.7263L254.844 69.0378L248.313 61.2373C247.82 60.6478 246.915 60.642 246.414 61.2245L240.314 68.3142V56.5836H263.147ZM256.089 60.9426C256.089 59.6814 257.111 58.6593 258.372 58.6593C259.634 58.6593 260.656 59.6814 260.656 60.9426C260.656 62.2038 259.634 63.2258 258.372 63.2258C257.111 63.2259 256.089 62.2038 256.089 60.9426Z'
        fill='#6B7280'
      />
      <rect
        x='13.0771'
        y='13.0769'
        width='150.385'
        height='45.7692'
        rx='5'
        fill='#E5E7EB'
      />
      <path
        d='M92.0821 40.6311C91.9487 40.6311 91.8207 40.5781 91.7264 40.4837C91.6321 40.3894 91.5791 40.2615 91.5791 40.1281V35.6809C91.5791 35.5475 91.6321 35.4196 91.7264 35.3253C91.8207 35.231 91.9487 35.178 92.0821 35.178C92.2155 35.178 92.3434 35.231 92.4377 35.3253C92.532 35.4196 92.585 35.5475 92.585 35.6809V40.1281C92.585 40.2615 92.532 40.3894 92.4377 40.4837C92.3434 40.5781 92.2155 40.6311 92.0821 40.6311Z'
        fill='#6B7280'
      />
      <path
        d='M94.3056 38.4073H89.8584C89.725 38.4073 89.5971 38.3543 89.5028 38.26C89.4085 38.1656 89.3555 38.0377 89.3555 37.9043C89.3555 37.7709 89.4085 37.643 89.5028 37.5487C89.5971 37.4544 89.725 37.4014 89.8584 37.4014H94.3056C94.439 37.4014 94.5669 37.4544 94.6612 37.5487C94.7556 37.643 94.8085 37.7709 94.8085 37.9043C94.8085 38.0377 94.7556 38.1656 94.6612 38.26C94.5669 38.3543 94.439 38.4073 94.3056 38.4073Z'
        fill='#6B7280'
      />
      <path
        d='M92.7171 31.7366H82.2344C82.101 31.7366 81.9731 31.6836 81.8788 31.5893C81.7844 31.495 81.7314 31.3671 81.7314 31.2337C81.7314 31.1003 81.7844 30.9723 81.8788 30.878C81.9731 30.7837 82.101 30.7307 82.2344 30.7307H92.7171C92.8505 30.7307 92.9784 30.7837 93.0727 30.878C93.167 30.9723 93.22 31.1003 93.22 31.2337C93.22 31.3671 93.167 31.495 93.0727 31.5893C92.9784 31.6836 92.8505 31.7366 92.7171 31.7366Z'
        fill='#6B7280'
      />
      <path
        d='M92.7171 31.7366H82.2344C82.101 31.7366 81.9731 31.6836 81.8788 31.5893C81.7844 31.495 81.7314 31.3671 81.7314 31.2337C81.7314 31.1003 81.7844 30.9723 81.8788 30.878C81.9731 30.7837 82.101 30.7307 82.2344 30.7307H92.7171C92.8505 30.7307 92.9784 30.7837 93.0727 30.878C93.167 30.9723 93.22 31.1003 93.22 31.2337C93.22 31.3671 93.167 31.495 93.0727 31.5893C92.9784 31.6836 92.8505 31.7366 92.7171 31.7366Z'
        fill='#6B7280'
      />
      <path
        d='M92.7171 34.2776H82.2344C82.101 34.2776 81.9731 34.2247 81.8788 34.1303C81.7844 34.036 81.7314 33.9081 81.7314 33.7747C81.7314 33.6413 81.7844 33.5134 81.8788 33.419C81.9731 33.3247 82.101 33.2717 82.2344 33.2717H92.7171C92.8505 33.2717 92.9784 33.3247 93.0727 33.419C93.167 33.5134 93.22 33.6413 93.22 33.7747C93.22 33.9081 93.167 34.036 93.0727 34.1303C92.9784 34.2247 92.8505 34.2776 92.7171 34.2776Z'
        fill='#6B7280'
      />
      <path
        d='M87.6347 36.8189H82.2344C82.101 36.8189 81.9731 36.7659 81.8788 36.6716C81.7844 36.5773 81.7314 36.4493 81.7314 36.3159C81.7314 36.1826 81.7844 36.0546 81.8788 35.9603C81.9731 35.866 82.101 35.813 82.2344 35.813H87.6347C87.7681 35.813 87.896 35.866 87.9903 35.9603C88.0846 36.0546 88.1376 36.1826 88.1376 36.3159C88.1376 36.4493 88.0846 36.5773 87.9903 36.6716C87.896 36.7659 87.7681 36.8189 87.6347 36.8189Z'
        fill='#6B7280'
      />
      <path
        d='M87.6347 39.3604H82.2344C82.101 39.3604 81.9731 39.3074 81.8788 39.2131C81.7844 39.1188 81.7314 38.9908 81.7314 38.8575C81.7314 38.7241 81.7844 38.5961 81.8788 38.5018C81.9731 38.4075 82.101 38.3545 82.2344 38.3545H87.6347C87.7681 38.3545 87.896 38.4075 87.9903 38.5018C88.0846 38.5961 88.1376 38.7241 88.1376 38.8575C88.1376 38.9908 88.0846 39.1188 87.9903 39.2131C87.896 39.3074 87.7681 39.3604 87.6347 39.3604Z'
        fill='#6B7280'
      />
      <rect
        x='13.0771'
        y='71.9231'
        width='150.385'
        height='45.7692'
        rx='5'
        fill='#E5E7EB'
      />
      <path
        d='M92.0821 99.4772C91.9487 99.4772 91.8207 99.4243 91.7264 99.3299C91.6321 99.2356 91.5791 99.1077 91.5791 98.9743V94.5271C91.5791 94.3937 91.6321 94.2658 91.7264 94.1715C91.8207 94.0772 91.9487 94.0242 92.0821 94.0242C92.2155 94.0242 92.3434 94.0772 92.4377 94.1715C92.532 94.2658 92.585 94.3937 92.585 94.5271V98.9743C92.585 99.1077 92.532 99.2356 92.4377 99.3299C92.3434 99.4243 92.2155 99.4772 92.0821 99.4772Z'
        fill='#6B7280'
      />
      <path
        d='M94.3056 97.2537H89.8584C89.725 97.2537 89.5971 97.2007 89.5028 97.1064C89.4085 97.0121 89.3555 96.8842 89.3555 96.7508C89.3555 96.6174 89.4085 96.4894 89.5028 96.3951C89.5971 96.3008 89.725 96.2478 89.8584 96.2478H94.3056C94.439 96.2478 94.5669 96.3008 94.6612 96.3951C94.7556 96.4894 94.8085 96.6174 94.8085 96.7508C94.8085 96.8842 94.7556 97.0121 94.6612 97.1064C94.5669 97.2007 94.439 97.2537 94.3056 97.2537Z'
        fill='#6B7280'
      />
      <path
        d='M92.7171 90.5828H82.2344C82.101 90.5828 81.9731 90.5298 81.8788 90.4355C81.7844 90.3412 81.7314 90.2133 81.7314 90.0799C81.7314 89.9465 81.7844 89.8185 81.8788 89.7242C81.9731 89.6299 82.101 89.5769 82.2344 89.5769H92.7171C92.8505 89.5769 92.9784 89.6299 93.0727 89.7242C93.167 89.8185 93.22 89.9465 93.22 90.0799C93.22 90.2133 93.167 90.3412 93.0727 90.4355C92.9784 90.5298 92.8505 90.5828 92.7171 90.5828Z'
        fill='#6B7280'
      />
      <path
        d='M92.7171 90.5828H82.2344C82.101 90.5828 81.9731 90.5298 81.8788 90.4355C81.7844 90.3412 81.7314 90.2133 81.7314 90.0799C81.7314 89.9465 81.7844 89.8185 81.8788 89.7242C81.9731 89.6299 82.101 89.5769 82.2344 89.5769H92.7171C92.8505 89.5769 92.9784 89.6299 93.0727 89.7242C93.167 89.8185 93.22 89.9465 93.22 90.0799C93.22 90.2133 93.167 90.3412 93.0727 90.4355C92.9784 90.5298 92.8505 90.5828 92.7171 90.5828Z'
        fill='#6B7280'
      />
      <path
        d='M92.7171 93.1238H82.2344C82.101 93.1238 81.9731 93.0708 81.8788 92.9765C81.7844 92.8822 81.7314 92.7543 81.7314 92.6209C81.7314 92.4875 81.7844 92.3596 81.8788 92.2652C81.9731 92.1709 82.101 92.1179 82.2344 92.1179H92.7171C92.8505 92.1179 92.9784 92.1709 93.0727 92.2652C93.167 92.3596 93.22 92.4875 93.22 92.6209C93.22 92.7543 93.167 92.8822 93.0727 92.9765C92.9784 93.0708 92.8505 93.1238 92.7171 93.1238Z'
        fill='#6B7280'
      />
      <path
        d='M87.6347 95.6653H82.2344C82.101 95.6653 81.9731 95.6124 81.8788 95.518C81.7844 95.4237 81.7314 95.2958 81.7314 95.1624C81.7314 95.029 81.7844 94.9011 81.8788 94.8067C81.9731 94.7124 82.101 94.6594 82.2344 94.6594H87.6347C87.7681 94.6594 87.896 94.7124 87.9903 94.8067C88.0846 94.9011 88.1376 95.029 88.1376 95.1624C88.1376 95.2958 88.0846 95.4237 87.9903 95.518C87.896 95.6124 87.7681 95.6653 87.6347 95.6653Z'
        fill='#6B7280'
      />
      <path
        d='M87.6347 98.2066H82.2344C82.101 98.2066 81.9731 98.1536 81.8788 98.0593C81.7844 97.965 81.7314 97.837 81.7314 97.7036C81.7314 97.5702 81.7844 97.4423 81.8788 97.348C81.9731 97.2537 82.101 97.2007 82.2344 97.2007H87.6347C87.7681 97.2007 87.896 97.2537 87.9903 97.348C88.0846 97.4423 88.1376 97.5702 88.1376 97.7036C88.1376 97.837 88.0846 97.965 87.9903 98.0593C87.896 98.1536 87.7681 98.2066 87.6347 98.2066Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4322_1030'
          x='0'
          y='0'
          width='342'
          height='132.769'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4322_1030'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4322_1030'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
