import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'

type HRProps = React.ComponentProps<'hr'>

interface DividerProps extends HRProps {
  className?: string
  smallScreenOnly?: boolean
}

/**
 * Renders an <hr> element.  Can be configured to only show up on small screens.
 */
export const Divider: React.FC<DividerProps> = ({
  className,
  smallScreenOnly = false,
  ...restProps
}) => {
  const isSmallScreen = useMediaQuery({ maxWidth: 864 })

  return (
    <hr
      className={classNames(
        className,
        'divider h-px bg-gray-200 border-0',
        !isSmallScreen && smallScreenOnly ? 'hidden' : 'block',
      )}
      {...restProps}
    />
  )
}
