import {
  DistributorVestingSummary,
  HelpText,
  Section,
  Stacked,
  TokenInfoCard,
} from '@components'
import { useAuth } from '@contexts'
import { getTruncatedAddress } from '@utils'

interface DistributorDeployProps {
  context: any
  setContext: Function
}

export const DistributorDeploy = ({
  context,
  setContext,
}: DistributorDeployProps) => {
  // const { getNetworkDetails } = useNetworks();
  // const { nativeOraclePrice } = useNativeOraclePrice(context.networkId);

  const {
    user: { walletAddress },
  } = useAuth()
  const formattedWalletAddress = getTruncatedAddress(walletAddress)

  const key = 'distributor-deploy'

  return (
    <Stacked data-testid={`${key}`}>
      <Section>
        <TokenInfoCard
          tokenInfo={context.tokenInfo}
          networkId={context.networkId}
          showSuccess={false}
        />
      </Section>

      <DistributorVestingSummary context={context}></DistributorVestingSummary>

      {/* <Card id={`${key}-card`}>
        <CardHeader place={'center'}>
          <CardTitle open data-testid={`${key}-title`}>
            Distributor Deployment Details
          </CardTitle>
        </CardHeader>

        <CardBody>
          <Stacked>
            <Section gap={5} place={'start'}>
              <Stretched gap={5}>
                <Stretched ygap={5}>
                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Total Allocation</TableHeader>
                    <Text>
                      {formatAmountWithSymbol(
                        getFormattedAmount(
                          context.totalAllocations,
                          context.tokenInfo,
                          8,
                        ),
                        context.tokenInfo,
                      )}
                    </Text>
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Processing Fee (1 USD)</TableHeader>
                    <Text>
                      {
                        div(
                          div(
                            mult('100000000', '100000000'),
                            nativeOraclePrice,
                          ),
                          '100000000',
                        )
                      }
                      &nbsp;{getNetworkDetails(context.networkId).symbol}
                    </Text>
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Network Fee (1%)</TableHeader>
                    <Text>
                      {formatAmountWithSymbol(
                        getFormattedAmount(
                          div(context.totalAllocations, 100),
                          context.tokenInfo,
                          8,
                        ),
                        context.tokenInfo,
                      )}
                    </Text>
                  </Col>

                  <Col gap={2.5} className={'w-full md:w-1/2'}>
                    <TableHeader>Total Quantity Required</TableHeader>
                    <Text>
                      {formatAmountWithSymbol(
                        getFormattedAmount(
                          add(
                            div(context.totalAllocations, 100),
                            context.totalAllocations,
                          ),
                          context.tokenInfo,
                          8,
                        ),
                        context.tokenInfo,
                      )}
                    </Text>
                  </Col>
                </Stretched>

                <Stretched>
                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>

                  <Col gap={2.5} width={'1/2'} className={'hidden md:block'}>
                    <TableHeader>&nbsp;</TableHeader>
                    <TableData>&nbsp;</TableData>
                  </Col>
                </Stretched>
              </Stretched>
            </Section>
          </Stacked>
        </CardBody>
      </Card> */}

      <HelpText data-testid={`${key}-help-text`}>
        The address ({formattedWalletAddress}) you use to launch the distributor
        will own the new distributor contract. You can adjust distributor
        settings after launch.
      </HelpText>
    </Stacked>
  )
}
