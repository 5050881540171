import { useUpdateEvent } from '@apiServices'
import {
  BackButton,
  ButtonRow,
  DocumentsList,
  DocumentUploadSaveButton,
  DocumentUploadSection,
  PageSubtitle,
  PageTitle,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
} from '@components'
import {
  DocumentUploadProvider,
  useEvent,
  useToast,
  WizardContext,
} from '@contexts'
import {
  allowedRegionsToCountryList,
  blockedRegionsFromCountryList,
  eventDocumentsToEditEventDocuments,
  formatCreateEventDocumentsPayload,
  getDocumentCategoryForEventType,
} from '@utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'
import './event-create-document.css'

export const EditEventDocumentsWizardPage = () => {
  const navigate = useNavigate()
  const { event } = useEvent()
  const { showErrorToast, showSuccessToast } = useToast()

  const allowedCountryList = allowedRegionsToCountryList(event?.allowedRegions)
  const blockedCountryList = blockedRegionsFromCountryList(allowedCountryList)

  const [wizardState, setWizardState] = useState<any>({
    isEditingDocuments: null,
    eventId: event?.id,
    allowedCountryList: allowedCountryList,
    blockedCountryList: blockedCountryList,
    documents: eventDocumentsToEditEventDocuments(event?.documents),
    eventConfig: ['identity', 'sale', 'distribution'],
    eventType: event?.type,
  })

  const { mutate: update, isPending: updateLoading } = useUpdateEvent()

  const goBack = () => {
    navigate(-1)
  }

  const handleSave = () => {
    const allowedRegions = wizardState.allowedCountryList.map(
      (country: any) => country.value,
    )

    const payload = {
      id: event?.id,
      ...event,
      documents: formatCreateEventDocumentsPayload(
        getDocumentCategoryForEventType(event?.type),
        wizardState.documents,
        allowedRegions,
      ),
    }

    update(payload, {
      onSuccess: (data) => {
        showSuccessToast({ description: 'Successfully updated event.' })
        goBack()
      },
      onError: (error) => {
        showErrorToast({ description: error.message })
      },
    })
  }

  const handleCancel = () => {
    goBack()
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Document Setup</PageTitle>
            <PageSubtitle>
              Please upload the documents you would like your event participants
              to review and accept.
            </PageSubtitle>
          </StepHeader>

          <DocumentsList
            wizardState={wizardState}
            setWizardState={setWizardState}
          />

          <StepFooter hideProgressBar={true}>
            {wizardState?.isEditingDocuments ? null : (
              <ButtonRow place='between'>
                <BackButton label={'Cancel'} onClick={handleCancel} />
                <SaveButton
                  disabled={!wizardState?.documentsFormValid || updateLoading}
                  onClick={handleSave}
                />
              </ButtonRow>
            )}
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <PageTitle>Document Setup</PageTitle>
            <PageSubtitle>
              Please upload the documents you would like your event participants
              to review and accept.
            </PageSubtitle>
          </StepHeader>

          <DocumentUploadProvider
            wizardState={wizardState}
            setWizardState={setWizardState}
          >
            <DocumentUploadSection />

            <StepFooter hideProgressBar={true}>
              <ButtonRow place='between'>
                <BackButton label='Cancel' />
                <DocumentUploadSaveButton />
              </ButtonRow>
            </StepFooter>
          </DocumentUploadProvider>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  )
}
