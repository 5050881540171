import { useEffect, useState } from 'react'
import { useWindowSize } from './use-window-size'

// Tailwind CSS default breakpoints
const defaultBreakpoints = {
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  '2xl': 1536,
}

export const useBreakpoint = (breakpoints = defaultBreakpoints) => {
  const [breakpoint, setBreakpoint] =
    useState<Maybe<keyof typeof defaultBreakpoints>>(null)
  const { width } = useWindowSize()

  useEffect(() => {
    if (width !== undefined) {
      const sortedBreakpoints: [string, number][] = Object.keys(
        breakpoints,
      ).map((key) => [key, breakpoints[key as keyof typeof breakpoints]])

      sortedBreakpoints.sort((a: any, b: any) => a[1] - b[1])

      let activeBreakpoint: string = sortedBreakpoints[0][0]
      for (const [key, value] of sortedBreakpoints) {
        if (width >= value) {
          activeBreakpoint = key
        }
      }
      setBreakpoint(activeBreakpoint as keyof typeof breakpoints)
    }
  }, [width, breakpoints])

  return breakpoint
}
