import {
  Col,
  ICON_100,
  Text,
  TITLE_100,
  TITLE_ICON_50_50,
  TITLE_ICON_50_50_REVERSED,
  TITLE_ICON_CENTERED,
  TITLE_ONLY_100_CENTERED,
  TITLE_ONLY_100_LEFT,
  TITLE_ONLY_100_RIGHT,
} from '@components'
import { LAYOUT_TYPE } from '@enums'
import classNames from 'classnames'
interface LayoutIconProps {
  layout: any
  value: any
  setValue: Function
}

export const LayoutIcon = ({ layout, value, setValue }: LayoutIconProps) => {
  const renderLayoutIcon = () => {
    switch (layout.type) {
      case LAYOUT_TYPE.TITLE_ICON_50_50:
        return <TITLE_ICON_50_50 />
      case LAYOUT_TYPE.TITLE_ICON_50_50_REVERSED:
        return <TITLE_ICON_50_50_REVERSED />
      case LAYOUT_TYPE.ICON_100:
        return <ICON_100 />
      case LAYOUT_TYPE.TITLE_100:
        return <TITLE_100 />
      case LAYOUT_TYPE.TITLE_ICON_CENTERED:
        return <TITLE_ICON_CENTERED />
      case LAYOUT_TYPE.TITLE_ONLY_100_CENTERED:
        return <TITLE_ONLY_100_CENTERED />
      case LAYOUT_TYPE.TITLE_ONLY_100_LEFT:
        return <TITLE_ONLY_100_LEFT />
      case LAYOUT_TYPE.TITLE_ONLY_100_RIGHT:
        return <TITLE_ONLY_100_RIGHT />
    }
  }

  return (
    <Col
      onClick={() => setValue(layout.type)}
      className={'hover:cursor-pointer'}
      ygap={2.5}
    >
      <Text>{layout.name}</Text>
      <div
        className={classNames(
          'rounded border border-solid border-gray-400',
          layout.type === value && 'border-primary-light border-2',
        )}
      >
        {renderLayoutIcon()}
      </div>
    </Col>
  )
}
