import { EventDocument, EventUserDocumentStatus } from 'tokensoft-shared-types'

export const areDocumentRequirementsMet = (
  eventDocuments: EventDocument[],
  eventUserDocumentsStatus: EventUserDocumentStatus[],
): boolean => {
  if (eventDocuments.length === 0) {
    return true
  }

  return eventDocuments.every((eventDocument) => {
    const eventUserDocumentStatus = eventUserDocumentsStatus.find(
      (status) => status.eventDocumentVersionId === eventDocument.id,
    )

    if (!eventUserDocumentStatus) {
      return false
    }

    return isDocumentRequirementMet(eventDocument, eventUserDocumentStatus)
  })
}

const isDocumentRequirementMet = (
  eventDocument: EventDocument,
  eventUserDocumentStatus: EventUserDocumentStatus,
): boolean => {
  if (!eventUserDocumentStatus) {
    return false
  }

  if (eventDocument.requiresSignature) {
    return eventUserDocumentStatus.signedAt !== null
  }

  return eventUserDocumentStatus.acceptedAt !== null
}
