import { useLayout } from '@contexts'
import { useEffect } from 'react'

export const useHideSidebar = (hide: boolean = true) => {
  const { setShouldHideSidebar } = useLayout()

  // Reverse the sidebar visibility when the component unmounts
  useEffect(() => {
    setShouldHideSidebar(hide)
    return () => setShouldHideSidebar(!hide)
  }, [hide, setShouldHideSidebar])

  return { setShouldHideSidebar }
}
