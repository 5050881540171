import { useModal } from '@contexts'
import * as Dialog from '@radix-ui/react-dialog'
import classNames from 'classnames'
import { useState } from 'react'
import { FaRegTimesCircle } from 'react-icons/fa'
import './modal.css'

export const Modal = () => {
  const { modal, closeModal } = useModal()
  const [open] = useState<boolean>(true)

  const { content, disableClose, className } = modal || {}

  const onOpenChange = () => {
    if (open) {
      if (disableClose) {
        return
      }

      closeModal()
    }
  }

  return modal ? (
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className='DialogOverlay' />
        <Dialog.Content className='DialogContent' onClick={onOpenChange}>
          {/* Trick to get content to center in the middle of the screen */}
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>

          <div
            className={classNames('modal-content', className)}
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {!disableClose ? (
              <div className={'flex w-full justify-end'}>
                <Dialog.Close asChild>
                  <button className='IconButton' aria-label='Close'>
                    <FaRegTimesCircle className='text-neutral-medium cursor-pointer' />
                  </button>
                </Dialog.Close>
              </div>
            ) : null}

            {content}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  ) : null
}
