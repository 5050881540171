export const validatePurchasesFinalAgreement = (
  value: boolean | undefined,
): string | true => {
  if (value !== true) {
    return 'You must agree to the purchases final terms.'
  }
  return true
}

export const validateCompanyRightToRefuseAgreement = (
  value: boolean | undefined,
): string | true => {
  if (value !== true) {
    return 'You must agree to the company right to refuse terms.'
  }
  return true
}

export const createSignatureValidator =
  (signerName: string) =>
  (value: string | undefined): string | true => {
    if (!value) {
      return 'You must sign the agreement.'
    }

    if (value !== signerName) {
      return 'Invalid signature.'
    }

    return true
  }
