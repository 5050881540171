import {
  Button,
  Col,
  ModalTitle,
  PreparingIcon,
  Stacked,
  Text,
} from '@components'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export const PreparingSaleModal = () => {
  const key = 'preparing-sale-modal'

  return (
    <Stacked data-testid={key}>
      <Col gap={5} place={'center'}>
        <PreparingIcon />
        <ModalTitle data-testid={`${key}-title`}>
          {'Preparing Your Sale'}
        </ModalTitle>
        <Text data-testid={`${key}-subtitle`} width='3/4' textAlign={'center'}>
          Thank you for your patience as we prepare your Sale contract. This
          could take a few minutes.
        </Text>
      </Col>

      <Button data-testid={`${key}-button`} color={'primary'} disabled={true}>
        <div className='animate-spin'>
          <AiOutlineLoading3Quarters size={24} />
        </div>

        <Text>Preparing...</Text>
      </Button>
    </Stacked>
  )
}
