import { getErc20ABI } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Address, Hex } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useFundDistributor = () => {
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>(
    undefined,
  )
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [prepareError, setPrepareError] = useState<Error | null>(null)
  const { executeContractWrite } = useContractWrite()

  const write = async (
    contractAddress: string,
    toAddress: string,
    amount: string,
    chainId: number,
  ) => {
    setSubmitting(true)
    setPrepareError(null)

    try {
      const abi =
        contractAddress.toLowerCase() ===
        `0xdAC17F958D2ee523a2206206994597C13D831ec7`.toLowerCase()
          ? getErc20ABI(true)
          : getErc20ABI()

      const writeContractResponse = await executeContractWrite({
        address: contractAddress as Address,
        abi: abi as Abi,
        functionName: 'transfer',
        args: [toAddress, amount],
      })

      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (e) {
      setPrepareError(
        e instanceof Error ? e : new Error('Unknown error occurred'),
      )
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
    error: prepareError || waitForTransactionResponse?.error,
  }
}
