export enum LAYOUT_TYPE_NAME {
  TITLE_ICON_50_50 = 'Standard',
  TITLE_ICON_50_50_REVERSED = 'Reversed',
  ICON_100 = 'Icon Centered',
  TITLE_100 = 'Title Centered',
  TITLE_ICON_CENTERED = 'Center Aligned w/ Logo',
  TITLE_ONLY_100_CENTERED = 'Center Aligned Text Only',
  TITLE_ONLY_100_LEFT = 'Left Aligned Text Only',
  TITLE_ONLY_100_RIGHT = 'Right Aligned Text Only',
}
