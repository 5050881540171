import { NavHeader, NotificationPopover } from '@components'
import { useAuth, useTSWagmi, useWallet } from '@contexts'
import { useTSAddress } from '@hooks'
import { usePrivy, useWallets } from '@privy-io/react-auth'
import { formatValue, getTruncatedEvmAddress } from '@utils'
import { useEffect, useState } from 'react'
import { IoIosLogOut } from 'react-icons/io'
import { useBalance } from 'wagmi'
import './header.css'

export const Header = () => {
  const { isAuthenticated, disconnect } = useAuth()
  const { address: TSAddress, chainId } = useTSAddress()
  const { data: balance } = useBalance({
    address: TSAddress,
  })
  const { activeChains } = useTSWagmi()
  const { logout, authenticated, ready } = usePrivy()
  const [isHovered, setIsHovered] = useState(false)
  const [copied, setCopied] = useState(false)
  const { wallets } = useWallets()
  const { switchChain } = useWallet()
  const [isChainDropdownOpen, setIsChainDropdownOpen] = useState(false)

  useEffect(() => {
    console.log('INFO: Header-useEffect() called')
    console.log({ chainId })
    console.log({ activeChains })
  }, [chainId, activeChains])

  const handleCopy = () => {
    navigator.clipboard.writeText(TSAddress ?? '')
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  /**
   * Handle disconnect events
   */
  const handleDisconnect = async () => {
    try {
      console.log('INFO: Header-handleDisconnect() called')
      // console.log('provider: disconnecting...');
      // First disconnect any non-injected wallets
      for (const wallet of wallets) {
        try {
          await wallet.disconnect()
        } catch (e) {
          console.error(`Failed to disconnect wallet ${wallet.address}:`, e)
        }
      }
      logout()
      disconnect()
    } catch (e) {
      console.log({ e })
    }
  }

  return (
    <>
      <NavHeader />
      <div className='header'>
        <div className='header-content'>
          <nav className='navbar navbar-expand'>
            <div className='navbar-collapse justify-between'>
              <div className='header-left' />
              {isAuthenticated ? (
                <ul
                  className='navbar-nav header-right main-notification'
                  data-testid='authenticated-header'
                >
                  <li className='nav-item header-profile'>
                    <ul className='flex items-center border border-gray-200 rounded-full px-6 py-1.5 space-x-4 text-black'>
                      <li>
                        {formatValue(Number(balance?.formatted), {
                          commas: true,
                          decimalPlaces: 6,
                        })}{' '}
                        {balance?.symbol}
                      </li>
                      <button
                        className='flex items-center justify-center rounded-full bg-gray-100 px-4 py-1.5 cursor-pointer transition-colors hover:bg-gray-200 min-w-[160px]'
                        onClick={handleCopy}
                        onMouseEnter={() => setIsHovered(true)}
                        onMouseLeave={() => setIsHovered(false)}
                      >
                        {copied ? (
                          <span className='text-gray-600'>COPIED!</span>
                        ) : isHovered ? (
                          <span className='text-gray-600'>COPY</span>
                        ) : TSAddress ? (
                          getTruncatedEvmAddress(TSAddress)
                        ) : (
                          ''
                        )}
                      </button>
                    </ul>
                  </li>
                  <li className='nav-item'>
                    <div className='relative'>
                      <div className='flex items-center border border-gray-200 rounded-full px-6 py-1.5 space-x-4 text-black'>
                        <span>Chain ID: {chainId}</span>
                        <button
                          onClick={() =>
                            setIsChainDropdownOpen(!isChainDropdownOpen)
                          }
                          className='flex items-center justify-center rounded-full bg-gray-100 px-4 py-1.5 cursor-pointer transition-colors hover:bg-gray-200'
                        >
                          Switch Chain
                        </button>
                      </div>
                      {isChainDropdownOpen && (
                        <div className='absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5'>
                          <div className='py-1' role='menu'>
                            {(activeChains ?? []).map((chain) => (
                              <button
                                key={chain.id}
                                onClick={() => {
                                  void switchChain(chain.id)
                                  setIsChainDropdownOpen(false)
                                }}
                                className='block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100'
                                role='menuitem'
                              >
                                {chain.name}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                  <li className='nav-item dropdown notification_dropdown'>
                    <NotificationPopover />
                  </li>
                  <li className='nav-item'>
                    {authenticated ? (
                      <button
                        data-testid='disconnect-button'
                        className='rounded-full w-10 h-10 bg-red-500 flex items-center justify-center'
                        onClick={handleDisconnect}
                        disabled={!ready}
                      >
                        <IoIosLogOut className='text-white w-6 h-6 translate-x-[1px]' />
                      </button>
                    ) : null}
                  </li>
                </ul>
              ) : null}
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}
