import { EventPropertyRow, FormattedCurrency } from '@newComponents'
import { getTruncatedAddress } from '@utils'
import { twMerge } from 'tailwind-merge'

interface TokenPurchaseSummaryProps {
  className?: string
  userAmount: CurrencyValue
  tokensPurchased: CurrencyValue
  purchaseFees: {
    protocolFeeInPaymentMethodCurrency: CurrencyValue
    platformFeeInNativeCurrency: CurrencyValue
  }
  recipientWallet: EvmAddress
  isNativePaymentMethod: boolean
}

export const TokenPurchaseSummary = ({
  className,
  userAmount,
  tokensPurchased,
  purchaseFees,
  recipientWallet,
  isNativePaymentMethod,
}: TokenPurchaseSummaryProps) => {
  const totalNativeCostValue = isNativePaymentMethod
    ? userAmount.value.plus(purchaseFees.platformFeeInNativeCurrency.value)
    : purchaseFees.platformFeeInNativeCurrency.value

  const totalCost: CurrencyValue = {
    value: totalNativeCostValue,
    symbol: userAmount.symbol,
  }

  return (
    <div
      className={twMerge(
        className,
        'flex flex-col items-center justify-between gap-2 mx-auto mb-4 border border-gray-300 rounded-md p-4',
      )}
    >
      <EventPropertyRow
        className='w-full'
        label='Network Fee'
        value={
          <FormattedCurrency
            currencyValue={purchaseFees.protocolFeeInPaymentMethodCurrency}
          />
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Processing Fee'
        value={
          <FormattedCurrency
            currencyValue={purchaseFees.platformFeeInNativeCurrency}
          />
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Total Cost'
        value={
          <>
            {isNativePaymentMethod ? (
              <FormattedCurrency currencyValue={totalCost} />
            ) : (
              <>
                <FormattedCurrency currencyValue={userAmount} /> +{' '}
                <FormattedCurrency
                  currencyValue={purchaseFees.platformFeeInNativeCurrency}
                />
              </>
            )}
          </>
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Tokens Purchased'
        value={
          <span>
            ~
            <FormattedCurrency
              currencyValue={tokensPurchased}
              displayDecimals={0}
            />
          </span>
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Recipient Wallet'
        value={getTruncatedAddress(recipientWallet)}
      />
    </div>
  )
}
