import { useCurrencyRegistry } from '@contexts'
import { EventPropertyRow, FormattedCurrency } from '@newComponents'
import { getTruncatedAddress } from '@utils'
import { twMerge } from 'tailwind-merge'

interface NodePurchaseSummaryProps {
  className?: string
  nodesPurchased: number
  pricePerNodeInUsd: CurrencyValue
  purchaseFees: {
    protocolFeeInPaymentMethodCurrency: CurrencyValue
    platformFeeInNativeCurrency: CurrencyValue
  }
  recipientWallet: EvmAddress
  paymentMethod: TokenSymbol
  isNativePaymentMethod: boolean
}

export const NodePurchaseSummary = ({
  className,
  nodesPurchased,
  pricePerNodeInUsd,
  purchaseFees,
  recipientWallet,
  paymentMethod,
  isNativePaymentMethod,
}: NodePurchaseSummaryProps) => {
  const { convert } = useCurrencyRegistry()
  const pricePerNodeInPaymentMethodCurrency = convert(
    pricePerNodeInUsd,
    paymentMethod,
  )

  const userAmountInPaymentMethodCurrency = {
    value: pricePerNodeInPaymentMethodCurrency.value.times(nodesPurchased),
    symbol: paymentMethod,
  }

  const totalNativeCostValue = isNativePaymentMethod
    ? userAmountInPaymentMethodCurrency.value.plus(
        purchaseFees.platformFeeInNativeCurrency.value,
      )
    : purchaseFees.platformFeeInNativeCurrency.value

  const totalCost: CurrencyValue = {
    value: totalNativeCostValue,
    symbol: pricePerNodeInPaymentMethodCurrency.symbol,
  }

  return (
    <div
      className={twMerge(
        className,
        'flex flex-col items-center justify-between gap-2 rounded-md text-sm',
      )}
    >
      <EventPropertyRow
        className='w-full'
        label='Network Fee (1%)'
        value={
          <FormattedCurrency
            currencyValue={purchaseFees.protocolFeeInPaymentMethodCurrency}
          />
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Processing Fee ($1 USD)'
        value={
          <FormattedCurrency
            currencyValue={purchaseFees.platformFeeInNativeCurrency}
          />
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Total Cost'
        value={
          <div>
            {isNativePaymentMethod ? (
              <FormattedCurrency currencyValue={totalCost} />
            ) : (
              <>
                <FormattedCurrency
                  currencyValue={userAmountInPaymentMethodCurrency}
                />{' '}
                +{' '}
                <FormattedCurrency
                  currencyValue={purchaseFees.platformFeeInNativeCurrency}
                />
              </>
            )}
          </div>
        }
      />
      <EventPropertyRow
        className='w-full'
        label='Nodes Purchased'
        value={<span>{nodesPurchased}</span>}
      />
      <EventPropertyRow
        className='w-full'
        label='Recipient Wallet'
        value={getTruncatedAddress(recipientWallet)}
      />
    </div>
  )
}
