import { div, mult, sub, toBigNumber } from '@utils'
import { BigNumber } from 'bignumber.js'

// Interfaces
export interface Tranche {
  id: string
  index?: number
  time: number
  vestedFraction: string
}

export interface TierVesting {
  price: string
  id: string
  vestedFraction: string
  oracle: string
}

// Enums
export enum DISTRIBUTOR_TYPES {
  AdvancedDistributor = 'AdvancedDistributor',
  CrosschainDistributor = 'CrosschainDistributor',
  IConnext = 'IConnext',
  IContinuousVesting = 'IContinuousVesting',
  IDistributor = 'IDistributor',
  IMerkleSet = 'IMerkleSet',
  IPriceTierVesting = 'IPriceTierVesting',
  ITrancheVesting = 'ITrancheVesting',
  IVesting = 'IVesting',
  IVoting = 'IVoting',
  IVotingWithoutDelegation = 'IVotingWithoutDelegation',
  Satellite = 'Satellite',
}

// Helper Functions
export const isInterfaceType = (
  interfaces: any[],
  type: DISTRIBUTOR_TYPES,
): boolean => {
  return interfaces?.some((iface) => iface.name === type)
}

export const getCurrentTimeInSeconds = (): number => {
  return Date.now() / 1000
}

// Vesting Calculations
export const getVestedFractionForContinuous = (
  start: number,
  cliff: number,
  end: number,
  fractionDenominator: BigNumber,
): BigNumber => {
  const currentTime = getCurrentTimeInSeconds()

  if (currentTime <= cliff) {
    return toBigNumber(0)
  }

  if (currentTime >= end) {
    return fractionDenominator
  }

  const timeElapsed = sub(currentTime, start)
  const vestingDuration = sub(end, start)
  const vestedFraction = div(
    mult(fractionDenominator, timeElapsed),
    vestingDuration,
  )

  return toBigNumber(vestedFraction)
}

export const getVestedFractionForTranche = (tranches: Tranche[]): BigNumber => {
  const currentTime = getCurrentTimeInSeconds()

  const sortedTranches = tranches.sort(
    (a, b) => Number(a.index) - Number(b.index),
  )
  let vestedFraction = toBigNumber(0)

  for (const tranche of sortedTranches) {
    if (currentTime > tranche.time) {
      vestedFraction = toBigNumber(tranche.vestedFraction)
    } else {
      break // No need to check further tranches
    }
  }

  return vestedFraction
}

export const getVestedFractionForPriceTier = (
  tierVesting: TierVesting[],
  oraclePrice: number,
): BigNumber => {
  if (!oraclePrice) {
    return toBigNumber(0)
  }

  const sortedTiers = tierVesting.sort(
    (a, b) => Number(a.price) - Number(b.price),
  )
  let vestedFraction = toBigNumber(0)

  for (const tier of sortedTiers) {
    if (oraclePrice > Number(tier.price)) {
      vestedFraction = toBigNumber(tier.vestedFraction)
    } else {
      break
    }
  }

  return vestedFraction
}

// Main Function
// export const getClaimableAmountOnDistributor = (
//   interfaces: any[],
//   totalAllocationInWei: number | string | BigNumber,
//   startTimestamp?: number,
//   cliffTimestamp?: number,
//   endTimestamp?: number,
//   tranches?: Tranche[],
//   fractionDenominator?: BigNumber,
//   oraclePrice?: number,
//   tierVesting?: TierVesting[],
// ): BigNumber => {
//   if (!totalAllocationInWei || !fractionDenominator) {
//     return new BigNumber(0);
//   }

//   let vestedFractionAsDecimal: Maybe<BigNumber> = null;

//   if (
//     isInterfaceType(interfaces, DISTRIBUTOR_TYPES.IContinuousVesting) &&
//     startTimestamp !== undefined &&
//     cliffTimestamp !== undefined &&
//     endTimestamp !== undefined
//   ) {
//     vestedFractionAsDecimal = getVestedFractionForContinuous(
//       startTimestamp,
//       cliffTimestamp,
//       endTimestamp,
//       fractionDenominator,
//     );
//   }

//   if (
//     isInterfaceType(interfaces, DISTRIBUTOR_TYPES.ITrancheVesting) &&
//     tranches?.length
//   ) {
//     vestedFractionAsDecimal = getVestedFractionForTranche(tranches);
//     console.info('Vested Fraction:', vestedFractionAsDecimal.toString());
//   }

//   if (
//     isInterfaceType(interfaces, DISTRIBUTOR_TYPES.IPriceTierVesting) &&
//     tierVesting?.length &&
//     oraclePrice !== undefined
//   ) {
//     vestedFractionAsDecimal = getVestedFractionForPriceTier(
//       tierVesting,
//       oraclePrice,
//     );
//     console.info('Vested Fraction:', vestedFractionAsDecimal.toString());
//   }

//   if (vestedFractionAsDecimal === null) {
//     throw new Error('No valid vesting type found');
//   }

//   // Final calculation
//   return new BigNumber(
//     div(
//       mult(totalAllocationInWei, vestedFractionAsDecimal),
//       fractionDenominator,
//     ),
//   );
// };

export const getClaimableAmountForContinuousVesting = (
  totalAllocationInWei: number | string | BigNumber,
  startTimestamp: number,
  cliffTimestamp: number,
  endTimestamp: number,
  fractionDenominator: BigNumber,
): BigNumber => {
  if (!totalAllocationInWei || !fractionDenominator) {
    return new BigNumber(0)
  }

  const vestedFractionAsDecimal = getVestedFractionForContinuous(
    startTimestamp,
    cliffTimestamp,
    endTimestamp,
    fractionDenominator,
  )

  return calculateClaimableAmountInWei(
    totalAllocationInWei,
    vestedFractionAsDecimal,
    fractionDenominator,
  )
}

export const getClaimableAmountForTrancheVesting = (
  totalAllocationInWei: number | string | BigNumber,
  tranches: Tranche[],
  fractionDenominator: BigNumber,
): BigNumber => {
  if (!totalAllocationInWei || !fractionDenominator || !tranches?.length) {
    return new BigNumber(0)
  }

  const vestedFractionAsDecimal = getVestedFractionForTranche(tranches)
  console.info('Vested Fraction:', vestedFractionAsDecimal.toString())

  return calculateClaimableAmountInWei(
    totalAllocationInWei,
    vestedFractionAsDecimal,
    fractionDenominator,
  )
}

export const getClaimableAmountForPriceTierVesting = (
  totalAllocationInWei: number | string | BigNumber,
  tierVesting: TierVesting[],
  oraclePrice: number,
  fractionDenominator: BigNumber,
): BigNumber => {
  if (
    !totalAllocationInWei ||
    !fractionDenominator ||
    !tierVesting?.length ||
    oraclePrice === undefined
  ) {
    return new BigNumber(0)
  }

  const vestedFractionAsDecimal = getVestedFractionForPriceTier(
    tierVesting,
    oraclePrice,
  )
  console.info('Vested Fraction:', vestedFractionAsDecimal.toString())

  return calculateClaimableAmountInWei(
    totalAllocationInWei,
    vestedFractionAsDecimal,
    fractionDenominator,
  )
}

export const calculateClaimableAmountInWei = (
  totalAllocationInWei: number | string | BigNumber,
  vestedFraction: BigNumber,
  fractionDenominator: BigNumber,
): BigNumber => {
  return new BigNumber(
    div(mult(totalAllocationInWei, vestedFraction), fractionDenominator),
  )
}
