import { Col, FormLabel, Row } from '@components'
import classNames from 'classnames'
import { KeyboardEventHandler, ReactNode, useEffect, useState } from 'react'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import './multi-select.css'

interface MultiSelectProps {
  id?: string
  name?: string
  label?: string
  options?: any
  onChange: Function
  width?: Pick<React.ComponentProps<typeof Row>, 'width'>['width']
  value: any
  defaultValue: any
  disabled?: boolean
  required?: boolean
  readonly?: boolean
  valid?: boolean
  isMulti?: boolean
  isCreateable?: boolean
  isSearchable?: boolean
  className?: string
  error?: ReactNode
  append?: ReactNode
  prepend?: ReactNode
}

export const MultiSelect = ({
  id,
  name,
  label,
  options,
  onChange,
  width,
  value,
  defaultValue,
  disabled,
  required,
  readonly = false,
  valid = true,
  isMulti = true,
  isSearchable = true,
  isCreateable = false,
  className,
  append,
  prepend,
  error,
}: MultiSelectProps) => {
  const [requiredValidationMessage, setRequiredValidationMessage] =
    useState<Maybe<string>>(null)
  const [inputValue, setInputValue] = useState<string>('')

  const key = id || name

  const components = {
    DropdownIndicator: null,
  }

  useEffect(() => {
    if (readonly) {
      setRequiredValidationMessage(null)
    }
  }, [readonly])

  const handleOnChange = (values: any) => {
    if (!readonly && required && !values) {
      setRequiredValidationMessage(`${label} is required`)
    } else {
      setRequiredValidationMessage(null)
    }

    onChange && onChange(values)
  }

  const handleCreateableOnChange = (values: any) => {
    if (!readonly && required && !values) {
      setRequiredValidationMessage(`${label} is required`)
    } else {
      setRequiredValidationMessage(null)
    }

    onChange && onChange(values)
  }

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        onChange &&
          onChange([
            ...value,
            {
              label: inputValue,
              value: inputValue,
            },
          ])
        setInputValue('')
        event.preventDefault()
    }
  }

  return (
    <Row
      width={width}
      className={classNames(
        readonly && 'readonly',
        'input-group-container',
        className,
        !valid ? 'invalid' : '',
      )}
    >
      <Col>
        {label && (
          <FormLabel data-testid={`${key}-label`}>
            <label htmlFor={name}>
              {label}
              {required && ' *'}
            </label>
          </FormLabel>
        )}
      </Col>
      {isCreateable ? (
        <div className='multi-select__group createable' data-testid={`${key}`}>
          {prepend}
          <CreatableSelect
            components={components}
            inputValue={inputValue}
            isClearable
            isMulti
            menuIsOpen={false}
            onChange={handleCreateableOnChange}
            onInputChange={(newValue) => setInputValue(newValue)}
            onKeyDown={handleKeyDown}
            placeholder='Enter a value and press enter...'
            value={value}
          />
          {append}
        </div>
      ) : (
        <div className='multi-select__group' data-testid={`${key}`}>
          {prepend}
          <Select
            id={name}
            className='multi-select'
            classNamePrefix='multi-select'
            isDisabled={disabled}
            isSearchable={isSearchable}
            value={value}
            defaultValue={defaultValue}
            options={options}
            isMulti={isMulti}
            onChange={handleOnChange}
            data-lpignore='true'
          />
          {append}
        </div>
      )}
      {requiredValidationMessage && (
        <span className='form-control-error' data-testid={`${key}-error`}>
          {requiredValidationMessage}
        </span>
      )}
      {error && !requiredValidationMessage && (
        <span className='form-control-error' data-testid={`${key}-error`}>
          {error}
        </span>
      )}
    </Row>
  )
}
