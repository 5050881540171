import { useNotifications } from '@apiServices'
import { Alert, Text } from '@components'
import { NOTIFICATION_TYPE } from '@enums'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const KYCNotificationBanner = () => {
  const { notifications } = useNotifications()
  const navigate = useNavigate()
  const [showKycAddiitonalInfoBanner, setShowKycAddiitonalInfoBanner] =
    useState(false)

  useEffect(() => {
    const KYCNotifications = notifications.filter(
      (notification: any) =>
        notification.type.name.split('.')[0].toLowerCase() === 'kyc' &&
        notification.status === 'unread',
    )

    for (const notification of KYCNotifications) {
      if (
        notification.type.name === NOTIFICATION_TYPE.KYC_COMPLETED ||
        notification.type.name === NOTIFICATION_TYPE.KYC_EXPIRED ||
        notification.type.name === NOTIFICATION_TYPE.KYC_FAILED
      ) {
        setShowKycAddiitonalInfoBanner(false)
        break
      }
      if (
        notification.type.name ===
        NOTIFICATION_TYPE.KYC_ADDITIONAL_INFO_REQUESTED
      ) {
        setShowKycAddiitonalInfoBanner(true)
        break
      }
    }
  }, [notifications])

  return showKycAddiitonalInfoBanner ? (
    <Alert type='alert-warning'>
      <Text>
        You have a kyc action pending:{' '}
        <a
          className='hover:cursor-pointer'
          onClick={() => navigate('/account/identity')}
        >
          Additional Info Requested
        </a>
      </Text>
    </Alert>
  ) : null
}
