import { Col, EventTypeCard, PageHeader, Section, Stretched } from '@components'
import { useProject } from '@contexts'
import { EVENT_TYPE } from '@enums'

interface SelectEventTypePageProps {
  setSelectedEventType: Function
  selectedEventType: EVENT_TYPE
}

export const SelectEventTypePage = ({
  setSelectedEventType,
  selectedEventType,
}: SelectEventTypePageProps) => {
  const { project } = useProject()
  const eventTypesList = [
    {
      type: EVENT_TYPE.IDENTITY,
      name: 'Identity',
      description: 'Connect Identities & Wallets.',
    },
    {
      type: EVENT_TYPE.RAISE,
      name: 'Sale',
      description: 'Fundraise from the General Public.',
      helpText:
        'Anyone who meets your compliance requirements will be able to participate.',
    },
    {
      type: EVENT_TYPE.DISTRIBUTE,
      name: 'Distribute',
      description: 'Distribute Tokens with Time- or Price-Based Vesting.',
    },
  ]

  return (
    <Col>
      <PageHeader
        title={'Event Type'}
        subtitle='Please select the type of event you would like to set up and launch.'
      />

      <Section>
        <Stretched gap={5} place={'between-start'}>
          {eventTypesList.map((eventTypeListEntry, index) => (
            <EventTypeCard
              id={`event-type-card-${eventTypeListEntry.name.toLowerCase()}`}
              key={index}
              type={eventTypeListEntry.type}
              name={eventTypeListEntry.name}
              description={eventTypeListEntry.description}
              helpText={eventTypeListEntry.helpText}
              className={
                selectedEventType === eventTypeListEntry.type ? 'selected' : ''
              }
              setSelectedEventType={setSelectedEventType}
            />
          ))}
        </Stretched>
      </Section>
    </Col>
  )
}
