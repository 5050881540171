import { useGetEventPrivacyConfiguration } from '@apiServices'
import {
  AdminEventDetails,
  EventDistributorSummary,
  EventSaleSummary,
  PageHeader,
  Stacked,
} from '@components'
import { useEvent, useProject } from '@contexts'
import { EventPrivacyConfiguration } from '@customTypes'
import { EVENT_TYPE } from '@enums'
import {
  EventContentPage,
  EventDocumentsPage,
  EventEligibilityPage,
  EventPrivacyOverviewPage,
  EventPublicOverviewPage,
} from '@pages'
import { useLocation, useNavigate } from 'react-router-dom'

export const EditEventDetailsPage = () => {
  const { event } = useEvent()

  const { data: privacyConfiguration, isPendingEventPrivacyConfiguration } =
    useGetEventPrivacyConfiguration(event.id ?? null)

  // If navigated here with a state, use that state as the privacy configuration;
  // otherwise, use the fetched privacy configuration
  const { state } = useLocation()
  const receivedPrivacyConfiguration = state as EventPrivacyConfiguration

  const activePrivacyConfiguration =
    receivedPrivacyConfiguration ?? privacyConfiguration

  const { project } = useProject()
  const navigate = useNavigate()

  const onEditPrivacyConfiguration = () => {
    const projectId = project?.id
    const eventId = event.id

    if (!projectId || !eventId) {
      return
    }

    navigate(`/admin/project/${projectId}/event/${eventId}/privacy`, {
      state: activePrivacyConfiguration,
    })
  }

  return (
    <>
      <PageHeader
        title={`Edit Event Details`}
        breadcrumb={
          <>
            <a
              className='cursor-pointer'
              onClick={() => {
                navigate(`/admin/project/${project?.id}`)
              }}
            >
              &lt; {project?.name}
            </a>{' '}
            &lt;{' '}
            <a
              className='cursor-pointer'
              onClick={() => {
                navigate(`/admin/project/${project?.id}/event/${event.id}`)
              }}
            >
              Event Dashboard
            </a>{' '}
            &lt; Edit Event Details
          </>
        }
      />

      <Stacked>
        <AdminEventDetails readonly={true} />

        <EventEligibilityPage context={event} />

        {isPendingEventPrivacyConfiguration ? (
          <div>Loading...</div>
        ) : activePrivacyConfiguration ? (
          <EventPrivacyOverviewPage
            privacyConfiguration={activePrivacyConfiguration}
            onEdit={onEditPrivacyConfiguration}
          />
        ) : (
          <EventPublicOverviewPage onEdit={onEditPrivacyConfiguration} />
        )}

        <EventDocumentsPage context={event} />

        <EventContentPage context={event} />

        {event.type === EVENT_TYPE.DISTRIBUTE ? (
          <EventDistributorSummary event={event} readonly />
        ) : null}

        {[EVENT_TYPE.RAISE, EVENT_TYPE.SALE].includes(event.type) ? (
          <EventSaleSummary event={event} readonly />
        ) : null}
      </Stacked>
    </>
  )
}
