import { ButtonRow, PageHeader, PageSubtitle, PageTitle } from '@components'
import { Link } from 'react-router-dom'

interface WhitelabelRestrictionProps {
  className?: string
  style?: React.CSSProperties
}

export const WhitelabelRestriction = ({
  className,
  style,
}: WhitelabelRestrictionProps) => {
  return (
    <>
      <PageHeader className={className}>
        <PageTitle style={style}>We're sorry!</PageTitle>
        <PageSubtitle style={style}>
          This feature is not available at this time.
        </PageSubtitle>
      </PageHeader>

      <ButtonRow place={'center'}>
        <Link className='btn btn-primary' to='/'>
          Dashboard
        </Link>
      </ButtonRow>
    </>
  )
}
