import { Error } from '@components'
import { FC } from 'react'

interface PermissionDeniedErrorProps {
  message?: string
}

export const PermissionDeniedError: FC<PermissionDeniedErrorProps> = ({
  message,
}) => {
  const errorTitle = 'Permisson Denied'
  const errorMessage =
    message || 'You do not have permission to access this page.'

  return (
    <>
      <Error title={errorTitle} message={errorMessage} />
    </>
  )
}
