import { ProfileContext, useAccount } from '@contexts'
import { FC, ReactNode, useState } from 'react'

interface ProfileProviderProps {
  children?: ReactNode
}

export const ProfileProvider: FC<ProfileProviderProps> = ({ children }) => {
  const {
    account,
    loading,
    tier1Status,
    isTier1Started,
    isTier1Finished,
    isTier1Completed,
    tier1CurrentState,
    tier3Status,
    isTier3Started,
    isTier3Finished,
    isTier3Eligible,
    isTier3Completed,
    tier3CurrentState,
    isProfileSetupComplete,
  } = useAccount()
  const [view, setView] = useState('profile-details')

  return (
    <ProfileContext.Provider
      value={{
        account: account,
        loading: loading,
        view: view,
        setView: setView,
        hasVerifiedEmail: account?.emailVerified ?? false,
        verificationEmailSent: account?.verificationEmailSent ?? false,
        tier1Status: tier1Status,
        isTier1Started: isTier1Started,
        isTier1Finished: isTier1Finished,
        isTier1Completed: isTier1Completed,
        tier1CurrentState: tier1CurrentState,
        tier3Status: tier3Status,
        isTier3Started: isTier3Started,
        isTier3Finished: isTier3Finished,
        hasSetProfileDetails: !!account?.firstName && !!account?.lastName,
        isProfileSetupComplete: isProfileSetupComplete,
        isTier3Eligible: isTier3Eligible,
        isTier3Completed: isTier3Completed,
        tier3CurrentState: tier1CurrentState,
      }}
    >
      {children}
    </ProfileContext.Provider>
  )
}
