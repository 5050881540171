import { Button } from '@components'
import { NumberInput, SelectInput } from '@newComponents'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form'
import { SaleSubgraphPaymentMethod } from 'tokensoft-shared-types'
import { NodePurchaseFormInputs } from '../node-sale/node-purchase-flow'
import { TokenPurchaseFormInputs } from './token-purchase-flow'

interface TokenPurchaseFormProps {
  errors: FieldErrors<TokenPurchaseFormInputs>
  onMaxClick: () => void
  onMinClick: () => void
  paymentMethods: string[]
  register: UseFormRegister<TokenPurchaseFormInputs>
  trigger: UseFormTrigger<NodePurchaseFormInputs>
  control: Control<NodePurchaseFormInputs>
  selectedPaymentMethod: SaleSubgraphPaymentMethod
  validators: FormValidators<TokenPurchaseFormInputs>
}

export const TokenPurchaseForm = ({
  errors,
  onMaxClick,
  onMinClick,
  paymentMethods,
  register,
  trigger,
  control,
  selectedPaymentMethod,
  validators,
}: TokenPurchaseFormProps) => {
  return (
    <>
      <SelectInput
        id='paymentMethod'
        label='Payment Method'
        options={paymentMethods.map((method) => ({
          value: method,
          label: method,
        }))}
        onValueChanged={() => void trigger('paymentMethod')}
        control={control}
        rules={{
          required: 'Payment method is required',
          validate: validators.paymentMethod,
        }}
        disabled={paymentMethods.length === 1}
        error={errors.paymentMethod?.message}
      />

      <div>
        <NumberInput
          id='amount'
          label='Amount'
          placeholder={`Amount (${selectedPaymentMethod.symbol})`}
          validate={validators.amount}
          register={register}
          error={errors.amount?.message}
        />
        <div className='flex justify-end items-center gap-2'>
          <Button size='xs' onClick={onMinClick}>
            Min
          </Button>
          <Button size='xs' onClick={onMaxClick}>
            Max
          </Button>
        </div>
      </div>
    </>
  )
}
