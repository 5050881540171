import { Row } from '@components'
import classNames from 'classnames'
import './button.css'

type HTMLAnchorProps = React.AnchorHTMLAttributes<HTMLAnchorElement>

type ButtonLinkProps = HTMLAnchorProps & {
  color?: 'primary' | 'secondary' | 'tertiary' | 'danger' | 'success'
  fill?: 'ghost' | 'solid' | 'outline'
  size?: 'sm' | 'md' | 'lg'
  disabled?: boolean
  children: React.ReactNode
}

export const ButtonLink: React.FC<ButtonLinkProps> = ({
  className = '',
  href,
  target,
  color = 'primary',
  fill = null,
  size = null,
  disabled = false,
  children,
  ...restProps
}) => {
  return (
    <a
      href={href}
      target={target}
      rel='noreferrer'
      onClick={(e) => e.stopPropagation()}
      className={classNames(
        'btn truncate',
        !fill ? `btn-${color}` : '',
        fill ? `btn-${fill}-${color}` : '',
        size ? `btn-${size}` : '',
        disabled ? 'disabled' : '',
        className,
      )}
      {...restProps}
    >
      <Row nowrap gap={2.5} place={'center'}>
        {children}
      </Row>
    </a>
  )
}
