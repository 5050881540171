import { Col } from '@components'
import { ReactNode } from 'react'

interface StepProps {
  children?: ReactNode
}

export const Step: React.FC<StepProps> = ({ children }) => {
  return (
    <Col className={'flex-1'} gap={12}>
      {children}
    </Col>
  )
}
