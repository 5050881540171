import { SocialIcon, SocialIconProps } from './social-icon'

interface SocialIconListProps {
  socialsProps: SocialIconProps[]
}

export const SocialIconList = ({
  socialsProps: socialsProps,
}: SocialIconListProps) => {
  return (
    <div className='flex items-start p-0 gap-2'>
      {socialsProps.map((props, index) => (
        <SocialIcon key={index} {...props} />
      ))}
    </div>
  )
}
