import { useGetDistributor } from '@apiServices'
import {
  addEventTranches,
  Alert,
  Button,
  Card,
  DistributorOwnerSummary,
  DistributorParticipatesSummary,
  DistributorProgress,
  DistributorVestingSummary,
  generateTranches,
  LoadingIndicator,
  renderVestingChart,
  Row,
  Stacked,
  Text,
} from '@components'
import { DATE_TIME_ISO_FORMAT } from '@constants'
import { useAccount } from '@contexts'
import { EVENT_STATUS, VESTING_TYPE } from '@enums'
import { addToUtcUnixDateTime, utcToUtcUnixDateTime } from '@utils'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { FaRocket } from 'react-icons/fa'
import { RiFundsLine } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'

export const EventDistributorSummary = ({ event, readonly = false }: any) => {
  const { account } = useAccount()
  const navigate = useNavigate()
  const [distributorData, setDistributorData] = useState<Maybe<any>>(null)
  const [claimsData, setClaimsData] = useState<Maybe<any>>(null)

  const { distributor, loading } = useGetDistributor(event?.distributor?.id)

  const isDeployedOnChain = event?.distributor?.id

  const handleDeploy = () => {
    navigate(
      `/admin/project/${event.project.id}/event/${event.id}/deploy/distributor`,
    )
  }

  const generateDummyData = () => {
    // generate fake data and blur out the chart
    const eventStartTime = utcToUtcUnixDateTime('01/01/2023')
    const startTime = utcToUtcUnixDateTime('02/01/2023')
    const cliffTime = utcToUtcUnixDateTime('03/01/2023')
    const endTime = utcToUtcUnixDateTime('10/01/2023')
    const eventEndTime = utcToUtcUnixDateTime('01/01/2024')

    setDistributorData({
      account: {
        timezone: account.timezone,
      },
      event: {
        ...event,
        startTime: eventStartTime,
        endTime: eventEndTime,
      },
      tokenSymbol: 'DEMO',
      totalAllocations: 500000,
      vestingType: VESTING_TYPE.TRANCHE,
      startTime: startTime,
      cliffTime: cliffTime,
      endTime: endTime,
      trancheVesting: {
        tranches: generateTranches(
          eventStartTime,
          startTime,
          cliffTime,
          endTime,
          eventEndTime,
          'month',
          VESTING_TYPE.TRANCHE,
        ),
      },
    })

    setClaimsData([])
  }

  useEffect(() => {
    if (!event) {
      return
    }

    if (!isDeployedOnChain) {
      generateDummyData()
    }
  }, [event])

  useEffect(() => {
    if (!distributor) {
      generateDummyData()
      return
    }

    const vestingType = distributor.trancheVesting
      ? VESTING_TYPE.TRANCHE
      : VESTING_TYPE.CONTINUOUS
    const eventStartTime = distributor.event.startTime
      ? utcToUtcUnixDateTime(distributor.event.startTime, DATE_TIME_ISO_FORMAT)
      : null
    const eventEndTime = distributor.event.endTime
      ? utcToUtcUnixDateTime(distributor.event.endTime, DATE_TIME_ISO_FORMAT)
      : null

    let startTime
    let cliffTime
    let endTime
    let trancheVesting

    if (vestingType === VESTING_TYPE.CONTINUOUS) {
      startTime = eventStartTime || distributor.continuousVesting?.start || 0
      cliffTime = distributor.continuousVesting?.cliff || 0
      endTime = distributor.continuousVesting?.end || 0
    } else if (vestingType === VESTING_TYPE.TRANCHE) {
      const tranches = distributor.trancheVesting.tranches.sort(function (
        a: any,
        b: any,
      ) {
        return Number(a.index) - Number(b.index)
      })
      startTime = eventStartTime
      cliffTime = tranches[0].time
      endTime = addToUtcUnixDateTime(
        tranches[tranches.length - 1].time,
        1,
        'months',
      )
      trancheVesting = {
        tranches: addEventTranches(
          tranches,
          eventStartTime,
          startTime,
          cliffTime,
          endTime,
          eventEndTime,
        ),
      }
    }

    setDistributorData({
      ...distributor,
      trancheVesting: trancheVesting,
      account: {
        timezone: account.timezone,
      },
      event: {
        ...event,
        status: event.status,
        startTime: Number(eventStartTime),
        endTime: Number(eventEndTime),
      },
      totalAllocations: distributor.total,
      vestingType: vestingType,
      startTime: Number(startTime),
      cliffTime: Number(cliffTime),
      endTime: Number(endTime),
      unixStartTime: Number(startTime),
      unixCliffTime: Number(cliffTime),
      unixEndTime: Number(endTime),
      tokenInfo: {
        symbol: distributor.tokenSymbol,
        decimals: distributor.tokenDecimals,
      },
    })

    setClaimsData([])
  }, [distributor])

  const renderVestingChartHandler = () => {
    return renderVestingChart(
      distributorData,
      distributorData.vestingType,
      distributorData.startTime,
      distributorData.cliffTime,
      distributorData.endTime,
      distributorData.event.endTime,
      account,
    )
  }

  if (!distributorData) {
    return (
      <Card
        className={classNames(
          'col-span-3',
          !isDeployedOnChain || !distributor ? 'blur' : '',
        )}
      >
        {loading ? (
          <LoadingIndicator
            text={'Loading Data'}
            className={'no-padding h-96'}
          />
        ) : null}
      </Card>
    )
  }

  //TODO: this needs to be changed to check if the distributor has funds
  const distributorFunded = true

  if (readonly) {
    if (distributorData && isDeployedOnChain) {
      return (
        <Stacked>
          <DistributorVestingSummary context={distributorData} />
          <DistributorParticipatesSummary context={distributorData} />
          <DistributorOwnerSummary
            context={distributorData}
            setContext={setDistributorData}
          />
        </Stacked>
      )
    }
    return <></>
  }

  return (
    <>
      {isDeployedOnChain && !distributor && !loading ? (
        <Alert type='alert-warning'>
          <Text>Data Pending</Text>
        </Alert>
      ) : null}

      {!isDeployedOnChain && event?.status === EVENT_STATUS.COMPLETED ? (
        <Alert type='alert-warning'>
          <Text>Event Closed</Text>
        </Alert>
      ) : null}

      <Row place={'center'} className={'relative'}>
        {!isDeployedOnChain && event?.status !== EVENT_STATUS.COMPLETED ? (
          <Button className={'btn-floating'} onClick={handleDeploy}>
            <FaRocket />
            <Text>Launch Distribution</Text>
          </Button>
        ) : null}

        <Card
          className={classNames(
            !isDeployedOnChain || !distributor ? 'blur' : '',
          )}
        >
          {renderVestingChartHandler()}
        </Card>
      </Row>

      <Row place={'center'} className={'relative'}>
        {/* TODO: this will use the info about if the distirbutor has funds, the button will be displayed if has no funds and the event is not closed */}
        {!distributorFunded &&
        isDeployedOnChain &&
        event?.status !== EVENT_STATUS.COMPLETED ? (
          <Button className={'btn-floating'} onClick={handleDeploy}>
            <RiFundsLine />
            <Text>Fund Distributor</Text>
          </Button>
        ) : null}
        <DistributorProgress
          context={distributorData}
          className={classNames(
            !isDeployedOnChain || !distributor || !distributorFunded
              ? 'blur'
              : '',
          )}
        />
      </Row>
    </>
  )
}
