import { CLAIM_STEP } from '@enums'
import { createContext, useContext } from 'react'

interface DistributorContextProps {
  event: any
  distributor: any
  isLoading: boolean
  delay: any
  documentsToAcceptOrSign: any[]
  pageState: CLAIM_STEP
  setPageState: Function
  delegateSaved: any
  setDelegateSaved: Function
  pendingTransaction: any
  onPendingTransaction: Function
  completedTransaction: any
  onCompletedTransaction: Function
  correctNetworkChainId: number
}

export const DistributorContext =
  createContext<Maybe<DistributorContextProps>>(null)
DistributorContext.displayName = 'DistributorContext'

export const useDistributor = (): DistributorContextProps => {
  const context = useContext(DistributorContext)

  if (!context) {
    throw new Error(
      'DistributorContext context is undefined, please verify you are calling useDistributor() as child of a <DistributorContext> component.',
    )
  }

  return context
}
