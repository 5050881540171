import { Col, PageHeader, PageSubtitle, PageTitle } from '@components'
import { useHideSidebar } from '@hooks'

export const ErrorPage = () => {
  useHideSidebar()

  return (
    <>
      <Col place={'center'}>
        <PageHeader>
          <PageTitle>We're sorry!</PageTitle>
          <PageSubtitle>
            It looks like this isn't a valid project. Please check the URL and
            try again.
          </PageSubtitle>
        </PageHeader>
      </Col>
    </>
  )
}
