import { useToast } from '@contexts'
import { useBuyWithNative } from '@hooks'
import { LoaderButton } from '@newComponents'
import { useEffect } from 'react'
import { Blockchain, SaleSubgraphPaymentMethod } from 'tokensoft-shared-types'
import { BaseError, TransactionReceipt } from 'viem'

interface NativePurchaseButtonProps {
  saleAddress: EvmAddress
  chain: Blockchain
  purchaseAmountInNative: CurrencyValue
  paymentMethod: SaleSubgraphPaymentMethod
  platformFeeRecipient: Maybe<EvmAddress>
  platformFeeInDollars: CurrencyValue
  platformFeeInNative: CurrencyValue
  onSuccess: (receipt: TransactionReceipt) => void
  disabled: boolean
  className?: string
}

export const NativePurchaseButton = ({
  saleAddress,
  chain,
  purchaseAmountInNative,
  paymentMethod,
  platformFeeRecipient,
  platformFeeInDollars,
  platformFeeInNative,
  onSuccess,
  disabled,
  className = '',
}: NativePurchaseButtonProps) => {
  const { showErrorToast } = useToast()

  const { buyWithNative, receipt, isPending, error } = useBuyWithNative(
    chain.id,
    saleAddress,
  )

  const handleBuy = async () => {
    const purchaseAmountInWei = purchaseAmountInNative.value.times(
      10 ** paymentMethod.decimals,
    )

    const platformFeeInWei = platformFeeInNative.value
      .times(10 ** paymentMethod.decimals)
      .decimalPlaces(0)

    await buyWithNative(
      purchaseAmountInWei,
      platformFeeRecipient,
      platformFeeInDollars,
      platformFeeInWei,
    )
  }

  useEffect(() => {
    if (error) {
      console.log('Error: ', error)
      showErrorToast({
        description:
          error instanceof BaseError ? error.shortMessage : error.message,
      })
    }
  }, [error])

  useEffect(() => {
    if (receipt) {
      onSuccess(receipt)
    }
  }, [receipt])

  return (
    <LoaderButton
      className={className}
      onClick={handleBuy}
      disabled={disabled}
      isLoading={isPending}
      loadingText='Purchasing...'
      text='Purchase'
    >
      Purchase
    </LoaderButton>
  )
}
