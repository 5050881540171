import { useGetProject } from '@apiServices'
import { AnalyticsContext } from '@contexts'
import { FC, ReactNode, useState } from 'react'
import ReactGA from 'react-ga4'

interface AnalyticsProviderProps {
  children?: ReactNode
}

export const AnalyticsProvider: FC<AnalyticsProviderProps> = ({ children }) => {
  const tokensoftTrackerId = import.meta.env.VITE_GA_TRACKING_ID || null
  const [initialized, setInitialized] = useState<boolean>(false)
  const { data: project } = useGetProject()

  /**
   * Initialize GA.  Note: tracking is only enabled if the tokensoft tracker is defined
   * in a given environment.
   */
  const initialize = () => {
    if (initialized) {
      return
    }

    if (tokensoftTrackerId) {
      console.log('Initializing Analytics...', tokensoftTrackerId)
      const trackingIds = [
        {
          trackingId: tokensoftTrackerId,
        },
      ]

      if (project?.gaTrackingId) {
        console.log(
          'Initializing Analytics for project ...',
          project?.gaTrackingId,
        )
        trackingIds.push({
          trackingId: project?.gaTrackingId,
        })
      }

      ReactGA.initialize(trackingIds)
      setInitialized(true)
    }
  }

  /**
   * Log a new event with GA. This will log to the tokensoft trackerId and to the  project trackerId if present in Project project
   */
  const pageEvent = (category: string, action: string, value?: number) => {
    let actionString = action.split('/').join('.')
    actionString =
      category +
      (actionString.charAt(0) === '.' ? actionString : '.' + actionString)

    initialize()
    ReactGA.event({
      category: category,
      action: actionString,
      value: value,
    })
  }

  return (
    <AnalyticsContext.Provider
      value={{
        pageEvent,
      }}
    >
      {children}
    </AnalyticsContext.Provider>
  )
}
