import { useEventApiClient } from '@apiClients'
import { formatEvent } from '@apiServices'
import { useAccount, useAuth, useNetworks, useToast } from '@contexts'
import { SALE_STATUS } from '@enums'
import { useAsync } from '@hooks'
// import * as Sentry from '@sentry/react';
import { SaleContractDefinition } from '@contractABIs'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  allowedRegionsToCountryList,
  blockedRegionsFromCountryList,
  getFlatPriceSaleABI,
  gt,
  min,
  mult,
  sub,
  unixToLocalDateTime,
} from '@utils'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Hex } from 'viem'
import { useReadContract } from 'wagmi'
import web3 from 'web3'

export const useValidateSaleParticipants = () => {
  const client = useEventApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/participants/validate`, {
        method: 'post',
        data,
      })
    },
  })
}

export const usePrepareSaleParticipantsUpdate = () => {
  const client = useEventApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/prepare/participants`, {
        method: 'post',
        data,
      })
    },
  })
}

export const usePrepareSale = () => {
  const client = useEventApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/prepare`, {
        method: 'post',
        data,
      })
    },
  })
}

export const useSaleDeployed = () => {
  const client = useEventApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/saleDeployed`, {
        method: 'post',
        data,
      })
    },
  })
}

export const useSaleConfigUpdated = () => {
  const client = useEventApiClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/saleConfigUpdated`, {
        method: 'post',
        data,
      })
    },
  })
}

export const useGetSaleParticipantsSummary = (saleId?: string) => {
  const client = useEventApiClient()

  return useQuery({
    enabled: !!saleId,
    queryKey: ['sales/participants', saleId],
    queryFn: () => client(`sales/${saleId}/participants/summary`),
  })
}

export const useGetSale = (isOwner: boolean = false, eventId: string) => {
  const { account } = useAccount()
  const {
    user: { walletAddress },
  } = useAuth()
  const { supportedNetworks } = useNetworks()
  const client = useEventApiClient()
  const { run, data } = useAsync()
  const [sale, setSale] = useState<Maybe<any>>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [reload, setReload] = useState<Date | null>(null)

  useEffect(() => {
    if (!reload) {
      return
    }

    const supportedNetworkChains = supportedNetworks.map((network) => {
      return { chainId: network.id }
    })

    run(
      client(`sales/find`, {
        method: 'post',
        data: {
          eventId,
          chains: supportedNetworkChains,
          walletAddress: walletAddress.toLowerCase(),
          isOwner: isOwner,
          includeIpfsConfig: true,
        },
      }),
    )
  }, [reload])

  useEffect(() => {
    if (walletAddress) {
      refresh()
    }
  }, [walletAddress])

  useEffect(() => {
    if (!data) {
      return
    }

    if (data?.sale) {
      const formattedSale = formatSale(data.sale, walletAddress, account)
      setSale(formattedSale)
      setLoading(false)
    } else {
      console.log('No sale records returned', data)
      setLoading(false)
    }
  }, [data])

  const refresh = () => {
    setSale(null)
    setLoading(true)
    setReload(new Date())
  }

  return { sale: sale, loading: loading, refresh: refresh }
}

export const useGetSaleContractOwner = (sale: {
  id: string
  chainId: number
}) => {
  const { data: owner, isError } = useReadContract({
    address: sale?.id as Hex,
    abi: getFlatPriceSaleABI(),
    functionName: 'owner',
    chainId: sale?.chainId,
    query: {
      enabled: !!sale,
    },
  }) as { data: string | undefined; isError: boolean }

  const { showErrorToast } = useToast()

  useEffect(() => {
    if (isError) {
      showErrorToast({
        description: 'Error fetching sale contract owner.',
      })
      // Sentry.captureException(e);
    }
  }, [isError, showErrorToast])

  return owner
}

const formatSale = (sale: any, walletAddress: string, account?: any) => {
  const formattedSale: any = {
    ...sale,
  }

  const allowedRegions = allowedRegionsToCountryList(sale.event?.allowedRegions)
  const blockedRegions = blockedRegionsFromCountryList(allowedRegions)

  formattedSale.restrictedCities = sale.restrictedCities
  formattedSale.blockedRegions = blockedRegions
  formattedSale.decimals = sale.decimals || '18'
  formattedSale.isOwner =
    web3.utils.toChecksumAddress(sale.owner.id) ===
    web3.utils.toChecksumAddress(walletAddress)
  formattedSale.status = setSaleStatus(sale)
  formattedSale.saleOpenDate = unixToLocalDateTime(
    sale.startTime,
    account?.timezone,
  )
  formattedSale.saleCloseDate = unixToLocalDateTime(
    sale.endTime,
    account?.timezone,
  )
  formattedSale.validSale = true
  formattedSale.purchaseDocumentsGenerated = false
  formattedSale.logo = Array.isArray(sale.logo) ? null : sale.logo
  formattedSale.favicon = Array.isArray(sale.favicon) ? null : sale.favicon
  formattedSale.proof = sale.authorization?.proof || sale.proof

  if (formattedSale.event) {
    formattedSale.event = formatEvent(sale.event)
  }

  return formattedSale
}

const formatSales = (data: any, walletAddress: string, account?: any) => {
  return data?.sales.map((sale: any) => {
    return formatSale(sale, walletAddress, account)
  })
}

const setSaleStatus = (sale: any): SALE_STATUS => {
  const { startTime, endTime, purchaseTotal, saleMaximum, userMaximum } = sale
  let status = SALE_STATUS.COMPLETED
  const now = dayjs()

  if (!startTime && !endTime) {
    return SALE_STATUS.UPCOMING
  }

  if (now.isBefore(dayjs.unix(parseInt(startTime)))) {
    status = SALE_STATUS.UPCOMING
  } else {
    status = SALE_STATUS.CURRENT
    if (now.isAfter(dayjs.unix(parseInt(endTime)))) {
      status = SALE_STATUS.COMPLETED
    }
  }

  if (isSaleCapMet(purchaseTotal, saleMaximum, userMaximum)) {
    status = SALE_STATUS.COMPLETED
  }

  return status
}

const isSaleCapMet = (
  purchaseTotal: string,
  saleMaximum: string,
  userMaximum: string,
) => {
  const limit = sub(
    saleMaximum,
    min(mult(5, userMaximum), mult(0.005, saleMaximum)),
  )
  return gt(purchaseTotal, limit)
}

export const useGetSalesPaymentsMethodBalance = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`sales/paymentMethodsBalance`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['sale'] }),
  })
}

export const usePurchaseTotal = (saleId: string) => {
  const { data } = useReadContract({
    address: saleId as Hex, // Cast to address type
    abi: SaleContractDefinition,
    functionName: 'metrics',
    scopeKey: `metrics-${saleId}`,
    query: {
      enabled: !!saleId,
      refetchInterval: 30_000,
    },
  })

  return (data as any)?.purchaseTotal?.toString() || '0'
}
