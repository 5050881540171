import { Card, InputGroup } from '@components'

export const AdditionalInformationPanel = ({
  value,
  onChange,
}: {
  value: string
  onChange: Function
}) => {
  return (
    <Card title='Additional Information'>
      <p className='mb-2'>
        Provide additional notes or comments to send to the reviewer (optional)
      </p>
      <InputGroup
        type='textarea'
        value={value}
        name='notes'
        required={false}
        onChange={onChange}
      />
    </Card>
  )
}
