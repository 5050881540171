import { useTSAddress } from '@hooks'
import { div, getClaimableAmountABI, getVestedFractionABI } from '@utils'
import BigNumber from 'bignumber.js'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Distributor, DistributorMerkleTreeLeaf } from 'tokensoft-shared-types'
import {
  Abi,
  ContractFunctionArgs,
  encodeAbiParameters,
  parseAbiParameters,
} from 'viem'
import { useReadContract } from 'wagmi'
import { calculateClaimableAmountInWei } from '../../../pages/event/distribution/claim-util'
import { useGetDistributorByContractAddress } from './use-get-distributor-by-contract-address'

const encodeVestingSchedule = (
  vestingScheduleData: Distributor['vestingScheduleData'],
): string => {
  switch (vestingScheduleData.type) {
    case 'continuous': {
      const { start, cliff, end } = vestingScheduleData

      return encodeAbiParameters(
        parseAbiParameters('uint256, uint256, uint256'),
        [
          BigInt(start.toString()),
          BigInt(cliff.toString()),
          BigInt(end.toString()),
        ],
      )
    }

    case 'tranche': {
      const { tranches } = vestingScheduleData

      return encodeAbiParameters(parseAbiParameters('(uint128, uint128)[]'), [
        tranches.map(([time, vestedFraction]) => [
          BigInt(time),
          BigInt(vestedFraction),
        ]) as readonly [bigint, bigint][],
      ])
    }
  }
}

/**
 * Attempts to read getClaimableAmount from distributor contracts.
 * In cases where the user has not claimed on the contract before,
 * this call *will* return a Wagmi error of type `ContractFunctionExecutionError`,
 * in which case we calculate the claimable amount manually, multiplying
 * getVestedFraction by the user's allocation.
 */
export const useGetMyDistributorClaimableAmount = (params: {
  distributor: {
    version: 'legacy' | 'v5'
    address: EvmAddress
  }
  merkleLeaf?: DistributorMerkleTreeLeaf
}) => {
  const [claimableAmountInWei, setClaimableAmountInWei] =
    useState<Maybe<BigNumber>>(null)

  const { address } = useTSAddress()

  const {
    data: distributor,
    isPending: isDistributorPending,
    isError: isDistributorError,
  } = useGetDistributorByContractAddress(params.distributor.address)

  const claimableAmount = useMemo(() => {
    if (!distributor || claimableAmountInWei === null) {
      return null
    }

    return new BigNumber(
      div(claimableAmountInWei, Math.pow(10, distributor.token.decimals)),
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [distributor?.token?.decimals, claimableAmountInWei])

  let args: ContractFunctionArgs | undefined

  switch (params.distributor.version) {
    case 'legacy':
      args = [address]
      break

    case 'v5': {
      if (distributor && params.merkleLeaf) {
        args = [address, BigInt(params.merkleLeaf.amount.toString())]

        if (
          params.merkleLeaf.encodedVestingSchedule &&
          params.merkleLeaf.encodedVestingSchedule !== '0x'
        ) {
          args = [...args, params.merkleLeaf.encodedVestingSchedule]
        } else {
          args = [
            ...args,
            encodeVestingSchedule(distributor.vestingScheduleData),
          ]
        }

        break
      }
    }
  }

  const {
    data: _claimableAmountInWei,
    isPending: isClaimablePending,
    isError: isClaimableError,
    error: claimableError,
  } = useReadContract({
    address: params.distributor.address,
    abi: getClaimableAmountABI(params.distributor.version),
    functionName: 'getClaimableAmount',
    args,
    query: {
      enabled: !!distributor && !!params.merkleLeaf,
    },
  })

  useEffect(() => {
    if (distributor && _claimableAmountInWei !== undefined) {
      const claimableAmountInWei = new BigNumber(
        _claimableAmountInWei as string,
      )
      setClaimableAmountInWei(new BigNumber(claimableAmountInWei))
    }
  }, [_claimableAmountInWei, distributor])

  const timestampInSecondsRef = useRef<number>(Math.floor(Date.now() / 1000))

  let vestedFractionAbi: Abi
  let vestedFractionArgs: ContractFunctionArgs = [
    address,
    timestampInSecondsRef.current,
  ]

  let vestedFractionEnabled = false

  switch (params.distributor.version) {
    case 'legacy':
      vestedFractionAbi = getVestedFractionABI()
      vestedFractionEnabled = true

      break

    case 'v5':
      vestedFractionAbi = getClaimableAmountABI('v5')

      if (distributor && params.merkleLeaf) {
        vestedFractionEnabled = true

        if (
          params.merkleLeaf.encodedVestingSchedule &&
          params.merkleLeaf.encodedVestingSchedule !== '0x'
        ) {
          vestedFractionArgs = [
            ...vestedFractionArgs,

            params.merkleLeaf.encodedVestingSchedule,
          ]
        } else {
          vestedFractionArgs = [
            ...vestedFractionArgs,

            encodeVestingSchedule(distributor.vestingScheduleData),
          ]
        }
      }

      break
  }

  const {
    data: vestedFraction,
    isPending: isVestedFractionPending,
    isError: isVestedFractionError,
  } = useReadContract({
    address: params.distributor.address,
    abi: vestedFractionAbi,
    functionName: 'getVestedFraction',
    args: vestedFractionArgs,
    query: {
      enabled: !!distributor && vestedFractionEnabled,
    },
  })

  useEffect(() => {
    if (
      _claimableAmountInWei !== undefined ||
      isVestedFractionPending ||
      isVestedFractionError ||
      isDistributorPending ||
      isDistributorError ||
      !distributor ||
      !params.merkleLeaf ||
      !vestedFraction ||
      distributor.fractionDenominator === null
    ) {
      return
    }

    const calculatedAmountInWei = calculateClaimableAmountInWei(
      params.merkleLeaf.amount,
      vestedFraction as BigNumber,
      distributor.fractionDenominator,
    )

    setClaimableAmountInWei(new BigNumber(calculatedAmountInWei))
  }, [
    _claimableAmountInWei,
    isDistributorError,
    isDistributorPending,
    isVestedFractionError,
    isVestedFractionPending,
    params.merkleLeaf,
    distributor,
    vestedFraction,
  ])

  return {
    data: claimableAmount,
    isPending: isClaimablePending,
    isError: isClaimableError,
    error: claimableError,
  }
}
