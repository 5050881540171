import { getAdvancedDistributorABI } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Address, Hex } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useUpdateDistributorOwner = () => {
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { executeContractWrite } = useContractWrite()

  const write = async (
    chainId: number,
    contractAddress: Address,
    args: [Address],
  ) => {
    setSubmitting(true)

    try {
      const writeContractResponse = await executeContractWrite({
        address: contractAddress,
        abi: getAdvancedDistributorABI() as Abi,
        functionName: 'transferOwnership',
        args,
      })

      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
