import { getFlatPriceSaleABI } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Hex } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useSweepNative = () => {
  const { executeContractWrite } = useContractWrite()
  const [transactionHash, setTransactionHash] = useState<Maybe<Hex>>(null)
  const [submitting, setSubmitting] = useState<boolean>(false)

  const write = async (
    chainId: number,
    toAddress: Hex,
    proof: unknown,
    payee: Hex,
  ) => {
    setSubmitting(true)

    console.log('preparing sweep Native', proof)

    try {
      const writeContractResponse = await executeContractWrite({
        address: toAddress,
        abi: getFlatPriceSaleABI() as Abi,
        functionName: 'withdrawPayments',
        args: [payee],
      })

      setTransactionHash(writeContractResponse)
      console.log('sweep Native transaction response', writeContractResponse)
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash || undefined,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
