import { PageHeader, PageSubtitle, PageTitle } from '@components'

interface RegionRestrictionProps {
  className?: string
  style?: React.CSSProperties
}

export const RegionRestriction = ({
  className,
  style,
}: RegionRestrictionProps) => {
  return (
    <>
      <PageHeader className={className}>
        <PageTitle style={style}>We're sorry!</PageTitle>
        <PageSubtitle style={style}>
          This feature is not available in your region.
        </PageSubtitle>
      </PageHeader>
    </>
  )
}
