import { FieldValues, Path } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

interface RadioInputProps<T extends FieldValues, K extends Path<T>>
  extends BaseInputProps<T, K> {
  options: { label: string; value: string }[]
}

export const RadioInput = <T extends FieldValues, K extends Path<T>>({
  id,
  label,
  options,
  register,
  validate,
  className,
  error,
}: RadioInputProps<T, K>) => (
  <div className={className}>
    <label className='block text-gray-700 text-md font-medium mb-2'>
      {label}
    </label>
    <div className='space-y-2'>
      {options.map((option) => (
        <div key={option.value} className='flex items-center'>
          <input
            id={`${id}-${option.value}`}
            type='radio'
            value={option.value}
            {...register(id, { required: `${label} is required`, validate })}
            className={twMerge(
              `h-4 w-4 flex-shrink-0 rounded-full border-gray-300 text-primary focus:ring-primary`,
              error ? 'border-red-500' : '',
            )}
          />
          <label
            htmlFor={`${id}-${option.value}`}
            className='ml-2 text-gray-700'
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
    {error && <p className='text-red-500 text-xs italic'>{error}</p>}
  </div>
)
