import { TokensoftLogoDark } from '@newComponents'

export const TokensoftPoweredByIcon = () => (
  <a className='flex items-center gap-1 group' href='https://tokensoft.io'>
    <p className='transform translate-y-[2px] text-black text-xs'>Powered by</p>
    <TokensoftLogoDark
      className='transition-all group-hover:scale-[1.02]'
      width={93}
      height={15}
    />
  </a>
)
