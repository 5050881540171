import { getErc20ABI } from '@utils'
import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { Abi } from 'viem'
import { useReadContracts, useAccount as useWagmiAccount } from 'wagmi'

export const useGetERC20Balances = (tokenAddresses: EvmAddress[]) => {
  const { address: userWalletAddress } = useWagmiAccount()
  const erc20Abi = getErc20ABI() as Abi

  const contracts = tokenAddresses.flatMap((address) => [
    {
      address,
      abi: erc20Abi,
      functionName: 'balanceOf',
      args: [userWalletAddress],
    },
    {
      address,
      abi: erc20Abi,
      functionName: 'decimals',
    },
    {
      address,
      abi: erc20Abi,
      functionName: 'symbol',
    },
  ])

  const {
    data: erc20BalanceData,
    isLoading: isLoadingErc20,
    isError: isERC20Error,
    error: erc20Error,
  } = useReadContracts({
    contracts: contracts,
  })

  const erc20Balances = useMemo(() => {
    if (!erc20BalanceData) return []
    const chunkSize = 3
    const result: Array<{ value: BigNumber; symbol: string }> = []

    for (let i = 0; i < erc20BalanceData.length; i += chunkSize) {
      const [balance, decimals, symbol] = erc20BalanceData
        .slice(i, i + chunkSize)
        .map((item) => item.result)

      const raw = new BigNumber((balance as bigint).toString())
      const scaled = raw.div(new BigNumber(10).pow(decimals as number))
      result.push({ value: scaled, symbol: symbol as TokenSymbol })
    }

    return result
  }, [erc20BalanceData])

  return {
    erc20Balances,
    isLoading: isLoadingErc20,
    isError: isERC20Error,
    error: erc20Error,
  }
}
