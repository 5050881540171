import { useCurrencyRegistry, usePurchaseInputs, useToast } from '@contexts'
import {
  useCalculateTokenSalePurchaseFees,
  useCalculateTokensPurchased,
  useHeightAnimation,
  usePrevious,
} from '@hooks'
import { CheckboxInput } from '@newComponents'
import { getTxUrl } from '@utils'
import BigNumber from 'bignumber.js'
import { motion } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { VscLinkExternal } from 'react-icons/vsc'
import { Sale } from 'tokensoft-shared-types'
import { TransactionReceipt } from 'viem'
import { useAccount as useWagmiAccount } from 'wagmi'
import { NativePurchaseButton } from '../purchase-flow/purchase-buttons/native-purchase-button'
import { TokenPurchaseButton } from '../purchase-flow/purchase-buttons/token-purchase-button'
import {
  validateCompanyRightToRefuseAgreement,
  validatePurchasesFinalAgreement,
} from '../purchase-flow/purchase-confirmation-form-validations'
import { validatePaymentMethod } from '../purchase-flow/purchase-form-validations'
import { PurchaseLimits } from '../purchase-flow/purchase-limits'
import { TokenPurchaseForm } from './token-purchase-form'
import {
  createAmountValidator,
  MAX_AMOUNT_DECIMALS,
} from './token-purchase-form-validations'
import { TokenPurchaseSummary } from './token-purchase-summary'

export interface TokenPurchaseFormInputs {
  amount: string
  paymentMethod: string
  potentialLossAgreement: boolean
  feeResponsibilityAgreement: boolean
}

interface TokenPurchaseFlowProps {
  sale: Sale
  onFinished: () => void
}
export const TokenPurchaseFlow = ({
  sale,
  onFinished,
}: TokenPurchaseFlowProps) => {
  const { address: userWalletAddress } = useWagmiAccount()

  const { convert } = useCurrencyRegistry()

  const {
    register,
    control,
    watch,
    setValue,
    trigger,
    formState: { errors: formErrors, isValid: isFormValid },
  } = useForm<TokenPurchaseFormInputs>({
    mode: 'onTouched',
    defaultValues: {
      paymentMethod: sale.contract.paymentMethods[0].symbol ?? '',
      amount: '',
      potentialLossAgreement: false,
      feeResponsibilityAgreement: false,
    },
  })

  const values = watch()

  const {
    amount: userEnteredAmount,
    paymentMethod: selectedPaymentMethodSymbol,
  } = values

  const { userPaymentMethodBalances, userWalletPurchaseTotal, chain } =
    usePurchaseInputs()

  const validateAmount = useCallback(
    createAmountValidator(
      userPaymentMethodBalances,
      selectedPaymentMethodSymbol,
      sale.minimumPurchase,
      sale.maximumPurchase,
      userWalletPurchaseTotal,
      convert,
    ),
    [
      userPaymentMethodBalances,
      selectedPaymentMethodSymbol,
      sale.minimumPurchase,
      sale.maximumPurchase,
      convert,
    ],
  )

  const formValidators: FormValidators<TokenPurchaseFormInputs> = {
    amount: validateAmount,
    paymentMethod: validatePaymentMethod,
    potentialLossAgreement: validatePurchasesFinalAgreement,
    feeResponsibilityAgreement: validateCompanyRightToRefuseAgreement,
  }

  const paymentMethodOptions = sale.contract.paymentMethods.map(
    (pm) => pm.symbol,
  )

  const selectedPaymentMethod = sale.contract.paymentMethods.find(
    (pm) => pm.symbol === selectedPaymentMethodSymbol,
  )!

  const previousPaymentMethodSymbol = usePrevious(selectedPaymentMethod.symbol)

  const userAmount: CurrencyValue = {
    value: new BigNumber(0),
    symbol: selectedPaymentMethod.symbol,
  }

  if (userEnteredAmount) {
    try {
      userAmount.value = new BigNumber(userEnteredAmount)
    } catch (e) {
      console.error('Error processing user input:', e)
    }
  }

  // When user selected a payment method, convert the user entered
  // amount from the previous payment method to the new payment method's currency
  useEffect(() => {
    if (
      selectedPaymentMethod.symbol !== previousPaymentMethodSymbol &&
      previousPaymentMethodSymbol !== null
    ) {
      if (!userAmount.value.eq(new BigNumber(0))) {
        const convertedAmount = convert(
          { value: userAmount.value, symbol: previousPaymentMethodSymbol },
          selectedPaymentMethod.symbol,
        )

        setValue(
          'amount',
          convertedAmount.value.decimalPlaces(MAX_AMOUNT_DECIMALS).toString(),
        )
        void trigger('amount')
      }
    }
  }, [selectedPaymentMethod])

  const purchaseFees: PurchaseFees =
    useCalculateTokenSalePurchaseFees(userAmount)

  /*
  Token purchase price is measured in USD. User's payment method has to be
  converted to USD, then the amount of tokens purchased is calculated.
  */
  const tokensPurchased = useCalculateTokensPurchased(
    userAmount,
    purchaseFees.protocolFeeInPaymentMethodCurrency,
    sale.token.symbol,
  )

  // Show summary on first valid entry, and persist from then on
  const shouldShowSummary = userAmount.value.gt(0)
  const [didShowSummary, setDidShowSummary] = useState(false)
  useEffect(() => {
    if (shouldShowSummary && !didShowSummary) {
      setDidShowSummary(true)
    }
  }, [shouldShowSummary])

  const { contentRef, ...motionProps } = useHeightAnimation(didShowSummary)

  const { showSuccessToast, showInfoToast } = useToast()

  const handleTransactionSuccess = (receipt: TransactionReceipt) => {
    const txUrl = getTxUrl(receipt?.transactionHash, chain)

    showSuccessToast({
      description: (
        <a
          target='_blank'
          rel='noreferrer'
          href={txUrl}
          className='text-white flex items-center justify-center gap-1'
          onClick={(e) => e.stopPropagation()}
        >
          <span>
            Your purchase was successful! Click here to view your transaction on
            the block explorer.
          </span>
          <VscLinkExternal size={24} color='white' />
        </a>
      ),
    })

    onFinished()
  }

  const handleMinClick = () => {
    const minPurchase: CurrencyValue = convert(
      sale.minimumPurchase,
      selectedPaymentMethod.symbol,
    )

    const minAmount: BigNumber = minPurchase.value.decimalPlaces(
      MAX_AMOUNT_DECIMALS,
      BigNumber.ROUND_UP,
    )

    setValue('amount', minAmount.toString())
    trigger('amount')
  }

  const handleMaxClick = () => {
    // Set amount to smaller of max purchase or user's remaining balance
    const maxPurchase: CurrencyValue = convert(
      sale.maximumPurchase,
      selectedPaymentMethod.symbol,
    )

    const remainingBalance: Maybe<CurrencyValue> =
      userPaymentMethodBalances.find(
        (currencyValue) =>
          currencyValue.symbol === selectedPaymentMethod.symbol,
      ) ?? null

    if (remainingBalance === null) {
      return
    }

    const maxAmount = BigNumber.min(
      maxPurchase.value,
      remainingBalance.value,
    ).decimalPlaces(MAX_AMOUNT_DECIMALS, BigNumber.ROUND_DOWN)

    // Notify user that they have insufficient balance to purchase the maximum amount
    if (remainingBalance.value.lt(maxPurchase.value)) {
      showInfoToast({
        description: `Your balance is insufficient to meet the maximum purchase size; amount has been set to wallet balance.`,
      })
    }

    setValue('amount', maxAmount.toString())
    void trigger('amount')
  }

  return (
    <>
      <div className='flex flex-col gap-6 mb-8'>
        <PurchaseLimits
          sale={sale}
          userWalletPurchaseTotal={userWalletPurchaseTotal}
        />
        <TokenPurchaseForm
          key='purchase-step'
          register={register}
          control={control}
          trigger={trigger}
          errors={formErrors}
          paymentMethods={paymentMethodOptions}
          selectedPaymentMethod={selectedPaymentMethod}
          onMinClick={handleMinClick}
          onMaxClick={handleMaxClick}
          validators={formValidators}
        />
      </div>
      {/* Conditional Purchase Summary */}
      <motion.div {...motionProps}>
        <div ref={contentRef} className='flex flex-col gap-6'>
          <TokenPurchaseSummary
            className='mb-4'
            userAmount={userAmount}
            tokensPurchased={tokensPurchased}
            purchaseFees={{
              protocolFeeInPaymentMethodCurrency:
                purchaseFees.protocolFeeInPaymentMethodCurrency,
              platformFeeInNativeCurrency:
                purchaseFees.platformFeeInNativeCurrency,
            }}
            recipientWallet={userWalletAddress ?? '0x'}
            isNativePaymentMethod={selectedPaymentMethod.native}
          />
          <CheckboxInput
            id='potentialLossAgreement'
            label='I understand that my payment must be made on the Ethereum network, and that my payment will be lost if I do not follow instructions exactly.'
            register={register}
            validate={formValidators.potentialLossAgreement}
            className='max-w-[592px]'
            error={formErrors.potentialLossAgreement?.message}
          />

          <CheckboxInput
            id='feeResponsibilityAgreement'
            label='I further acknowledge and accept that the Company (as defined in the purchase agreement) reserves the right to refuse or cancel these documents at any time at its sole discretion.'
            register={register}
            validate={formValidators.feeResponsibilityAgreement}
            className='max-w-[592px]'
            error={formErrors.feeResponsibilityAgreement?.message}
          />
          {selectedPaymentMethod.native ? (
            <NativePurchaseButton
              chain={chain}
              saleAddress={sale.id}
              paymentMethod={selectedPaymentMethod}
              purchaseAmountInNative={userAmount}
              platformFeeInDollars={sale.platformFeeInUSD}
              platformFeeInNative={purchaseFees.platformFeeInNativeCurrency}
              platformFeeRecipient={chain.platformFeeRecipient}
              onSuccess={handleTransactionSuccess}
              disabled={!isFormValid}
            />
          ) : (
            <TokenPurchaseButton
              // Remount on payment method or amount change to prompt new
              // approval and purchase sequence
              key={`${userAmount.value.toString()}${selectedPaymentMethod.symbol}`}
              saleAddress={sale.id}
              chain={chain}
              purchaseAmountInToken={userAmount}
              paymentMethod={selectedPaymentMethod}
              platformFeeRecipient={chain.platformFeeRecipient}
              platformFeeInDollars={sale.platformFeeInUSD}
              platformFeeInNative={purchaseFees.platformFeeInNativeCurrency}
              nativeDecimals={chain.decimals}
              onSuccess={handleTransactionSuccess}
              disabled={!isFormValid}
            />
          )}
        </div>
      </motion.div>
    </>
  )
}
