import React, { createContext, ReactNode, useContext, useMemo } from 'react'

export const PurchaseContext = createContext<Maybe<PurchaseInputs>>(null)

export const PurchaseInputsProvider: React.FC<{
  children: ReactNode
  purchaseInputs: PurchaseInputs
}> = ({ children, purchaseInputs: user }) => {
  const value = useMemo(() => user, [user])
  return (
    <PurchaseContext.Provider value={value}>
      {children}
    </PurchaseContext.Provider>
  )
}

/**
 * Hook to consume UserContext without null or optional values.
 */
export const usePurchaseInputs = (): PurchaseInputs => {
  const purchaseInputs = useContext(PurchaseContext)
  if (!purchaseInputs) {
    throw new Error('usePurchaseInputs must be used within a PurchaseProvider')
  }
  return purchaseInputs
}
