import {
  IdentityCompleted,
  IdentityFailed,
  IdentityForm,
  IdentityPending,
  LoadingIndicator,
} from '@components'
import { useAccount } from '@contexts'
import { KYC_TIER_STATE, TIER_NAME, VERIFICATION_STATUS } from '@enums'
import { getVerificationStatusForTier, isEmptyObject } from '@utils'
import { useEffect, useState } from 'react'

interface IdentityPageProps {
  onCancel?: () => void
  onSubmitted?: () => void
}

export const IdentityPage = ({ onCancel, onSubmitted }: IdentityPageProps) => {
  const { account, tier1CurrentState } = useAccount()
  const [identityStatus, setIdentityStatus] =
    useState<Maybe<VERIFICATION_STATUS>>(null)

  useEffect(() => {
    if (
      !isEmptyObject(account) &&
      account?.kyc?.tiers &&
      account?.kyc?.tiers.length > 0
    ) {
      setIdentityStatus(
        getVerificationStatusForTier(account.kyc.tiers, TIER_NAME.IDENTITY),
      )
    }
  }, [account])

  if (identityStatus) {
    switch (identityStatus) {
      case VERIFICATION_STATUS.COMPLETED:
        return <IdentityCompleted />
      case VERIFICATION_STATUS.FAILED:
        return <IdentityFailed />
      case VERIFICATION_STATUS.PENDING:
        if (
          tier1CurrentState !== null &&
          [
            KYC_TIER_STATE.T1_MANUAL_REVIEW,
            KYC_TIER_STATE.T1_DOCUMENT_REVIEW,
          ].includes(tier1CurrentState)
        ) {
          return <IdentityPending />
        }

        return <IdentityForm onCancel={onCancel} />
      case VERIFICATION_STATUS.NONE:
      case VERIFICATION_STATUS.NEW:
      default:
        return <IdentityForm onCancel={onCancel} />
    }
  } else {
    return <LoadingIndicator />
  }
}
