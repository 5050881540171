import { Discord, Globe, Telegram, Twitter } from '@components'
import { SocialLinks } from 'tokensoft-shared-types'
import { SocialIconProps } from './social-icon'
import { SocialIconList } from './social-icon-list'

type Props = {
  socialLinks?: SocialLinks
  color?: HexColorCode
}

export const ProjectSocials = ({ socialLinks, color }: Props) => {
  let twitter: Maybe<Url> = 'https://twitter.com/tokensoftinc'
  let website: Maybe<Url> = 'https://www.tokensoft.io'
  let telegram: Maybe<Url> = 'https://t.me/tokensoft'
  let discord: Maybe<Url> = 'https://discord.gg/tokensoft'

  if (socialLinks !== undefined) {
    website = socialLinks.website || null
    twitter = socialLinks.twitter || null
    telegram = socialLinks.telegram || null
    discord = socialLinks.discord || null
  }

  const socialsProps: SocialIconProps[] = []

  if (website) {
    socialsProps.push({ icon: <Globe />, href: website, color })
  }
  if (twitter) {
    socialsProps.push({ icon: <Twitter />, href: twitter, color })
  }
  if (telegram) {
    socialsProps.push({ icon: <Telegram />, href: telegram, color })
  }
  if (discord) {
    socialsProps.push({ icon: <Discord />, href: discord, color })
  }

  return <SocialIconList socialsProps={socialsProps} />
}
