import { useGetEvent, useGetProjectSocials } from '@apiServices'
import { Error, LoadingIndicator } from '@components'
import { DEFAULT_EVENT_HEADER_FONT_COLOR } from '@constants'
import { useAnalytics } from '@contexts'
import { usePositiveIntegerParam } from '@hooks'
import axios from 'axios'
import { TSEventPage } from './event'
import { useEventPageLayout } from './use-event-page-layout'

export const EventLoader = () => {
  useEventPageLayout()

  const eventId: Maybe<ID> = usePositiveIntegerParam('eventId')

  if (eventId === null) {
    return <div>Invalid Event ID</div>
  }

  useAnalytics(`/event/${eventId}`)

  const {
    data: event,
    isPending: isPendingEvent,
    error: eventError,
  } = useGetEvent(eventId)

  const {
    data: projectSocials,
    isPending: isPendingProjectSocials,
    error: projectSocialsError,
  } = useGetProjectSocials()

  const contentHeaderFontColor =
    event?.content.header.fontColor || DEFAULT_EVENT_HEADER_FONT_COLOR

  if (isPendingEvent || isPendingProjectSocials) {
    return <LoadingIndicator text='Fetching your event...' fullScreen />
  }

  if (eventError || projectSocialsError) {
    const error = eventError || projectSocialsError
    let message = error?.message

    if (axios.isAxiosError(error)) {
      if (error.response?.status === 404) {
        message = 'Event data not found.'
      }

      if (error.response?.status === 403) {
        message = 'You do not have access to this event.'
      }
    }

    return (
      <div className='h-full w-full flex justify-center items-center'>
        <Error
          title='Uh-oh!'
          message={message}
          style={{ color: contentHeaderFontColor }}
        />
      </div>
    )
  }

  return <TSEventPage event={event} projectSocials={projectSocials} />
}
