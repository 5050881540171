import {
  Card,
  Col,
  ContinuousIcon,
  HelpPopover,
  InfoIcon,
  InstantIcon,
  MonthlyIcon,
  Row,
  SelectedIcon,
  Text,
  Title,
} from '@components'
import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums'
import classNames from 'classnames'

type CardProps = React.ComponentProps<typeof Card>

interface VestingTypeCardProps extends CardProps {
  vestingType: any
  selectedVestingType: any
  title: any
  description: any
  help: any
  disabled: any
}

export const VestingTypeCard: React.FC<VestingTypeCardProps> = ({
  vestingType,
  selectedVestingType,
  title,
  description,
  help,
  disabled,
  ...restProps
}) => {
  const key = `${vestingType}-vesting-type-card`

  return (
    <Card
      id={`${key}`}
      className={classNames(
        `h-fit md:h-full ${disabled ? 'opacity-50' : 'hover-card'}`,
        vestingType === selectedVestingType ? 'selected' : '',
      )}
      {...restProps}
    >
      <Col className={'h-full'} gap={5}>
        {vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT ? (
          <InstantIcon />
        ) : null}

        {vestingType === DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS ? (
          <ContinuousIcon />
        ) : null}

        {vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY ? (
          <MonthlyIcon />
        ) : null}

        <Col>
          <Row gap={2.5} place={'start-center'}>
            <Title data-testid={`${key}-title`}>{title}</Title>
            <HelpPopover
              data-testid={`${key}-help-popover`}
              className='md'
              button={
                <div className='cursor-pointer'>
                  <InfoIcon />
                </div>
              }
            >
              <>{help}</>
            </HelpPopover>
          </Row>

          {description ? (
            <Text data-testid={`${key}-description`} className={'px-0.5'}>
              {description}
            </Text>
          ) : null}
        </Col>
        <Row className='h-[36px] mt-auto' place={'end'}>
          {vestingType === selectedVestingType ? <SelectedIcon /> : null}
        </Row>
      </Col>
    </Card>
  )
}
