import { usePublicApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { SocialLinks } from 'tokensoft-shared-types'

export const useGetProjectSocials = () => {
  const fetchWithClient = usePublicApiClient()

  return useQuery<SocialLinks>({
    queryKey: ['project/socials'],
    initialData: {
      website: null,
      twitter: null,
      telegram: null,
      discord: null,
    },
    queryFn: async () => {
      return fetchWithClient('project/socials')
    },
  })
}
