import { sessionStorageKey } from '@apiClients'
import { useToast } from '@contexts'
import { User } from '@customTypes'
import { useEffect } from 'react'
import { normalize } from 'viem/ens'
import { useEnsAddress } from 'wagmi'
import { useSessionStorage } from './use-session-storage'

/**
 * A hook to manage storing and retrieving the current User.
 * @returns A stateful user object and a function to update it.
 */
export const useUserStore = (): [Maybe<User>, (user: Maybe<User>) => void] => {
  const [user, setUser] = useSessionStorage<Maybe<User>>(
    sessionStorageKey,
    null,
  )

  const { data: resolvedEns = null, error: ensResolutionError } = useEnsAddress(
    {
      chainId: 1,
      name: normalize(user?.walletAddress ?? ''),
      blockTag: 'latest',
      query: {
        enabled: user !== null && user.walletAddress.endsWith('.eth'),
      },
    },
  )

  const { showErrorToast } = useToast()

  useEffect(() => {
    if (ensResolutionError) {
      showErrorToast({
        description:
          'There was an issue resolving your ENS address. Please try reloadnig the page. If this issue persists, please contact support.',
      })
      return
    }

    if (
      user !== null &&
      user.walletAddress.endsWith('.eth') &&
      resolvedEns !== null
    ) {
      setUser({
        ...user,
        walletAddress: resolvedEns.toLowerCase() as EvmAddress,
      })
    }
  }, [user, setUser, resolvedEns, ensResolutionError, showErrorToast])

  const handleSetUser = (user: Maybe<User>) => {
    if (user === null) {
      setUser(null)
      return
    }

    setUser({
      ...user,
      walletAddress: user.walletAddress.toLowerCase() as EvmAddress,
    })
  }

  return [user, handleSetUser]
}
