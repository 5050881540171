import { GENESIS_ADDRESS } from '@constants'
import { Account, PaymentMethod, Sale } from '@customTypes'
import {
  gt,
  gte,
  localToUtcUnixDateTime,
  lte,
  validateEndDate,
  validateStartDate,
} from '@utils'
import { Blockchain } from 'tokensoft-shared-types'
import { isAddress } from 'viem'

export const validateOnChainConfig = (
  onChainConfig: any,
  account?: Account,
) => {
  const {
    recipient,
    saleMaximum,
    userMaximum,
    price,
    purchaseMinimum,
    startTime,
    endTime,
    maxQueueTime,
    owner,
  } = onChainConfig

  const validSaleMaximum =
    !saleMaximum ||
    (gt(saleMaximum, 0) && (!userMaximum || gte(saleMaximum, userMaximum)))

  let saleMaximumError
  if (!validSaleMaximum) {
    saleMaximumError = userMaximum
      ? 'Max raise must be greater than per-user limit'
      : 'Max raise must be greater than 0'
  }

  const validPurchaseMinimum =
    !purchaseMinimum || !userMaximum || lte(purchaseMinimum, userMaximum)

  const validUserMaximum =
    !userMaximum ||
    (gt(userMaximum, 0) &&
      (!saleMaximum || lte(userMaximum, saleMaximum)) &&
      (!purchaseMinimum || gte(userMaximum, purchaseMinimum)))

  let userMaximumError
  if (!validUserMaximum) {
    if (
      saleMaximum &&
      !lte(userMaximum, saleMaximum) &&
      purchaseMinimum &&
      !gte(userMaximum, purchaseMinimum)
    ) {
      userMaximumError =
        'Per-user limit must be more than minimum purchase and less than max raise'
    } else if (saleMaximum && !lte(userMaximum, saleMaximum)) {
      userMaximumError = 'Per-user limit must be less than max raise'
    } else {
      userMaximumError = 'Per-user limit must be more than minimum purchase'
    }
  }

  const validRecipient =
    !recipient || (isAddress(recipient) && recipient !== GENESIS_ADDRESS)
  let recipientError
  if (!validRecipient) {
    recipientError = 'Invalid address'
  }

  const priceError = price && price <= 0 ? 'Price must be greater than 0' : null

  const validOwner = !owner || (isAddress(owner) && owner !== GENESIS_ADDRESS)

  let ownerError
  if (!validOwner) {
    ownerError = 'Invalid address'
  }

  const startTimeError = validateStartDate(
    startTime,
    endTime,
    account?.timezone,
  )
  const validStartTime = !startTime || startTimeError == null

  const endTimeError = validateEndDate(startTime, endTime, account?.timezone)
  const validEndTime = !endTime || endTimeError == null

  const validStartAndEndTimes = validStartTime && validEndTime

  let validMaxQueueTime = true
  const startTimeUnix = localToUtcUnixDateTime(startTime, account?.timezone)
  const endTimeUnix = localToUtcUnixDateTime(endTime, account?.timezone)
  if (
    startTime &&
    endTime &&
    validStartAndEndTimes &&
    maxQueueTime &&
    startTimeUnix &&
    endTimeUnix
  ) {
    validMaxQueueTime = startTimeUnix + Number(maxQueueTime) <= endTimeUnix
  }

  let maxQueueTimeError
  if (!validMaxQueueTime && startTimeUnix && endTimeUnix) {
    if (startTimeUnix + Number(maxQueueTime) > endTimeUnix) {
      maxQueueTimeError = 'Cannot exceed length of sale'
    } else {
      maxQueueTimeError = `Invalid value`
    }
  }

  return {
    validRecipient,
    recipientError,
    validOwner,
    ownerError,
    priceError,
    validSaleMaximum,
    saleMaximumError,
    validUserMaximum,
    userMaximumError,
    validPurchaseMinimum,
    validStartTime,
    startTimeError,
    validEndTime,
    endTimeError,
    validStartAndEndTimes,
    validMaxQueueTime,
    maxQueueTimeError,
  }
}

export const getPaymentMethods = (
  sale: Sale,
  network: Blockchain,
): PaymentMethod[] => {
  const { symbol: nativeSymbol } = network

  return sale.paymentMethods
    .map((paymentMethod) => {
      const { token, native, symbol } = paymentMethod
      return {
        ...paymentMethod,
        address: token,
        symbol: symbol ?? nativeSymbol ?? '',
        label: symbol ?? nativeSymbol ?? '',
        value: symbol ?? nativeSymbol ?? '',
        native,
      }
    })
    .sort((a, b) => (!a.address ? -1 : !b.address ? 1 : 0))
}
