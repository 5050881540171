import { LineChart, LoadingIndicator } from '@components'
import { EVENT_STATUS, VESTING_TYPE } from '@enums'
import { formatValue, isEmptyObject, sub, toNumber } from '@utils'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import {
  buildAxes,
  buildChartPadding,
  buildClaimTooltipContent,
  buildDatasets,
  buildVestingTooltipContent,
  generateContinuousTranches,
} from './vesting-chart-utils'

export const VestingChart = ({
  distributorData,
  claimsData,
  className = '',
  ...restProps
}: any) => {
  const [options, setOptions] = useState<Maybe<any>>(null)

  const isMediumScreen = useMediaQuery({ maxWidth: 864 })

  const buildVestingDataset = (distributorData: any) => {
    if (isEmptyObject(distributorData)) {
      return []
    }

    let tranches: any[] = []

    if (distributorData.vestingType === VESTING_TYPE.CONTINUOUS) {
      tranches = generateContinuousTranches(
        distributorData.startTime,
        distributorData.cliffTime,
        distributorData.endTime,
        distributorData.event.endTime,
      )
    } else if (distributorData.vestingType === VESTING_TYPE.TRANCHE) {
      tranches = distributorData.trancheVesting?.tranches
    }

    return tranches
      ?.sort((a, b) => {
        return Number(sub(a.time, b.time))
      })
      .map((tranche) => {
        const vestingValue = tranche.vestedFraction
          ? formatValue(tranche.vestedFraction / 100, {
              commas: false,
              decimalPlaces: 2,
            })
          : 0
        return {
          x: new Date(tranche.time * 1000),
          y: toNumber(vestingValue),
          toolTipContent: buildVestingTooltipContent(
            vestingValue,
            tranche.time,
            distributorData,
          ),
        }
      })
  }

  const buildClaimsDataset = (claimsData: any) => {
    if (isEmptyObject(claimsData)) {
      return []
    }

    return claimsData.map((item: any) => {
      return {
        x: item.time * 1000,
        y: toNumber(item.amount),
        toolTipContent: buildClaimTooltipContent(
          item.amount,
          item.time,
          distributorData,
        ),
      }
    })
  }

  const buildVestingChartOptions = (
    distributorData: any,
    claimsData: any,
    isMediumScreen: any,
  ) => {
    const vestingDataset = buildVestingDataset(distributorData)
    const claimsDataset = buildClaimsDataset(claimsData)
    const paddedDatasets = buildChartPadding(
      vestingDataset,
      claimsDataset,
      distributorData,
    )

    const options = {
      toolTip: {
        enabled:
          !!distributorData.id ||
          distributorData.event?.status === EVENT_STATUS.COMPLETED,
      },
      ...buildAxes(
        distributorData,
        paddedDatasets.minimum,
        paddedDatasets.maximum,
        isMediumScreen,
      ),
      data: buildDatasets(
        distributorData.vestingType,
        paddedDatasets.vestingDataset,
        paddedDatasets.claimsDataset,
      ),
    }

    return options
  }

  useEffect(() => {
    if (distributorData && distributorData.totalAllocations !== 'NaN') {
      setOptions(
        buildVestingChartOptions(distributorData, claimsData, isMediumScreen),
      )
    }
  }, [distributorData, claimsData])

  return (
    <>
      <div className={classNames('', className)} {...restProps}>
        {!options ? (
          <LoadingIndicator
            text={'Loading Data'}
            className={'no-padding h-96'}
          />
        ) : (
          <LineChart
            options={options}
            props={{
              width: '100%',
              height: isMediumScreen ? '200px' : '400px',
            }}
          />
        )}
      </div>
    </>
  )
}
