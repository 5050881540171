import {
  BackButton,
  ButtonRow,
  ContinueButton,
  CreateDistributorModal,
  DistributorDeploy,
  DistributorParticipants,
  DistributorTokenSelection,
  DistributorVesting,
  Error,
  PageSubtitle,
  PageTitle,
  Step,
  StepFooter,
  StepHeader,
  WizardBreadcrumb,
} from '@components'
import {
  useEvent,
  useModal,
  useNetworks,
  useProject,
  useWallet,
  WizardContext,
} from '@contexts'
import { useState } from 'react'
import { HiRocketLaunch } from 'react-icons/hi2'
import { Link, useNavigate } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'
import { TSEvent } from 'tokensoft-shared-types'

export const DeployDistributorWizardPage = () => {
  const navigate = useNavigate()
  const { project } = useProject()
  const { connectedChainId } = useWallet()
  const { getNetworkDetails } = useNetworks()
  const { event: untypedEvent, isOnChainEvent } = useEvent()
  const { showModal } = useModal()

  const event = untypedEvent as TSEvent

  const [wizardState, setWizardState] = useState<any>({
    tokenSelectionFormValid: false,
    participantsFormValid: false,
    vestingTypeFormValid: false,
    eventId: untypedEvent?.id,
    projectId: project?.id,
    startTime: untypedEvent?.startTime,
    cliffTime: untypedEvent?.startTime,
    endTime: untypedEvent?.endTime,
    networkId: untypedEvent?.networkId || connectedChainId,
    useIndividualVestingSchedules: false,
  })

  const selectedNetworkDetails = getNetworkDetails(wizardState?.networkId)

  const showDeployModal = () => {
    showModal({
      className: 'md',
      content: (
        <CreateDistributorModal context={wizardState} event={untypedEvent} />
      ),
    })
  }

  // when user clicks deploy button, switch to correct network before opening modal
  const { switchChain } = useWallet()
  const isWrongNetwork = connectedChainId !== wizardState.networkId
  const handleClick = () =>
    isWrongNetwork
      ? switchChain(wizardState.networkId, { onSuccess: showDeployModal })
      : showDeployModal()

  if (untypedEvent && isOnChainEvent && !wizardState?.vestingTypeFormValid) {
    return (
      <>
        <Error
          title='Event Already Deployed'
          message='It looks like this event has already been deployed.'
        />
        <ButtonRow place={'center'}>
          <Link
            className='btn btn-primary'
            to={`/admin/project/${untypedEvent.project.id}/event/${untypedEvent.id}`}
          >
            Back to Event
          </Link>
        </ButtonRow>
      </>
    )
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 1</WizardBreadcrumb>
            <PageTitle>Token Selection</PageTitle>
            <PageSubtitle>
              Select the ERC-20 token you want to distribute. We&apos;ll use
              this information to help you deploy a distributor contract on the
              appropriate network: you can edit the token address later but not
              the network.
            </PageSubtitle>
          </StepHeader>

          <DistributorTokenSelection
            context={wizardState}
            setContext={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={() =>
                  navigate(
                    `/admin/project/${untypedEvent.project.id}/event/${untypedEvent.id}`,
                  )
                }
              />
              <ContinueButton disabled={!wizardState.tokenSelectionFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 2</WizardBreadcrumb>
            <PageTitle>Participants</PageTitle>
            <PageSubtitle>
              Specify who can claim tokens and how many total tokens each
              participant can claim. Everyone in the participant list must also
              meet all event eligibility requirements before they can claim
              tokens in this app.
            </PageSubtitle>
          </StepHeader>

          <DistributorParticipants
            context={wizardState}
            setContext={setWizardState}
            useExperimentalContractFeatures={
              event.useExperimentalContractFeatures
            }
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <ContinueButton disabled={!wizardState.participantsFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 3</WizardBreadcrumb>
            <PageTitle>Vesting</PageTitle>
            <PageSubtitle>
              Specify how tokens unlock over time. Participants can only claim
              tokens once they are vested or unlocked.
            </PageSubtitle>
          </StepHeader>

          <DistributorVesting
            context={wizardState}
            setContext={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              <ContinueButton disabled={!wizardState.vestingTypeFormValid} />
            </ButtonRow>
          </StepFooter>
        </Step>

        <Step>
          <StepHeader>
            <WizardBreadcrumb>STEP 4</WizardBreadcrumb>
            <PageTitle>Deploy</PageTitle>
            <PageSubtitle>
              It’s time to deploy the distributor contract to{' '}
              {selectedNetworkDetails?.name || 'Unknown Network'}. Let’s review
              settings first.
            </PageSubtitle>
          </StepHeader>

          <DistributorDeploy
            context={wizardState}
            setContext={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton />
              {/* switch network onclick if wrong network connected */}
              <ContinueButton
                label='Deploy'
                icon={<HiRocketLaunch />}
                onClick={handleClick}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  )
}
