import { ButtonRow, PageHeader } from '@components'
import { useAccount, useAnalytics } from '@contexts'
import { TIER_NAME } from '@enums'
import { getKycTier, utcToLocalDate } from '@utils'
import { Link } from 'react-router-dom'

export const AccreditationCompleted = () => {
  useAnalytics('/account/accreditation-completed')
  const { account } = useAccount()

  if (account?.kyc?.tiers === undefined) {
    throw new Error('Account kyc tiers is not defined')
  }

  const accreditation = getKycTier(account.kyc.tiers, TIER_NAME.ACCREDITATION)
  const expirationDate = accreditation?.expiresAt
    ? utcToLocalDate(accreditation.expiresAt)
    : null

  return (
    <PageHeader
      title={'Congratulations!'}
      instructions={
        <>
          <p className='text-xs md:text-base mb-8 text-center'>
            {`You are accredited. This accreditation expires ${
              expirationDate
                ? `on ${expirationDate}.`
                : '90 days from the date of approval.'
            }`}
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>
            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>
          </ButtonRow>
        </>
      }
    />
  )
}
