import { useWebApiClient } from '@apiClients'
import { useMutation } from '@tanstack/react-query'

export const useIsEmailInUse = () => {
  const client = useWebApiClient()

  return useMutation<boolean, Error, { email: EmailAddress }>({
    mutationFn: async ({ email }) => {
      const result = (await client(`accounts/email-in-use`, {
        method: 'POST',
        data: { email },
      })) as { isEmailInUse: boolean }

      return result.isEmailInUse
    },
  })
}
