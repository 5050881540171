import { FormattedNumber } from './formatted-number'

type FormattedCurrencyValueProps = {
  currencyValue: CurrencyValue
  displayDecimals?: number
  displayCommas?: boolean
  trimTrailingZeros?: boolean
}

export const FormattedCurrency = ({
  currencyValue,
  displayDecimals = 6,
  displayCommas = true,
  trimTrailingZeros = true,
}: FormattedCurrencyValueProps) => {
  return (
    <span>
      {currencyValue.symbol === 'USD' ? '$' : ''}
      <FormattedNumber
        value={currencyValue.value}
        displayDecimals={displayDecimals}
        withCommas={displayCommas}
        trimTrailingZeros={trimTrailingZeros}
      />
      &nbsp;
      {`${currencyValue.symbol}`}
    </span>
  )
}
