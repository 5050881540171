import { useToast } from '@contexts'
import { getAdvancedDistributorABI } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Address, Hex, TransactionExecutionError } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useUpdateDistributorUri = () => {
  const { showErrorToast } = useToast()
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { executeContractWrite } = useContractWrite()

  const write = async (
    chainId: number,
    contractAddress: Address,
    args: [string],
  ) => {
    setSubmitting(true)

    try {
      const writeContractResponse = await executeContractWrite({
        address: contractAddress,
        abi: getAdvancedDistributorABI() as Abi,
        functionName: 'setUri',
        args,
      })

      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (e) {
      let msg: string
      if (e instanceof TransactionExecutionError) {
        msg = e.shortMessage
      } else if (e instanceof Error) {
        msg = e.message
      } else {
        msg = 'An error occurred while updating the distributor.'
      }

      if (msg === '') {
        msg = 'An error occurred while updating the distributor.'
      }

      showErrorToast({ description: msg })
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
