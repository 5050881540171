import { generatePeriodicTranches } from '@components'
import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums'
import {
  getContinuousVestingMerkleABI,
  getTrancheVestingMerkleABI,
} from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Address, Hex } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

interface VestingConfigArgs {
  startTime: number
  cliffTime: number
  endTime: number
}

export const useUpdateVestingConfig = () => {
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { executeContractWrite } = useContractWrite()

  const getTranches = (args: VestingConfigArgs) => {
    const tranches = generatePeriodicTranches(
      args.startTime,
      args.cliffTime,
      args.endTime,
    )
    const tranchesArray = tranches.map((t) => {
      return [t.time, t.vestedFraction]
    })
    return [tranchesArray]
  }

  const write = async (
    chainId: number,
    contractAddress: Address,
    args: VestingConfigArgs,
    vestingType: `monthly` | `continuous` | `instant`,
  ) => {
    setSubmitting(true)
    console.log('updating distributor vesting config')

    const abi =
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
        ? getTrancheVestingMerkleABI()
        : getContinuousVestingMerkleABI()
    const functionName =
      vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
        ? 'setTranches'
        : 'setVestingConfig'

    try {
      const writeContractResponse = await executeContractWrite({
        address: contractAddress,
        abi: abi as Abi,
        functionName,
        args:
          vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY
            ? getTranches(args)
            : [args.startTime, args.cliffTime, args.endTime],
      })

      setTransactionHash(writeContractResponse)
      console.log(
        'update distributor vesting config transaction response',
        writeContractResponse,
      )
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
