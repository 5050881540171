import { usePostPolkadotWalletFileValidation } from '@api/services'
import { useToast } from '@contexts'
import { useEffect, useState } from 'react'
import {
  CsvParseResult,
  PolkadotWalletsConfigurationFileData,
} from 'tokensoft-shared-types'

interface UseValidatePolkadotWalletFileParams {
  polkadotWalletsFileUri: Maybe<S3Uri>
  polkadotWalletUserIdentifier: PolkadotWalletUserIdentifier
}

export const useValidatePolkadotWalletsFile = ({
  polkadotWalletsFileUri,
  polkadotWalletUserIdentifier,
}: UseValidatePolkadotWalletFileParams) => {
  const [isPending, setIsPending] = useState(false)
  const [uploadedData, setUploadedData] =
    useState<Maybe<PolkadotWalletsConfigurationFileData[]>>(null)
  const [uploadFileErrorMessage, setUploadFileErrorMessage] =
    useState<Maybe<string>>(null)

  const [arePolkadotAddressesValid, setArePolkadotAddressesValid] =
    useState(false)
  const [areEmailsValid, setAreEmailsValid] = useState(false)
  const [areEthAddressesValid, setAreEthAddressesValid] = useState(false)

  const { showErrorToast } = useToast()
  const { mutate: validateFile } = usePostPolkadotWalletFileValidation()

  const handleUploadError = (message?: string) => {
    setUploadedData(null)
    setAreEmailsValid(false)
    setAreEthAddressesValid(false)
    setArePolkadotAddressesValid(false)
    if (message) {
      setUploadFileErrorMessage(message)
    }
  }

  useEffect(() => {
    if (!polkadotWalletsFileUri) return

    setIsPending(true)
    setUploadedData(null)
    setUploadFileErrorMessage(null)

    validateFile(
      {
        polkadotWalletsFileUri,
        polkadotWalletUserIdentifier,
      },
      {
        onSuccess: (
          result: CsvParseResult<PolkadotWalletsConfigurationFileData>,
        ) => {
          if (result.valid) {
            if (result.data) {
              setUploadedData(result.data)
            }
            setAreEmailsValid(true)
            setArePolkadotAddressesValid(true)
          } else {
            handleUploadError(result.errorMessage ?? 'Validation failed.')
          }
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error validating file. Please try again.',
          })

          handleUploadError(error.message)
        },
        onSettled: () => {
          setIsPending(false)
        },
      },
    )
  }, [
    polkadotWalletsFileUri,
    validateFile,
    showErrorToast,
    polkadotWalletUserIdentifier,
  ])

  const resetUpload = () => {
    setUploadedData(null)
    setUploadFileErrorMessage(null)
    setAreEmailsValid(false)
    setAreEthAddressesValid(false)
    setArePolkadotAddressesValid(false)
  }

  return {
    isPending,
    uploadedData,
    uploadFileErrorMessage,
    arePolkadotAddressesValid,
    areEmailsValid,
    areEthAddressesValid,
    resetUpload,
  }
}
