import { BackArrowIcon } from '@components'

type Props = {
  children: React.ReactNode
  onBack: () => void
}

export const PageContent = ({ children, onBack }: Props) => {
  return (
    <div className='mx-4 mt-4 sm:mx-8 sm:mt-8 lg:mx-24 lg:mt-16 w-full max-w-[1364px]'>
      <div className='flex mb-1 p-2'>
        <div
          className='flex justify-start items-center gap-2 cursor-pointer'
          onClick={onBack}
        >
          <BackArrowIcon size={14} />
          <span>Back to all events</span>
        </div>
      </div>
      {children}
    </div>
  )
}
