import { Button, CheckLargeIcon } from '@components'
import Confetti from 'react-confetti-boom'
import { ProjectColors } from 'tokensoft-shared-types'

type ConfettiMessageProps = {
  message: string | JSX.Element
  onFinished: () => void
  themeColors?: ProjectColors
  children?: React.ReactNode
}

export const ConfettiMessage = ({
  message,
  onFinished,
  themeColors,
  children,
}: ConfettiMessageProps) => {
  const getConfettiColors = () => {
    let defaultColors: HexColorCode[] = []
    if (themeColors) {
      if (themeColors.primary) {
        defaultColors.push(themeColors.primary)
      }
      if (themeColors.secondary) {
        defaultColors.push(themeColors.secondary)
      }
    }
    if (defaultColors.length === 0) {
      defaultColors = ['#ff577f', '#ff884b', '#ffd384', '#fff9b0']
    }
    return defaultColors
  }

  return (
    <div className='bg-white overflow-hidden relative'>
      <Confetti
        mode='fall'
        shapeSize={8}
        particleCount={60}
        colors={getConfettiColors()}
      />
      <div className='p-6 flex flex-col justify-center items-center'>
        <div className='w-96 flex flex-col justify-center items-center gap-4'>
          <CheckLargeIcon />
          <h2 className='text-2xl font-semibold text-gray-800'>
            Congratulations! 🎉
          </h2>
          <p className='text-gray-600 text-center'>{message}</p>
          {children}
          <div className='flex justify-end'>
            <Button
              className='text-white font-bold py-2 px-4 rounded transition duration-200'
              onClick={onFinished}
            >
              Done
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
