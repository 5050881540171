import { Card } from './card'

interface CountdownItemProps {
  value: number
  label: string
}

/** Simple component to render value and label */
export const CountdownItem = ({ value, label }: CountdownItemProps) => {
  return (
    <Card
      data-testid='countdown-item'
      className='py-[16px] px-[8px] flex-col h-[84px] w-[84px] gap-[24px] items-center justify-center bg-[#fffbe9] '
    >
      <div className='font-semibold text-black text-2xl text-center'>
        {value}
      </div>
      <p className='text-xs mt-1 text-center uppercase font-normal'>{label}</p>
    </Card>
  )
}
