import { createContext, useContext } from 'react'

interface WalletContextProps {
  provider?: any
  connectedChainId?: any
  supportedNetwork: boolean
  account?: any
  network: any
  connectWallet: Function
  disconnectWallet: Function
  switchChain: Function
  enableAddWalletMode: Function
  disableAddWalletMode: Function
}

export const WalletContext = createContext<Maybe<WalletContextProps>>(null)
WalletContext.displayName = 'WalletContext'

export const useWallet = (): WalletContextProps => {
  const context = useContext(WalletContext)

  if (!context) {
    throw new Error(
      'WalletContext context is undefined, please verify you are calling useWallet() as child of a <WalletContext> component.',
    )
  }

  return context
}
