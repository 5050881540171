import { useValidateParticipants } from '@apiServices'
import {
  Alert,
  ButtonLink,
  CheckboxInput,
  Col,
  DownloadIcon,
  FileUploadButton,
  InputGroup,
  LoadingIndicator,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
  UploadIcon,
} from '@components'
import { useToast } from '@contexts'
import { TokenInfo } from '@custom-types/token-info'
import { FileUploadResult } from '@customTypes'
import { convertBaseUnitsToDecimal, formatValue } from '@utils'
import { useEffect, useState } from 'react'

export type DistributorParticipantsContext = {
  participantsFile?: FileUploadResult
  totalParticipants?: number
  totalAllocations?: string
  validParticipantsFile?: boolean
  verificationPending?: boolean
  tokenInfo?: TokenInfo
  participantsFormValid?: boolean
  useIndividualVestingSchedules?: boolean
}

interface DistributorParticipantsProps {
  context: DistributorParticipantsContext
  setContext: (context: DistributorParticipantsContext) => void
  useExperimentalContractFeatures: boolean
}

export const DistributorParticipants = ({
  context,
  setContext,
  useExperimentalContractFeatures,
}: DistributorParticipantsProps) => {
  const { showErrorToast } = useToast()

  const [useIndividualVestingSchedules, setUseIndividualVestingSchedules] =
    useState<boolean>(context.useIndividualVestingSchedules ?? false)

  const [participantsFile, setParticipantsFile] = useState<
    FileUploadResult | undefined
  >(context.participantsFile)
  const [totalParticipants, setTotalParticipants] = useState(
    context?.totalParticipants || 0,
  )
  const [totalAllocations, setTotalAllocations] = useState<string>(
    context?.totalAllocations || '0',
  )
  const [validParticipantsFile, setValidParticipantsFile] = useState(
    context?.validParticipantsFile || false,
  )
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null)
  const [verificationPending, setVerificationPending] = useState(
    context?.verificationPending || false,
  )

  const { mutate: validateParticipants, isPending } = useValidateParticipants()

  const formValid = validParticipantsFile

  const formattedTotalParticipants = formatValue(totalParticipants, {
    commas: true,
  })
  const formattedTotalAllocations = formatValue(
    convertBaseUnitsToDecimal(
      totalAllocations,
      context?.tokenInfo?.decimals || 18,
    ),
    { commas: true },
  )

  const handleUploadError = (message?: string) => {
    setParticipantsFile(undefined)
    setTotalParticipants(0)
    setTotalAllocations('0')
    setValidParticipantsFile(false)
    setErrorMessage(message ?? null)
    setVerificationPending(false)
  }

  const handleFileUploaded = (fileUploadResult: FileUploadResult) => {
    setParticipantsFile(fileUploadResult)
    setTotalParticipants(0)
    setTotalAllocations('0')
    setValidParticipantsFile(false)
    setErrorMessage(null)
    setVerificationPending(true)
  }

  useEffect(() => {
    setContext({
      ...context,
      participantsFile,
      totalParticipants,
      totalAllocations,
      validParticipantsFile,
      participantsFormValid: formValid && !isPending,
      useIndividualVestingSchedules,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    verificationPending,
    participantsFile,
    formValid,
    useIndividualVestingSchedules,
  ])

  useEffect(() => {
    if (verificationPending) {
      // validate file
      validateParticipants(
        { uri: participantsFile?.s3Uri, useIndividualVestingSchedules },
        {
          onSuccess: (
            result:
              | { valid: true; totalParticipants: number; totalAmount: string }
              | { valid: false; errorMessage: string },
          ) => {
            if (result.valid) {
              setTotalParticipants(result.totalParticipants)
              setTotalAllocations(result.totalAmount)
              setValidParticipantsFile(true)
              setErrorMessage(null)
              setVerificationPending(false)
            } else {
              handleUploadError(result.errorMessage)
            }
          },
          onError: (error) => {
            console.error(error)
            showErrorToast({
              description: 'Error validating file.  Please try again.',
            })
            handleUploadError()
          },
        },
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationPending])

  const downloadTemplateButton = (
    <ButtonLink
      className='flex-shrink-0'
      fill='outline'
      href={
        /* TODO: if useIndividualVestingSchedules then link to a sample file that includes the tranches column */
        'https://s3.amazonaws.com/media.tokensoft.io/sample_participants.csv'
      }
      target={'blank'}
      data-testid={'download-template-button'}
    >
      <Text>Download Template</Text>
      <DownloadIcon />
    </ButtonLink>
  )

  return (
    <>
      {useExperimentalContractFeatures && (
        <CheckboxInput
          className='mt-5 cursor-pointer max-w-[fit-content]'
          name='useIndividualVestingSchedules'
          testId='useIndividualVestingSchedules'
          checked={useIndividualVestingSchedules}
          onClick={(e: React.ChangeEvent<HTMLInputElement>) => {
            setUseIndividualVestingSchedules(e.target.checked)
          }}
        >
          Use Individual Vesting Schedules
        </CheckboxInput>
      )}

      <Stacked data-testid={'distributor-token-selection'}>
        <Section>
          <SectionTitle>Upload a participant list</SectionTitle>
          {useIndividualVestingSchedules ? (
            <div className='flex items-start gap-8 justify-between flex-wrap'>
              <Text className='flex-1 min-w-[200px] lg:min-w-[384px]'>
                Your CSV file must include:
                <ul className='list-inside list-disc'>
                  <li>
                    A column labeled <b>address</b>
                  </li>

                  <li>
                    A column labeled <b>amount</b> (as an integer in base units)
                  </li>

                  <li>
                    A column labeled <b>tranches</b> (formatted as{' '}
                    <code>T,F,T,F,T,F...</code> where <code>T</code> is an
                    ISO-8601 datetime and <code>F</code> is a number between 0
                    and 1, representing the vested percentage of the allocation)
                  </li>
                </ul>
                You are limited to one file upload, any additional upload will
                override you previous files.
              </Text>

              {downloadTemplateButton}
            </div>
          ) : (
            <div className='flex items-start gap-8 justify-between flex-wrap'>
              <Text className='flex-1 min-w-[200px] lg:min-w-[384px]'>
                Your CSV file must include a column labeled <b>address</b> and a
                column labeled <b>amount</b> (as an integer in base units). You
                are limited to one file upload, any additional upload will
                override you previous files.
              </Text>

              {downloadTemplateButton}
            </div>
          )}
        </Section>

        <Section>
          <div className='flex w-full gap-x-4 gap-y-0 items-end flex-wrap'>
            <InputGroup
              className='flex-1 min-w-[200px] flex-wrap'
              label='File Name *'
              name={'participantsFile'}
              value={participantsFile?.filename}
              placeholder={'Select a File'}
            />
            <FileUploadButton
              className={'mb-6 flex-shrink-0'}
              fileTypes={'.csv'}
              maxSize={10485760}
              disabled={isPending}
              data-testid={'upload-file-button'}
              onFileUploaded={handleFileUploaded}
            >
              <UploadIcon />
              <Text>Upload File</Text>
            </FileUploadButton>
          </div>
        </Section>

        {isPending ? (
          <Col place={'center'}>
            <LoadingIndicator
              text={'Verifying Uploaded File'}
              className={'no-padding'}
            />
          </Col>
        ) : null}

        {!isPending && validParticipantsFile ? (
          <Stretched place={'start'} gap={2.5}>
            <Section
              gap={2.5}
              width={'1/2'}
              place={'start'}
              data-testid={'participants-file-summary'}
            >
              <SectionTitle data-testid={'total-participants-title'}>
                Total Participants
              </SectionTitle>
              <Text data-testid={'total-participants-value'}>
                {formattedTotalParticipants} addresses
              </Text>
            </Section>

            <Section gap={2.5} width='fit' place={'start'}>
              <SectionTitle data-testid={'total-allocations-title'}>
                Total Distribution Amount
              </SectionTitle>
              <Text data-testid={'total-allocations-value'}>
                {formattedTotalAllocations} {context?.tokenInfo?.symbol}
              </Text>
            </Section>
          </Stretched>
        ) : null}

        {!isPending && !validParticipantsFile && errorMessage ? (
          <Alert data-testid={'invalid-participants-file'} type='alert-danger'>
            {errorMessage}
          </Alert>
        ) : null}
      </Stacked>
    </>
  )
}
