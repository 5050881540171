import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { BigNumber } from 'bignumber.js'
import { DistributorMerkleTreeLeaf } from 'tokensoft-shared-types'
import { Hex } from 'viem'

export const useGetMyDistributorMerkleLeaf = (
  distributorAddress: EvmAddress,
  enabled: boolean = true,
) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<Maybe<DistributorMerkleTreeLeaf>>({
    queryKey: ['distributors', distributorAddress, 'merkle-tree-leaf'],
    queryFn: async () => {
      try {
        const leafRaw = (await fetchWithClient(
          `distributors/${distributorAddress}/merkle-tree-leaf`,
        )) as Record<string, unknown>

        if (!leafRaw) {
          return null
        }

        const leaf: DistributorMerkleTreeLeaf = {
          amount: new BigNumber(leafRaw.amount as string),
          beneficiary: leafRaw.beneficiary as Hex,
          index: leafRaw.index as number,
          merkleProof:
            leafRaw.proof as DistributorMerkleTreeLeaf['merkleProof'],
          encodedVestingSchedule: leafRaw.encodedVestingSchedule as Hex,
        }

        return leaf
      } catch (e) {
        // 404 occurs if user doesn't have a merkle leaf on the distributor
        if (e instanceof AxiosError && e.response?.status === 404) {
          return null
        }

        throw e
      }
    },
    enabled: enabled,
  })
}
