import {
  AnimatedCheckmark,
  Button,
  Col,
  DepositIcon,
  InputGroup,
  ModalTitle,
  Row,
  Stacked,
  Text,
  TextLink,
} from '@components'
import { useAuth, useModal, useNetworks, useToast, useWallet } from '@contexts'
import { FUND_TYPE } from '@enums'
import { useFundDistributor, useSweepDistributor } from '@hooks'
import {
  convertBaseUnitsToDecimal,
  formatValue,
  getAddressUrl,
  getTxUrl,
  toBaseUnits,
} from '@utils'
import { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { VscLinkExternal } from 'react-icons/vsc'

interface FundDistributorModalProps {
  distributor: any
  fundType: FUND_TYPE
  amount?: string
  contractAddress: string
  token: string
  toAddress: string
  onSuccess: Function
}

export const FundDistributorModal = ({
  distributor,
  fundType,
  amount,
  contractAddress,
  token,
  toAddress,
  onSuccess,
}: FundDistributorModalProps) => {
  const {
    user: { walletAddress },
  } = useAuth()
  const { getNetworkDetails } = useNetworks()
  const { closeModal } = useModal()
  const { connectedChainId, switchChain } = useWallet()
  const [userOnCorrectNetwork, setUserOnCorrectNetwork] =
    useState<boolean>(true)
  const { showSuccessToast, showErrorToast } = useToast()
  const [amountInput, setAmountInput] = useState<Maybe<any>>(null)
  const network = getNetworkDetails(distributor.chainId)
  const {
    write: fundDistributor,
    data: fundingReceipt,
    isLoading: isLoadingFunding,
    isSubmitting: isSubmittingFunding,
    error: errorFunding,
  } = useFundDistributor()
  const {
    write: sweepDistributor,
    data: sweepingReceipt,
    isLoading: isLoadingSweeping,
    isSubmitting: isSubmittingSweeping,
    error: errorSweeping,
  } = useSweepDistributor()
  const receipt = fundingReceipt || sweepingReceipt

  const value = formatValue(
    convertBaseUnitsToDecimal(amount ?? -1, distributor?.tokenDecimals || 18),
    { commas: true },
  )
  const symbol = distributor?.symbol.toUpperCase()

  useEffect(() => {
    if (receipt?.blockHash) {
      onSuccess && onSuccess()
    }
  }, [receipt?.blockHash])

  useEffect(() => {
    if (connectedChainId) {
      setUserOnCorrectNetwork(connectedChainId === distributor.chainId)
    }
  }, [connectedChainId])

  const handleAction = () => {
    if (fundType === FUND_TYPE.DEPOSIT) {
      const baseUnitAmount = toBaseUnits(
        amountInput,
        distributor.tokenDecimals || 18,
      )
      fundDistributor(
        contractAddress,
        toAddress,
        baseUnitAmount,
        distributor.chainId,
      )
    } else {
      sweepDistributor(
        contractAddress,
        token,
        amount ?? `-1`,
        distributor.chainId,
      )
    }
  }

  const title =
    fundType === FUND_TYPE.DEPOSIT ? 'Deposit Funds' : 'Withdraw Excess Funds'

  useEffect(() => {
    if (receipt) {
      const text = fundType === FUND_TYPE.DEPOSIT ? 'deposit' : 'withdrawal'
      showSuccessToast({
        description: (
          <div className='flex flex-row'>
            {`Successfully submitted ${text}.`}
            <a
              target='_blank'
              rel='noreferrer'
              href={getTxUrl(
                receipt.transactionHash,
                getNetworkDetails(distributor.chainId),
              )}
              className='w-[30px] flex items-center justify-center text-white'
              onClick={(e) => e.stopPropagation()}
            >
              <VscLinkExternal color='white' />
            </a>
          </div>
        ),
      })
    }
  }, [receipt])

  useEffect(() => {
    const error = errorFunding || errorSweeping
    if (error) {
      console.error(error)
      showErrorToast({
        description:
          'The transaction could not be completed, please try again later.',
      })
    }
  }, [errorFunding, errorSweeping])

  const renderDeposit = () => {
    return (
      <Col gap={5} place={'center'}>
        <DepositIcon />
        <ModalTitle>{title}</ModalTitle>
        <Text textAlign='center'>
          Please select the amount of {symbol} Tokens you would like to deposit
          into your distributor. Currently you are missing{' '}
          <span className='font-semibold'>{value}</span> {symbol} in your
          distributor.
        </Text>
        <Col place='left' gap={2}>
          <TextLink
            place={'start-center'}
            width='fit'
            className='underline'
            onClick={() => {
              if (isSubmittingFunding || isLoadingFunding) {
                return
              }
              setAmountInput(value)
            }}
          >
            Full Amount
          </TextLink>
          <InputGroup
            width={'full'}
            type='number'
            decimalScale={distributor.tokenDecimals || 18}
            name='Amount'
            placeholder='Enter amount here'
            value={amountInput}
            readonly={isSubmittingFunding || isLoadingFunding}
            required={true}
            onChange={({ value }: any) => {
              setAmountInput(value)
            }}
          />
          <TextLink place={'center'}>
            <a
              target='_blank'
              rel='noreferrer'
              href={getAddressUrl(
                distributor.id,
                getNetworkDetails(distributor.chainId),
              )}
              onClick={(e) => e.stopPropagation()}
            >
              <Row nowrap place={'start-center'} gap={2.5}>
                <Text>Distributor Address</Text>
                <VscLinkExternal />
              </Row>
            </a>
          </TextLink>
        </Col>
        <Button
          className='w-full btn-primary'
          onClick={() => handleAction()}
          disabled={isLoadingFunding || isSubmittingFunding || !amountInput}
        >
          {isSubmittingFunding || isLoadingFunding ? (
            <>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>

              <Text>Submitting</Text>
            </>
          ) : (
            <Text>Submit Deposit</Text>
          )}
        </Button>
      </Col>
    )
  }

  const renderBody = () => {
    if (!userOnCorrectNetwork) {
      return (
        <Col gap={5} place={'center'}>
          <ModalTitle>{title}</ModalTitle>
          <Text>Please switch to the {network.name} network to continue.</Text>
          <Button
            color='primary'
            className='w-full'
            onClick={() => {
              switchChain(distributor.chainId)
            }}
          >
            <Text>Switch Network</Text>
          </Button>
        </Col>
      )
    }

    if (receipt?.blockHash) {
      return (
        <Col gap={5} place={'center'}>
          <AnimatedCheckmark size={'large'} />
          <ModalTitle>{title}</ModalTitle>
          <Text width='3/4' textAlign={'center'}>
            Your request to {title.toLowerCase()} was a success.
          </Text>
          <Button
            color={'primary'}
            className='w-full'
            onClick={() => closeModal()}
          >
            <Text>Finish</Text>
          </Button>
        </Col>
      )
    }

    // For withdrawal, verify that the current user is the owner of the distributor contract
    if (
      fundType === FUND_TYPE.WITHDRAW &&
      distributor?.advanced?.owner?.id.toLowerCase() !==
        walletAddress?.toLowerCase()
    ) {
      return (
        <Col gap={5} place={'center'}>
          <ModalTitle>{title}</ModalTitle>
          <Text>
            You are not the owner of this distributor contract. Only the owner
            can withdraw excess funds.
          </Text>
          <Button
            color={'primary'}
            className='w-full'
            onClick={() => closeModal()}
          >
            <Text>Finish</Text>
          </Button>
        </Col>
      )
    }

    if (fundType === FUND_TYPE.DEPOSIT) {
      return renderDeposit()
    }

    return (
      <Col gap={5} place={'center'}>
        <DepositIcon />
        <ModalTitle>{title}</ModalTitle>
        <Text>
          Currently you have an exess of{' '}
          <span className='font-semibold'>{value}</span> {symbol} tokens in your
          distributor. Click the button below to withdraw {value} {symbol}.
        </Text>
        <TextLink place={'center'}>
          <a
            target='_blank'
            rel='noreferrer'
            href={getAddressUrl(
              distributor.id,
              getNetworkDetails(distributor.chainId),
            )}
            onClick={(e) => e.stopPropagation()}
          >
            <Row nowrap place={'start-center'} gap={2.5}>
              <Text>Distributor Address</Text>
              <VscLinkExternal />
            </Row>
          </a>
        </TextLink>
        <Button
          className='w-full btn-primary'
          onClick={() => handleAction()}
          disabled={isLoadingSweeping || isSubmittingSweeping}
        >
          {isSubmittingSweeping || isLoadingSweeping ? (
            <>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={24} />
              </div>

              <Text>Withdrawing</Text>
            </>
          ) : (
            <Text>Submit Withdrawal</Text>
          )}
        </Button>
      </Col>
    )
  }

  return <Stacked>{renderBody()}</Stacked>
}
