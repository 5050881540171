import { viewDocumentVersion } from '@apiServices'
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  EditIcon,
  PDFIcon,
  Row,
  Section,
  Stacked,
  Stretched,
  TableData,
  Text,
  TextLink,
} from '@components'
import { useAuth } from '@contexts'
import { DOCUMENT_SHOW_TO_TYPE } from '@enums'
import { showDocumentTo } from '@utils'
import classNames from 'classnames'
import { useState } from 'react'
import { AiFillEye } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom'

interface EventDocumentsPageProps {
  context: any
  className?: string
}

export const EventDocumentsPage = ({
  context,
  className = '',
}: EventDocumentsPageProps) => {
  const navigate = useNavigate()
  const {
    user: { token },
  } = useAuth()
  const [isOpen, setIsOpen] = useState(!Object.keys(context).includes('id'))

  const key = 'event-documents'

  const formatDocumentTitle = (document: any): string => {
    return `${document.documentVersion.document.name}`
  }

  const formatDocumentRestrictionType = (document: any): string => {
    const restrictionType = showDocumentTo(document.regionRestriction)
    let restriction = ''
    switch (restrictionType) {
      case DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS:
        restriction = 'All eligible persons'
        break
      case DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US:
        restriction = 'Eligible persons outside the US'
        break
      case DOCUMENT_SHOW_TO_TYPE.US_ONLY:
        restriction = 'Eligible persons within the US'
        break
    }

    return restriction
  }

  const handleOpenDocument = async (document: any) => {
    await viewDocumentVersion(document, token)
  }

  return (
    <Card className={classNames(className)}>
      <CardHeader place={'center'}>
        <CardTitle
          open={isOpen}
          onOpen={context.id ? setIsOpen : undefined}
          data-testid={`${key}-title`}
        >
          Documents
        </CardTitle>
        {context.id ? (
          <TextLink
            width={'fit'}
            onClick={() => {
              navigate(
                `/admin/project/${context.project.id}/event/${context.id}/documents`,
              )
            }}
          >
            <EditIcon />
            <Text>Edit Details</Text>
          </TextLink>
        ) : null}
      </CardHeader>

      <CardBody open={isOpen}>
        <Stacked>
          <Section gap={0} place={'start'}>
            {!context?.documents?.length ? (
              <Stretched ygap={5}>
                <Col>
                  <TableData data-testid={`${key}-start-date-data`}>
                    No documents found
                  </TableData>
                </Col>
              </Stretched>
            ) : (
              context?.documents?.map((doc: any, i: any) => {
                return (
                  <Row
                    xalign={'between'}
                    key={i}
                    className='document-section cursor-pointer'
                    onClick={() => {
                      handleOpenDocument(doc)
                    }}
                  >
                    <div className='flex flex-row justify-center items-center'>
                      <PDFIcon />
                      <span className='ml-2'>{formatDocumentTitle(doc)}</span>
                    </div>
                    <div className='flex flex-row justify-center items-center mt-2 sm:mt-0'>
                      <span className='mr-2'>
                        <AiFillEye fill='var(--primary)' size={22} />
                      </span>
                      <span className='mr-3'>
                        {formatDocumentRestrictionType(doc)}
                      </span>
                    </div>
                  </Row>
                )
              })
            )}
          </Section>
        </Stacked>
      </CardBody>
    </Card>
  )
}
