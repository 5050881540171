import { useAccount, useChains } from 'wagmi'
import { useTSAddress } from './use-ts-address'

export const useWagmiAccount = (): Pick<
  ReturnType<typeof useAccount>,
  'chain' | 'chainId' | 'address'
> => {
  const { chain: wagmiChain } = useAccount()

  const { address, chainId } = useTSAddress()

  const chains = useChains()

  const chain = wagmiChain || chains.find((chain) => chain.id === chainId)

  return { address, chain, chainId: wagmiChain?.id || chainId }
}
