import { useToast } from '@contexts'
import { useApproveErc20Transfer, useBuyWithToken } from '@hooks'
import { LoaderButton } from '@newComponents'
import BigNumber from 'bignumber.js'
import { useEffect } from 'react'
import { Blockchain, SaleSubgraphPaymentMethod } from 'tokensoft-shared-types'
import { BaseError, TransactionReceipt } from 'viem'

interface TokenPurchaseButtonProps {
  saleAddress: EvmAddress
  chain: Blockchain
  purchaseAmountInToken: CurrencyValue
  paymentMethod: SaleSubgraphPaymentMethod
  platformFeeRecipient: Maybe<EvmAddress>
  platformFeeInDollars: CurrencyValue
  platformFeeInNative: CurrencyValue
  /** The number of decimals in the chain native token */
  nativeDecimals: number
  onSuccess: (receipt: TransactionReceipt) => void
  disabled: boolean
  className?: string
}

export const TokenPurchaseButton = ({
  saleAddress,
  chain,
  purchaseAmountInToken,
  paymentMethod,
  platformFeeRecipient,
  platformFeeInDollars,
  platformFeeInNative,
  nativeDecimals,
  onSuccess,
  disabled,
  className = '',
}: TokenPurchaseButtonProps) => {
  const { showErrorToast } = useToast()

  const {
    approveErc20Transfer,
    isPending: isPendingErc20TransferApproval,
    receipt: approveErc20TranferReceipt,
    error: approveErc20TransferError,
  } = useApproveErc20Transfer()

  const isErc20TransferApproved = approveErc20TranferReceipt !== null

  const {
    buyWithToken,
    receipt: buyWithTokenReceipt,
    isPending: isPendingBuyWithToken,
    error: buyWithTokenError,
  } = useBuyWithToken(chain.id, saleAddress)

  const handleApprove = async () => {
    if (!paymentMethod.token) {
      throw new Error('Payment method address is missing')
    }

    // Round up to make sure spending cap is above necessary transfer amount
    const erc20AmountInWei = purchaseAmountInToken.value
      .times(10 ** paymentMethod.decimals)
      .decimalPlaces(0, BigNumber.ROUND_UP)

    await approveErc20Transfer(
      chain.id,
      paymentMethod.token,
      saleAddress,
      erc20AmountInWei,
    )
  }

  const handleBuy = async () => {
    if (!paymentMethod.token) {
      throw new Error('Payment method address is missing')
    }

    // Convert ERC20 purchase amount to wei based on token decimals.
    // Can't pass decimals into contract, so round up to make sure the value
    // remains above the required platform fee.
    const tokenValueInUSDWei = purchaseAmountInToken.value
      .times(10 ** paymentMethod.decimals)
      .decimalPlaces(0, BigNumber.ROUND_UP)

    // Platform fee in native is always paid as `value` in the transaction.
    // Round up to make sure the value remains above the required platform fee.
    const platformFeeInNativeWei = platformFeeInNative.value
      .times(10 ** nativeDecimals)
      .decimalPlaces(0, BigNumber.ROUND_UP)

    await buyWithToken(
      paymentMethod.token,
      tokenValueInUSDWei,
      platformFeeRecipient,
      platformFeeInDollars.value,
      platformFeeInNativeWei,
    )
  }

  useEffect(() => {
    if (buyWithTokenError) {
      showErrorToast({
        description:
          buyWithTokenError instanceof BaseError
            ? buyWithTokenError.shortMessage
            : buyWithTokenError.message,
      })
    }

    if (approveErc20TransferError) {
      showErrorToast({
        description:
          approveErc20TransferError instanceof BaseError
            ? approveErc20TransferError.shortMessage
            : approveErc20TransferError.message,
      })
    }
  }, [buyWithTokenError, approveErc20TranferReceipt])

  useEffect(() => {
    if (buyWithTokenReceipt) {
      onSuccess(buyWithTokenReceipt)
    }
  }, [buyWithTokenReceipt])

  return (
    <>
      <div className='flex gap-4 justify-center'>
        {!isErc20TransferApproved && (
          <LoaderButton
            className={className}
            disabled={disabled}
            onClick={handleApprove}
            isLoading={isPendingErc20TransferApproval}
            loadingText='Approving...'
            text='Approve Payment Transfer'
          />
        )}
        {isErc20TransferApproved && (
          <LoaderButton
            className={className}
            disabled={disabled}
            onClick={handleBuy}
            isLoading={isPendingBuyWithToken}
            loadingText='Purchasing...'
            text='Confirm Purchase'
          />
        )}
      </div>
    </>
  )
}
