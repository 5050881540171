import { Text } from '@components'
import classNames from 'classnames'

type TextProps = React.ComponentProps<typeof Text>
interface BreadcrumbProps extends TextProps {
  className?: string
  children: React.ReactNode
}

export const Breadcrumb: React.FC<BreadcrumbProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      textAlign={'center'}
      className={classNames(
        'breadcrumb text-xs font-normal text-neutral-medium',
        className,
      )}
      {...restProps}
    >
      {children}
    </Text>
  )
}
