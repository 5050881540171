import { useToast } from '@contexts'
import { useEffect } from 'react'
import './toast.css'

export const Toast = () => {
  const { toast, clearToast } = useToast()
  const {
    dismissTime,
    position,
    autoDelete,
    backgroundColor,
    icon,
    title,
    description,
  } = toast || {}

  useEffect(() => {
    const interval = setInterval(() => {
      if (autoDelete) {
        clearToast()
      }
    }, dismissTime)

    return () => clearInterval(interval)
  }, [toast])

  return toast ? (
    <div
      className={`toast-container ${position}`}
      onClick={(e) => {
        e.stopPropagation()
        clearToast()
      }}
    >
      <div
        className={`toast-content ${position}`}
        style={{ backgroundColor: backgroundColor }}
      >
        <div className='pr-2 toast-icon'>{icon}</div>
        <div className='flex flex-col'>
          {title ? (
            <span className='block font-semibold text-white text-base'>
              {title}
            </span>
          ) : null}
          <span>{description}</span>
        </div>
      </div>
    </div>
  ) : null
}
