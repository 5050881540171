import {
  Col,
  FormLabel,
  HelpPopover,
  HexColorInput,
  ImageUploadButton,
  InfoIcon,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components'
import {
  DEFAULT_EVENT_HEADER_BG_COLOR,
  DEFAULT_PROJECT_CONTAINER_BG_COLOR,
  DEFAULT_PROJECT_CONTENT_BG_COLOR,
  DEFAULT_PROJECT_HEADER_BG_COLOR,
  DEFAULT_PROJECT_HEADER_FONT_COLOR,
  DEFAULT_PROJECT_PRIMARY_COLOR,
  DEFAULT_PROJECT_SECONDARY_COLOR,
  DEFAULT_PROJECT_SIDEBAR_BG_COLOR,
  DEFAULT_PROJECT_SIDEBAR_FONT_COLOR,
} from '@constants'
import { useAccount } from '@contexts'
import { ReactNode, useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'

export const ProjectThemeSetup = ({
  wizardState,
  updateWizardState,
  children,
}: {
  wizardState: any
  updateWizardState: Function
  editMode?: boolean
  children?: ReactNode
}) => {
  const { account } = useAccount()

  const [formState, setFormState] = useState({
    titleFont: wizardState.titleFont,
    baseFont: wizardState.baseFont,
    titleFontSize: wizardState.titleFontSize,
    baseFontSize: wizardState.baseFontSize,
    headerBgColor: wizardState.headerBgColor || DEFAULT_PROJECT_HEADER_BG_COLOR,
    sidebarBgColor:
      wizardState.sidebarBgColor || DEFAULT_PROJECT_SIDEBAR_BG_COLOR,
    sidebarFontColor:
      wizardState.sidebarFontColor || DEFAULT_PROJECT_SIDEBAR_FONT_COLOR,
    contentBgColor:
      wizardState.contentBgColor || DEFAULT_PROJECT_CONTENT_BG_COLOR,
    containerBgColor:
      wizardState.containerBgColor || DEFAULT_PROJECT_CONTAINER_BG_COLOR,
    primaryColor: wizardState.primaryColor || DEFAULT_PROJECT_PRIMARY_COLOR,
    headerFontColor:
      wizardState.headerFontColor || DEFAULT_PROJECT_HEADER_FONT_COLOR,
    secondaryColor:
      wizardState.secondaryColor || DEFAULT_PROJECT_SECONDARY_COLOR,
    logo: wizardState.logo || '',
    logoSmall: wizardState.logoSmall || '',
    favicon: wizardState.favicon || '',
  })

  const validLogoImage = formState.logo?.trim() !== ''
  const validLogoSmallImage = formState.logoSmall?.trim() !== ''
  const validFaviconImage = formState.favicon?.trim() !== ''

  const formValid = validLogoImage && validLogoSmallImage && validFaviconImage

  useEffect(() => {
    updateWizardState({
      ...wizardState,
      ...formState,
      projectThemeFormValid: formValid,
    })
  }, [formState])

  const handleImageChanged = (imagekey: any, value: any) => {
    setFormState({ ...formState, [imagekey]: value })
  }

  const handleColorChange = (e: any) => {
    let newColor = ''

    if (e.target.value.length === 0) {
      newColor = '#'
    } else {
      newColor =
        e.target.value[0] === '#' ? e.target.value : `#${e.target.value}`
    }

    setFormState({ ...formState, [e.target.name]: newColor })
  }

  return (
    <Stacked gap={5}>
      {/* HEADER SECTION */}
      <Section gap={5}>
        <Col>
          <SectionTitle>Universal Branding Elements</SectionTitle>
          <Text>
            Please pick your primary color which will be used for all buttons
            and calls to action. You can upload a custom header logo and small
            screen icon as well as your favicon for browser.
          </Text>
        </Col>
        <Col xgap={0} ygap={2.5}>
          <Stretched xgap={4} ygap={0}>
            <HexColorInput
              label='Primary Color'
              color={formState.primaryColor}
              defaultColor={DEFAULT_EVENT_HEADER_BG_COLOR}
              onChange={handleColorChange}
              name='primaryColor'
              required={true}
              className={'w-full'}
            />

            <Col className={'w-full'}>
              <Row yalign={'center'} gap={2}>
                <FormLabel>Header Logo *</FormLabel>
                <HelpPopover
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <div>
                    <Text>
                      Please ensure that the image is in .png format and has a
                      transparent background.
                    </Text>
                    <Text>It shouldn't exceed 2MB in size.</Text>
                  </div>
                </HelpPopover>
              </Row>
              {formState.logo ? (
                <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                  <Text>Logo.png</Text>
                  <button
                    onClick={() => {
                      handleImageChanged('logo', '')
                    }}
                  >
                    <AiFillCloseCircle size={14} color='var(--danger)' />
                  </button>
                </Row>
              ) : (
                <Row className='w-44'>
                  <ImageUploadButton
                    fill={'outline'}
                    onFileUploaded={(result) => {
                      handleImageChanged('logo', result.httpsUrl)
                    }}
                  />
                </Row>
              )}
            </Col>
          </Stretched>
          <Stretched xgap={4} ygap={0}>
            <Col className={'w-full'}>
              <Row yalign={'center'} gap={2}>
                <FormLabel>Logo Small/Icon *</FormLabel>
                <HelpPopover
                  positions={['right']}
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <div>
                    <Text>
                      Please ensure that the image is in .png format and has a
                      transparent background.
                    </Text>
                    <Text>It shouldn't exceed 2MB in size.</Text>
                  </div>
                </HelpPopover>
              </Row>
              {formState.logoSmall ? (
                <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                  <Text>LogoSmall.png</Text>
                  <button
                    onClick={() => {
                      handleImageChanged('logoSmall', '')
                    }}
                  >
                    <AiFillCloseCircle size={14} color='var(--danger)' />
                  </button>
                </Row>
              ) : (
                <Row className='w-44'>
                  <ImageUploadButton
                    fill={'outline'}
                    onFileUploaded={(result) => {
                      handleImageChanged('logoSmall', result.httpsUrl)
                    }}
                  />
                </Row>
              )}
            </Col>
            <Col className={'w-full'}>
              <Row yalign={'center'} gap={2}>
                <FormLabel>Favicon *</FormLabel>
                <HelpPopover
                  button={
                    <div className='block cursor-pointer' onClick={() => {}}>
                      <InfoIcon fill={'var(--neutral)'} />
                    </div>
                  }
                >
                  <div>
                    <Text>
                      Please ensure that the image is in .png format and has a
                      transparent background.
                    </Text>
                    <Text>It shouldn't exceed 2MB in size.</Text>
                  </div>
                </HelpPopover>
              </Row>
              {formState.favicon ? (
                <Row nowrap gap={2} place={'center'} className={'mt-4 w-44'}>
                  <Text>Favicon.png</Text>
                  <button
                    onClick={() => {
                      handleImageChanged('favicon', '')
                    }}
                  >
                    <AiFillCloseCircle size={14} color='var(--danger)' />
                  </button>
                </Row>
              ) : (
                <Row className='w-44'>
                  <ImageUploadButton
                    fill={'outline'}
                    onFileUploaded={(result) => {
                      handleImageChanged('favicon', result.httpsUrl)
                    }}
                  />
                </Row>
              )}
            </Col>
          </Stretched>
        </Col>
      </Section>
    </Stacked>
  )
}
