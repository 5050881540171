import { VERIFICATION_STATUS } from '@enums'
import { AiFillCheckCircle, AiFillCloseCircle } from 'react-icons/ai'
import { PiArrowCircleUpRightFill } from 'react-icons/pi'

export const EligibilityStatus = ({
  id = 'status-icon',
  status,
  size = 'sm',
  ...restProps
}: any) => {
  const SIZES = {
    xs: 16,
    sm: 20,
    md: 24,
    lg: 48,
    xl: 56,
  }
  const iconSize = SIZES[size as keyof typeof SIZES]

  const key = id || 'status-icon'

  const renderIcon = () => {
    switch (status) {
      case true:
        return (
          <AiFillCheckCircle
            className={`status-icon-${VERIFICATION_STATUS.COMPLETED}`}
            data-testid={`${key}`}
            color='var(--success)'
            size={iconSize}
            {...restProps}
          />
        )

      case false:
        return (
          <AiFillCloseCircle
            className={`status-icon-${VERIFICATION_STATUS.PENDING}`}
            data-testid={`${key}`}
            color='var(--danger)'
            size={iconSize}
            {...restProps}
          />
        )

      default:
        return (
          <PiArrowCircleUpRightFill
            className={`status-icon-NEW`}
            data-testid={`${key}`}
            color='var(--info-light)'
            size={iconSize}
            {...restProps}
          />
        )
    }
  }

  return (
    <div className='flex flex-row gap-2 capitalize items-center'>
      {renderIcon()}
      {(status !== null ? (status ? 'Yes' : 'No') : 'Unknown').toLowerCase()}
    </div>
  )
}
