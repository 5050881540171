export enum DISTRIBUTOR_TYPES {
  // base distributor
  IDistributor = 'IDistributor',
  // Adbanced Distributor
  AdvancedDistributor = 'AdvancedDistributor',
  // unclaimed tokens in the distributor have voting power
  IVoting = 'IVoting',
  // distributor eligibility is determined by a merkle proof
  IMerkleSet = 'IMerkleSet',
  // tokens to distribute are vested/unlocked based on some conditions
  IVesting = 'IVesting',
  // tokens unlock in discrete chunks over time
  ITrancheVesting = 'ITrancheVesting',
  // tokens unlock based on price of a reference asset
  IPriceTierVesting = 'IPriceTierVesting',
  // tokens unlock continuously
  IContinuousVesting = 'IContinuousVesting',
  // one off interface to support misconfigured softdrop distributors
  IVotingWithoutDelegation = 'IVotingWithoutDelegation',
  // CrosschainDistributor for connext
  CrosschainDistributor = 'CrosschainDistributor',
  // Satellite contracts for connext
  Satellite = 'Satellite',
  IConnext = 'IConnext',
}
