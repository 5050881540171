import { useUpdateProject } from '@apiServices'
import {
  Button,
  ButtonRow,
  Card,
  LoadingIndicator,
  RadioInput,
  Text,
} from '@components'
import { useProject, useToast } from '@contexts'
import { VERIFICATION_STATUS } from '@enums'
import classNames from 'classnames'
import { useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import { StatusIcon } from './status-icon'

export const ProjectAuthorizedSigner = () => {
  const { project, loading, setView, authorizedSigner, isAuthorizedSigner } =
    useProject()
  const { tier1Status, isTier1Started } = authorizedSigner || {}
  const { mutate: update, isPending: updateLoading } = useUpdateProject()

  const { showSuccessToast, showErrorToast } = useToast()

  const [authorizedSignerId, setAuthorizedSignerId] = useState<Maybe<any>>(null)

  const handleSave = () => {
    update(
      {
        ...project,
        authorizedSignerId,
      },
      {
        onSuccess: () =>
          showSuccessToast({
            description: 'Successfully designated authorized signer.',
          }),
        onError: (error) => showErrorToast({ description: error.message }),
      },
    )
  }

  const authorizedSignerOptions = project?.members
    ?.filter((member: any) => !!member.account.emailVerified)
    .map((member: any) => ({
      id: member.id,
      name:
        member.account.firstName && member.account.lastName
          ? `${member.account.firstName} ${member.account.lastName}`
          : member.account.email,
    }))

  const kybStatus = {
    [VERIFICATION_STATUS.NONE]: <span>KYB has not been started</span>,
    [VERIFICATION_STATUS.PENDING]: (
      <span>
        KYB status is <span className='warning'>pending</span>
      </span>
    ),
    [VERIFICATION_STATUS.COMPLETED]: (
      <span>
        KYB status is <span className='success'>completed</span>
      </span>
    ),
    [VERIFICATION_STATUS.FAILED]: (
      <span>
        KYB status is <span className='danger'>failed</span>
      </span>
    ),
  }

  return (
    <Card className='h-full no-header-border' title='Authorized Signer'>
      {loading ? (
        <div className='mb-12'>
          <LoadingIndicator />
        </div>
      ) : authorizedSigner ? (
        <div className='h-full flex flex-col items-center space-y-8'>
          <div className='mt-4'>
            <StatusIcon status={tier1Status} size='xl' />
          </div>
          <div className='mt-2 title-text text-center'>
            {kybStatus[tier1Status as keyof typeof kybStatus]}
          </div>
          <div className='flex flex-col text-center'>
            <div className='font-semibold'>Authorized Signer</div>
            {authorizedSigner.firstName && authorizedSigner.lastName ? (
              <div>
                Name: {authorizedSigner.firstName} {authorizedSigner.lastName}
              </div>
            ) : null}
            <div>Email: {authorizedSigner.email}</div>
          </div>
          <div className='w-full h-full flex flex-row items-end justify-end'>
            {isAuthorizedSigner ? (
              <Link
                className={classNames(
                  'btn mr-2',
                  isTier1Started ? 'btn-primary' : 'btn-outline-primary',
                )}
                to='/account'
              >
                My Profile
              </Link>
            ) : null}
            {!isAuthorizedSigner ? null : isTier1Started ? null : (
              <Link className='btn btn-primary' to='/account/identity'>
                Start KYB
              </Link>
            )}
          </div>
        </div>
      ) : (
        <>
          {authorizedSignerOptions.length > 0 ? (
            <div className='h-full flex flex-col justify-between'>
              <div className='flex flex-row flex-wrap'>
                <div className='w-full'>
                  <div className={'mb-8'}>Choose an authorized signer:</div>
                  {authorizedSignerOptions?.map(({ id, name }: any) => (
                    <RadioInput
                      key={id}
                      name='authorizedSigner'
                      checked={authorizedSignerId === id}
                      onClick={() => setAuthorizedSignerId(id)}
                    >
                      {name}
                    </RadioInput>
                  ))}
                </div>

                <div className={'text-xs mt-8'}>
                  * Only team members with verified emails are eligible to be
                  the authorized signer.
                </div>
              </div>

              <ButtonRow place={'end'}>
                <Button
                  fill={'outline'}
                  disabled={updateLoading}
                  className='btn btn-outline-primary'
                  onClick={() => setView('project-members')}
                >
                  <Text>Invite Team Members</Text>
                </Button>

                <Button
                  disabled={!authorizedSignerId || updateLoading}
                  onClick={handleSave}
                >
                  {updateLoading ? (
                    <>
                      <div className='animate-spin'>
                        <AiOutlineLoading3Quarters size={24} />
                      </div>
                      <Text className='pl-2'>Saving...</Text>
                    </>
                  ) : (
                    <Text>Save</Text>
                  )}
                </Button>
              </ButtonRow>
            </div>
          ) : (
            <>
              <div className={'flex flex-col items-center'}>
                <div>
                  <StatusIcon status={'PENDING'} size='xl' />
                </div>
                <div className='mt-4 title-text text-center'>
                  <span className=''>Team Members Required</span>
                </div>
                <div className='mt-3 text-center'>
                  <span>
                    Only team members who have verified their emails are
                    eligible to be the authorized signer. Click{' '}
                    <a onClick={() => setView('project-members')}>here</a> to
                    invite additional team members.
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </Card>
  )
}
