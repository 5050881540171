import { useGetUsersEvent } from '@apiServices'
import { EventUsersTableHeader, NetworkIcon } from '@components'
import { POLKADOT_LOGO_URL } from '@constants'
import { BLOCKCHAIN_KEY } from '@enums'
import { AiFillCheckCircle } from 'react-icons/ai'

export enum USER_PAGE_TABLE_CONFIG_TYPES {
  WALLETS = 'WALLETS',
}

export const USER_PAGE_TABLE_RELATIONSHIP = [
  {
    type: USER_PAGE_TABLE_CONFIG_TYPES.WALLETS,
    text: 'Wallets',
    tableConfig: USER_PAGE_TABLE_CONFIG_TYPES.WALLETS,
  },
]

const getNetworkIcon = (info: any) => {
  const value = info.getValue()

  if (value.blockchainType === BLOCKCHAIN_KEY.POLKADOT) {
    return <img src={POLKADOT_LOGO_URL} width='24' />
  }

  return (
    <NetworkIcon
      chainId={value.protocol}
      logoSize={'sm'}
      showNetworkName={false}
    />
  )
}

export const USER_PAGE_TABLE_CONFIG = {
  WALLETS: {
    getData: useGetUsersEvent,
    columnsData: (orderBy: any, handleOrderByChange: any, disabled: any) => [
      {
        accessorFn: (row: any) => row.walletAddress,
        id: 'walletAddress',
        cell: (info: any) => <div>{info.getValue()}</div>,
        header: () => (
          <EventUsersTableHeader
            content={'WALLET ADDRESS'}
            orderKey='wallet'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        headerText: 'WALLET ADDRESS',
        footer: (props: any) => props.column.id,
        size: 160,
      },
      {
        accessorFn: (row: any) => row,
        id: 'protocol',
        cell: (info: any) => getNetworkIcon(info),
        header: () => (
          <EventUsersTableHeader
            content={'PROTOCOL'}
            orderKey='wallet'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        headerText: 'PROTOCOL',
        footer: (props: any) => props.column.id,
        size: 160,
      },
      {
        accessorFn: (row: any) => row.primary,
        id: 'primary',
        cell: (info: any) => (
          <div>
            {info.getValue() ? (
              <AiFillCheckCircle color='var(--success)' size={24} />
            ) : null}
          </div>
        ),
        header: () => (
          <EventUsersTableHeader
            content={'PRIMARY'}
            orderKey='wallet'
            orderList={orderBy}
            orderModify={handleOrderByChange}
            disabled={disabled}
          />
        ),
        headerText: 'PRIMARY',
        footer: (props: any) => props.column.id,
        size: 160,
      },
    ],
    handleTableData: (data: any) => {
      return data?.wallets?.map((acc: any) => {
        return {
          walletAddress: acc.walletAddress,
          protocol: acc.networkId,
          primary: acc.primary,
          blockchainType: acc.blockchainType,
        }
      })
    },
    totalRecords: (data: any) => {
      return data?.wallets?.length
    },
    orderBy: () => {
      return {}
    },
    filterBy: () => {
      return {}
    },
  },
}
