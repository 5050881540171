import {
  useCreateProjectMember,
  useRemoveProjectMember,
  useUpdateProjectMember,
} from '@apiServices'
import {
  Button,
  Card,
  Col,
  InputGroup,
  LoadingIndicator,
  ModalTitle,
  Row,
  Section,
  Stacked,
  StatusIcon,
  Stretched,
  Text,
} from '@components'
import { useAuth, useModal, useProject, useToast } from '@contexts'
import { getTruncatedAddress } from '@utils'
import { useState } from 'react'
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineEdit,
  AiOutlineLoading3Quarters,
} from 'react-icons/ai'
import { FaTrashAlt } from 'react-icons/fa'

export const ProjectMembers = () => {
  const {
    user: { walletAddress },
  } = useAuth()
  const { project, loading } = useProject()
  const { showSuccessToast, showErrorToast } = useToast()
  const [newWalletAddress, setNewWalletAddress] = useState<Maybe<any>>(null)
  const [editingProjectMember, setEditingProjectMember] =
    useState<Maybe<any>>(null)
  const [newNickname, setNewNickname] = useState<Maybe<any>>(null)
  const [removedProjectMemberId, setRemovedProjectMemberId] = useState<
    any | null
  >(null)

  const { showModal, closeModal } = useModal()

  const {
    mutate: create,
    isPending: createLoading,
    isError: createIsError,
  } = useCreateProjectMember()

  const {
    mutate: update,
    isPending: updateLoading,
    isError: updateIsError,
  } = useUpdateProjectMember()

  const { mutate: remove, isPending: removeLoading } = useRemoveProjectMember()

  const mutationInProgress = removeLoading || createLoading || updateLoading

  const handleNicknameChange = (changed: any) => {
    setNewNickname(changed.target.value)
  }

  const handleWalletAddressChange = (changed: any) => {
    setNewWalletAddress(changed.target.value)
  }

  const handleEditButtonClick = (member: any) => {
    setEditingProjectMember(member)
  }

  const handleCancelButtonClick = () => {
    setEditingProjectMember(null)
  }

  const handleEditingNicknameChange = (changed: any) => {
    setEditingProjectMember({
      ...editingProjectMember,
      nickname: changed.target.value,
    })
  }

  const handleCreateProjectMember = () => {
    create(
      {
        projectId: project.id,
        nickname: newNickname,
        walletAddress: newWalletAddress,
      },
      {
        onSuccess: () => {
          showSuccessToast({ description: 'Successfully added team member.' })
          setNewNickname(null)
          setNewWalletAddress(null)
        },
        onError: (error) => {
          console.log(error)
          showErrorToast({
            description: 'Invalid or duplicate team member.  Please try again.',
          })
        },
      },
    )
  }

  const handleRemoveProjectMember = (id: number) => {
    setRemovedProjectMemberId(id)
    remove(
      {
        projectId: project.id,
        id: id,
      },
      {
        onSuccess: () => {
          showSuccessToast({
            description: 'Successfully removed team member.',
          })
          setRemovedProjectMemberId(null)
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error removing team member.  Please try again.',
          })
          setRemovedProjectMemberId(null)
        },
      },
    )
  }

  const handleUpdateProjectMember = () => {
    update(
      {
        projectId: project.id,
        id: editingProjectMember.id,
        nickname: editingProjectMember.nickname,
        walletAddress: editingProjectMember.walletAddress,
      },
      {
        onSuccess: () => {
          showSuccessToast({
            description: 'Successfully updated team member.',
          })
          setEditingProjectMember(null)
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error updating team member.  Please try again.',
          })
        },
      },
    )
  }

  const deleteProjectMemberOnConfirm = (id: any) => {
    showModal({
      content: (
        <Col gap={4}>
          <Row xalign='center'>
            <ModalTitle>Confirm Delete</ModalTitle>
            <Text>Are you sure you want to remove this team member?</Text>
          </Row>
          <Row gap={4} xalign='end'>
            <Button
              size='sm'
              color='primary'
              onClick={() => {
                handleRemoveProjectMember(id)
                closeModal()
              }}
            >
              Delete
            </Button>
            <button
              className='btn btn-outline-primary btn-sm'
              onClick={() => {
                closeModal()
              }}
            >
              Cancel
            </button>
          </Row>
        </Col>
      ),
      className: 'md',
      disableClose: false,
    })
  }

  return (
    <>
      <Card className='mb-8 h-full'>
        <Stacked>
          <Section>
            <Stretched xgap={4} ygap={0} place={'center'}>
              <InputGroup
                label='Nickname'
                value={newNickname}
                name='nickname'
                valid={!createIsError}
                onChange={handleNicknameChange}
              />

              <InputGroup
                label='Wallet address'
                value={newWalletAddress}
                name='name'
                valid={!createIsError}
                onChange={handleWalletAddressChange}
              />

              <Button
                className={'w-full md:w-fit'}
                disabled={
                  mutationInProgress || !newNickname || !newWalletAddress
                }
                onClick={() => {
                  handleCreateProjectMember()
                }}
              >
                {createLoading ? (
                  <>
                    <div className='animate-spin'>
                      <AiOutlineLoading3Quarters size={24} />
                    </div>
                    <Text>Saving...</Text>
                  </>
                ) : (
                  <Text>Save</Text>
                )}
              </Button>
            </Stretched>
          </Section>

          <Section>
            <table className={'w-full'}>
              <thead>
                <tr>
                  <th
                    style={{ borderBottom: '1px solid var(--neutral-light)' }}
                    className={'text-left px-2'}
                  >
                    Nickname
                  </th>
                  <th
                    style={{ borderBottom: '1px solid var(--neutral-light)' }}
                    className={'text-left px-2'}
                  >
                    Wallet Address
                  </th>
                  <th
                    style={{ borderBottom: '1px solid var(--neutral-light)' }}
                    className={'text-left px-2'}
                  >
                    Authorized Signer
                  </th>
                  <th
                    style={{ borderBottom: '1px solid var(--neutral-light)' }}
                    className={'text-right px-2'}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {project.members?.length > 0 && (
                  <>
                    {project.members.map((member: any, i: any) => (
                      <tr key={i}>
                        <td className={'text-left p-2'}>
                          {editingProjectMember &&
                          editingProjectMember.id === member.id ? (
                            <InputGroup
                              label=''
                              value={editingProjectMember.nickname}
                              name='editingNickname'
                              valid={!updateIsError}
                              onChange={handleEditingNicknameChange}
                            />
                          ) : (
                            <span>{member.nickname || '-'}</span>
                          )}
                        </td>
                        <td className={'text-left p-2'}>
                          {member.walletAddress
                            ? getTruncatedAddress(member.walletAddress)
                            : '-'}
                        </td>
                        <td className='flex py-4 justify-center'>
                          {member.account.authId ===
                          project.authorizedSigner?.authId ? (
                            <StatusIcon status={true} />
                          ) : null}
                        </td>
                        <td className={'text-right p-2'}>
                          <div className={'flex flex-row justify-end'}>
                            {editingProjectMember &&
                            editingProjectMember.id === member.id ? (
                              <>
                                <button
                                  className={'btn btn-outline-primary btn-sm'}
                                  disabled={mutationInProgress}
                                  onClick={() => {
                                    handleCancelButtonClick()
                                  }}
                                >
                                  <AiOutlineClose />
                                </button>

                                <button
                                  className={
                                    'btn btn-outline-primary btn-sm ml-2'
                                  }
                                  disabled={mutationInProgress}
                                  onClick={() => {
                                    handleUpdateProjectMember()
                                  }}
                                >
                                  {updateLoading &&
                                  editingProjectMember.id === member.id ? (
                                    <div className='flex'>
                                      <div className='animate-spin'>
                                        <AiOutlineLoading3Quarters size={18} />
                                      </div>
                                    </div>
                                  ) : (
                                    <AiOutlineCheck />
                                  )}
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  className={'btn btn-outline-primary btn-sm'}
                                  disabled={mutationInProgress}
                                  onClick={() => {
                                    handleEditButtonClick(member)
                                  }}
                                >
                                  <AiOutlineEdit />
                                </button>

                                {member?.walletAddress &&
                                  walletAddress &&
                                  member?.walletAddress.toLowerCase() !==
                                    walletAddress.toLowerCase() &&
                                  member.account.authId !==
                                    project.authorizedSigner?.authId && (
                                    <button
                                      className={
                                        'btn btn-outline-primary btn-sm ml-2'
                                      }
                                      disabled={mutationInProgress}
                                      onClick={() =>
                                        deleteProjectMemberOnConfirm(member.id)
                                      }
                                    >
                                      {removeLoading &&
                                      removedProjectMemberId === member.id ? (
                                        <div className='flex'>
                                          <div className='animate-spin'>
                                            <AiOutlineLoading3Quarters
                                              size={14}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <FaTrashAlt />
                                      )}
                                    </button>
                                  )}
                              </>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}

                {!loading && project?.members?.length === 0 && (
                  <div className='subtitle mb-4'>
                    <p className='text-xs md:text-base'>{`No records found`}</p>
                  </div>
                )}

                {loading && <LoadingIndicator />}
              </tbody>
            </table>
          </Section>
        </Stacked>
      </Card>
    </>
  )
}
