import { twMerge } from 'tailwind-merge'
import { EventOverview } from 'tokensoft-shared-types'
import { BadgeList } from './badge-list'
import { FadeInImage } from './fade-in-image'
import placeholder1 from '/images/placeholders/placeholder-1.webp'
import placeholder2 from '/images/placeholders/placeholder-2.webp'
import placeholder3 from '/images/placeholders/placeholder-3.webp'
import placeholder4 from '/images/placeholders/placeholder-4.webp'
import placeholder5 from '/images/placeholders/placeholder-5.webp'
import placeholder6 from '/images/placeholders/placeholder-6.webp'
import placeholder7 from '/images/placeholders/placeholder-7.webp'

const getRandomPlaceholder = () => {
  const placeholders = [
    placeholder1,
    placeholder2,
    placeholder3,
    placeholder4,
    placeholder5,
    placeholder6,
    placeholder7,
  ]
  return placeholders[Math.floor(Math.random() * placeholders.length)]
}

type EventCard = {
  event: EventOverview
  projectLogoUrl: Maybe<Url>
  onClick: () => void
  className?: string
}

export const EventCard = ({
  event,
  projectLogoUrl,
  onClick,
  className,
}: EventCard) => {
  const badgeTitles = event.timeline.getRegistrationAndEventStatuses()
  const capitalizedEventType =
    event.type.charAt(0).toUpperCase() + event.type.slice(1)
  badgeTitles.splice(0, 0, `${capitalizedEventType}`)

  const imageUrl = event.imageUrl || getRandomPlaceholder()

  const iconUrl = event.iconSmallUrl ?? projectLogoUrl

  return (
    <div
      className={twMerge(
        `relative 
        rounded-[8px] 
        border 
        border-[#eaecf0] 
        cursor-pointer 
        overflow-hidden 
        hover:shadow-[0px_2px_8px_0px_rgba(99,99,99,0.2)] 
        hover:scale-[1.01] 
        transition-all
        duration-[200ms]`,
        className,
      )}
      onClick={onClick}
    >
      <FadeInImage
        alt='event-image'
        className='w-full'
        width={177}
        src={imageUrl}
      />
      {iconUrl !== null && (
        <FadeInImage
          className='absolute rounded-full -translate-y-1/2 ml-2 border-2 border-white'
          alt='event or project logo'
          width={48}
          src={iconUrl}
        />
      )}
      <div className='flex flex-col gap-4 pt-8 px-5 pb-6'>
        <div>
          <p className='text-xl text-black font-bold'>{event.name}</p>
          <BadgeList
            titles={badgeTitles}
            customizeBadgeClass={(badgeTitle) => {
              return badgeTitle === 'Live' ? 'bg-green-500 text-white' : ''
            }}
          />
        </div>
        <p className='text-md font-medium'>{event.description}</p>
        {/* <p className='text-sm text-primary-medium'>{event.type}</p> */}
      </div>
    </div>
  )
}
