import { Button, StatusIcon, Stretched } from '@components'
import { VERIFICATION_STATUS } from '@enums'
import { HiOutlineRefresh } from 'react-icons/hi'

export const DomainValidationStatus = (props: {
  status: string
  onRefresh: () => void
  loading: boolean
}) => {
  const content = {
    PENDING_VALIDATION: (
      <Stretched xgap={2.5} ygap={2.5}>
        <div className='flex items-center justify-center space-x-1 bg-warning-medium text-white py-1.5 px-4 rounded'>
          <StatusIcon color='white' status={VERIFICATION_STATUS.PENDING} />
          <span>Pending</span>
        </div>

        <Button
          fill={'outline'}
          size={'xs'}
          className={'w-full md:w-fit'}
          onClick={props.onRefresh}
          disabled={props.loading}
        >
          <div className='flex items-center space-x-1'>
            <HiOutlineRefresh className='' />
            <span className='text-sm'>Refresh</span>
          </div>
        </Button>
      </Stretched>
    ),
    SUCCESS: (
      <div className='flex items-center space-x-1 bg-success-medium text-white py-1.5 px-4 rounded'>
        <StatusIcon color='white' status={VERIFICATION_STATUS.COMPLETED} />
        <span>Success</span>
      </div>
    ),
    FAILED: (
      <div className='flex items-center space-x-1 bg-danger-medium text-white py-1.5 px-4 rounded'>
        <StatusIcon color='white' status={VERIFICATION_STATUS.FAILED} />
        <span>Failed</span>
      </div>
    ),
    VALIDATION_TIMED_OUT: (
      <div className='flex items-center space-x-1 bg-danger-medium text-white py-1.5 px-4 rounded'>
        <StatusIcon color='white' status={VERIFICATION_STATUS.FAILED} />
        <span>Validation Timed Out</span>
      </div>
    ),
  }

  return (
    <>
      <div className='col-form-label !mb-2'>Validation Status</div>
      <div className='flex items-center space-x-2'>
        {content[props.status as keyof typeof content]}
      </div>
    </>
  )
}
