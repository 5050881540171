import { Blockchain } from 'tokensoft-shared-types'

export const getBlockExplorerTokenUrl = (
  chain: Blockchain,
  address: EvmAddress,
) => {
  return `${chain.blockExplorerUri}address/${address}`
}

export const getBlockExplorerTransactionUrl = (
  chain: Blockchain,
  txHash: EvmAddress,
) => {
  return `${chain.blockExplorerUri}tx/${txHash}`
}

export const getTruncatedEvmAddress = (address: EvmAddress): string => {
  if (address.length < 10) {
    return address
  }

  return `${address.slice(0, 6)}...${address.slice(-4)}`
}
