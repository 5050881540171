import {
  Button,
  ButtonRow,
  PageHeader,
  PageSubtitle,
  PageTitle,
} from '@components'
import { useNetworks, useWallet } from '@contexts'
import { CSSProperties } from 'react'

interface SwitchChainWarningProps {
  chainId?: number
  subtitle?: string
  title?: string
  style?: CSSProperties
}

export const SwitchChainWarning: React.FC<SwitchChainWarningProps> = ({
  chainId,
  title,
  subtitle,
  style,
}: {
  chainId?: number
  subtitle?: string
  title?: string
  style?: CSSProperties
}) => {
  const { switchChain } = useWallet()
  const { getNetworkDetails } = useNetworks()

  const network = getNetworkDetails(chainId ?? -1)

  const titleText = title || 'Switch Network'
  const instructionText =
    subtitle || `Please connect to the ${network.name} network to continue`

  if (!chainId) {
    return <div></div>
  }

  return (
    <div>
      <PageHeader>
        <PageTitle style={style}>{titleText}</PageTitle>
        <PageSubtitle style={style}>{instructionText}</PageSubtitle>
      </PageHeader>

      <ButtonRow place={'center'}>
        <Button color='primary' onClick={() => switchChain(chainId)}>
          Switch Now
        </Button>
      </ButtonRow>
    </div>
  )
}
