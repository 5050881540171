import type { IconProps } from '@components'

export const TokensoftLogoDark = ({
  color,
  className,
  size = 20,
  width,
  height,
}: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={width ?? size}
        height={height ?? size}
        fill='none'
        stroke={color !== undefined ? color : '#101828'}
        strokeLinecap='round'
        strokeLinejoin='round'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 338 54'
      >
        <path
          d='M80.876 35.4965C80.876 46.2265 73.2813 53.7416 62.3982 53.7416C51.4473 53.7416 43.8525 46.2265 43.8525 35.4965C43.8525 24.6987 51.4473 17.1836 62.3982 17.1836C73.2813 17.1802 80.876 24.6987 80.876 35.4965ZM71.3097 35.4965C71.3097 29.8059 67.6582 25.8653 62.3982 25.8653C57.0704 25.8653 53.4189 29.8059 53.4189 35.4965C53.4189 41.1159 57.0704 45.0565 62.3982 45.0565C67.6582 45.0565 71.3097 41.1159 71.3097 35.4965Z'
          fill='black'
        />
        <path
          d='M156.959 38.2706H131.409C132.142 42.2858 134.768 45.3482 140.232 45.3482C142.787 45.3482 144.542 44.9107 145.78 43.3778H156.371C154.047 49.9467 147.84 53.7416 140.171 53.7416C128.999 53.7416 121.625 45.8603 121.625 35.4965C121.625 25.1328 128.928 17.1836 139.587 17.1836C151.563 17.1802 158.794 26.2315 156.959 38.2706ZM131.619 31.7763H147.463C146.954 27.3269 144.107 25.282 139.658 25.282C134.989 25.282 132.505 28.1272 131.629 31.7763H131.619Z'
          fill='black'
        />
        <path
          d='M197.565 33.9635V52.8665H187.998V34.5536C187.998 28.8596 185.151 25.8685 180.112 25.8685C177.485 25.8685 174.563 27.4013 172.079 30.3212V52.8699H162.513V18.0584H172.079V20.6832C174.925 18.3722 178.491 17.1324 182.158 17.18C191.432 17.18 197.565 23.7489 197.565 33.9635Z'
          fill='black'
        />
        <path
          d='M6.31197 5.43652C7.55955 5.4372 8.77893 5.80751 9.81592 6.50066C10.8529 7.1938 11.661 8.17864 12.1379 9.33068C12.6149 10.4827 12.7393 11.7502 12.4955 12.9729C12.2517 14.1956 11.6506 15.3186 10.7682 16.2C9.88583 17.0813 8.76174 17.6814 7.53808 17.9244C6.31443 18.1674 5.04616 18.0424 3.89361 17.5651C2.74107 17.0878 1.75599 16.2798 1.06295 15.2431C0.369899 14.2064 1.80501e-07 12.9877 0 11.7409C-1.19903e-07 10.9127 0.163282 10.0926 0.48053 9.32754C0.797779 8.56244 1.26277 7.8673 1.84893 7.28184C2.4351 6.69638 3.13095 6.23207 3.89674 5.91544C4.66252 5.59882 5.48323 5.43608 6.31197 5.43652Z'
          fill='#003ED7'
        />
        <path
          d='M27.2748 38.9997C27.2748 43.0692 29.8369 44.9073 33.4137 44.9073H40.0583V53.7246H33.6682V53.6907C33.2135 53.7144 32.7656 53.7246 32.3108 53.7246C30.6052 53.7367 28.9064 53.5083 27.2646 53.0464C21.0918 51.2829 17.6982 46.1078 17.6982 38.9692V5.39551H27.2646V18.0246H40.0481V26.045H27.2748V38.9997Z'
          fill='black'
        />
        <path
          d='M120.958 18.0582H108.84L94.8926 33.0171V5.03906H85.3262V52.8663H94.8926V45.5682L100.733 39.4402L110.299 52.8663H122.638L107.303 32.505L120.958 18.0582Z'
          fill='black'
        />
        <path
          d='M220.888 45.8302C224.54 45.8302 227.095 44.8806 227.095 42.9103C227.095 41.5232 225.928 40.3566 223.518 39.9158L216.005 38.6033C210.894 37.7284 205.709 34.9543 205.709 28.3854C205.709 21.2366 211.77 16.9297 220.607 16.9297C226.885 16.9297 235.199 18.683 236.013 28.6771H227.19C226.969 25.5402 223.976 24.5194 220.617 24.5194C216.965 24.5194 215.214 25.7606 215.214 27.4393C215.214 28.7517 216.161 30.2845 219.521 30.8678L225.802 31.8852C231.205 32.8348 236.244 35.3172 236.244 42.0319C236.244 49.764 229.525 53.5623 220.688 53.5623C213.022 53.5623 205.281 50.717 204.552 40.5737L213.375 40.6483C213.969 44.5856 216.799 45.8302 220.888 45.8302Z'
          fill='#003ED7'
        />
        <path
          d='M276.72 35.3197C276.72 46.0464 269.125 53.5614 258.245 53.5614C247.291 53.5614 239.696 46.0464 239.696 35.3197C239.696 24.5185 247.291 17.0068 258.245 17.0068C269.125 17.0034 276.72 24.5185 276.72 35.3197ZM267.153 35.3197C267.153 29.6258 263.502 25.6851 258.245 25.6851C252.914 25.6851 249.263 29.6258 249.263 35.3197C249.263 40.9391 252.914 44.8798 258.245 44.8798C263.516 44.8798 267.153 40.9391 267.153 35.3197Z'
          fill='#003ED7'
        />
        <path
          d='M310.879 25.906H297.37V52.6971H287.803V25.906H278.162V17.8788H287.803V14.8877C287.803 5.61935 294.376 0 303.21 0C305.785 0.0152515 308.344 0.408312 310.805 1.1666L309.491 9.26836C307.737 8.68506 305.619 8.25098 304.381 8.25098C300.071 8.25098 297.37 10.808 297.37 14.8945V17.8856H310.879V25.906Z'
          fill='#003ED7'
        />
        <path
          d='M337.971 52.0467C335.297 53.0987 332.444 53.6194 329.572 53.5796C320.226 53.5796 314.979 47.7432 314.979 38.8411V25.9067H310.144V17.8796H314.969V5.24707H324.536V17.8728H337.312V25.9H324.536V38.8207C324.536 42.8903 327.091 44.7317 330.668 44.7317C332.86 44.7317 334.903 44.0738 336.657 43.3447L337.971 52.0467Z'
          fill='#003ED7'
        />
      </svg>
    </div>
  )
}
