export const ONE_SECOND_IN_MS = 1000
export const ONE_MINUTE_IN_MS = ONE_SECOND_IN_MS * 60
export const ONE_HOUR_IN_MS = ONE_MINUTE_IN_MS * 60
export const ONE_DAY_IN_MS = ONE_HOUR_IN_MS * 24

/**
 * @returns The number of days until the given date. If
 * the date is in the past, returns `0`.
 */
export const getNumberOfDaysUntilDate = (date: Date) => {
  return getNumberOfTimeUnitsUntilDate(date, 'days')
}

/**
 * @returns The number of hours until the given date. If
 * the date is in the past, returns `0`.
 */
export const getNumberOfHoursUntilDate = (date: Date) => {
  return getNumberOfTimeUnitsUntilDate(date, 'hours')
}

/**
 * @returns The number of minutes until the given date. If
 * the date is in the past, returns `0`.
 */
export const getNumberOfMinutesUntilDate = (date: Date) => {
  return getNumberOfTimeUnitsUntilDate(date, 'minutes')
}

/**
 * @returns The number of seconds until the given date. If
 * the date is in the past, returns `0`.
 */
export const getNumberOfSecondsUntilDate = (date: Date) => {
  return getNumberOfTimeUnitsUntilDate(date, 'seconds')
}

/**
 *
 * @param date The date to calculate the time until.
 * @param unit The time unit to calculate.
 * @param roundToZeroIfNegative If `true`, returns `0` if the date is in the past.
 */
export const getNumberOfTimeUnitsUntilDate = (
  date: Date,
  unit: 'days' | 'hours' | 'minutes' | 'seconds',
  roundToZeroIfNegative: boolean = true,
) => {
  if (!dateIsInFuture(date) && roundToZeroIfNegative) {
    return 0
  }

  const msUntilDate = calculateMsUntilDate(date)

  switch (unit) {
    case 'days':
      return getNumberOfDaysInTimeInterval(msUntilDate)
    case 'hours':
      return getNumberOfHoursInTimeInterval(msUntilDate)
    case 'minutes':
      return getNumberOfMinutesInTimeInterval(msUntilDate)
    case 'seconds':
      return getNumberOfSecondsInTimeInterval(msUntilDate)
    default:
      throw new Error('Invalid unit')
  }
}

export const dateIsInPast = (date: Date): boolean => {
  return date.getTime() < new Date().getTime()
}

export const dateIsInFuture = (date: Date): boolean => {
  return date.getTime() > new Date().getTime()
}

/**
 * @returns The number of milliseconds until the given date.
 * @note This value can be negative if the date is in the past.
 */
const calculateMsUntilDate = (date: Date): number => {
  const now = new Date()
  return date.getTime() - now.getTime()
}

/**
 * @returns The number of days in the given time interval.
 */
const getNumberOfDaysInTimeInterval = (ms: number): number => {
  return Math.floor(ms / ONE_DAY_IN_MS)
}

const getNumberOfHoursInTimeInterval = (ms: number): number => {
  return Math.floor((ms % ONE_DAY_IN_MS) / ONE_HOUR_IN_MS)
}

const getNumberOfMinutesInTimeInterval = (ms: number): number => {
  return Math.floor((ms % ONE_HOUR_IN_MS) / ONE_MINUTE_IN_MS)
}

const getNumberOfSecondsInTimeInterval = (ms: number): number => {
  return Math.floor((ms % ONE_MINUTE_IN_MS) / ONE_SECOND_IN_MS)
}
