export const renderAccreditationText = (
  qualificationMethod: AccountAccreditationQualificationMethod,
) => {
  const textMap: Record<AccountAccreditationQualificationMethod, JSX.Element> =
    {
      letter: (
        <>
          <p className='pb-2'>
            Please upload a signed letter from a registered broker-dealer, an
            SEC-registered investment adviser, a licensed attorney, or a
            certified public accountant certifying you as an accredited investor
          </p>
          <p className='pb-2'>
            The letter <strong>cannot be older than 90 days</strong> from the
            date of submission
          </p>
        </>
      ),
      income: (
        <>
          <p className='pb-2'>Please upload relevant documents showing:</p>
          <p className='pl-4'>
            1. Your gross income exceeded $200,000 (individually) or $300,000
            (with a spouse/partner) for the past two years, and
          </p>
          <p className='pl-4 pb-2'>
            2. You reasonably expect to have the same income level in the
            current year
          </p>
          <p className='pb-2'>
            Suggested documents: W2, 1099, K-1 or equivalent for the past two
            years, or certified tax returns. The documents{' '}
            <strong>cannot be older than 90 days</strong> from date of
            submission.
          </p>
        </>
      ),
      'net-worth': (
        <>
          <p className='pb-2'>
            Please upload relevant documents showing your net worth (excluding
            your primary residence) exceeds $1,000,000
          </p>
          <p className='pb-2'>
            Suggested documents: bank statements, brokerage holdings, or
            appraisal reports issued by third parties.
          </p>
        </>
      ),
      professional: (
        <>
          <p className='pb-2'>
            Please upload relevant documents showing you are an investment
            professional holding Series 7, 65, or 82 licenses.
          </p>
          <p className='pb-2'>
            Suggested documents: licensure documentation, not older than 90
            days.
          </p>
        </>
      ),
      'entity-net-worth': (
        <>
          <p className='pb-2'>
            Upload documents proving total assets or investments exceed
            $5,000,000.
          </p>
          <p className='pb-2'>
            Suggested documents: bank statements, balance sheets.
          </p>
        </>
      ),
      'trust-net-worth': (
        <>
          <p className='pb-2'>
            Upload documents showing trust assets exceed $5,000,000.
          </p>
          <p className='pb-2'>
            Suggested documents: trust deeds, balance sheets, and accredited
            investor documentation.
          </p>
        </>
      ),
      'family-office': (
        <>
          <p className='pb-2'>
            Upload relevant documents showing you are a family office managing
            investments.
          </p>
        </>
      ),
      'family-client': (
        <>
          <p className='pb-2'>
            Upload documents showing you are a family client whose investments
            are directed by a qualified family office.
          </p>
        </>
      ),
      'accredited-owners': (
        <>
          <p className='pb-2'>
            Upload documents verifying all owners as accredited investors.
          </p>
        </>
      ),
      none: <></>,
    }

  return (
    textMap[qualificationMethod] || (
      <p className='pb-2'>Please upload documents</p>
    )
  )
}

const renderEntityText = (entityType: AccountEntityType) => {
  const textMap: Record<AccountEntityType, JSX.Element> = {
    company: (
      <>
        <p className='pb-2'>Please upload:</p>
        <ul className='list-disc ml-4'>
          <li>Certificate of Incorporation</li>
          <li>Articles of Association</li>
          <li>Register of Directors and Shareholders</li>
        </ul>
      </>
    ),
    partnership: (
      <>
        <p className='pb-2'>Upload certified copies of:</p>
        <ul className='list-disc ml-4'>
          <li>Certificate of Registration</li>
          <li>Partnership Agreement</li>
        </ul>
      </>
    ),
    trust: (
      <>
        <p className='pb-2'>
          Upload a certified copy of the Trust Deed, including any amendments.
        </p>
      </>
    ),
    'non-profit': (
      <>
        <p className='pb-2'>
          Upload certified copies of Incorporation and governing documents.
        </p>
      </>
    ),
  }

  return (
    textMap[entityType] || (
      <p className='pb-2'>Please upload relevant documents</p>
    )
  )
}

export const renderDocUploadText = (
  type: KycDocumentUploadType,
  qualificationMethod?: Maybe<AccountAccreditationQualificationMethod>,
  entityType?: Maybe<AccountEntityType>,
) => {
  if (!type) {
    return <p className='pb-2'>Please upload any relevant documents</p>
  }

  if (type === 'accreditation' && !!qualificationMethod) {
    return renderAccreditationText(qualificationMethod)
  }

  if (type === 'entity' && !!entityType) {
    return renderEntityText(entityType)
  }

  return null
}
