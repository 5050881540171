import { PageHeader, Projects } from '@components'

export const ProjectsPage = () => {
  return (
    <>
      <PageHeader
        title={'Projects'}
        instructions={`These projects are created by Tokensoft clients and partners. Each project determines eligibility and compliance requirements to participate in their events. Tokensoft makes no representations regarding these events. Please do your own research.`}
      />

      <Projects adminOnly={false} />
    </>
  )
}
