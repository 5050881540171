import {
  Button,
  Col,
  ModalTitle,
  PreparingIcon,
  Stacked,
  Text,
  WarningAlertIcon,
} from '@components'
import { DeployOptions } from '@custom-types/deploy-options'
import { PreparedDistributorData } from '@custom-types/prepared-distributor-data'
import { TokenInfo } from '@custom-types/token-info'
import { DEPLOY_VESTING_TYPE_OPTIONS } from '@enums'
import { useDeployDistributor } from '@hooks'
import {
  add,
  convertBaseUnitsToDecimal,
  formatValue,
  isEmptyObject,
  mult,
} from '@utils'
import { useCallback, useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import {
  Hex,
  TransactionReceipt,
  WaitForTransactionReceiptErrorType,
} from 'viem'

interface DeployDistributorModalProps {
  totalAllocations: string
  tokenInfo: TokenInfo
  preparedData?: PreparedDistributorData
  vestingType: DEPLOY_VESTING_TYPE_OPTIONS
  deployOptions: Pick<DeployOptions, 'useExperimentalContractFeatures'>
  onSuccess?: (receipt: TransactionReceipt & { contractAddress: Hex }) => void
  onError?: (error: WaitForTransactionReceiptErrorType) => void
}

export const DeployDistributorModal = ({
  totalAllocations,
  tokenInfo,
  preparedData,
  vestingType,
  deployOptions,
  onSuccess,
  onError,
}: DeployDistributorModalProps) => {
  const { deployedAddress, deployDistributor, receipt, error, isPending } =
    useDeployDistributor(deployOptions)

  const [submitted, setSubmitted] = useState<boolean>(false)
  const key = 'deploy-distributor-modal'

  useEffect(() => {
    if (!isEmptyObject(receipt)) {
      console.log('Deploy distributor transaction receipt', receipt)
      if (receipt.status === 'success' && onSuccess) {
        if (deployedAddress === undefined) {
          throw new Error('no deployedAddress')
        }

        onSuccess({ ...receipt, contractAddress: deployedAddress })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [receipt, onSuccess])

  useEffect(() => {
    if (error && onError) {
      onError(error)
    }

    setSubmitted(false)
  }, [error, onError])

  useEffect(() => {
    setSubmitted(isPending)
  }, [isPending])

  const fee = mult(totalAllocations, '0.01')

  const totalRequiredAmount = add(totalAllocations, fee)

  const formattedTotalAllocations = formatValue(
    convertBaseUnitsToDecimal(totalAllocations, tokenInfo.decimals ?? 18, 6),
    { commas: true },
  )

  const formattedFee = formatValue(
    convertBaseUnitsToDecimal(fee, tokenInfo.decimals ?? 18, 6),
    { commas: true },
  )

  const formattedTotalRequiredAmount = formatValue(
    convertBaseUnitsToDecimal(totalRequiredAmount, tokenInfo.decimals ?? 18, 6),
    { commas: true },
  )

  const handleDeployClick = useCallback(() => {
    if (!preparedData) {
      throw new Error('user clicked "deploy" but there is no prepared data')
    }

    if (!preparedData.valid) {
      throw new Error('user clicked "deploy" but prepared data is invalid')
    }

    void deployDistributor(vestingType, preparedData)
  }, [deployDistributor, vestingType, preparedData])

  const feeCollectionWarning = (
    <div className='w-full text-left rounded-lg border border-[#fcd34d] p-5 space-y-5'>
      <div className='flex items-center gap-5'>
        <WarningAlertIcon className='w-10 h-10' />
        <p className='font-mono uppercase high-contrast'>
          NOTICE: Using Experimental Features
        </p>
      </div>

      <p>
        A protocol fee is required when deploying with experimental features.
        Before deploying, an approval will be requested for transferring the fee
        amount to the protocol.
      </p>

      <div className='text-xs leading-5'>
        <div className='flex justify-between'>
          <div>Total Distribution Amount</div>
          <div>
            {formattedTotalAllocations} {tokenInfo.symbol}
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Protocol Fee Amount</div>
          <div>
            {formattedFee} {tokenInfo.symbol}
          </div>
        </div>
        <div className='flex justify-between'>
          <div>Total Amount Required For Deployment</div>
          <div>
            {formattedTotalRequiredAmount} {tokenInfo.symbol}
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <Stacked>
      <Col data-testid={key} gap={5} place={'center'}>
        <PreparingIcon />
        <ModalTitle data-testid={`${key}-title`}>
          {'Deploy Your Distributor'}
        </ModalTitle>
        <Text data-testid={`${key}-subtitle`} width='3/4' textAlign={'center'}>
          Your distributor contract is ready to deploy! You&apos;ll be able to
          update configuration after deployment.
        </Text>
        {deployOptions.useExperimentalContractFeatures && feeCollectionWarning}
      </Col>
      <Button
        data-testid={`${key}-button`}
        color={'primary'}
        onClick={handleDeployClick}
        disabled={submitted}
      >
        {submitted ? (
          <>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={24} />
            </div>

            <Text>Deploying...</Text>
          </>
        ) : (
          <Text>
            {deployOptions.useExperimentalContractFeatures
              ? 'Approve & Deploy'
              : 'Deploy'}
          </Text>
        )}
      </Button>
    </Stacked>
  )
}
