import {
  GlobalRestriction,
  KycRestriction,
  LoadingIndicator,
  PageHeader,
  PermissionDeniedError,
  Projects,
  RegionRestriction,
  WalletAddressRestriction,
  WhitelabelRestriction,
} from '@components'
import { useAccount } from '@contexts'
import { FEATURE, FEATURE_RESTRICTION } from '@enums'
import { useFeature } from '@hooks'

export const AdminPage = () => {
  const { getFeatureRestriction } = useFeature()
  const { account, loading } = useAccount()
  const restriction = getFeatureRestriction(FEATURE.ADMIN)

  if (restriction) {
    switch (restriction) {
      case FEATURE_RESTRICTION.GLOBAL_RESTRICTION:
        return <GlobalRestriction />
      case FEATURE_RESTRICTION.WHITELABEL_RESTRICTION:
        return <WhitelabelRestriction />
      case FEATURE_RESTRICTION.KYC_RESTRICTION:
        return <KycRestriction />
      case FEATURE_RESTRICTION.REGION_RESTRICTION:
        return <RegionRestriction />
      case FEATURE_RESTRICTION.WALLET_ADDRESS_RESTRICTION:
        return <WalletAddressRestriction />
    }
  }

  if (loading) {
    return <LoadingIndicator text={'Loading'} />
  }

  if (!account?.projects?.length) {
    return (
      <PermissionDeniedError
        message={'You are not a member of any active projects.'}
      />
    )
  }

  return (
    <>
      <PageHeader title={'My Projects'} />

      <Projects adminOnly={true} />
    </>
  )
}
