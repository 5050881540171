import { getStakingABI } from '@utils'
import BigNumber from 'bignumber.js'
import { Abi } from 'viem'
import { useReadContract } from 'wagmi'

export const useGetProtocolFeeInBasisPoints = (
  stakingAddress?: Maybe<EvmAddress>,
  walletAddress?: Maybe<EvmAddress>,
): AsyncResult<BigNumber> => {
  const { data, isLoading, error } = useReadContract({
    address: stakingAddress!,
    abi: getStakingABI() as Abi,
    functionName: 'getFeeLevel',
    args: [walletAddress!],
    query: {
      enabled: !!stakingAddress && !!walletAddress,
    },
  })

  let fee: Maybe<BigNumber> = null

  if (data) {
    fee = new BigNumber(data.toString())

    // Staking contract returns '0' for users who haven't staked at all,
    // and no amount of staking should result in a 0 fee, so handle
    // that case by setting the fee to 100 basis points if result is 0.
    if (fee.isEqualTo(0)) {
      fee = new BigNumber(100)
    }
  }

  return {
    data: fee,
    error,
    isLoading,
  }
}
