import { DOCUMENT_CATEGORY, EVENT_TYPE } from '@enums'
import { LaunchEventWizardPage } from '@pages'
import './event-create-document.css'

export const LaunchDistributorEventWizardPage = () => {
  const eventType = EVENT_TYPE.DISTRIBUTE
  const documentCategory = DOCUMENT_CATEGORY.CLAIM_AGREEMENTS

  return (
    <LaunchEventWizardPage
      eventType={eventType}
      documentCategory={documentCategory}
    />
  )
}
