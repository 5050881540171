import {
  useRemoveUserWalletAddress,
  useSetPrimaryWalletAddress,
} from '@apiServices'
import { AddWalletModal, Card, LoadingIndicator, Row, Text } from '@components'
import { ETHEREUM_LOGO_URL, POLKADOT_LOGO_URL } from '@constants'
import { useAuth, useModal, useProfile, useToast, useWallet } from '@contexts'
import { BLOCKCHAIN_KEY } from '@enums'
import { useClipboard } from '@hooks'
import { getTruncatedAddress } from '@utils'
import { useState } from 'react'
import {
  AiFillCheckCircle,
  AiOutlineCopy,
  AiOutlineLoading3Quarters,
} from 'react-icons/ai'
import { FaRegCircle, FaTrashAlt } from 'react-icons/fa'

export const ProfileWallets = () => {
  const { user, removeWalletAddress } = useAuth()
  const { walletAddress } = user || {}
  const { disconnectWallet, enableAddWalletMode } = useWallet()
  const { copyToClipboard } = useClipboard()
  const { account, loading } = useProfile()
  const { showSuccessToast, showErrorToast } = useToast()
  const [currentWalletAddress, setCurrentWalletAddress] =
    useState<Maybe<any>>(null)
  const [view, setView] = useState('list-wallets')
  const { showModal, closeModal } = useModal()

  const { mutate: remove, isPending: removeLoading } =
    useRemoveUserWalletAddress()

  const { mutate: setPrimary, isPending: setPrimaryLoading } =
    useSetPrimaryWalletAddress()

  const handleAddButtonClick = () => {
    enableAddWalletMode()
    showModal({
      className: 'md',
      content: <AddWalletModal onComplete={() => {}} initialUser={user} />,
    })
  }

  const handleSetPrimaryWalletAddress = (wallet: any) => {
    setCurrentWalletAddress(wallet)
    setPrimary(
      {
        walletAddress: wallet.walletAddress,
      },
      {
        onSuccess: (data) => {
          showSuccessToast({
            description: 'Successfully changed primary address!',
          })
          setCurrentWalletAddress(null)
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error setting primary wallet.  Please try again.',
          })
          setCurrentWalletAddress(null)
        },
      },
    )
  }

  const handleRemoveWalletAddress = (wallet: any) => {
    remove(
      {
        id: wallet.id,
      },
      {
        onSuccess: (data) => {
          showSuccessToast({
            description: `${getTruncatedAddress(
              wallet.walletAddress,
            )} successfully disconnected!`,
          })

          // Only remove wallet address fom auth context if it is an ethereum wallet
          if (wallet.blockchain_type === BLOCKCHAIN_KEY.ETHEREUM) {
            removeWalletAddress(wallet.walletAddress)

            if (
              walletAddress.toLowerCase() === wallet.walletAddress.toLowerCase()
            ) {
              disconnectWallet()
            }
          }
          setCurrentWalletAddress(null)
        },
        onError: (error) => {
          showErrorToast({
            description: 'Error disconnecting wallet.  Please try again.',
          })
          setCurrentWalletAddress(null)
          setView('list-wallets')
        },
      },
    )
  }

  const handleRemoveWalletAddressInline = (wallet: any) => {
    setCurrentWalletAddress(wallet)

    if (account?.wallets?.length > 1) {
      handleRemoveWalletAddress(wallet)
    } else {
      setView('remove-wallet-confirm')
    }
  }

  return (
    <>
      <Card className='h-full no-header-border' title='Wallet Addresses'>
        <div className='h-full flex flex-col justify-between'>
          <table className={'w-full'}>
            <thead>
              <tr className='border-b text-neutral-extra-dark'>
                <th className={'text-left'}>Wallet Address</th>
                <th className={'text-center'}>Protocol</th>
                <th className={'text-center'}>Primary</th>
                <th className='text-center'>Actions</th>
              </tr>
            </thead>
            <tbody>
              {account.wallets?.length > 0 && (
                <>
                  {account.wallets
                    .filter((wallet: any) => !!wallet.lastSignedAt)
                    .map((wallet: any, i: any) => (
                      <tr key={i}>
                        <td className={'text-left py-2'}>
                          <Row yalign='center' gap={2}>
                            <Text>
                              {getTruncatedAddress(wallet.walletAddress)}
                            </Text>
                            <div
                              onClick={() =>
                                copyToClipboard(wallet.walletAddress)
                              }
                              className='cursor-pointer'
                            >
                              <AiOutlineCopy size={16} />
                            </div>
                          </Row>
                        </td>
                        <td>
                          <Row place='center'>
                            <img
                              src={
                                wallet.blockchainType ===
                                BLOCKCHAIN_KEY.POLKADOT
                                  ? POLKADOT_LOGO_URL
                                  : ETHEREUM_LOGO_URL
                              }
                              width='24'
                              alt=''
                            />
                          </Row>
                        </td>
                        <td>
                          {wallet.primary && wallet.lastSignedAt !== null ? (
                            <AiFillCheckCircle className='mx-auto fill-success-medium' />
                          ) : (
                            <>
                              {setPrimaryLoading &&
                              currentWalletAddress?.id === wallet.id ? (
                                <AiOutlineLoading3Quarters
                                  className='mx-auto animate-spin'
                                  size={14}
                                />
                              ) : (
                                <FaRegCircle
                                  size={14}
                                  className='cursor-pointer mx-auto'
                                  onClick={() => {
                                    handleSetPrimaryWalletAddress(wallet)
                                  }}
                                />
                              )}
                            </>
                          )}
                        </td>
                        <td className={'text-center py-2'}>
                          <button
                            className={'btn btn-outline-primary btn-sm'}
                            onClick={() => {
                              handleRemoveWalletAddressInline(wallet)
                            }}
                            disabled={
                              wallet.walletAddress ===
                              user.walletAddress.toLowerCase()
                            }
                          >
                            {removeLoading &&
                            currentWalletAddress?.id === wallet.id ? (
                              <AiOutlineLoading3Quarters className='animate-spin' />
                            ) : (
                              <FaTrashAlt />
                            )}
                          </button>
                        </td>
                      </tr>
                    ))}
                </>
              )}

              {!loading && account?.wallets?.length === 0 && (
                <div className='subtitle mb-4'>
                  <p className='text-xs md:text-base'>{`No records found`}</p>
                </div>
              )}

              {loading && <LoadingIndicator />}
            </tbody>
          </table>

          {/* <div className={'flex items-center justify-end'}>
            {!user.smartContract ? (
              <button
                className={'btn btn-primary ml-2 mt-8'}
                onClick={handleAddButtonClick}
              >
                <div className='flex items-center space-x-2'>
                  <FaWallet size={18} />
                  <span>+ New Address</span>
                </div>
              </button>
            ) : null}
          </div> */}
        </div>
      </Card>
    </>
  )
}
