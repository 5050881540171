import { defineChain } from 'viem'

export const taurus = defineChain({
  id: 490000,
  name: 'Taurus',
  nativeCurrency: {
    decimals: 18,
    name: 'Autonomys (Testnet)',
    symbol: 'AUT',
  },
  rpcUrls: {
    default: {
      http: ['https://auto-evm-0.taurus.autonomys.xyz/ws'],
    },
  },
})
