import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Address, Hex } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

const sweepTokenMinimalAbi: Abi = [
  {
    inputs: [
      {
        internalType: 'contract IERC20',
        name: 'token',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'sweepToken',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
] as const

export const useSweepDistributor = () => {
  const { executeContractWrite } = useContractWrite()
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [prepareError, setPrepareError] = useState<string | null>(null)

  const write = async (
    contractAddress: string,
    token: string,
    amount: string,
    chainId: number,
  ) => {
    setSubmitting(true)
    setPrepareError(null)

    try {
      const writeContractResponse = await executeContractWrite({
        address: contractAddress as Address,
        abi: sweepTokenMinimalAbi,
        functionName: 'sweepToken',
        args: [token, amount],
      })

      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (e) {
      const error = e as Error
      setPrepareError(error.message)
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
    error: prepareError || waitForTransactionResponse?.error,
  }
}
