import { useApproveProjectDocuments } from '@apiServices'
import {
  AnimatedCheckmark,
  ButtonRow,
  Card,
  CheckboxInput,
  DocumentLink,
} from '@components'
import { useProject, useToast } from '@contexts'
import { useState } from 'react'
import { AiOutlineInfoCircle, AiOutlineLoading3Quarters } from 'react-icons/ai'

export const ProjectReviewComplainceDocuments = () => {
  const { project, hasApprovedComplianceDocs } = useProject()
  const { showErrorToast, showSuccessToast } = useToast()
  const [acceptedDocs, setAcceptedDocs] = useState<boolean>(false)

  const handleCheckboxChange = () => {
    setAcceptedDocs(!acceptedDocs)
  }

  const { mutate: approve, isPending: approveLoading } =
    useApproveProjectDocuments()

  const handleSave = () => {
    approve(project, {
      onSuccess: () =>
        showSuccessToast({
          description: 'Compliance documents successfully approved.',
        }),
      onError: (error) => showErrorToast({ description: error.message }),
    })
  }

  const renderDocumentList = () => {
    return (
      <div className={'w-full py-4'}>
        {project.documents?.map((doc: any, i: any) => (
          <DocumentLink document={doc} key={i} />
        ))}
      </div>
    )
  }

  return (
    <>
      <Card className='vertical mb-8 h-full' title='Compliance Documents'>
        <div className={'h-full flex flex-col justify-between'}>
          <div>
            {hasApprovedComplianceDocs ? (
              <>
                <div className={'flex flex-col items-center'}>
                  <div>
                    <AnimatedCheckmark size={'xxLarge'} />
                  </div>
                  <div className='mt-4 title-text text-center'>
                    <span className=''>Documents Approved</span>
                  </div>
                </div>

                <div className='mt-4'>
                  The following compliance documents have been approved for your
                  project:
                </div>

                {renderDocumentList()}
              </>
            ) : (
              <>
                <div className='mb-4'>
                  Please review the following compliance documents:
                </div>

                {renderDocumentList()}

                <div className='my-4'>
                  <CheckboxInput
                    onClick={handleCheckboxChange}
                    name='acceptedDocs'
                    checked={acceptedDocs}
                  >
                    I have read and approve these documents
                  </CheckboxInput>
                </div>
              </>
            )}

            <div className='flex items-center space-x-2'>
              <AiOutlineInfoCircle />
              <div className='text-sm'>
                Have your own compliance manual? Contact{' '}
                <a
                  href='mailto:compliance@tokensoft.io'
                  target='_blank'
                  rel='noreferrer'
                >
                  compliance@tokensoft.io
                </a>
              </div>
            </div>
          </div>

          <ButtonRow place={'end'}>
            {!hasApprovedComplianceDocs && (
              <button
                className='btn btn-primary'
                disabled={!acceptedDocs || approveLoading}
                onClick={handleSave}
              >
                {approveLoading ? (
                  <div className='flex flex-row'>
                    <div className='animate-spin'>
                      <AiOutlineLoading3Quarters size={24} />
                    </div>
                    <span className='pl-2'>Saving...</span>
                  </div>
                ) : (
                  <span>Save</span>
                )}
              </button>
            )}
          </ButtonRow>
        </div>
      </Card>
    </>
  )
}
