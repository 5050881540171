import { Card, RadioInput } from '@components'
import { AccountDetails } from '@customTypes'
import { useEffect } from 'react'

type EntityTypePanelProps = {
  accountDetails: AccountDetails
  onChange: (details: AccountDetails) => void
}

export const EntityTypePanel = ({
  accountDetails,
  onChange,
}: EntityTypePanelProps) => {
  // provide a default value if nothing is set
  useEffect(() => {
    if (accountDetails && !accountDetails.entityType) {
      onChange({ ...accountDetails, entityType: 'company' })
    }
  }, [accountDetails])

  return (
    <div id='entity-type'>
      <Card title='Entity Type'>
        <p className='pb-4'>What type of entity is it?</p>

        <div className='pb-4'>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === 'company'}
            onClick={() =>
              onChange({ ...accountDetails, entityType: 'company' })
            }
          >
            Corporation
          </RadioInput>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === 'partnership'}
            onClick={() =>
              onChange({
                ...accountDetails,
                entityType: 'partnership',
              })
            }
          >
            Partnership
          </RadioInput>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === 'trust'}
            onClick={() => onChange({ ...accountDetails, entityType: 'trust' })}
          >
            Trust
          </RadioInput>
          <RadioInput
            name='entityType'
            checked={accountDetails?.entityType === 'non-profit'}
            onClick={() =>
              onChange({
                ...accountDetails,
                entityType: 'non-profit',
              })
            }
          >
            Non-profit
          </RadioInput>
        </div>
      </Card>
    </div>
  )
}
