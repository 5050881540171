import { createContext, useContext, useEffect } from 'react'

export interface AnalyticsContextProps {
  pageEvent(category: string, action: string, label?: number): void
}

export const AnalyticsContext =
  createContext<Maybe<AnalyticsContextProps>>(null)
AnalyticsContext.displayName = 'AnalyticsContext'

export const useAnalytics = (pathName?: string): AnalyticsContextProps => {
  const context = useContext(AnalyticsContext)

  if (!context) {
    throw new Error(
      'AnalyticsProvider context is undefined, please verify you are calling useAnalytics() as child of a <AnalyticsProvider> component.',
    )
  }

  useEffect(() => {
    if (pathName) {
      context.pageEvent('page', pathName)
    }
  }, [pathName])

  return context
}
