export const StatusIconQuestion = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2107_10361)'>
        <path
          d='M10 13.3333V13.3416'
          stroke='#D4AF12'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 10.8334C10.3748 10.8345 10.739 10.7093 11.0339 10.4779C11.3287 10.2465 11.537 9.92254 11.625 9.55824C11.7131 9.19393 11.6758 8.81058 11.5191 8.4701C11.3625 8.12961 11.0956 7.85187 10.7617 7.68171C10.4301 7.51189 10.0509 7.45923 9.68567 7.53231C9.32043 7.60539 8.99066 7.79991 8.75 8.08421'
          stroke='#D4AF12'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M8.33278 17.3138C7.60745 17.1493 6.91084 16.8771 6.26611 16.5063'
          stroke='#D4AF12'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.6665 2.68555C13.3233 3.06394 14.8025 3.99362 15.862 5.32239C16.9215 6.65115 17.4985 8.30026 17.4985 9.99971C17.4985 11.6992 16.9215 13.3483 15.862 14.677C14.8025 16.0058 13.3233 16.9355 11.6665 17.3139'
          stroke='#D4AF12'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.81547 14.2442C3.36089 13.5838 3.01564 12.8545 2.79297 12.0842'
          stroke='#D4AF12'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.604 8.74969C2.73734 7.95802 2.994 7.20802 3.354 6.52052L3.49484 6.26636'
          stroke='#D4AF12'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M5.75537 3.81555C6.53508 3.2788 7.40981 2.89531 8.33287 2.68555'
          stroke='#D4AF12'
          strokeWidth='1.5'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_2107_10361'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
