import { Flex } from '@components'

type FlexProps = React.ComponentProps<typeof Flex> & {}

interface StackedProps extends FlexProps {}

export const Stacked: React.FC<StackedProps> = ({
  gap = 10,
  children,
  ...restProps
}) => {
  return (
    <Flex direction={'col'} gap={gap} {...restProps}>
      {children}
    </Flex>
  )
}
