import {
  ButtonRow,
  Card,
  InputGroup,
  MultiSelect,
  PageHeader,
} from '@components'
import { useAccount } from '@contexts'
import {
  validateEmail,
  validatePhoneNumber,
  validateProjectOwnerInformation,
} from '@utils'
import { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

export const RegisterUserPage = ({
  onBack,
  onSubmit,
  userInfo,
  onUserInfoChange,
  userRole,
  onUserRoleChange,
  projectCreationPending,
}: {
  onBack: Function
  onSubmit: Function
  userInfo: {
    firstName?: string
    lastName?: string
    email?: string
    phoneNumber?: string
  }
  onUserInfoChange: Function
  userRole: { label: string; value: string }
  onUserRoleChange: Function
  projectCreationPending: boolean
}) => {
  const { account } = useAccount()

  const [formValid, setFormValid] = useState<boolean>(false)

  const roleOptions = [
    { label: 'Admin', value: 'admin' },
    { label: 'Purchase Manager', value: 'purchase_manager' },
    { label: 'Purchase Viewer', value: 'purchase_viewer' },
    { label: 'KYC Manager', value: 'kyc_manager' },
    { label: 'KYC Viewer', value: 'kyc_viewer' },
  ]

  const handleSubmit = () => {
    // notify parent
    onSubmit()
  }

  const handleBack = () => {
    // notify parent
    onBack()
  }

  useEffect(() => {
    const valid = validateProjectOwnerInformation({ ...userInfo })
    setFormValid(valid)
  }, [userInfo])

  const emailValid = !userInfo?.email || validateEmail(userInfo?.email)
  const phoneNumberValid =
    !userInfo?.phoneNumber || validatePhoneNumber(userInfo?.phoneNumber)

  return (
    <>
      <PageHeader title={'Tell us about yourself'} />

      <Card>
        <form>
          <MultiSelect
            defaultValue=''
            label={'Project role'}
            name='role'
            options={roleOptions}
            value={userRole}
            onChange={onUserRoleChange}
            required
            isMulti={false}
          />

          <InputGroup
            label='First Name'
            value={userInfo?.firstName}
            name='firstName'
            required={true}
            onChange={onUserInfoChange}
            readonly={!!account?.firstName}
          />

          <InputGroup
            label='Last Name'
            value={userInfo?.lastName}
            name='lastName'
            required={true}
            onChange={onUserInfoChange}
            readonly={!!account?.lastName}
          />

          <InputGroup
            label='Email'
            value={userInfo?.email}
            name='email'
            required={true}
            onChange={onUserInfoChange}
            readonly={!!account?.email}
            error={emailValid ? null : 'Invalid email'}
          />

          <InputGroup
            label='Phone Number'
            value={userInfo?.phoneNumber}
            name='phoneNumber'
            onChange={onUserInfoChange}
            readonly={!!account?.phoneNumber}
            error={phoneNumberValid ? null : 'Invalid phone number'}
          />
        </form>

        <ButtonRow place={'between'}>
          <button className='btn btn btn-outline-dark' onClick={handleBack}>
            Back
          </button>
          <button
            className='btn btn-primary'
            disabled={!formValid || projectCreationPending}
            onClick={handleSubmit}
          >
            {projectCreationPending ? (
              <div className='flex'>
                <div className='animate-spin'>
                  <AiOutlineLoading3Quarters size={24} />
                </div>
                <span className='pl-2'>Creating Project...</span>
              </div>
            ) : (
              'Submit'
            )}
          </button>
        </ButtonRow>
      </Card>
    </>
  )
}
