import { useWebApiClient } from '@api/clients'
import { useMutation } from '@tanstack/react-query'

export interface EventPolkadotWalletsValidationRequestBody {
  polkadotWalletsFileUri: S3Uri
  polkadotWalletUserIdentifier: PolkadotWalletUserIdentifier
}

export const usePostPolkadotWalletFileValidation = () => {
  const client = useWebApiClient()

  return useMutation({
    mutationFn: (data: EventPolkadotWalletsValidationRequestBody) => {
      return client(`events/polkadot-wallets/validate`, {
        method: 'post',
        data,
      })
    },
  })
}
