import { Text } from '@components'
import classNames from 'classnames'

type TextProps = React.ComponentProps<typeof Text>
interface FormLabelProps extends TextProps {
  className?: string
  children: React.ReactNode
}

export const FormLabel: React.FC<FormLabelProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <div className='py-1 mb-0'>
      <Text
        className={classNames(
          'form-label text-sm font-semibold high-contrast',
          className,
        )}
        {...restProps}
      >
        {children}
      </Text>
    </div>
  )
}
