import { Text } from '@components'
import { useTSAddress } from '@hooks'
import {
  BadgeList,
  Card,
  FadeInImage,
  GenerateReferralLinkButton,
  ProjectSocials,
} from '@newComponents'
import { SocialLinks } from 'tokensoft-shared-types'

type EventHeaderProps = {
  logo: Maybe<Url>
  eventTitle: string
  eventDescription: Maybe<string>
  shouldShowReferralButton: boolean
  socials: SocialLinks
  badgeTitles: string[]
  className?: string
}

export const EventHeaderCard = ({
  logo,
  eventTitle,
  eventDescription,
  shouldShowReferralButton,
  socials,
  badgeTitles,
  className = '',
}: EventHeaderProps) => {
  const imgDimension = 138
  const { address } = useTSAddress()

  return (
    <Card className={className}>
      <div className='flex flex-row gap-8 flex-wrap'>
        {logo !== null && (
          <FadeInImage
            className='rounded-[4px] overflow-hidden max-w-[256px] max-h-[128px] w-auto h-auto object-contain'
            src={logo}
            alt='Project logo'
            width={imgDimension}
          />
        )}
        <div className='flex flex-1 flex-col gap-2'>
          <div className='flex gap-x-8 gap-y-2 justify-between overflow-hidden flex-wrap'>
            <span className='font-bold text-2xl text-black'>{eventTitle}</span>
            <BadgeList
              titles={badgeTitles}
              customizeBadgeClass={(title) => {
                return title === 'Live' ? 'bg-green-500 text-white' : ''
              }}
            />
          </div>
          {eventDescription !== null && (
            <Text className='mb-4'>{eventDescription}</Text>
          )}
          <div className='mt-auto flex justify-between items-center flex-wrap gap-4'>
            <ProjectSocials socialLinks={socials} />
            {shouldShowReferralButton && address !== undefined && (
              <GenerateReferralLinkButton code={address} />
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}
