import { Chain, getDefaultConfig } from '@rainbow-me/rainbowkit'
import { createContext, useContext } from 'react'
type WagmiConfig = ReturnType<typeof getDefaultConfig>

export interface TSWagmiContextProps {
  activeChains: Readonly<Maybe<[Chain, ...Chain[]]>>
  defaultChain: any
  updateDefaultChain: Function
  wagmiConfig: WagmiConfig
}

export const TSWagmiContext = createContext<Maybe<TSWagmiContextProps>>(null)
TSWagmiContext.displayName = 'WagmiContext'

export const useTSWagmi = (): TSWagmiContextProps => {
  const context = useContext(TSWagmiContext)

  if (!context) {
    throw new Error(
      'TSWagmiProvider context is undefined, please verify you are calling useTSWagmi() as child of a <TSWagmiProvider> component.',
    )
  }

  return context
}
