import { generateColumnsCSVDownload } from '@apiServices'
import { Button, Text } from '@components'
import { useToast } from '@contexts'
import { getLocalNow, getTableDataForExport, makeCsv } from '@utils'
import { useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { BsArrowDownSquare } from 'react-icons/bs'

interface EventReportDownload {
  event: any
  project: any
  button: any
  eventUserTableConfig: any
  handleParameters: Function
  disabled: boolean
  className?: string
}

export const EventReportDownload = ({
  event,
  project,
  button,
  eventUserTableConfig,
  handleParameters,
  disabled,
  className,
}: EventReportDownload) => {
  const [loading, setLoading] = useState<boolean>(false)
  const { showErrorToast } = useToast()

  const { mutateAsync: getData } = eventUserTableConfig.getData(event.id)

  const getDataPromise = async (offset: number, queryLimit: number) => {
    return getData(handleParameters(offset, queryLimit), {
      onSuccess: (data: any) => {
        return data
      },
      onError: (error: any) => {
        throw new Error(error.message)
      },
    })
  }

  const downloadData = async () => {
    setLoading(true)

    let dataConstructed: any[] = []
    let nextLink
    let offset = 0
    let queryLimit = 200

    const columns = eventUserTableConfig.columnsData(null, null, null)

    try {
      do {
        const data = await getDataPromise(offset, queryLimit)
        if (data.nextLink) {
          const paramString = data.nextLink.split('?')[1]
          const searchParams = new URLSearchParams(paramString)
          offset = Number(searchParams.get('offset'))
          queryLimit = Number(searchParams.get('queryLimit'))
        }

        nextLink = data.nextLink

        const dataConstructedShunk = eventUserTableConfig.handleTableData(data)
        console.log({ data, columns: generateColumnsCSVDownload(columns) })

        dataConstructed = dataConstructed.concat(...dataConstructedShunk)
      } while (nextLink)
      const localTime = getLocalNow()
      makeCsv(
        getTableDataForExport(
          dataConstructed,
          generateColumnsCSVDownload(columns),
        ),
        `${project.name}-${
          event.name
        }-${localTime}-${button.type.toLowerCase()}.csv`,
      )
      setLoading(false)
    } catch (e: any) {
      setLoading(false)
      showErrorToast({ description: e.message })
    }
  }

  function handleButtonClick() {
    if (loading) return
    return downloadData()
  }

  return (
    <Button
      fill={'outline'}
      size={'md'}
      disabled={disabled}
      onClick={handleButtonClick}
      className={className}
    >
      {loading ? (
        <>
          <div className='animate-spin'>
            <AiOutlineLoading3Quarters size={18} />
          </div>
          <Text>Generating...</Text>
        </>
      ) : (
        <>
          <Text>Download CSV</Text>
          <BsArrowDownSquare />
        </>
      )}
    </Button>
  )
}
