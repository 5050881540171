import { Text } from '@components'
import classNames from 'classnames'

export type EventSubtitleProps = {
  className?: string
  children: React.ReactNode
} & React.ComponentProps<typeof Text>

export const EventSubtitle: React.FC<EventSubtitleProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      className={classNames('text-xl', className)}
      data-testid='event-banner-subtitle'
      {...restProps}
    >
      {children}
    </Text>
  )
}
