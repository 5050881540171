import { Col, Stacked } from '@components'
import classNames from 'classnames'

type StackedProps = React.ComponentProps<typeof Stacked>

interface MaxContentWidthProps extends StackedProps {
  className?: string
}

export const MaxContentWidth: React.FC<MaxContentWidthProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Col className={'px-8'}>
      <Stacked
        className={classNames('mx-auto max-w-6xl', className)}
        {...restProps}
      >
        {children}
      </Stacked>
    </Col>
  )
}
