import {
  useCreateAccreditedInvestoryKycTier,
  useUpdateAccount,
  useUploadKycDocuments,
} from '@apiServices'
import { ButtonRow, Error, PageHeader } from '@components'
import { useAccount, useAnalytics, useToast } from '@contexts'
import { KycDocumentUpload, LoaderButton } from '@newComponents'
import * as Sentry from '@sentry/react'
import { isEntity } from '@utils'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AdditionalInformationPanel } from '../additional-information-panel/additional-information-panel'
import { AccreditationHistoryPanel } from './accreditation-history-panel'
import { AccreditationQualificationsPanel } from './accreditation-qualifications-panel'

export const AccreditationForm = () => {
  const { showSuccessToast, showErrorToast } = useToast()
  useAnalytics('/account/accreditation-form')
  const { account } = useAccount()

  const {
    mutateAsync: createAccreditedInvestorKycTier,
    isPending: createAccountKycTierLoading,
  } = useCreateAccreditedInvestoryKycTier()

  const {
    mutateAsync: updateAccount,
    isPending: updateAccountLoading,
    isSuccess,
  } = useUpdateAccount()

  const [qualificationMethod, setQualificationMethod] =
    useState<AccountAccreditationQualificationMethod>(
      isEntity(account) ? 'entity-net-worth' : 'net-worth',
    )
  const [notes, setNotes] = useState('')

  const [filesForUpload, setFilesForUpload] = useState<File[]>([])

  const areDocumentsRequired = qualificationMethod !== 'none'

  const kycTier: KycTier = 'accredited_investor'
  const {
    mutateAsync: uploadKycDocuments,
    isPending: isUploadingKycDocuments,
  } = useUploadKycDocuments(kycTier)

  const isLoading =
    isUploadingKycDocuments ||
    createAccountKycTierLoading ||
    updateAccountLoading

  const disableSubmit =
    isLoading || (areDocumentsRequired === true && filesForUpload.length === 0)

  const handleSubmit = async () => {
    try {
      const documentIds = await uploadKycDocuments({
        files: filesForUpload,
      })

      // On accredited investor kyc initialization, need to create an account
      // kyc tier with the appropriate qualification method
      await createAccreditedInvestorKycTier({
        qualificationMethod: qualificationMethod,
        message: notes || 'Accreditation documentation submitted for review',
        documentIds: documentIds,
      })

      showSuccessToast({
        title: 'Accredited investor docs successfully uploaded.',
      })
    } catch (e) {
      Sentry.captureException(e)
      showErrorToast({
        description:
          'An error occurred while submitting your accreditation documents.',
      })
    }
  }

  return isSuccess ? (
    <Error
      title={"We're sorry!"}
      message={
        'You are not eligible for accreditation, but you may re-apply at any time.'
      }
    />
  ) : (
    <>
      <PageHeader title={'Accreditation (US)'} />

      <div data-test-id='profile' className='grid gap-6 grid-cols-1'>
        <AccreditationHistoryPanel
          account={account}
          qualificationMethod={qualificationMethod}
          onClick={setQualificationMethod}
        />
        <AccreditationQualificationsPanel
          account={account}
          qualificationMethod={qualificationMethod}
          onClick={setQualificationMethod}
        />
        {qualificationMethod === 'none' ? null : (
          <>
            <KycDocumentUpload
              files={filesForUpload}
              onFileChange={setFilesForUpload}
              qualificationMethod={qualificationMethod}
              type='accreditation'
              entityType={account.entityType}
            />
            <AdditionalInformationPanel
              value={notes}
              onChange={(e: any) => {
                setNotes(`${e.target.value}`)
              }}
            />
          </>
        )}

        <ButtonRow place={'between'}>
          <Link className='btn btn-outline-dark' to='/account'>
            Cancel
          </Link>
          <LoaderButton
            className=''
            isLoading={isLoading}
            disabled={disableSubmit}
            onClick={handleSubmit}
            text='Submit'
            loadingText='Uploading...'
          />
        </ButtonRow>
      </div>
    </>
  )
}
