import { Card } from '@components'
import { FileSelector } from '@newComponents'
import { renderDocUploadText } from './kyc-document-upload-text'

interface KycDocumentUploadProps {
  type: KycDocumentUploadType
  qualificationMethod?: Maybe<AccountAccreditationQualificationMethod>
  entityType?: Maybe<AccountEntityType>
  onFileChange: (files: File[]) => void
  files: File[]
}

export const KycDocumentUpload = ({
  type,
  qualificationMethod,
  entityType,
  onFileChange,
  files,
}: KycDocumentUploadProps) => {
  const displayType = type.charAt(0).toUpperCase() + type.slice(1)
  return (
    <Card title={`${displayType || 'Upload'} Documents`}>
      {renderDocUploadText(type, qualificationMethod, entityType)}
      <FileSelector
        onFileChange={onFileChange}
        files={files}
        uploadStyle='dropzone'
      />
      <p className='text-xs text-gray-500 pt-2'>
        * By providing information, you represent and warrant that it is true,
        complete, and correct.
      </p>
    </Card>
  )
}
