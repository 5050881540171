import {
  paymentApiClient,
  usePaymentApiClient,
  usePublicApiClient,
} from '@apiClients'
import { useGetProject } from '@apiServices'
import {
  DEFAULT_WELCOME_BG_COLOR,
  DEFAULT_WELCOME_BG_COLOR_LEGACY,
} from '@constants'
import { useAuth } from '@contexts'
import { useAsync } from '@hooks'
import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { uniqueValues } from '@utils'
import axios from 'axios'
import { useEffect, useState } from 'react'

export const useGetProjects = (adminOnly: boolean) => {
  const { isAuthenticated } = useAuth()
  const { data: project } = useGetProject()
  const client = usePaymentApiClient()
  const { run, data } = useAsync()
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [moreResults, setMoreResults] = useState<Maybe<any>>(null)
  const [loadingComplete, setLoadingComplete] = useState<boolean>(false)
  const [reload, setReload] = useState<Date | null>(null)

  const PAGE_SIZE: number = 10

  /**
   * Build API request
   */
  const getClient = (request: any) => {
    let url = 'projects/search'

    if (request.nextLink) {
      url += '?' + request.nextLink.split('?')[1]
    } else {
      url += `?offset=0&queryLimit=${PAGE_SIZE}`
    }

    return client(url, {
      method: 'post',
      data: request.searchRequest,
    })
  }

  useEffect(() => {
    if (!isAuthenticated || project === undefined) {
      return
    }

    /* Setup default query parameters */
    run(
      getClient({
        searchRequest: {
          adminOnly: adminOnly,
          includeHidden: adminOnly,
          projectId: project.id,
        },
      }),
    )
  }, [isAuthenticated, reload])

  useEffect(() => {
    if (!data) {
      return
    }

    if (data?.projects?.length > 0) {
      console.log('Adding new projects search results', data)

      setSearchResults(uniqueValues(searchResults.concat(data)))

      if (data.nextLink) {
        setMoreResults(data)
      } else {
        setLoadingComplete(true)
      }
    } else {
      setLoadingComplete(true)
    }
  }, [data])

  /**
   * Fetch additional page of data
   */
  useEffect(() => {
    if (moreResults) {
      console.log('Fetching more projects results:', moreResults)
      run(getClient(moreResults))
    }
  }, [moreResults])

  const refresh = () => {
    setSearchResults([])
    setMoreResults(null)
    setLoadingComplete(false)
    setReload(new Date())
  }

  return {
    results: searchResults,
    loading: !loadingComplete,
    refresh: refresh,
  }
}

export const updateProject = (
  project: any,
  token: any,
  signOut: any,
): Promise<any> => {
  const data = { ...project }
  return paymentApiClient(`projects/${project.id}`, {
    method: 'put',
    data,
    token,
    signOut,
  })
}

export const useGetProjectById = (projectId?: string) => {
  const client = usePaymentApiClient()
  return useQuery({
    enabled: !!projectId,
    queryKey: ['project', projectId],
    queryFn: () => client(`projects/${projectId}`),
  })
}

export const useUpdateProject = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.id}`, {
        method: 'put',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useGetProjectPrivacyDocuments = (projectId?: string) => {
  const client = usePublicApiClient()
  return useQuery({
    enabled: !!projectId,
    queryKey: ['project-documents', projectId],
    queryFn: () => client(`projects/${projectId}/documents/privacy`),
  })
}

/**
 * HACK ALERT! Rainbowkit's authentication provider is instantiated before a typical use hook is called (useGetProjectPrivacyDocuments)
 * when fetching documents from the backend.  Instead, we are forced to fetch the documents in the getProjectPrivacyDocuments()
 * function of the authentication provider.  However, the getProjectPrivacyDocuments() function is not async so
 * we have to go old school here and fetch the nonce syncrhonously.
 */
export const getProjectPrivacyDocumentsSync = (projectId?: string) => {
  if (!projectId) {
    return []
  }

  const httpRequest = new XMLHttpRequest()
  const endpoint = `projects/${projectId}/documents/privacy`
  const url = `${import.meta.env.VITE_API_URL}/public/v1/${endpoint}`
  httpRequest.open('GET', url, false)
  httpRequest.setRequestHeader('Content-type', 'application/json')
  httpRequest.send(JSON.stringify({ projectId: projectId }))

  const documentsResponse = JSON.parse(httpRequest.responseText)
  return documentsResponse?.documents || []
}

export const useApproveProjectDocuments = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.id}/documents/approve`, {
        method: 'put',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useCreateProject = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client('projects', {
        method: 'post',
        data,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['project'] })
      queryClient.invalidateQueries({ queryKey: ['account'] })
    },
  })
}

export const useCreateProjectDomain = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.projectId}/domains`, {
        method: 'post',
        data: {
          domainName: data.domainName,
        },
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useRemoveProjectDomain = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.projectId}/domains/${data.id}`, {
        method: 'delete',
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useCreateProjectMember = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.projectId}/members`, {
        method: 'post',
        data: data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useUpdateProjectMember = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.projectId}/members/${data.id}`, {
        method: 'put',
        data: data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useRemoveProjectMember = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/${data.projectId}/members/${data.id}`, {
        method: 'delete',
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useDomainStatuses = (project: any) => {
  const domainStatuses = useQueries({
    queries: project?.domains?.map(
      ({ domainName }: { domainName: string }) => ({
        queryKey: ['status', domainName],
        queryFn: () =>
          axios.get(
            import.meta.env.PROCESS_ENV === 'development'
              ? `http://${domainName}/up.json`
              : `https://${domainName}/up.json`,
          ),
      }),
    ),
  }).map((result) => result?.data)

  return { domainStatuses }
}

export const projectThemeModified = (project: any) => {
  if (!project?.content) {
    return false
  }

  return (
    (project.content.welcomeBgColor !== DEFAULT_WELCOME_BG_COLOR_LEGACY &&
      project.content.welcomeBgColor !== DEFAULT_WELCOME_BG_COLOR) ||
    project.content.welcomeBgImage ||
    project.content.welcomeBody
  )
}

export const useCreateCertificate = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/domains/${data.domainId}/certificate`, {
        data: {
          projectId: data.projectId,
          validationMethod: data.validationMethod,
        },
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useGetCertificate = (projectDomainId: string) => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()
  const result = useQuery({
    enabled: false,
    queryKey: ['certificate', projectDomainId],
    queryFn: () => client(`projects/domains/${projectDomainId}/certificate`),
  })

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['project'] })
  }, [result.isSuccess])

  return result
}

export const useCreateDistribution = () => {
  const client = usePaymentApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`projects/domains/${data.domainId}/distribution`, {
        data: {
          projectId: data.projectId,
        },
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['project'] }),
  })
}

export const useGetDomainStatus = (domainName: string) => {
  const result = useQuery({
    enabled: false,
    queryKey: ['status', domainName],
    queryFn: () =>
      axios.get(
        import.meta.env.PROCESS_ENV === 'development'
          ? `http://${domainName}/up.json`
          : `https://${domainName}/up.json`,
      ),
  })

  return {
    ...result,
    domainStatus:
      JSON.stringify(result?.data?.data) === '{"up":true}'
        ? 'Active'
        : 'Inactive',
    loading: result?.isLoading || result?.isRefetching,
    getDomainStatus: result.refetch,
  }
}
