import BigNumber from 'bignumber.js'

type FormattedNumberProps = {
  value: BigNumber
  displayDecimals: number
  withCommas?: boolean
  trimTrailingZeros?: boolean
}

/**
 * Formats a BigNumber into a human-readable string with specified decimal places and optional commas.
 */
export const FormattedNumber: React.FC<FormattedNumberProps> = ({
  value,
  displayDecimals,
  withCommas = true,
  trimTrailingZeros = true,
}) => {
  // Convert BigNumber to a fixed decimal string
  let formattedNumber = value.toFixed(displayDecimals)

  if (withCommas) {
    // Split the number into integer and fractional parts
    const [integerPart, fractionalPart] = formattedNumber.split('.')

    // Apply comma formatting to the integer part only
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')

    // Reassemble the formatted number
    formattedNumber =
      fractionalPart !== undefined
        ? `${formattedInteger}.${fractionalPart}`
        : formattedInteger
  }

  if (trimTrailingZeros) {
    // Remove trailing zeros after the decimal point
    formattedNumber = formattedNumber
      .replace(/(\.\d*?)0+$/, '$1')
      .replace(/\.$/, '')
  }

  return <span>{formattedNumber}</span>
}
