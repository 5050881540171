import {
  Button,
  ButtonRow,
  Card,
  EventBodyWrapper,
  EventContent,
  EventHeader,
  EventHeaderWrapper,
  Row,
  Stacked,
} from '@components'
import { useAccount, useModal } from '@contexts'
import { CountdownTimer } from '@newComponents'
import { localToUtcDateTime } from '@utils'
import classNames from 'classnames'
import { useState } from 'react'

export const PreviewPage = ({
  context,
  onContinue,
}: {
  context: any
  onContinue?: Function
}) => {
  const { closeModal } = useModal()
  const { account } = useAccount()
  const [bodyContentDisplay, setBodyContentDisplay] = useState(
    'contentBodyUpcoming',
  )

  const startTime = localToUtcDateTime(context?.startTime, account?.timezone)

  const onContinueHandler = () => {
    onContinue && onContinue()
    closeModal()
  }

  return (
    <Stacked className='mt-10'>
      <Card>
        <Row className='mb-3'>
          {context.contentBodyTabs.map((tab: any, index: any) => (
            <Button
              className={classNames(
                'btn-sm btn-tab',
                tab?.value === bodyContentDisplay && 'active',
              )}
              onClick={() => setBodyContentDisplay(tab.value)}
              key={index}
            >
              {tab.label}
            </Button>
          ))}
        </Row>
        <Stacked>
          <EventHeaderWrapper
            backgroundColor={context?.headerBgColor}
            backgroundImage={context.contentHeaderBgImage}
          >
            <EventHeader
              headerTitle={context?.contentHeaderTitle}
              headerSubtitle={context?.contentHeaderSubtitle}
              contentHeaderImage={context?.contentHeaderImage}
              contentHeaderFontColor={context?.contentHeaderFontColor}
              themeLayoutVersion={context?.themeLayoutVersion}
            />
          </EventHeaderWrapper>

          <EventBodyWrapper>
            {bodyContentDisplay === 'contentBodyUpcoming' &&
              !!context?.startTime && (
                <CountdownTimer date={new Date(startTime)} />
              )}

            <EventContent bodyContent={context?.[bodyContentDisplay]} />
          </EventBodyWrapper>
        </Stacked>
      </Card>
      <ButtonRow>
        <Button
          className={'w-full'}
          fill={'outline'}
          onClick={() => closeModal()}
        >
          Continue Editing
        </Button>
        <Button className={'w-full'} onClick={() => onContinueHandler()}>
          Approve & Continue
        </Button>
      </ButtonRow>
    </Stacked>
  )
}
