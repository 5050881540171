import { Col, FormLabel } from '@components'
import { ContentState, EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Editor as ReactEditor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

type EditorProps = {
  name: string
  contentHtml?: string
  label?: string
  subLabel?: ReactNode
  required?: boolean
  onChange: Function
}

export const Editor: FC<EditorProps> = ({
  name,
  contentHtml,
  label,
  subLabel,
  required,
  onChange,
}) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [touched, setTouched] = useState<boolean>(false)
  const [requiredValidationMessage, setRequiredValidationMessage] =
    useState<Maybe<string>>(null)

  const isEmpty = (value: any) => {
    const strippedString = value.replace(/<p>|<\/p>/g, '')
    // When empty, the stripped string will be 1 for editor
    return strippedString.length === 1
  }

  useEffect(() => {
    if (required && isEmpty(contentHtml) && touched) {
      setRequiredValidationMessage(`${label} is required`)
    } else {
      setRequiredValidationMessage(null)
    }
  }, [contentHtml, touched])

  useEffect(() => {
    if (contentHtml) {
      const blocksFromHtml = htmlToDraft(contentHtml)
      const { contentBlocks, entityMap } = blocksFromHtml
      const contentState = ContentState.createFromBlockArray(
        contentBlocks,
        entityMap,
      )
      const _editorState = EditorState.createWithContent(contentState)
      setEditorState(_editorState)
    }
  }, [])

  const onEditorStateChange = (e: any) => {
    setEditorState(e)
    const htmlValue = draftToHtml(convertToRaw(e.getCurrentContent()))
    if (isEmpty(htmlValue)) {
      onChange && onChange('')
    } else {
      onChange && onChange(htmlValue)
    }
  }

  return (
    <Col>
      {label && (
        <FormLabel>
          <label htmlFor={name}>
            {label}
            {required && ' *'}
          </label>
        </FormLabel>
      )}
      {subLabel && <div className='col-form-sublabel'>{subLabel}</div>}
      <ReactEditor
        editorState={editorState}
        wrapperClassName='wrapper-class'
        editorClassName={
          'border border-neutral-light min-h-[200px] max-h-[800px] overflow-y-auto px-4'
        }
        toolbarClassName='toolbar-class'
        onEditorStateChange={onEditorStateChange}
        onBlur={() => setTouched(true)}
        toolbar={{
          options: [
            'inline',
            'blockType',
            'list',
            'textAlign',
            'link',
            'embedded',
            'image',
            'colorPicker',
          ],
        }}
      />
      {requiredValidationMessage && (
        <span className='form-control-error'>{requiredValidationMessage}</span>
      )}
    </Col>
  )
}
