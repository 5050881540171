import { ButtonRow, PageHeader, PageSubtitle, PageTitle } from '@components'
import { Link } from 'react-router-dom'

interface KycRestrictionProps {
  className?: string
  style?: React.CSSProperties
}

export const KycRestriction = ({ className, style }: KycRestrictionProps) => {
  return (
    <>
      <PageHeader className={className}>
        <PageTitle style={style}>Oops!</PageTitle>
        <PageSubtitle style={style}>
          Your profile is not complete. Please visit the My Profile page to
          finish the profile setup and identity verification process.
        </PageSubtitle>
      </PageHeader>

      <ButtonRow place={'center'}>
        <Link className='btn btn-primary' to='/account'>
          My Profile
        </Link>

        <Link className='btn btn-primary' to='/'>
          Dashboard
        </Link>
      </ButtonRow>
    </>
  )
}
