import { CheckableCircle } from '@newComponents'
import { motion } from 'framer-motion'

interface TimelineItemProps {
  date: Date
  title: string
}

export const TimelineItem = ({ date, title }: TimelineItemProps) => {
  const now = new Date()
  const isPast = date < now

  return (
    <motion.div
      className='bg-white rounded-md w-full max-w-md mb-4 relative'
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <div className='flex gap-4 space-between justify-start items-center border-b'>
        <CheckableCircle isChecked={isPast} checkedColor='#1051d0' />
        <div>
          <h3 className='text-md text-gray-800'>{title}</h3>
          <p className='text-gray-500 text-sm mb-2'>
            {/* Show date and time */}
            {date.toLocaleDateString('en-US', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            })}
          </p>
        </div>
      </div>
    </motion.div>
  )
}
