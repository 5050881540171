import { useAdminApiClient } from '@api/clients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useUpdateEvent = () => {
  const client = useAdminApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/${data.id}`, {
        method: 'put',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}
