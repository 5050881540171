import { IconProps } from './icon-props'

export const BackArrowIcon = ({ color, className, size = 10 }: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 6 10'
        fill='none'
        stroke={color !== undefined ? color : 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M4.78166 9.66666C4.66782 9.667 4.55533 9.64502 4.45247 9.60234C4.34961 9.55966 4.25899 9.49736 4.18727 9.42002L0.506607 5.4204C0.394525 5.30112 0.333252 5.1515 0.333252 4.9971C0.333252 4.8427 0.394525 4.69309 0.506607 4.57381L4.31682 0.574186C4.44617 0.438055 4.63204 0.352446 4.83354 0.336195C5.03504 0.319943 5.23567 0.374379 5.3913 0.487528C5.54692 0.600676 5.64478 0.763268 5.66336 0.939536C5.68194 1.1158 5.61971 1.29131 5.49036 1.42744L2.08403 5.00044L5.37606 8.57343C5.46924 8.67128 5.52843 8.79043 5.54663 8.91678C5.56483 9.04314 5.54127 9.17141 5.47874 9.28641C5.41621 9.40142 5.31733 9.49835 5.19379 9.56574C5.07026 9.63313 4.92724 9.66815 4.78166 9.66666Z'
          fill='#344054'
        />
      </svg>
    </div>
  )
}
