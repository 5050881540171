import { Card, CountdownTimer } from '@newComponents'
import { capitalize } from '@utils'
import { EventTimeline } from 'tokensoft-shared-types'

type EventCountdownProps = {
  eventType: string
  timeline: EventTimeline
}

export const EventCountdown = ({
  eventType,
  timeline,
}: EventCountdownProps) => {
  const isRegistrationUpcoming = timeline.isRegistrationUpcoming() === true
  const isEventUpcoming = timeline.isEventUpcoming() === true

  return (
    <>
      {isRegistrationUpcoming && (
        <Card>
          <CountdownTimer
            date={timeline.registrationStartTime}
            label={'Registration starts in'}
            onReachedZero={() => window.location.reload()}
          />
        </Card>
      )}
      {!isRegistrationUpcoming &&
        isEventUpcoming &&
        timeline.startTime !== null && (
          <Card>
            <CountdownTimer
              date={timeline.startTime}
              label={`${capitalize(eventType)} starts in`}
              onReachedZero={() => window.location.reload()}
            />
          </Card>
        )}
    </>
  )
}
