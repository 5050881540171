import {
  Button,
  Col,
  ModalTitle,
  PreparingIcon,
  Stacked,
  Text,
} from '@components'
import { useDeploySale } from '@hooks'
import { isEmptyObject } from '@utils'
import { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

interface DeploySaleModalProps {
  preparedData?: any
  onSuccess?: Function
  onError?: Function
}

export const DeploySaleModal = ({
  preparedData,
  onSuccess,
  onError,
}: DeploySaleModalProps) => {
  const {
    error: deploySaleError,
    write: deploySale,
    data: deploySaleReceipt,
    isSubmitting: deploySaleSubmitting,
  } = useDeploySale()
  const [submitted, setSubmitted] = useState<boolean>(false)
  const key = 'deploy-sale-modal'

  useEffect(() => {
    if (!isEmptyObject(deploySaleReceipt)) {
      if (deploySaleReceipt.status === 'success') {
        // For zkSync, the contract address of the newly deployed sale is at index 7, for other
        //    networks it's at index 3.
        const isZkSync =
          'chainId' in deploySaleReceipt &&
          (deploySaleReceipt.chainId === 324 ||
            deploySaleReceipt.chainId === 300)
        const contractAddressLogIndex = isZkSync ? 7 : 3
        onSuccess &&
          onSuccess({
            ...deploySaleReceipt,
            contractAddress:
              deploySaleReceipt.logs?.[contractAddressLogIndex]?.address,
          })
      }
    }
  }, [deploySaleReceipt])

  useEffect(() => {
    if (!isEmptyObject(deploySaleError)) {
      onError && onError(deploySaleError.toString())
    }
  }, [deploySaleError])

  useEffect(() => {
    if (deploySaleSubmitting) {
      setSubmitted(true)
    }
  }, [deploySaleSubmitting])

  return (
    <Stacked>
      <Col data-testid={key} gap={5} place={'center'}>
        <PreparingIcon />
        <ModalTitle data-testid={`${key}-title`}>
          {'Deploy Your Sale'}
        </ModalTitle>
        <Text data-testid={`${key}-subtitle`} width='3/4' textAlign={'center'}>
          Your sale contract is ready to deploy! You’ll be able to update
          configuration after deployment.
        </Text>
      </Col>
      <Button
        data-testid={`${key}-button`}
        color={'primary'}
        onClick={() => deploySale(preparedData.args)}
        disabled={submitted}
      >
        {submitted ? (
          <>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={24} />
            </div>

            <Text>Deploying...</Text>
          </>
        ) : (
          <Text>Deploy</Text>
        )}
      </Button>
    </Stacked>
  )
}
