import { useInterval, useWindowSize } from '@hooks'
import { useCallback, useLayoutEffect, useRef, useState } from 'react'

/**
 * Manages animating the `height` value of an element when it is opened or closed, and on resize.
 * @param isOpen Whether the content is open
 * @param intervalMs Interval in ms to recalculate height (e.g., for dynamic content). Defaults to `2000`.
 */
export const useHeightAnimation = (isOpen: boolean, intervalMs = 2000) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  const windowSize = useWindowSize() // Recalculate on window resize

  const updateHeight = useCallback(() => {
    if (contentRef.current) {
      const currentHeight = contentRef.current.scrollHeight
      setHeight(currentHeight)
    }
  }, [])

  useLayoutEffect(() => {
    // Recalculate height when `isOpen` changes or window is resized
    if (isOpen) {
      updateHeight()
    } else {
      setHeight(0)
    }
  }, [isOpen, updateHeight, windowSize])

  useInterval(() => {
    // Handle dynamic content loading (e.g., images) by recalculating height
    if (isOpen) {
      updateHeight()
    }
  }, intervalMs)

  return {
    contentRef,
    animate: {
      height,
      opacity: isOpen ? 1 : 0,
    },
    transition: {
      duration: 0.4,
      ease: 'easeInOut',
    },
    initial: {
      height: 0,
      opacity: 0,
    },
  }
}
