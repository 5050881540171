import { useGetProject } from '@apiServices'
import { TSWagmiContext, useNetworks, wagmiTransportUrls } from '@contexts'
import { createConfig, WagmiProvider } from '@privy-io/wagmi'
import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import { Config, fallback, http } from 'wagmi'
import { taurus } from '@utils/extra-chains'
import {
  anvil,
  arbitrum,
  avalanche,
  avalancheFuji,
  base,
  baseSepolia,
  bsc,
  bscTestnet,
  celoAlfajores,
  Chain,
  gnosis,
  mainnet,
  optimism,
  optimismSepolia,
  polygon,
  polygonAmoy,
  polygonMumbai,
  scroll,
  scrollSepolia,
  sepolia,
  zkSync,
  zkSyncSepoliaTestnet,
} from 'wagmi/chains'

interface TSWagmiProviderProps {
  children?: ReactNode
}

export const TSWagmiProvider: FC<TSWagmiProviderProps> = ({ children }) => {
  console.log('INFO: TSWagmiProvider() called')

  const { loading, supportedNetworks } = useNetworks()
  const [wagmiConfig, setWagmiConfig] = useState<Maybe<Config>>(null)
  const [activeChains, setActiveChains] =
    useState<Readonly<Maybe<[Chain, ...Chain[]]>>>(null)
  const [defaultChain, setDefaultChain] = useState<Maybe<Chain>>(null)
  const { data: project } = useGetProject()

  const updateDefaultChain = (chainId: number) => {
    if (activeChains !== null) {
      const chain = activeChains.find((c) => c.id === chainId)
      setDefaultChain(chain || null)
    }
  }

  const getSupportedChains = useCallback((): Readonly<[Chain, ...Chain[]]> => {
    if (supportedNetworks.length === 0) {
      return import.meta.env.VITE_PROCESS_ENV === 'production'
        ? [mainnet]
        : [sepolia]
    }

    let sn: (Chain | undefined)[] = supportedNetworks?.map((network) => {
      switch (network.name) {
        case 'Ethereum':
          return mainnet
        case 'Avalanche':
          return avalanche
        case 'Polygon':
          return polygon
        case 'Polygon Amoy':
          return polygonAmoy
        case 'Sepolia':
          return sepolia
        case 'Fuji':
          return avalancheFuji
        case 'Mumbai':
          return {
            ...polygonMumbai,
            rpcUrls: {
              public: { http: ['https://rpc-mumbai.maticvigil.com/'] },
              default: { http: ['https://rpc-mumbai.maticvigil.com/'] },
            },
          }
        case 'Optimism':
          return optimism
        case 'Arbitrum':
          return arbitrum
        case 'Binance':
          return bsc
        case 'BSC Testnet':
          return bscTestnet
        case 'Base':
          return base
        case 'Base Sepolia':
          return baseSepolia
        case 'Gnosis':
          return gnosis
        case 'Celo':
          return {
            id: 44787,
            name: 'Alfajores',
            network: 'alfajores',
            nativeCurrency: {
              name: 'Goerli CELO',
              symbol: 'ETH',
              decimals: 18,
            },
            rpcUrls: {
              default: { http: ['https://alfajores-forno.celo-testnet.org'] },
              public: { http: ['https://alfajores-forno.celo-testnet.org'] },
            },
            blockExplorers: {
              default: {
                name: 'alfajores',
                url: 'https://alfajores-blockscout.celo-testnet.org',
              },
            },
            testnet: true,
          }
        case 'zkSync':
          return zkSync
        case 'zkSync Sepolia':
          return zkSyncSepoliaTestnet
        case 'Scroll':
          return scroll
        case 'Scroll Sepolia':
          return scrollSepolia
        case 'Anvil':
          return anvil
        case 'Autonomys Taurus Testnet':
          return taurus
        default:
          break
      }
    })

    sn = sn.filter((element) => element !== undefined)
    return sn as [Chain, ...Chain[]]
  }, [supportedNetworks])

  useEffect(() => {
    if (activeChains) {
      // first, check to see if the user is connected to a supported network
      const connectedNetworkId = window.ethereum?.networkVersion
      if (connectedNetworkId) {
        console.log(
          'wallet provider already connected to chain id...',
          connectedNetworkId,
        )
        const foundChain = activeChains.find(
          (c) => c.id === Number(connectedNetworkId),
        )
        if (foundChain) {
          return setDefaultChain(foundChain)
        }
      }

      // as a last resort, fallback to the environment default
      if (import.meta.env.VITE_PROCESS_ENV === 'production') {
        setDefaultChain(activeChains.find((c) => c.id === mainnet.id) || null)
      } else {
        setDefaultChain(activeChains.find((c) => c.id === sepolia.id) || null)
      }
    }
  }, [activeChains, project])

  useEffect(() => {
    if (!loading && !wagmiConfig) {
      const supportedChains = getSupportedChains()

      const wagmiConfig = createConfig({
        chains: supportedChains,
        transports: {
          [mainnet.id]: fallback([
            ...wagmiTransportUrls[mainnet.id].map((url) => http(url)),
            http(),
          ]),
          [sepolia.id]: fallback([
            ...wagmiTransportUrls[sepolia.id].map((url) => http(url)),
            http(),
          ]),
          [polygon.id]: fallback([
            ...wagmiTransportUrls[polygon.id].map((url) => http(url)),
            http(),
          ]),
          [polygonMumbai.id]: fallback([
            ...wagmiTransportUrls[polygonMumbai.id].map((url) => http(url)),
            http(),
          ]),
          [polygonAmoy.id]: fallback([
            ...wagmiTransportUrls[polygonAmoy.id].map((url) => http(url)),
            http(),
          ]),
          [optimism.id]: fallback([
            ...wagmiTransportUrls[optimism.id].map((url) => http(url)),
            http(),
          ]),
          [optimismSepolia.id]: fallback([
            ...wagmiTransportUrls[optimismSepolia.id].map((url) => http(url)),
            http(),
          ]),
          [arbitrum.id]: fallback([
            ...wagmiTransportUrls[arbitrum.id].map((url) => http(url)),
            http(),
          ]),
          [base.id]: fallback([
            ...wagmiTransportUrls[base.id].map((url) => http(url)),
            http(),
          ]),
          [baseSepolia.id]: fallback([
            ...wagmiTransportUrls[baseSepolia.id].map((url) => http(url)),
            http(),
          ]),
          [bsc.id]: fallback([
            ...wagmiTransportUrls[bsc.id].map((url) => http(url)),
            http(),
          ]),
          [bscTestnet.id]: fallback([
            ...wagmiTransportUrls[bscTestnet.id].map((url) => http(url)),
            http(),
          ]),
          [avalanche.id]: fallback([
            ...wagmiTransportUrls[avalanche.id].map((url) => http(url)),
            http(),
          ]),
          [avalancheFuji.id]: fallback([
            ...wagmiTransportUrls[avalancheFuji.id].map((url) => http(url)),
            http(),
          ]),
          [gnosis.id]: fallback([
            ...wagmiTransportUrls[gnosis.id].map((url) => http(url)),
            http(),
          ]),
          [celoAlfajores.id]: fallback([
            ...wagmiTransportUrls[celoAlfajores.id].map((url) => http(url)),
            http(),
          ]),
          [zkSync.id]: fallback([
            ...wagmiTransportUrls[zkSync.id].map((url) => http(url)),
            http(),
          ]),
          [zkSyncSepoliaTestnet.id]: fallback([
            ...wagmiTransportUrls[zkSyncSepoliaTestnet.id].map((url) =>
              http(url),
            ),
            http(),
          ]),
          [scroll.id]: fallback([
            ...wagmiTransportUrls[scroll.id].map((url) => http(url)),
            http(),
          ]),
          [scrollSepolia.id]: fallback([
            ...wagmiTransportUrls[scrollSepolia.id].map((url) => http(url)),
            http(),
          ]),
          [anvil.id]: fallback([
            ...wagmiTransportUrls[anvil.id].map((url) => http(url)),
            http(),
          ]),
          [taurus.id]: fallback([
            ...wagmiTransportUrls[taurus.id].map((url) => http(url)),
            http(),
          ]),
        },
      })

      setWagmiConfig(wagmiConfig)
      setActiveChains(supportedChains)
    }
  }, [loading, getSupportedChains, wagmiConfig])

  if (!wagmiConfig) {
    return <></>
  }

  return (
    <>
      <TSWagmiContext.Provider
        value={{
          activeChains: activeChains,
          defaultChain: defaultChain,
          updateDefaultChain: updateDefaultChain,
          wagmiConfig: wagmiConfig,
        }}
      >
        <WagmiProvider config={wagmiConfig}>{children}</WagmiProvider>
      </TSWagmiContext.Provider>
    </>
  )
}
