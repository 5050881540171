import { DropdownLargeIcon, Text } from '@components'
import { useHeightAnimation } from '@hooks'
import { motion } from 'framer-motion'
import { HTMLAttributes, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface CollapsibleCardProps extends HTMLAttributes<HTMLDivElement> {
  cardTitle?: string
  className?: string
  children: React.ReactNode
}

export const CollapsibleCard = ({
  cardTitle = '',
  className,
  children,
  ...props
}: CollapsibleCardProps) => {
  const [isOpen, setIsOpen] = useState(true)
  const { contentRef, ...motionProps } = useHeightAnimation(isOpen)

  return (
    <div
      className={twMerge(
        'rounded-[8px] px-6 py-5 overflow-hidden bg-white transition-all duration-200',
        className,
      )}
      {...props}
    >
      <div className='flex flex-col'>
        <div className='flex flex-row justify-between'>
          <Text className='font-bold text-xl text-black content-center'>
            {cardTitle}
          </Text>
          <div onClick={() => setIsOpen(!isOpen)} className='cursor-pointer'>
            <DropdownLargeIcon
              size={36}
              className={twMerge(
                'transition-transform duration-[400ms] select-none',
                isOpen ? 'rotate-180' : '',
              )}
            />
          </div>
        </div>
        <motion.div className='overflow-hidden' {...motionProps}>
          <div ref={contentRef}>
            <div className='py-4'>{children}</div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}
