import { useToast } from '@contexts'
import { getAggregatorV3ABI } from '@utils'
import BigNumber from 'bignumber.js'
import { Abi } from 'viem'
import { useReadContracts } from 'wagmi'

export const useGetPaymentMethodOraclePrices = (
  chainId: ChainId | undefined,
  oracles: OracleConfig[],
): Maybe<CurrencyValue[]> => {
  const { showErrorToast } = useToast()

  // Prepare contracts array for useReadContracts
  const contracts = oracles.flatMap(({ oracleAddress }) => [
    {
      address: oracleAddress,
      abi: getAggregatorV3ABI() as Abi,
      functionName: 'latestAnswer',
      chainId,
    },
    {
      address: oracleAddress,
      abi: getAggregatorV3ABI() as Abi,
      functionName: 'decimals',
      chainId,
    },
  ])

  const { data, isLoading, error } = useReadContracts({
    contracts,
    query: {
      enabled: chainId !== undefined && oracles.length > 0,
    },
  })

  const prices: Maybe<CurrencyValue[]> = []

  // Parse results dynamically
  if (data) {
    for (let i = 0; i < oracles.length; i++) {
      const latestAnswerIndex = i * 2
      const decimalsIndex = i * 2 + 1

      let latestAnswer: Maybe<bigint> = null
      let decimals: Maybe<number> = null

      if (data[latestAnswerIndex]?.status === 'success') {
        latestAnswer = data[latestAnswerIndex].result as bigint
      }

      if (data[decimalsIndex]?.status === 'success') {
        decimals = data[decimalsIndex].result as number
      }

      if (error) {
        showErrorToast({
          description: error.message,
        })

        return null
      }

      if (!isLoading && latestAnswer !== null && decimals !== null) {
        // Get bignumber value

        const priceRaw = new BigNumber(latestAnswer.toString())
        const price = priceRaw.div(10 ** decimals)

        prices.push({
          value: price,
          symbol: oracles[i].symbol,
        })
      } else {
        return null
      }
    }
  }

  return prices.length > 0 ? prices : null
}
