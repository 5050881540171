import { Text } from '@components'
import { StatusIconInfo, StatusIconSuccess } from '@components/icon/statuses'
import { StatusIconError } from '@components/icon/statuses/status-icon-error'
import { StatusIconQuestion } from '@components/icon/statuses/status-icon-question'
import { ReactElement } from 'react'

interface StatusBoxProps {
  status: Status
  text: string
}

const statusColors: Record<Status, HexColorCode> = {
  success: '#E8FDF2',
  info: '#EFF3FF',
  warning: '#FFFBE9',
  error: '#FEECEC',
}

const statusTextColors: Record<Status, HexColorCode> = {
  success: '#0D914F',
  info: '#667085',
  warning: '#D4AF12',
  error: '#D01010',
}

const statusIcons: Record<Status, ReactElement> = {
  success: <StatusIconSuccess />,
  info: <StatusIconInfo />,
  warning: <StatusIconQuestion />,
  error: <StatusIconError />,
}

export const StatusBox = ({ status, text }: StatusBoxProps) => {
  const color = statusColors[status]
  const textColor = statusTextColors[status]
  const icon = statusIcons[status]

  return (
    <div
      style={{ backgroundColor: color }}
      className={`p-4 flex gap-2 rounded`}
    >
      {icon}
      <Text style={{ color: textColor }} className={`text-sm`}>
        {text}
      </Text>
    </div>
  )
}
