export const TITLE_100 = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 342 134'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='13.0771'
        y='13.8462'
        width='313.923'
        height='45.7692'
        rx='5'
        fill='#E5E7EB'
      />
      <path
        d='M162.393 46.4005C162.672 46.4005 162.939 46.302 163.136 46.1267C163.333 45.9514 163.443 45.7137 163.443 45.4657V37.2004C163.443 36.9525 163.333 36.7147 163.136 36.5394C162.939 36.3641 162.672 36.2656 162.393 36.2656C162.115 36.2656 161.848 36.3641 161.651 36.5394C161.454 36.7147 161.344 36.9525 161.344 37.2004V45.4657C161.344 45.7137 161.454 45.9514 161.651 46.1267C161.848 46.302 162.115 46.4005 162.393 46.4005Z'
        fill='#6B7280'
      />
      <path
        d='M157.752 42.2675H167.035C167.314 42.2675 167.581 42.169 167.777 41.9937C167.974 41.8184 168.085 41.5807 168.085 41.3327C168.085 41.0848 167.974 40.847 167.777 40.6717C167.581 40.4964 167.314 40.3979 167.035 40.3979H157.752C157.473 40.3979 157.206 40.4964 157.009 40.6717C156.812 40.847 156.702 41.0848 156.702 41.3327C156.702 41.5807 156.812 41.8184 157.009 41.9937C157.206 42.169 157.473 42.2675 157.752 42.2675Z'
        fill='#6B7280'
      />
      <path
        d='M161.068 29.8696H182.95C183.229 29.8696 183.496 29.7711 183.692 29.5958C183.889 29.4205 184 29.1827 184 28.9348C184 28.6869 183.889 28.4491 183.692 28.2738C183.496 28.0985 183.229 28 182.95 28H161.068C160.789 28 160.522 28.0985 160.325 28.2738C160.129 28.4491 160.018 28.6869 160.018 28.9348C160.018 29.1827 160.129 29.4205 160.325 29.5958C160.522 29.7711 160.789 29.8696 161.068 29.8696Z'
        fill='#6B7280'
      />
      <path
        d='M161.068 29.8696H182.95C183.229 29.8696 183.496 29.7711 183.692 29.5958C183.889 29.4205 184 29.1827 184 28.9348C184 28.6869 183.889 28.4491 183.692 28.2738C183.496 28.0985 183.229 28 182.95 28H161.068C160.789 28 160.522 28.0985 160.325 28.2738C160.129 28.4491 160.018 28.6869 160.018 28.9348C160.018 29.1827 160.129 29.4205 160.325 29.5958C160.522 29.7711 160.789 29.8696 161.068 29.8696Z'
        fill='#6B7280'
      />
      <path
        d='M161.068 34.5922H182.95C183.229 34.5922 183.496 34.4937 183.692 34.3184C183.889 34.1431 184 33.9054 184 33.6574C184 33.4095 183.889 33.1718 183.692 32.9964C183.496 32.8211 183.229 32.7227 182.95 32.7227H161.068C160.789 32.7227 160.522 32.8211 160.325 32.9964C160.129 33.1718 160.018 33.4095 160.018 33.6574C160.018 33.9054 160.129 34.1431 160.325 34.3184C160.522 34.4937 160.789 34.5922 161.068 34.5922Z'
        fill='#6B7280'
      />
      <path
        d='M171.677 39.3154H182.95C183.229 39.3154 183.496 39.2169 183.692 39.0416C183.889 38.8663 184 38.6285 184 38.3806C184 38.1327 183.889 37.8949 183.692 37.7196C183.496 37.5443 183.229 37.4458 182.95 37.4458H171.677C171.399 37.4458 171.132 37.5443 170.935 37.7196C170.738 37.8949 170.627 38.1327 170.627 38.3806C170.627 38.6285 170.738 38.8663 170.935 39.0416C171.132 39.2169 171.399 39.3154 171.677 39.3154Z'
        fill='#6B7280'
      />
      <path
        d='M171.677 44.0388H182.95C183.229 44.0388 183.496 43.9403 183.692 43.765C183.889 43.5897 184 43.3519 184 43.104C184 42.8561 183.889 42.6183 183.692 42.443C183.496 42.2677 183.229 42.1692 182.95 42.1692H171.677C171.399 42.1692 171.132 42.2677 170.935 42.443C170.738 42.6183 170.627 42.8561 170.627 43.104C170.627 43.3519 170.738 43.5897 170.935 43.765C171.132 43.9403 171.399 44.0388 171.677 44.0388Z'
        fill='#6B7280'
      />
      <rect
        x='13.0771'
        y='72.6924'
        width='313.923'
        height='45.7692'
        rx='5'
        fill='#E5E7EB'
      />
      <path
        d='M162.393 104.247C162.672 104.247 162.939 104.149 163.136 103.975C163.333 103.801 163.443 103.565 163.443 103.32V95.1234C163.443 94.8775 163.333 94.6418 163.136 94.4679C162.939 94.2941 162.672 94.1964 162.393 94.1964C162.115 94.1964 161.848 94.2941 161.651 94.4679C161.454 94.6418 161.344 94.8775 161.344 95.1234V103.32C161.344 103.565 161.454 103.801 161.651 103.975C161.848 104.149 162.115 104.247 162.393 104.247Z'
        fill='#6B7280'
      />
      <path
        d='M157.752 100.148H167.035C167.314 100.148 167.581 100.051 167.777 99.8769C167.974 99.703 168.085 99.4672 168.085 99.2214C168.085 98.9756 167.974 98.7398 167.777 98.5659C167.581 98.3921 167.314 98.2944 167.035 98.2944H157.752C157.473 98.2944 157.206 98.3921 157.009 98.5659C156.812 98.7398 156.702 98.9756 156.702 99.2214C156.702 99.4672 156.812 99.703 157.009 99.8769C157.206 100.051 157.473 100.148 157.752 100.148Z'
        fill='#6B7280'
      />
      <path
        d='M161.068 87.8539H182.95C183.229 87.8539 183.496 87.7563 183.692 87.5824C183.889 87.4086 184 87.1728 184 86.927C184 86.6811 183.889 86.4453 183.692 86.2715C183.496 86.0977 183.229 86 182.95 86H161.068C160.789 86 160.522 86.0977 160.325 86.2715C160.129 86.4453 160.018 86.6811 160.018 86.927C160.018 87.1728 160.129 87.4086 160.325 87.5824C160.522 87.7563 160.789 87.8539 161.068 87.8539Z'
        fill='#6B7280'
      />
      <path
        d='M161.068 87.8539H182.95C183.229 87.8539 183.496 87.7563 183.692 87.5824C183.889 87.4086 184 87.1728 184 86.927C184 86.6811 183.889 86.4453 183.692 86.2715C183.496 86.0977 183.229 86 182.95 86H161.068C160.789 86 160.522 86.0977 160.325 86.2715C160.129 86.4453 160.018 86.6811 160.018 86.927C160.018 87.1728 160.129 87.4086 160.325 87.5824C160.522 87.7563 160.789 87.8539 161.068 87.8539Z'
        fill='#6B7280'
      />
      <path
        d='M161.068 92.5369H182.95C183.229 92.5369 183.496 92.4393 183.692 92.2654C183.889 92.0916 184 91.8558 184 91.61C184 91.3641 183.889 91.1283 183.692 90.9545C183.496 90.7806 183.229 90.683 182.95 90.683H161.068C160.789 90.683 160.522 90.7806 160.325 90.9545C160.129 91.1283 160.018 91.3641 160.018 91.61C160.018 91.8558 160.129 92.0916 160.325 92.2654C160.522 92.4393 160.789 92.5369 161.068 92.5369Z'
        fill='#6B7280'
      />
      <path
        d='M171.677 97.221H182.95C183.229 97.221 183.496 97.1233 183.692 96.9495C183.889 96.7757 184 96.5399 184 96.294C184 96.0482 183.889 95.8124 183.692 95.6386C183.496 95.4647 183.229 95.3671 182.95 95.3671H171.677C171.399 95.3671 171.132 95.4647 170.935 95.6386C170.738 95.8124 170.627 96.0482 170.627 96.294C170.627 96.5399 170.738 96.7757 170.935 96.9495C171.132 97.1233 171.399 97.221 171.677 97.221Z'
        fill='#6B7280'
      />
      <path
        d='M171.677 101.905H182.95C183.229 101.905 183.496 101.807 183.692 101.633C183.889 101.459 184 101.224 184 100.978C184 100.732 183.889 100.496 183.692 100.322C183.496 100.149 183.229 100.051 182.95 100.051H171.677C171.399 100.051 171.132 100.149 170.935 100.322C170.738 100.496 170.627 100.732 170.627 100.978C170.627 101.224 170.738 101.459 170.935 101.633C171.132 101.807 171.399 101.905 171.677 101.905Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4349_649'
          x='0'
          y='0.769287'
          width='342'
          height='132.769'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4349_649'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4349_649'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
