import { useGetLegacyPurchases, viewLegacyDocumentVersion } from '@apiServices'
import { Card, IconLogo, LoadingIndicator } from '@components'
import { useAuth } from '@contexts'
import { useClipboard } from '@hooks'
import {
  formatValue,
  getBlockExplorerUrlForLegacyPurchase,
  getTruncatedAddress,
  utcToLocalDate,
} from '@utils'
import { AiOutlineCopy } from 'react-icons/ai'
import { BiLinkExternal } from 'react-icons/bi'

export const LegacyTransactions = () => {
  const {
    user: { token },
  } = useAuth()
  const { copyToClipboard } = useClipboard()
  const { purchases: legacyPurchases, loading } = useGetLegacyPurchases()

  if (loading) {
    return <LoadingIndicator text='Loading Legacy Purchase History' />
  }

  if (!loading && Object.keys(legacyPurchases).length === 0) {
    return <span className='block pt-4'>No purchases found.</span>
  }

  return (
    <div className='grid gap-6 grid-cols-1'>
      {legacyPurchases.map((legacyPurchase, i) => {
        return (
          <Card key={i}>
            <div
              className={
                'grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-x-8 gap-y-4 mb-6'
              }
            >
              <div className='flex flew-row space-x-2 items-center col-span-6'>
                <IconLogo className='large' logo={legacyPurchase.logo} />
                <div className={'flex flex-col self-center'}>
                  <span className='font-semibold high-contrast'>
                    {legacyPurchase.tenantName?.toUpperCase()}
                  </span>
                </div>
              </div>

              <div className='flex flex-col col-span-2'>
                <div className='mb-1'>
                  <span className='text-xs uppercase high-contrast'>Email</span>
                </div>
                <div className='text-sm high-contrast'>
                  {legacyPurchase.email}
                </div>
              </div>
            </div>

            <div className='w-full flex border-b border-neutral-light py-0.5 mt-6 mb-6'></div>

            <div
              className={
                'grid grid-cols-2 md:grid-cols-4 xl:grid-cols-12 gap-x-12 gap-y-4 mt-6 mb-3'
              }
            >
              <div className='flex flex-col col-span-2'>
                <span className='text-xs uppercase'>Wallet Address</span>
              </div>
              <div className='flex flex-col col-span-1'>
                <span className='text-xs uppercase'>Type</span>
              </div>
              <div className='flex flex-col col-span-2'>
                <span className='text-xs uppercase'>Tokens Purchased</span>
              </div>
              <div className='flex flex-col col-span-2'>
                <span className='text-xs uppercase'>USD Amount</span>
              </div>
              <div className='flex flex-col col-span-2'>
                <span className='text-xs uppercase'>Transaction Hash</span>
              </div>
              <div className='flex flex-col col-span-1'>
                <span className='text-xs uppercase'>SAFT</span>
              </div>
              <div className='flex flex-col col-span-2'>
                <span className='text-xs uppercase'>Date</span>
              </div>
            </div>

            {legacyPurchase.purchases?.map((purchase, i) => (
              <div
                className={
                  'grid grid-cols-2 md:grid-cols-4 xl:grid-cols-12 gap-x-12 gap-y-4'
                }
                key={i}
              >
                <div className='flex col-span-2 items-center mt-2 tracking-wide '>
                  <span className='text-sm'>
                    {getTruncatedAddress(purchase.walletAddress) || '-'}
                  </span>
                  {purchase.walletAddress ? (
                    <div
                      className='high-contrast ml-1 cursor-pointer'
                      onClick={() =>
                        copyToClipboard(purchase.walletAddress ?? '')
                      }
                    >
                      <AiOutlineCopy size={16} />
                    </div>
                  ) : null}
                </div>
                <div className='flex flex-col col-span-1 mt-2'>
                  <span className='text-sm uppercase'>
                    {purchase.paymentType}
                  </span>
                </div>
                <div className='flex flex-col col-span-2 mt-2'>
                  <span className='text-sm'>
                    {formatValue(purchase.tokensPurchase, { commas: true })}
                  </span>
                </div>
                <div className='flex flex-col col-span-2 mt-2'>
                  <span className='text-sm'>
                    {formatValue(purchase.usdAmount, { commas: true })}
                  </span>
                </div>
                <div className='flex flex-col col-span-2 mt-2'>
                  <span className='text-sm tracking-wide'>
                    {purchase.transactionHash ? (
                      <a
                        className='flex items-center high-contrast-link '
                        target='_blank'
                        href={getBlockExplorerUrlForLegacyPurchase(
                          purchase.paymentType,
                          purchase.transactionHash,
                        )}
                      >
                        {`${getTruncatedAddress(purchase.transactionHash)}`}{' '}
                        <BiLinkExternal className='ml-2' size={14} />
                      </a>
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
                <div className='flex flex-col col-span-1 mt-2'>
                  <span className='text-sm'>
                    {purchase.hasSaft ? (
                      <a
                        className='high-contrast-link font-bold'
                        onClick={() =>
                          viewLegacyDocumentVersion(purchase.id, token)
                        }
                        target='_blank'
                        rel='noreferrer'
                      >
                        <BiLinkExternal size={14} />
                      </a>
                    ) : (
                      '-'
                    )}
                  </span>
                </div>
                <div className='flex flex-col col-span-2 mt-2'>
                  <span className='text-sm'>
                    {utcToLocalDate(purchase.createdAt)}
                  </span>
                </div>
              </div>
            ))}
          </Card>
        )
      })}
    </div>
  )
}
