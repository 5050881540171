import { Col, Text } from '@components'
import classNames from 'classnames'
import './loading-indicator.css'

interface LoadingIndicatorProps {
  id?: string
  text?: string
  className?: string
  color?: string
  fullScreen?: boolean
}

export const LoadingIndicator = ({
  id,
  text,
  className,
  color,
  fullScreen = false,
}: LoadingIndicatorProps) => {
  const key = id || 'loading-indicator'

  return (
    <Col
      data-testid={key}
      gap={2}
      place='center'
      className={classNames(
        'loading-indicator',
        text ? 'pt-8' : '',
        fullScreen ? 'h-full w-full' : '',
        className,
      )}
    >
      <div
        data-testid={`${key}-loader`}
        className='pulse-loader mx-auto'
        style={color ? { backgroundColor: color } : {}}
      ></div>
      {text && (
        <Text
          textAlign='center'
          style={color ? { color: color } : {}}
          data-testid={`${key}-text`}
        >
          {text}
        </Text>
      )}
    </Col>
  )
}
