import { useUploadToS3 } from '@apiServices'
import { Button, Text, UploadIcon } from '@components'
import { useToast } from '@contexts'
import { FileUploadResult } from '@customTypes'
import { useRef } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

interface FileUploadButtonProps {
  onClick?: () => void
  disabled?: boolean
  maxSize?: number
  fileTypes?: string
  onFileUploaded?: (result: FileUploadResult) => void
  className?: string
  children?: React.ReactNode
}

export const FileUploadButton = ({
  onClick,
  disabled = false,
  maxSize = 2097152,
  fileTypes = '.pdf',
  onFileUploaded = () => {},
  className = '',
  children,
  ...restProps
}: FileUploadButtonProps) => {
  const { showErrorToast } = useToast()
  const { mutate: uploadToS3, isPending } = useUploadToS3()
  const hiddenFileInput = useRef<Maybe<any>>(null)

  const handleOnClick = (e: any) => {
    if (e) {
      e.preventDefault()
    }
    hiddenFileInput.current.click()
    onClick && onClick()
  }

  const handleChange = (event: any) => {
    const file: File = event.target.files[0]

    if (file.size > maxSize) {
      hiddenFileInput.current.value = null
      showErrorToast({ description: 'File size is too large' })
      return
    }

    uploadToS3(
      {
        file: file,
        isPublicResource: false,
      },
      {
        onSuccess: (result: UploadToS3Result) => {
          hiddenFileInput.current.value = null

          const fileUploadResult: FileUploadResult = {
            filename: file.name,
            contentType: file.type,
            httpsUrl: result.fileUrl as HttpsUrl,
            s3Uri: result.s3Uri as S3Uri,
            file,
          }

          onFileUploaded(fileUploadResult)
        },
        onError: (error) => {
          hiddenFileInput.current.value = null
          showErrorToast({ description: error.message })
        },
      },
    )
  }

  return (
    <>
      <Button
        fill='outline'
        className={className}
        onClick={handleOnClick}
        disabled={isPending || disabled}
        {...restProps}
      >
        {isPending ? (
          <>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <Text>Uploading</Text>
          </>
        ) : children ? (
          children
        ) : (
          <>
            <UploadIcon />
            <Text>Upload File</Text>
          </>
        )}
      </Button>
      <input
        data-testid={'file-upload-button-file-input'}
        type='file'
        accept={fileTypes}
        onChange={handleChange}
        ref={hiddenFileInput}
        style={{ display: 'none' }} // Make the file input element invisible
      />
    </>
  )
}
