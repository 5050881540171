// New hook: useGetPaymentMethodBalances.ts
import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { useBalance, useAccount as useWagmiAccount } from 'wagmi'
import { useGetERC20Balances } from './use-get-erc20-balances'

export interface SaleSubgraphPaymentMethod {
  token: Maybe<EvmAddress>
  oracle: EvmAddress
  native: boolean
  decimals: number
  symbol: TokenSymbol
}

export const useGetPaymentMethodBalances = (
  paymentMethods: SaleSubgraphPaymentMethod[],
) => {
  const { address: userWalletAddress } = useWagmiAccount()

  // Separate native vs. ERC20 methods
  const nativeMethod = paymentMethods.find((m) => m.native)
  const erc20Methods = paymentMethods.filter((m) => !m.native)

  // Fetch native balance if applicable
  const {
    data: nativeData,
    isLoading: isNativeLoading,
    isError: isNativeError,
    error: nativeError,
  } = useBalance({
    address: userWalletAddress,
    // Only fetch if a native method exists
    query: { enabled: !!nativeMethod },
  })

  // Must use TypeGuard to convince TS that token is not null
  const isNotNull = <T>(item: Maybe<T>): item is T => item !== null
  const nonNativeTokenAddresses: EvmAddress[] = erc20Methods
    .map((pm) => pm.token)
    .filter(isNotNull)

  // Fetch ERC20 balances for all non-native methods
  const {
    erc20Balances,
    isLoading: isERC20Loading,
    isError: isERC20Error,
    error: erc20Error,
  } = useGetERC20Balances(nonNativeTokenAddresses)

  // Combine them into a single array
  const allBalances: CurrencyValue[] = useMemo(() => {
    const balances: Array<{ symbol: TokenSymbol; value: BigNumber }> = []

    if (nativeMethod && nativeData) {
      const raw = new BigNumber(nativeData.value.toString())
      const scaled = raw.div(new BigNumber(10).pow(nativeData.decimals))
      balances.push({ symbol: nativeMethod.symbol, value: scaled })
    }

    erc20Balances.forEach(({ symbol, value }) => {
      balances.push({ symbol, value })
    })

    return balances
  }, [nativeMethod, nativeData, erc20Balances])

  return {
    balances: allBalances,
    isLoading: isNativeLoading || isERC20Loading,
    isError: isNativeError || isERC20Error,
    error: nativeError || erc20Error,
  } as const
}
