import { InfoIcon, Text } from '@components'
import { CheckboxInput, LoaderButton, TextInput } from '@newComponents'
import { useCallback, useEffect, useState } from 'react'
import { EligibilityItemStatus } from './eligibility-item-status'
import { EventModal } from './event-modal'
import { useForm } from 'react-hook-form'
import {
  useMyEventUser,
  useUpdateEventUserBeneficiaryWallet,
} from '@api/services/event-service'
import { TSEvent } from 'tokensoft-shared-types'
import { isAddress } from 'viem'
import { useTSAddress } from '@hooks'
import { useGetDistributorByContractAddress } from '@api/services'

interface BeneficiaryWalletEligibilityStatusProps {
  event: TSEvent
  highlightColor: HexColorCode
  hasMetRequirement: boolean
  onMetRequirement: () => void
}

export type BeneficiaryWalletModalState = {
  beneficiaryWallet: string
  confirmBeneficiaryWallet: string
  ownershipCertification: boolean
  accuracyAttestation: boolean
}

export const BeneficiaryWalletEligibilityStatus = ({
  event,
  highlightColor,
  hasMetRequirement,
  onMetRequirement,
}: BeneficiaryWalletEligibilityStatusProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const eventUser = useMyEventUser(event.id)
  const {
    isPending: isConfirming,
    mutate: updateBeneficiaryWalletByEventIdAndUserId,
    isSuccess,
  } = useUpdateEventUserBeneficiaryWallet()
  const { address } = useTSAddress()

  const { data: distributor } = useGetDistributorByContractAddress(
    event.distributorAddress as `0x${string}`,
  )

  const openModal = useCallback(() => {
    setIsModalOpen(true)
  }, [setIsModalOpen])

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [setIsModalOpen])

  useEffect(() => {
    if (isSuccess) {
      closeModal()
    }
  }, [isSuccess, closeModal])

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    setValue,
  } = useForm<BeneficiaryWalletModalState>({
    mode: 'onTouched',
    defaultValues: {
      beneficiaryWallet: eventUser.data?.beneficiary_wallet ?? '',
      confirmBeneficiaryWallet: eventUser.data?.beneficiary_wallet ?? '',
      ownershipCertification: false,
      accuracyAttestation: false,
    },
  })

  const formValues = watch()

  const onSubmit = useCallback(
    async (details: BeneficiaryWalletModalState) => {
      updateBeneficiaryWalletByEventIdAndUserId({
        event,
        user: { id: 'me' },
        beneficiaryWallet: details.beneficiaryWallet,
      })
    },
    [event, updateBeneficiaryWalletByEventIdAndUserId],
  )

  useEffect(() => {
    void trigger()
  }, [
    trigger,
    formValues.accuracyAttestation,
    formValues.beneficiaryWallet,
    formValues.confirmBeneficiaryWallet,
    formValues.ownershipCertification,
  ])

  const useAccountAddress = useCallback(() => {
    if (address !== undefined) {
      setValue('beneficiaryWallet', address, { shouldTouch: true })
      setValue('confirmBeneficiaryWallet', address)
    }
  }, [setValue, address])

  useEffect(() => {
    if (eventUser.data?.beneficiary_wallet != null) {
      onMetRequirement()
    }
  }, [eventUser.data?.beneficiary_wallet, onMetRequirement])

  return (
    <>
      <EventModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel='Confirm your Beneficiary Wallet'
      >
        {/* TODO: use a <Form> or <form> to allow users to use the enter key to submit */}
        <div className='p-4 flex flex-col items-center gap-4'>
          <Text className='text-2xl font-bold text-gray-700'>
            Confirm Beneficiary Wallet
          </Text>
          <Text className='text-md font-medium text-gray-700'>
            Please confirm where you would like your {distributor?.token.symbol}{' '}
            to be received.
          </Text>

          <Text className='text-md text-[#667085] bg-[#EFF3FF] flex gap-[8px] p-2 rounded'>
            <div className='shrink-0'>
              <InfoIcon fill='#1051D0' />
            </div>
            Please confirm by reading every letter of the address from the
            application interface of your beneficiary wallet and ensuring that
            it matches.
          </Text>

          <button
            className='rounded-full bg-gray-100 cursor-pointer hover:opacity-90 active:opacity-50 px-3 py-2'
            onClick={useAccountAddress}
          >
            Use My Account Address
          </button>

          <TextInput
            label='Beneficiary Wallet'
            id='beneficiaryWallet'
            testId='beneficiaryWalletInput'
            placeholder='0x...'
            validate={(value) => {
              if (value === undefined) {
                return 'Beneficiary Wallet is required'
              }

              if (!isAddress(value)) {
                return 'Wallet must be a valid EVM wallet address'
              }

              if (value !== formValues.confirmBeneficiaryWallet) {
                return 'Beneficiary Wallet must match Confirm Beneficiary Wallet'
              }

              return true
            }}
            register={register}
            error={errors.beneficiaryWallet?.message}
            className='w-full'
            inputClassName='font-mono'
          />

          <TextInput
            label='Confirm Beneficiary Wallet'
            id='confirmBeneficiaryWallet'
            testId='confirmBeneficiaryWalletInput'
            placeholder='0x...'
            validate={(value) => {
              if (value === undefined) {
                return 'Confirm Beneficiary Wallet is required'
              }

              if (!isAddress(value)) {
                return 'Wallet must be a valid EVM wallet address'
              }

              if (value !== formValues.beneficiaryWallet) {
                return 'Confirm Beneficiary Wallet must match Beneficiary Wallet'
              }

              return true
            }}
            register={register}
            error={errors.confirmBeneficiaryWallet?.message}
            className='w-full'
            inputClassName='font-mono'
          />

          <CheckboxInput
            className='mt-5 cursor-pointer max-w-[fit-content]'
            id='ownershipCertification'
            data-testid='ownershipCertification'
            register={register}
            validate={(value) => value === true || `You must certify`}
            label='I hereby certify that I directly and wholly own the Autonomys-compliant address listed above.'
            error={errors.ownershipCertification?.message}
          />

          {/* TODO: pull an entity name from a config instead of hard-coding Autonomys */}
          <CheckboxInput
            className='mt-5 cursor-pointer max-w-[fit-content]'
            id='accuracyAttestation'
            data-testid='accuracyAttestation'
            register={register}
            validate={(value) => value === true || `You must attest`}
            label='I hereby attest to the accuracy and suitability of the wallet address provided above and release Autonomys from all liability arising from any loss, delay, or other undesired result resulting from inaccurate or incomplete address information above.'
            error={errors.accuracyAttestation?.message}
          />

          <LoaderButton
            disabled={!isValid}
            data-testid='confirmBeneficiaryWalletButton'
            onClick={handleSubmit(onSubmit) as () => void}
            isLoading={isConfirming}
            loadingText='Confirming...'
            text='Confirm'
          />
        </div>
      </EventModal>

      <EligibilityItemStatus
        testId='confirmBeneficiaryWallet'
        onClick={openModal}
        title='Confirm Beneficiary Wallet'
        progressStatus={hasMetRequirement ? 'completed' : 'incomplete'}
        highlightColor={highlightColor}
      />
    </>
  )
}
