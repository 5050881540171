import { useUpdateEvent } from '@apiServices'
import {
  BackButton,
  ButtonRow,
  EventPageSetupGeneral,
  PageSubtitle,
  PageTitle,
  PreviewPage,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
} from '@components'
import { useAccount, useEvent, useToast, WizardContext } from '@contexts'
import { EVENT_TYPE } from '@enums'
import { localToUtcDateTime, utcToEditableDateTime } from '@utils'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'

export const EditEventGeneralWizardPage = () => {
  const { account } = useAccount()
  const navigate = useNavigate()
  const { event } = useEvent()
  const { showErrorToast, showSuccessToast } = useToast()

  const { mutate: update, isPending: updateLoading } = useUpdateEvent()

  const [wizardState, setWizardState] = useState<any>({
    eventPageGeneralFormValid: false,
    eventId: event?.id,
    projectId: event?.project?.id,
    name: event?.name,
    description: event?.description,
    startTime: event?.registrationStartTime
      ? utcToEditableDateTime(event.registrationStartTime, account?.timezone)
      : null,
    endTime: event?.registrationEndTime
      ? utcToEditableDateTime(event.registrationEndTime, account?.timezone)
      : null,
    alwaysOpen: !!event?.endTime,
    eventType: event?.type,
    useExperimentalContractFeatures: event?.useExperimentalContractFeatures,
    useCustomBeneficiary: event?.useCustomBeneficiary,
  })

  const renderPreviewContent = () => {
    return <PreviewPage context={wizardState} />
  }

  const goBack = () => {
    navigate(-1)
  }

  const handleSave = () => {
    let payload: any = {
      id: event.id,
      name: wizardState.name,
      description: wizardState.description,
      registrationStartTime: localToUtcDateTime(
        wizardState.startTime,
        account?.timezone,
      ),
      registrationEndTime: wizardState.endTime
        ? localToUtcDateTime(wizardState.endTime, account?.timezone)
        : null,
      useExperimentalContractFeatures:
        wizardState.useExperimentalContractFeatures,
      useCustomBeneficiary: wizardState.useCustomBeneficiary,
    }

    // keep event start/end time in sync with registration dates
    if (event.type === EVENT_TYPE.IDENTITY) {
      payload = {
        ...payload,
        startTime: localToUtcDateTime(wizardState.startTime, account?.timezone),
        endTime: wizardState.endTime
          ? localToUtcDateTime(wizardState.endTime, account?.timezone)
          : null,
      }
    }

    update(payload, {
      onSuccess: (data) => {
        showSuccessToast({ description: 'Successfully updated event.' })
        goBack()
      },
      onError: (error) => {
        showErrorToast({ description: error.message })
      },
    })
  }

  const handleCancel = () => {
    goBack()
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>General Information</PageTitle>
            <PageSubtitle>
              Let’s start with some general info about your event. Please fill
              out your event title, subtitle and pick a start date for your open
              registration period. This information will be part of your
              end-user facing event.
            </PageSubtitle>
          </StepHeader>

          <EventPageSetupGeneral
            editMode
            wizardState={wizardState}
            updateWizardState={setWizardState}
          />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateLoading}
              />
              <SaveButton
                disabled={
                  !wizardState?.eventPageGeneralFormValid || updateLoading
                }
                saving={updateLoading}
                onClick={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  )
}
