import {
  Card,
  Col,
  HelpText,
  NetworkIcon,
  Row,
  SalePaymentConfigSummary,
  SaleSetupSummary,
  Section,
  SectionTitle,
  Stacked,
} from '@components'
import { useAuth } from '@contexts'
import { getTruncatedAddress } from '@utils'

interface SaleDeployProps {
  context: any
  setContext: Function
}

export const SaleDeploy: React.FC<SaleDeployProps> = ({
  context,
  setContext,
}) => {
  const {
    user: { walletAddress },
  } = useAuth()
  const formattedWalletAddress = getTruncatedAddress(walletAddress)

  const key = 'sale-deploy'

  return (
    <Stacked data-testid={`${key}`}>
      <Section>
        <Card id={'sale-token-info-card'}>
          <Col gap={2.5}>
            <Row place={'between-center'} gap={5}>
              <SectionTitle data-testid={'sale-token-info-card-title'}>
                {context?.tokenName} ({context?.tokenSymbol})
              </SectionTitle>
              <NetworkIcon
                data-testid={`sale-token-info-card-icon`}
                chainId={context?.networkId}
                logoSize={'sm'}
                width={'fit'}
                place={'end-center'}
              />
            </Row>
          </Col>
        </Card>
      </Section>

      <SaleSetupSummary context={context}></SaleSetupSummary>

      <SalePaymentConfigSummary context={context}></SalePaymentConfigSummary>

      <HelpText data-testid={`${key}-help-text`}>
        The address ({formattedWalletAddress}) you use to launch the sale will
        own the new sale contract. You can adjust sale settings after launch.
      </HelpText>
    </Stacked>
  )
}
