export enum LAYOUT_TYPE {
  TITLE_ICON_50_50 = 'title_icon_50_50',
  TITLE_ICON_50_50_REVERSED = 'title_icon_reversed_50_50',
  ICON_100 = 'icon_100',
  TITLE_100 = 'title_100',
  TITLE_ICON_CENTERED = 'title_icon_centered',
  TITLE_ONLY_100_CENTERED = 'title_only_100_centered',
  TITLE_ONLY_100_LEFT = 'title_only_100_left',
  TITLE_ONLY_100_RIGHT = 'title_only_100_right',
}
