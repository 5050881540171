import {
  Card,
  Col,
  PDFIcon,
  Row,
  Section,
  SectionTitle,
  Stacked,
  TableData,
  TableHeader,
  Text,
} from '@components'
import { DEFAULT_BLOCKED_COUNTRIES } from '@constants'
import { documentShowTypeToString, useAccount } from '@contexts'
import { EventWizardState } from '@customTypes'
import { EventPrivacyOverviewPage } from '@pages'
import {
  complianceTierToComplianceSummary,
  editableToLocalDateTime,
  walletRequirementsString,
} from '@utils'
import { AiFillEye } from 'react-icons/ai'

interface EventCreateConfirmationProps {
  wizardState: EventWizardState
}

export const EventCreateConfirmation = ({
  wizardState,
}: EventCreateConfirmationProps) => {
  const { account } = useAccount()

  const blockedCountries = [
    ...DEFAULT_BLOCKED_COUNTRIES,
    ...(wizardState.blockedCountryList ?? []),
  ]

  return (
    <Stacked>
      <Section>
        <SectionTitle>Event Details</SectionTitle>
        <Card className='mt-5'>
          <Col gap={5}>
            <Col gap={2.5}>
              <TableHeader>Start Date</TableHeader>
              {wizardState.startTime && (
                <TableData>
                  {editableToLocalDateTime(
                    wizardState.startTime,
                    account?.timezone,
                  )}
                </TableData>
              )}
            </Col>

            <Col gap={2.5}>
              <TableHeader>End Date</TableHeader>
              <TableData>
                {wizardState.endTime
                  ? editableToLocalDateTime(
                      wizardState.endTime,
                      account?.timezone,
                    )
                  : '-'}
              </TableData>
            </Col>

            <Col gap={2.5}>
              <TableHeader>Compliance Tier</TableHeader>
              {wizardState.complianceTier && (
                <TableData>
                  {complianceTierToComplianceSummary(
                    wizardState.complianceTier,
                  )}
                </TableData>
              )}
            </Col>

            <Col gap={2.5}>
              <TableHeader>Wallet Requirements</TableHeader>
              <TableData>
                {`Ethereum${wizardState.isPolkadotWalletRequired ? ', Polkadot' : ''}`}
              </TableData>
            </Col>

            <Col gap={2.5}>
              <TableHeader>Blocked Regions</TableHeader>
              <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
                {blockedCountries.map((country, i) => (
                  <div key={i} className='col-span-1'>
                    <Text className=''>{country.label}</Text>
                  </div>
                ))}
              </div>
            </Col>
          </Col>
        </Card>
      </Section>

      {wizardState?.documents?.length !== undefined &&
        wizardState.documents.length > 0 && (
          <Section>
            <SectionTitle>Event Documents</SectionTitle>
            {wizardState?.documents.map((section, i) => {
              return (
                <Row xalign={'between'} key={i} className='document-section'>
                  <Row width={'fit'} gap={2} place='center'>
                    <PDFIcon />
                    <Text>
                      {section.documentTitle} - {section.uploadedFile?.name}
                    </Text>
                  </Row>
                  <Row width={'fit'} gap={2} place='center'>
                    <span>
                      <AiFillEye fill='var(--primary)' size={22} />
                    </span>
                    {section.showDocumentTo && (
                      <Text>
                        {documentShowTypeToString(section.showDocumentTo)}
                      </Text>
                    )}
                  </Row>
                </Row>
              )
            })}
          </Section>
        )}

      {wizardState.privacyConfiguration && (
        <EventPrivacyOverviewPage
          privacyConfiguration={wizardState.privacyConfiguration}
        />
      )}
    </Stacked>
  )
}
