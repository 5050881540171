import { useGetSale } from '@apiServices'
import {
  BackButton,
  ButtonRow,
  LoadingIndicator,
  PageSubtitle,
  PageTitle,
  SaleSetup,
  SaveButton,
  Step,
  StepFooter,
  StepHeader,
  SwitchChainWarning,
} from '@components'
import {
  DEFAULT_BASE_CURRENCY,
  DEFAULT_BASE_CURRENCY_DECIMALS,
} from '@constants'
import {
  useEvent,
  useNetworks,
  useToast,
  useWallet,
  WizardContext,
} from '@contexts'
import { useUpdateSaleOnChainConfig } from '@hooks'
import { convertBaseUnitsToDecimal, getTxUrl } from '@utils'
import { useEffect, useState } from 'react'
import { VscLinkExternal } from 'react-icons/vsc'
import { useNavigate } from 'react-router-dom'
import { Wizard } from 'react-use-wizard'

export const EditSaleSetupWizardPage = () => {
  const navigate = useNavigate()
  const { event } = useEvent()
  const { connectedChainId } = useWallet()
  const { sale, loading } = useGetSale(true, event?.id)
  const { showErrorToast, showSuccessToast } = useToast()
  const { getNetworkDetails } = useNetworks()
  const [txPending, setTxPending] = useState<boolean>(false)

  const {
    error: updateSaleConfigError,
    write: sendUpdateSaleConfig,
    isLoading: updateSaleConfigLoading,
    data: updateSaleConfigReceipt,
  } = useUpdateSaleOnChainConfig()

  const [wizardState, setWizardState] = useState<any>()

  useEffect(() => {
    if (sale) {
      let currencyDecimals = DEFAULT_BASE_CURRENCY_DECIMALS
      const isNativeBaseCurrency = sale.baseCurrency !== DEFAULT_BASE_CURRENCY
      if (isNativeBaseCurrency) {
        const networkDetails = getNetworkDetails(sale.chainId)
        currencyDecimals = networkDetails.decimals
      }
      const toFixedDecimals = 6

      const fairQueueEnabled =
        sale.maxQueueTime && Number(sale.maxQueueTime > 0)

      setWizardState({
        setupFormValid: true,
        eventId: sale.event?.id,
        saleId: sale.id,
        networkId: sale.chainId,
        tokenName: sale.tokenName,
        tokenSymbol: sale.symbol,
        decimals: sale.decimals,
        recipient: sale.recipient?.id,
        owner: sale.owner?.id,
        startTime: sale.startTime,
        endTime: sale.endTime,
        price: convertBaseUnitsToDecimal(
          sale.price,
          currencyDecimals,
          toFixedDecimals,
        ),
        maxRaise: convertBaseUnitsToDecimal(
          sale.saleMaximum,
          currencyDecimals,
          toFixedDecimals,
        ),
        minPurchase: convertBaseUnitsToDecimal(
          sale.purchaseMinimum,
          currencyDecimals,
          toFixedDecimals,
        ),
        userLimit: convertBaseUnitsToDecimal(
          sale.userMaximum,
          currencyDecimals,
          toFixedDecimals,
        ),
        fairQueueEnabled,
        maxQueueIntervalLength: sale.maxQueueTime,
        maxQueueIntervalUnits: 'second',
        nativeBaseCurrency: {
          symbol: sale.baseCurrency,
          decimals: currencyDecimals,
        },
      })
    }
  }, [sale])

  useEffect(() => {
    if (updateSaleConfigReceipt) {
      if (updateSaleConfigReceipt.status === 'success') {
        setTxPending(false)
        showSuccessToast({
          description: (
            <div className='flex flex-row'>
              Successfully submitted transaction.
              <a
                target='_blank'
                rel='noreferrer'
                href={getTxUrl(
                  updateSaleConfigReceipt.transactionHash,
                  getNetworkDetails(sale.chainId),
                )}
                className='w-[30px] flex items-center justify-center text-white'
                onClick={(e) => e.stopPropagation()}
              >
                <VscLinkExternal color='white' />
              </a>
            </div>
          ),
        })
        goBack()
      }
    }
  }, [updateSaleConfigReceipt])

  useEffect(() => {
    if (updateSaleConfigError) {
      setTxPending(false)
      showErrorToast({ description: updateSaleConfigError.toString() })
    }
  }, [updateSaleConfigError])

  const goBack = () => {
    navigate(-1)
  }

  const handleSave = async () => {
    setTxPending(true)

    let maxQueueTimeSeconds = 0
    if (wizardState.maxQueueIntervalUnits === 'second') {
      maxQueueTimeSeconds = wizardState.maxQueueIntervalLength
    } else if (wizardState.maxQueueIntervalUnits === 'minute') {
      maxQueueTimeSeconds = wizardState.maxQueueIntervalLength * 60
    } else if (wizardState.maxQueueIntervalUnits === 'hour') {
      maxQueueTimeSeconds = wizardState.maxQueueIntervalLength * 3600
    }

    try {
      const newOnChainConfig = {
        recipient: wizardState.recipient,
        merkleRoot: sale.merkleRoot,
        price: wizardState.priceBaseUnits,
        saleMaximum: wizardState.maxRaiseBaseUnits,
        userMaximum: wizardState.userLimitBaseUnits,
        purchaseMinimum: wizardState.minPurchaseBaseUnits,
        startTime: sale.startTime,
        endTime: sale.endTime,
        maxQueueTime: maxQueueTimeSeconds,
        URI: sale.uris[0],
      }

      await sendUpdateSaleConfig(sale.chainId, sale.id, [newOnChainConfig])
    } catch (e: any) {
      showErrorToast({ description: e.message })
      setTxPending(false)
      console.log(e)
    }
  }

  const handleCancel = () => {
    goBack()
  }

  if (!sale || !wizardState) {
    return <LoadingIndicator text={'Loading event'} />
  }

  if (!loading && sale && sale.chainId !== connectedChainId) {
    const correctNetworkDetails = getNetworkDetails(sale.chainId)
    return (
      <SwitchChainWarning
        chainId={sale.chainId}
        subtitle={`Please connect to the ${correctNetworkDetails.name} network to continue.`}
      />
    )
  }

  return (
    <WizardContext.Provider
      value={{
        wizardState,
        setWizardState,
      }}
    >
      <Wizard>
        <Step>
          <StepHeader>
            <PageTitle>Sale Basics</PageTitle>
            <PageSubtitle>
              First we need to setup some basic details about your sale, before
              we can proceed. Please fill out the fields below to start the
              process.
            </PageSubtitle>
          </StepHeader>

          <SaleSetup context={wizardState} setContext={setWizardState} />

          <StepFooter>
            <ButtonRow place='between'>
              <BackButton
                label={'Cancel'}
                onClick={handleCancel}
                disabled={updateSaleConfigLoading}
              />
              <SaveButton
                disabled={
                  !wizardState?.setupFormValid || updateSaleConfigLoading
                }
                saving={updateSaleConfigLoading}
                onClick={handleSave}
              />
            </ButtonRow>
          </StepFooter>
        </Step>
      </Wizard>
    </WizardContext.Provider>
  )
}
