import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useState } from 'react'
import { FaShareAlt } from 'react-icons/fa' // Importing the share icon

type Props = {
  code: string
}

export const GenerateReferralLinkButton = ({ code }: Props) => {
  const [shouldShowCopied, setShouldShowCopied] = useState(false)
  const [showingCopied, setShowingCopied] = useState(false)

  useEffect(() => {
    let timeout: Maybe<NodeJS.Timeout> = null

    if (shouldShowCopied) {
      setShowingCopied(true)
      timeout = setTimeout(() => {
        setShowingCopied(false)
        setShouldShowCopied(false)
      }, 2000)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [shouldShowCopied, setShouldShowCopied])

  const copyReferralLink = async () => {
    const currentUrl = window.location.origin + window.location.pathname // Get current URL and path (without query string)
    const referralUrl = `${currentUrl}?referral_code=${code}`

    try {
      // Copy the referral URL to clipboard
      await navigator.clipboard.writeText(referralUrl)
      setShouldShowCopied(true)
    } catch (error) {
      console.error('Failed to copy referral link to clipboard', error)
    }
  }

  return (
    <div className='relative'>
      <AnimatePresence>
        {showingCopied && (
          <motion.div
            className='absolute top-[-40px] left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-2 py-1 rounded text-sm'
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{ duration: 0.3 }}
          >
            Copied!
          </motion.div>
        )}
      </AnimatePresence>

      <div
        className='flex items-center text-blue-500 cursor-pointer hover:underline'
        onClick={copyReferralLink}
      >
        <FaShareAlt className='mr-2' /> {/* Share icon with some margin */}
        <span>Share Referral Link</span>
      </div>
    </div>
  )
}
