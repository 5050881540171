import * as Sentry from '@sentry/react'
import { ReactNode } from 'react'

interface ErrorBoundaryProps {
  children: ReactNode
}

export function ErrorBoundary({ children }: ErrorBoundaryProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack }) => (
        <div>
          <h1>Something went wrong.</h1>
          <div style={{ whiteSpace: 'pre-wrap' }}>
            {error?.toString()}
            <br />
            {componentStack}
          </div>
        </div>
      )}
      beforeCapture={(scope) => {
        scope.setTag('location', 'error_boundary')
      }}
      showDialog
    >
      {children}
    </Sentry.ErrorBoundary>
  )
}
