import { useAdminGetEvent } from '@apiServices'
import { Error, LoadingIndicator } from '@components'
import { EventContext } from '@contexts'
import { Outlet, useParams } from 'react-router-dom'

export const EventProvider = () => {
  const { eventId } = useParams()
  const {
    event: event,
    loading: loading,
    refresh: refresh,
  } = useAdminGetEvent(eventId)

  const hasSetEventDetails = !!event?.name
  const isOnChainEvent = !!event?.sale?.id || !!event?.distributor?.id

  if (loading) {
    return <LoadingIndicator text={'Loading event'} />
  }

  if (!loading && !event) {
    return (
      <Error
        title='Event Not Found'
        message="It looks like this isn't a valid event."
      />
    )
  }

  return (
    <EventContext.Provider
      value={{
        event: event,
        loading: loading,
        refresh: refresh,
        hasSetEventDetails: hasSetEventDetails,
        isOnChainEvent: isOnChainEvent,
      }}
    >
      <Outlet />
    </EventContext.Provider>
  )
}
