import { Button, Text, UploadIcon } from '@components'
import { useToast } from '@contexts'
import React, { useCallback, useRef } from 'react'
import { FileThumbnail } from './file-thumbnail'

interface FileSelectorProps {
  allowedFileExtensions?: string[]
  maxFileSizeMB?: number
  onFileChange: (files: File[]) => void
  files: File[]
  uploadStyle?: 'dropzone' | 'button'
  buttonTitle?: string
  allowMultiple?: boolean
  className?: string
}

export const FileSelector = ({
  allowedFileExtensions = ['jpg', 'jpeg', 'png', 'pdf', 'txt'],
  maxFileSizeMB = 10,
  onFileChange,
  files,
  uploadStyle = 'button',
  buttonTitle,
  allowMultiple = true,
  className = '',
}: FileSelectorProps) => {
  const { showErrorToast } = useToast()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const validateFile = useCallback(
    (file: File) => {
      const extension = file.name.split('.').pop()?.toLowerCase()
      const isAllowedExtension =
        !allowedFileExtensions.length ||
        allowedFileExtensions.includes(extension || '')
      const isAllowedSize = file.size <= maxFileSizeMB * 1024 * 1024

      if (!isAllowedExtension) {
        return `Invalid file type: ${file.name}`
      }
      if (!isAllowedSize) {
        return `File too large (${file.name}) - Max size is ${maxFileSizeMB} MB`
      }
      return null
    },
    [allowedFileExtensions, maxFileSizeMB],
  )

  const handleFiles = useCallback(
    (fileList: FileList) => {
      const newFiles: File[] = []
      const newErrors: string[] = []

      Array.from(fileList).forEach((file) => {
        if (files.some((f) => f.name === file.name)) {
          showErrorToast({
            description: `File ${file.name} is already selected.`,
          })
          return
        }

        const error = validateFile(file)
        if (error) {
          newErrors.push(error)
        } else {
          newFiles.push(file)
        }
      })

      if (newFiles.length > 0) {
        onFileChange(allowMultiple ? [...files, ...newFiles] : [newFiles[0]])
      }

      if (newErrors.length > 0) {
        const message = newErrors.join(' ')
        showErrorToast({ description: message })
      }
    },
    [files, onFileChange, showErrorToast, validateFile, allowMultiple],
  )

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      handleFiles(e.target.files)
    }
  }

  const handleButtonClick = () => {
    fileInputRef.current?.click()
  }

  const handleFileRemoved = (file: File) => {
    onFileChange(files.filter((f) => f !== file))
    if (fileInputRef.current) {
      fileInputRef.current.value = '' // Reset input to allow re-uploading removed files
    }
  }

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault()
      e.stopPropagation()
      if (e.dataTransfer.files) {
        handleFiles(e.dataTransfer.files)
      }
    },
    [handleFiles],
  )

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const buttonClasses =
    'px-4 py-2 bg-primary text-white rounded cursor-pointer transition'

  return (
    <div className={className}>
      {/* Dropzone or Button Upload */}
      {uploadStyle === 'button' && (
        <Button
          type='button'
          onClick={handleButtonClick}
          className={buttonClasses}
        >
          <Text>
            {buttonTitle
              ? buttonTitle
              : allowMultiple
                ? 'Upload Files'
                : 'Upload File'}
          </Text>
          <UploadIcon />
        </Button>
      )}

      {uploadStyle === 'dropzone' && (
        <div
          onDrop={handleDrop}
          onDragOver={handleDragOver}
          className='flex flex-col gap-2 items-center border-2 border-dashed border-gray-300 p-4 rounded-lg text-center'
        >
          <p className='select-none'>
            Drag & drop files here, or click to upload
          </p>
          <Button
            size='xs'
            className='cursor-pointer text-primary'
            onClick={handleButtonClick}
          >
            Browse Files
          </Button>
        </div>
      )}

      {/* Hidden File Input */}
      <input
        id='file-upload-input'
        data-testid='file-upload-input'
        ref={fileInputRef}
        accept={allowedFileExtensions.map((ext) => `.${ext}`).join(',')}
        type='file'
        multiple={allowMultiple} // Conditionally allow multiple files
        onChange={handleFileInput}
        className='hidden'
      />

      {/* Uploaded Files */}
      <div className='mt-2 flex flex-wrap gap-4'>
        {files.map((file) => (
          <FileThumbnail
            key={file.name}
            file={file}
            onRemove={() => handleFileRemoved(file)}
          />
        ))}
      </div>
    </div>
  )
}
