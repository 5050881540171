import { FieldValues, Path } from 'react-hook-form'
import { twMerge } from 'tailwind-merge'

export const CheckboxInput = <T extends FieldValues, K extends Path<T>>({
  id,
  label,
  register,
  className,
  validate,
}: BaseInputProps<T, K>) => (
  <div className={twMerge(`flex items-start`, className)}>
    <input
      id={id as string}
      data-testid={id as string}
      type='checkbox'
      {...register(id, { validate })}
      className='mt-1 mr-3 h-4 w-4 flex-shrink-0 rounded-md leading-tight'
    />
    <label htmlFor={id as string} className='text-gray-700 cursor-pointer'>
      {label}
    </label>
  </div>
)
