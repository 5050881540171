import { Text } from '@components'
import classNames from 'classnames'

type TextProps = React.ComponentProps<typeof Text>
interface ChartLabelProps extends TextProps {
  className?: string
  children: React.ReactNode
}

export const ChartLabel: React.FC<ChartLabelProps> = ({
  className,
  children,
  ...restProps
}) => {
  return (
    <Text
      className={classNames('text-xs font-bold high-contrast', className)}
      {...restProps}
    >
      {children}
    </Text>
  )
}
