import { useGetProject, useGetProjectTheme } from '@apiServices'
import { getResourceUri } from '@utils'
import { useNavigate } from 'react-router-dom'
import './nav-header.css'

export const NavHeader = () => {
  const {
    data: project,
    isPending: isProjectPending,
    isError: isProjectError,
  } = useGetProject()

  const {
    data: projectTheme,
    isPending: isProjectThemePending,
    isError: isProjectThemeError,
  } = useGetProjectTheme()

  const navigate = useNavigate()

  if (project === undefined || projectTheme === undefined) {
    return null
  }

  const parseUrlOrIpfsUrl = (url: string): string => {
    if (url.startsWith('ipfs://')) {
      return getResourceUri(url)
    }

    return url
  }

  const logo =
    projectTheme.logo !== null ? (
      <img src={parseUrlOrIpfsUrl(projectTheme.logo)} />
    ) : (
      <span className={'text-3xl font-semibold'}>{project.name}</span>
    )

  const logoSmall =
    projectTheme.logoSmall !== null ? (
      <img src={parseUrlOrIpfsUrl(projectTheme.logoSmall)} />
    ) : (
      <span className={'text-3xl font-semibold'}>
        {project.name.charAt(0).toUpperCase()}
      </span>
    )

  return (
    <div className='nav-header'>
      <div
        className='brand-logo hover:cursor-pointer'
        onClick={() => navigate('/')}
      >
        <div className='brand-logo-abbr w-full block md:hidden'>
          {logoSmall}
        </div>
        <div className='brand-logo-full w-full hidden md:block'>{logo}</div>
      </div>
    </div>
  )
}
