export const MonthlyIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      data-testid={'monthly-icon'}
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56ZM36.2445 18.7833H37.2167C38.8247 18.7833 40.1333 20.092 40.1333 21.7V36.2833C40.1333 37.8914 38.8247 39.2 37.2167 39.2H19.7167C18.1086 39.2 16.8 37.8914 16.8 36.2833V21.7C16.8 20.092 18.1086 18.7833 19.7167 18.7833H20.6888V16.8388C20.6888 16.3023 21.1244 15.8667 21.6612 15.8667H22.6333C23.1701 15.8667 23.6055 16.3023 23.6055 16.8388V18.7833H33.3278V16.8388C33.3278 16.3023 33.7633 15.8667 34.3 15.8667H35.2722C35.8089 15.8667 36.2445 16.3023 36.2445 16.8388V18.7833ZM37.2167 37.2555C37.7523 37.2555 38.1888 36.819 38.1888 36.2833V25.6278H18.7445V36.2833C18.7445 36.819 19.181 37.2555 19.7167 37.2555H37.2167Z'
        fill={fill}
      />
    </svg>
  )
}
