import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'

export const useGetJoinStatusForEvent = (eventId: ID) => {
  const client = useWebApiClient()

  return useQuery<boolean>({
    queryKey: ['event', eventId, 'join-status'],
    queryFn: async () => {
      const result = await client(`events/${eventId}/join-status`)
      return result.hasJoinedEvent === true
    },
  })
}
