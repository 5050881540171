import { useGetAccount } from '@api/services'
import {
  CheckboxInput,
  Col,
  HelpPopover,
  InfoIcon,
  MultiSelect,
  Row,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components'
import { DEFAULT_BLOCKED_COUNTRIES } from '@constants'
import { CountryList, EventWizardState, FileUploadResult } from '@customTypes'
import { COMPLIANCE_TIER, EVENT_TYPE } from '@enums'
import { useValidatePolkadotWalletsFile } from '@hooks'
import { useEffect, useState } from 'react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { PolkadotWalletFileUpload } from './polkadot-wallet-file-upload'

interface EventEligibilitySetupProps {
  wizardState: EventWizardState
  setWizardState: (state: EventWizardState) => void
}

export const EventEligibilitySetup = ({
  wizardState,
  setWizardState,
}: EventEligibilitySetupProps) => {
  const {
    account,
  }: {
    account: {
      configuration: { features: { name: string; enabled: boolean }[] }
    } | null
  } = useGetAccount()

  const canAlwaysCreateEventsWithoutIdentityVerification =
    account?.configuration?.features?.some((feature) => {
      return (
        feature.name ===
          'CAN_ALWAYS_CREATE_EVENTS_WITHOUT_IDENTITY_VERIFICATION' &&
        feature.enabled
      )
    })

  const [polkadotWalletUserIdentifier, setPolkadotWalletUserIdentifier] =
    useState(
      wizardState.polkadotWalletConfiguration?.polkadotWalletUserIdentifier ??
        'email',
    )

  const {
    isPending: isPendingPolkdadotWalletFileValidation,
    uploadedData,
    uploadFileErrorMessage,
    resetUpload,
  } = useValidatePolkadotWalletsFile({
    polkadotWalletsFileUri:
      wizardState.polkadotWalletConfiguration?.polkadotWalletsFileUri ?? null,
    polkadotWalletUserIdentifier,
  })

  useEffect(() => {
    if (wizardState.isPolkadotWalletRequired) {
      if (
        isPendingPolkdadotWalletFileValidation &&
        wizardState.eligibilityFormValid === true
      ) {
        setWizardState({
          ...wizardState,
          eligibilityFormValid: false,
        })
        return
      }

      if (
        uploadFileErrorMessage !== null &&
        wizardState.eligibilityFormValid === true
      ) {
        setWizardState({
          ...wizardState,
          eligibilityFormValid: false,
        })
        return
      }

      if (
        uploadFileErrorMessage === null &&
        wizardState.eligibilityFormValid === false &&
        !isPendingPolkdadotWalletFileValidation
      ) {
        setWizardState({
          ...wizardState,
          eligibilityFormValid: true,
        })
        return
      }
    }
  }, [
    wizardState,
    setWizardState,
    uploadFileErrorMessage,
    isPendingPolkdadotWalletFileValidation,
  ])

  const onPolkadotWalletUserIdentifierChanged = (
    newPolkadotWalletUserIdentifier: PolkadotWalletUserIdentifier,
  ) => {
    setWizardState({
      ...wizardState,
      polkadotWalletConfiguration: {
        polkadotWalletsFileUri:
          wizardState.polkadotWalletConfiguration?.polkadotWalletsFileUri ??
          null,
        polkadotWalletUserIdentifier: newPolkadotWalletUserIdentifier,
      },
    })
    setPolkadotWalletUserIdentifier(newPolkadotWalletUserIdentifier)
  }

  const resetPolkadotConfiguration = () => {
    setPolkadotWalletUserIdentifier('email')
    setWizardState({
      ...wizardState,
      polkadotWalletConfiguration: {
        polkadotWalletsFileUri: null,
        polkadotWalletUserIdentifier: 'email',
      },
    })
    // The uploadedData and uploadFileErrorMessage are managed by useValidatePolkadotWalletsFile
  }

  const onIsPolkadotWalletRequiredToggled = () => {
    const isPolkadotWalletRequired = !wizardState.isPolkadotWalletRequired
    // Toggling to public, reset visibility configuration
    if (isPolkadotWalletRequired === false) {
      resetPolkadotConfiguration()
      resetUpload()
    }

    setWizardState({
      ...wizardState,
      isPolkadotWalletRequired: isPolkadotWalletRequired,
      polkadotWalletConfiguration: isPolkadotWalletRequired
        ? {
            polkadotWalletUserIdentifier,
            polkadotWalletsFileUri:
              wizardState.polkadotWalletConfiguration?.polkadotWalletsFileUri ??
              null,
          }
        : undefined,
    })
  }

  const handleFileUploaded = async (uploadedFile: FileUploadResult) => {
    // setUploadedFileUri(uploadedFile.s3Uri)
    setWizardState({
      ...wizardState,
      polkadotWalletConfiguration: {
        polkadotWalletUserIdentifier:
          wizardState.polkadotWalletConfiguration
            ?.polkadotWalletUserIdentifier ?? 'email',
        polkadotWalletsFileUri: uploadedFile.s3Uri,
      },
    })
  }

  const handleFileRemoved = () => {
    setWizardState({
      ...wizardState,
      polkadotWalletConfiguration: {
        polkadotWalletUserIdentifier:
          wizardState.polkadotWalletConfiguration
            ?.polkadotWalletUserIdentifier ?? 'email',
        polkadotWalletsFileUri: null,
      },
    })
    resetUpload()
  }

  const handleComplianceTierChange = (tier: COMPLIANCE_TIER) => {
    setWizardState({
      ...wizardState,
      complianceTier: tier,
    })
  }

  const handleBlockCountryOnClick = (countryObj: any) => {
    const updatedAllowedCountryList = wizardState.allowedCountryList.filter(
      (country) => country.value !== countryObj.value,
    )
    const updatedBlockedCountryList = [
      ...wizardState.blockedCountryList,
      countryObj,
    ] as CountryList[]

    setWizardState({
      ...wizardState,
      allowedCountryList: updatedAllowedCountryList,
      blockedCountryList: updatedBlockedCountryList,
    })
  }

  const handleRemoveBlockCountry = (countryObj: any) => {
    const newAllowedCountryList = [
      ...wizardState.allowedCountryList,
      countryObj,
    ].sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase()),
    ) as CountryList[]

    const updatedBlockedCountryList = wizardState.blockedCountryList.filter(
      (country) => country.value !== countryObj.value,
    )

    setWizardState({
      ...wizardState,
      allowedCountryList: newAllowedCountryList,
      blockedCountryList: updatedBlockedCountryList,
    })
  }

  const sortedBlockedCountryList = (wizardState.blockedCountryList ?? [])
    .filter(
      (country) =>
        !DEFAULT_BLOCKED_COUNTRIES.some(
          (blockedCountry) => blockedCountry.value === country.value,
        ),
    )
    .sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()))

  return (
    <Stacked>
      <Section gap={2.5}>
        <SectionTitle>Compliance Tier</SectionTitle>
        <Text>Users must complete all required steps to be eligible.</Text>
        <Col className='mt-3'>
          <Row yalign={'center'}>
            <div>
              <CheckboxInput
                className='mr-2 inline'
                name='require-identity-verification'
                testId='require-identity-verification'
                checked={
                  wizardState.complianceTier ===
                    COMPLIANCE_TIER.IDENTITY_VERIFICATION ||
                  wizardState.complianceTier ===
                    COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION
                }
                onClick={(e) => {
                  handleComplianceTierChange(
                    e.target.checked
                      ? COMPLIANCE_TIER.IDENTITY_VERIFICATION
                      : COMPLIANCE_TIER.NO_VERIFICATION,
                  )
                }}
                disabled={
                  !canAlwaysCreateEventsWithoutIdentityVerification &&
                  (wizardState?.eventType === EVENT_TYPE.RAISE ||
                    wizardState?.eventType === EVENT_TYPE.SALE)
                }
              >
                Identity Verification
              </CheckboxInput>
            </div>
            <HelpPopover
              className='max-w-md'
              button={
                <div className='pb-2 block form-check-label cursor-pointer'>
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <span>
                To be eligible, each applicant must verify his or her identity
                by submitting a valid government identification document (such
                as a passport) and a live selfie video. Identity verification is
                required for sale events.
              </span>
            </HelpPopover>
          </Row>
          <div className='flex flex-row items-center'>
            <div>
              <CheckboxInput
                className='mr-2'
                name='require-accreditation-verification'
                testId='require-accreditation-verification'
                checked={
                  wizardState.complianceTier ===
                  COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION
                }
                onClick={(e) => {
                  handleComplianceTierChange(
                    e.target.checked
                      ? COMPLIANCE_TIER.ID_VERIFICATION_WITH_ACCREDITATION
                      : COMPLIANCE_TIER.IDENTITY_VERIFICATION,
                  )
                }}
              >
                Accredited Investor Verification
              </CheckboxInput>
            </div>
            <HelpPopover
              className='max-w-md'
              button={
                <div
                  className='pb-2 block form-check-label cursor-pointer'
                  onClick={() => {}}
                >
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <span>
                To be eligible, each applicant must qualify as an accredited
                investors{' '}
                <a
                  href='https://www.sec.gov/education/capitalraising/building-blocks/accredited-investor'
                  target='_blank'
                >
                  as defined by the US Securities and Exchange Commission
                </a>
                . Applicants must provide proof of a qualifying net worth,
                income, or professional membership.
              </span>
            </HelpPopover>
          </div>
        </Col>
      </Section>

      <Section gap={2.5}>
        <SectionTitle>Wallet Requirements</SectionTitle>
        <Text>
          Users must verify ownership of each of the following wallet types.
        </Text>
        <Col className='mt-3'>
          <Row yalign={'center'}>
            <div>
              <CheckboxInput
                disabled
                name=''
                className='mr-2'
                checked={true}
                onClick={() => {}}
              >
                Ethereum Wallet
              </CheckboxInput>
            </div>
            <HelpPopover
              className='max-w-md'
              button={
                <div
                  className='pb-2 block form-check-label cursor-pointer'
                  onClick={() => {}}
                >
                  <InfoIcon fill={'var(--neutral)'} />
                </div>
              }
            >
              <span>
                Sign in with Metamask, WalletConnect, or a smart contract wallet
              </span>
            </HelpPopover>
          </Row>
          <div className='flex flex-col'>
            <Row yalign={'center'}>
              <CheckboxInput
                className='mr-2'
                name=''
                checked={wizardState.isPolkadotWalletRequired}
                onClick={() => {
                  onIsPolkadotWalletRequiredToggled()
                }}
              >
                Polkadot Wallet
              </CheckboxInput>
              <HelpPopover
                className='max-w-md'
                button={
                  <div className='pb-2 block form-check-label cursor-pointer'>
                    <InfoIcon fill={'var(--neutral)'} />
                  </div>
                }
              >
                <span>{`Require the user to also verify a polkadot wallet to participate.`}</span>
              </HelpPopover>
            </Row>
            {wizardState.isPolkadotWalletRequired && (
              <PolkadotWalletFileUpload
                isPendingPolkdadotWalletFileValidation={
                  isPendingPolkdadotWalletFileValidation
                }
                onIsPolkadotWalletRequiredToggled={
                  onIsPolkadotWalletRequiredToggled
                }
                polkadotWalletUserIdentifier={polkadotWalletUserIdentifier}
                onPolkadotWalletUserIdentifierChanged={
                  onPolkadotWalletUserIdentifierChanged
                }
                onFileUploaded={(uploadResult: FileUploadResult) => {
                  void handleFileUploaded(uploadResult)
                }}
                onFileRemoved={handleFileRemoved}
                uploadedData={uploadedData}
                uploadFileErrorMessage={uploadFileErrorMessage}
              />
            )}
          </div>
        </Col>
      </Section>

      <Section gap={2.5}>
        <SectionTitle>Regional Restrictions</SectionTitle>
        <Text>
          OFAC regions are blocked by default. All other regions should be added
          in accordance with your corporate compliance manual.
        </Text>

        <Stretched gap={5} place={'between'}>
          <Col gap={2.5} className='mt-3'>
            <Text className='text-md font-semibold high-contrast'>Blocked</Text>
            <div className='grid grid-cols-2 md:grid-cols-4 gap-2'>
              {DEFAULT_BLOCKED_COUNTRIES.map((country, i) => (
                <div key={i} className='col-span-1'>
                  <span>{country.label}</span>
                </div>
              ))}
            </div>
          </Col>

          <Col gap={2.5} className='mt-3'>
            <Text className='text-md font-semibold high-contrast'>
              Additional regions to block
            </Text>
            <MultiSelect
              options={wizardState.allowedCountryList}
              defaultValue={{ value: null, label: 'Select a Region' }}
              value={{ value: null, label: 'Select a Region' }}
              onChange={(obj: any) => handleBlockCountryOnClick(obj)}
              required
              isMulti={false}
            />
          </Col>
        </Stretched>

        <div
          className={
            sortedBlockedCountryList.length === 0 ? 'hidden' : undefined
          }
        >
          <Col gap={2.5}>
            <span className='font-semibold high-contrast'>
              Added Blocked Regions
            </span>
            <div className='flex flex-col'>
              {sortedBlockedCountryList.map((country, i) => (
                <div key={i} className='flex flex-row items-center mb-2'>
                  <span
                    className='cursor-pointer'
                    onClick={() => handleRemoveBlockCountry(country)}
                  >
                    <AiFillCloseCircle />
                  </span>
                  <span className='ml-2 '>{country.label}</span>
                </div>
              ))}
            </div>
          </Col>
        </div>
      </Section>
    </Stacked>
  )
}
