import classNames from 'classnames'

type DivProps = React.HTMLAttributes<HTMLDivElement>

type TextProps = DivProps & {
  breakAnywhere?: boolean
  width?: string
  textAlign?: 'left' | 'center' | 'right'
  children?: React.ReactNode
}

export const Text: React.FC<TextProps> = ({
  className = '',
  breakAnywhere = false,
  width,
  textAlign = 'left',
  children,
  ...restProps
}) => {
  return (
    <div
      className={classNames(
        className,
        breakAnywhere ? 'break-anywhere' : '',
        textAlign ? `text-${textAlign}` : '',
        width ? `w-${width}` : '',
      )}
      {...restProps}
    >
      {children}
    </div>
  )
}
