import { useValidateSaleParticipants } from '@apiServices'
import {
  Alert,
  ButtonLink,
  Col,
  DownloadIcon,
  FileUploadButton,
  InputGroup,
  LoadingIndicator,
  RadioInput,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components'
import { useToast } from '@contexts'
import { FileUploadResult } from '@customTypes'
import { formatValue } from '@utils'
import { useEffect, useState } from 'react'

interface SaleParticipantsProps {
  context: any
  setContext: Function
}

export const SaleParticipants: React.FC<SaleParticipantsProps> = ({
  context,
  setContext,
}) => {
  const { showErrorToast } = useToast()

  const [participantsFile, setParticipantsFile] = useState<
    Maybe<FileUploadResult>
  >(context?.participantsFile || null)
  const [totalParticipants, setTotalParticipants] = useState(
    context?.totalParticipants || 0,
  )
  const [validParticipantsFile, setValidParticipantsFile] = useState(
    context?.validParticipantsFile || false,
  )
  const [errorMessage, setErrorMessage] = useState<Maybe<any>>(null)

  const [verificationPending, setVerificationPending] = useState(
    context?.verificationPending || false,
  )

  const { mutate: validateParticipants, isPending } =
    useValidateSaleParticipants()

  const formValid =
    context.includeEligibleEventUsersOnly ||
    (!context.includeEligibleEventUsersOnly && validParticipantsFile)

  const formattedTotalParticipants = formatValue(totalParticipants, {
    commas: true,
  })

  const handleUploadError = (message?: string) => {
    setParticipantsFile(null)
    setTotalParticipants(0)
    setValidParticipantsFile(false)
    setErrorMessage(message)
    setVerificationPending(false)
  }

  const handleFileUploaded = (fileUploadResult: FileUploadResult) => {
    setParticipantsFile(fileUploadResult)
    setTotalParticipants(0)
    setValidParticipantsFile(false)
    setErrorMessage(null)
    setVerificationPending(true)
  }

  useEffect(() => {
    setContext({
      ...context,
      participantsFile,
      totalParticipants,
      validParticipantsFile,
      participantsFormValid: formValid && !isPending,
    })
  }, [verificationPending, participantsFile, formValid])

  useEffect(() => {
    if (verificationPending) {
      // validate file
      validateParticipants(
        { uri: participantsFile?.s3Uri },
        {
          onSuccess: (result) => {
            if (result.valid) {
              setTotalParticipants(result.totalParticipants)
              setValidParticipantsFile(true)
              setErrorMessage(null)
              setVerificationPending(false)
            } else {
              handleUploadError(result.errorMessage)
            }
          },
          onError: (error) => {
            showErrorToast({
              description: 'Error validating file.  Please try again.',
            })
            handleUploadError()
          },
        },
      )
    }
  }, [verificationPending])

  const setIncludeEligibleEventUsersOnly = (value: any) => {
    setContext({
      ...context,
      includeEligibleEventUsersOnly: value,
    })
  }

  return (
    <>
      <Stacked data-testid={'sale-participants'}>
        <Section>
          <RadioInput
            className={'flex'}
            name='includeEligibleEventUsersOnly'
            checked={context.includeEligibleEventUsersOnly}
            onClick={() => setIncludeEligibleEventUsersOnly(true)}
          >
            <Text>Include all eligible event participants</Text>
          </RadioInput>

          <RadioInput
            className={'flex'}
            name='includeEligibleEventUsersOnly'
            checked={!context.includeEligibleEventUsersOnly}
            onClick={() => setIncludeEligibleEventUsersOnly(false)}
          >
            <Text>Upload a list of participants</Text>
          </RadioInput>
        </Section>

        {!context.includeEligibleEventUsersOnly && (
          <>
            <Section>
              <SectionTitle>Upload a participant list</SectionTitle>
              <Stretched xgap={5} ygap={5} place={'between'}>
                <Text width={'3/4'}>
                  Your CSV file must include a column labeled{' '}
                  <span className={'font-bold'}>address</span>. You are limited
                  to one file upload, any additional upload will override you
                  previous files.
                </Text>

                <ButtonLink
                  fill='outline'
                  href={
                    'https://s3.amazonaws.com/media.tokensoft.io/sample_sale_participants.csv'
                  }
                  target={'blank'}
                  data-testid={'download-template-button'}
                >
                  <Text>Download Template</Text>
                  <DownloadIcon />
                </ButtonLink>
              </Stretched>
            </Section>

            <Section>
              <Stretched xgap={2.5} ygap={0} place={'center'}>
                <InputGroup
                  label='File Name *'
                  name={'participantsFile'}
                  value={participantsFile?.filename}
                  className={'cursor-none'}
                  placeholder={'Select a File'}
                />

                <FileUploadButton
                  className={'w-full md:w-fit'}
                  fileTypes={'.csv'}
                  maxSize={10485760}
                  disabled={isPending}
                  data-testid={'upload-file-button'}
                  onFileUploaded={handleFileUploaded}
                />
              </Stretched>
            </Section>

            {isPending && (
              <Col place={'center'}>
                <LoadingIndicator
                  text={'Verifying Uploaded File'}
                  className={'no-padding'}
                />
              </Col>
            )}

            {!isPending && validParticipantsFile && (
              <Stretched place={'start'} gap={2.5}>
                <Section
                  gap={2.5}
                  width={'1/2'}
                  place={'start'}
                  data-testid={'participants-file-summary'}
                >
                  <SectionTitle data-testid={'total-participants-title'}>
                    Total Participants
                  </SectionTitle>
                  <Text data-testid={'total-participants-value'}>
                    {formattedTotalParticipants} addresses
                  </Text>
                </Section>
              </Stretched>
            )}

            {!isPending && !validParticipantsFile && errorMessage && (
              <Alert
                data-testid={'invalid-participants-file'}
                type='alert-danger'
              >
                {errorMessage}
              </Alert>
            )}
          </>
        )}
      </Stacked>
    </>
  )
}
