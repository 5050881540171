export const areFilesDifferent = (fileA: File, fileB: Maybe<File>): boolean => {
  if (fileB === null) {
    return true
  }

  return (
    fileA.name !== fileB.name ||
    fileA.size !== fileB.size ||
    fileA.type !== fileB.type ||
    fileA.lastModified !== fileB.lastModified
  )
}
