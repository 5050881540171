import { useCurrencyRegistry, usePurchaseInputs } from '@contexts'
import BigNumber from 'bignumber.js'

export const useCalculateTokenSalePurchaseFees = (
  userEnteredAmountInPaymentMethodCurrency: CurrencyValue,
): PurchaseFees => {
  const { sale, protocolFeeInBasisPoints } = usePurchaseInputs()
  const { platformFeeInUSD } = sale
  const { convert } = useCurrencyRegistry()

  const oneDollar = {
    value: new BigNumber(1),
    symbol: 'USD',
  }

  const platformFeeInETH = convert(platformFeeInUSD ?? oneDollar, 'ETH')
  const keepDecimals = 5

  const protocolFeeAsDecimal = new BigNumber(protocolFeeInBasisPoints)
    .div(10000)
    .decimalPlaces(keepDecimals)

  const protocolFeeTotalInPaymentMethodCurrency =
    userEnteredAmountInPaymentMethodCurrency.value
      .multipliedBy(protocolFeeAsDecimal)
      .decimalPlaces(keepDecimals)

  const protocolFeeInPaymentMethodCurrency: CurrencyValue = {
    value: protocolFeeTotalInPaymentMethodCurrency,
    symbol: userEnteredAmountInPaymentMethodCurrency.symbol,
  }

  return {
    protocolFeeInPaymentMethodCurrency,
    platformFeeInNativeCurrency: platformFeeInETH,
  }
}
