import { useDistributorDeployed, usePrepareDistributor } from '@apiServices'
import {
  DeployDistributorModal,
  DeployDistributorSuccessModal,
  PreparingDistributorModal,
} from '@components'
import { useModal, useNetworks, useToast } from '@contexts'
import { DEPLOY_STATUS, DEPLOY_VESTING_TYPE_OPTIONS } from '@enums'
import { formatValue, getAddressUrl } from '@utils'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface CreateDistributorModalProps {
  context?: any
  event?: any
}

export const CreateDistributorModal = ({
  context,
  event,
}: CreateDistributorModalProps) => {
  const { showErrorToast } = useToast()
  const navigate = useNavigate()
  const { closeModal } = useModal()
  const { getNetworkDetails } = useNetworks()
  const { mutate: distributorDeployed } = useDistributorDeployed()

  const [deployStatus, setDeployStatus] = useState(
    context.deployStatus || DEPLOY_STATUS.NOT_STARTED,
  )
  const [preparedData, setPreparedData] = useState<Maybe<any>>(null)
  const [receipt, setReceipt] = useState(context.deployReceipt || null)

  const { mutate: prepareDistributor } = usePrepareDistributor()

  useEffect(() => {
    prepareDistributor(
      {
        ...context,
        totalAllocations: formatValue(context.totalAllocations),
      },
      {
        onSuccess: (result) => {
          if (result.valid) {
            setPreparedData(result)
            setDeployStatus(DEPLOY_STATUS.PREPARED)
          } else {
            closeModal()
            setPreparedData(null)
            setDeployStatus(DEPLOY_STATUS.NOT_STARTED)
            showErrorToast({
              description:
                result.errorMessage ||
                'Error preparing distributor.  Please try again.',
            })
          }
        },
        onError: (error) => {
          closeModal()
          setPreparedData(null)
          setDeployStatus(DEPLOY_STATUS.NOT_STARTED)
          showErrorToast({
            description:
              error.toString() ||
              'Error preparing distributor.  Please try again.',
          })
        },
      },
    )
  }, [])

  return (
    <>
      {deployStatus === DEPLOY_STATUS.NOT_STARTED && (
        <PreparingDistributorModal />
      )}

      {deployStatus === DEPLOY_STATUS.PREPARED && (
        <DeployDistributorModal
          totalAllocations={context?.totalAllocations}
          tokenInfo={context?.tokenInfo}
          preparedData={preparedData}
          vestingType={context.vestingType}
          deployOptions={{
            useExperimentalContractFeatures:
              event.useExperimentalContractFeatures,
          }}
          onSuccess={async (receipt: any) => {
            setReceipt(receipt)

            let vestingType = context.vestingType
            if (context.vestingType === DEPLOY_VESTING_TYPE_OPTIONS.INSTANT) {
              vestingType = DEPLOY_VESTING_TYPE_OPTIONS.CONTINUOUS
            }

            await distributorDeployed({
              id: receipt.contractAddress,
              chainId: context?.networkId,
              startTime: context?.startTime,
              vestingType: vestingType.toUpperCase(),
              eventId: event.id,
            })
            setDeployStatus(DEPLOY_STATUS.DEPLOYED)
          }}
          onError={() => {
            showErrorToast({
              description: 'Error deploying distributor.  Please try again.',
            })
          }}
        />
      )}

      {deployStatus === DEPLOY_STATUS.DEPLOYED && (
        <DeployDistributorSuccessModal
          explorerUrl={getAddressUrl(
            receipt.contractAddress,
            getNetworkDetails(context.networkId),
          )}
          onFinish={() => {
            closeModal()
            navigate(
              `/admin/project/${event.project.id}/event/${event.id}/fund`,
            )
          }}
        />
      )}
    </>
  )
}
