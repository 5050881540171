import { useAccountApiClient } from '@apiClients'
import { Tier } from '@customTypes'
import { KYC_TIER_EVENT } from '@enums'

import { useMutation, useQueryClient } from '@tanstack/react-query'

interface CreateAccountKycTierEventInput {
  tier: Tier
  event: KYC_TIER_EVENT
  idvApplicantId?: string
}

export const useCreateAccountKycTierEvent = () => {
  const queryClient = useQueryClient()
  const client = useAccountApiClient()

  return useMutation({
    mutationFn: ({
      tier,
      event,
      idvApplicantId,
    }: CreateAccountKycTierEventInput) => {
      const data = {
        accountKycTier: {
          id: tier.id,
          account: {
            idvApplicantId: idvApplicantId,
          },
        },
        event,
      }

      return client(`accounts/kyc/tiers/events`, {
        method: 'post',
        data,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['account'] })
    },
  })
}
