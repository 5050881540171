import {
  EventPropertyRow,
  FormattedCurrency,
  FormattedNumber,
} from '@newComponents'
import { capitalize } from '@utils'
import BigNumber from 'bignumber.js'

type Props = {
  propertyName: 'minimum' | 'maximum'
  purchaseLimit: CurrencyValue
  assetType: SaleAssetType
  pricePerAsset: BigNumber
}

const SalePurchaseLimit = ({
  propertyName,
  purchaseLimit,
  assetType,
  pricePerAsset,
}: Props) => {
  const calculateQuantity = (purchaseAmount: CurrencyValue) => {
    return purchaseAmount.value.dividedBy(pricePerAsset)
  }

  const label = `${capitalize(propertyName)} ${assetType === 'node' ? 'Quantity' : 'Investment'}`

  const getEventPropertyRow = () => {
    switch (assetType) {
      case 'node':
        return (
          <FormattedNumber
            value={calculateQuantity(purchaseLimit)}
            displayDecimals={0}
          />
        )
      case 'token':
        return (
          <FormattedCurrency
            currencyValue={purchaseLimit}
            displayDecimals={0}
          />
        )
    }
  }

  return <EventPropertyRow label={label} value={getEventPropertyRow()} />
}

export default SalePurchaseLimit
