import {
  getProjectPrivacyDocumentsSync,
  useCreateUserPolkadotWallet,
  useGetProject,
} from '@apiServices'
import {
  Button,
  Col,
  Flex,
  LoadingIndicator,
  PolkadotWalletIcon,
  Row,
  Text,
} from '@components'
import { useAuth, useToast } from '@contexts'
import { BLOCKCHAIN_KEY } from '@enums'
import { ApiPromise, WsProvider } from '@polkadot/api'
import {
  isWeb3Injected,
  web3Accounts,
  web3Enable,
  web3FromSource,
} from '@polkadot/extension-dapp'
import type { InjectedExtension } from '@polkadot/extension-inject/types'
import { TypeRegistry } from '@polkadot/types/create'
import type { ChainProperties, ChainType } from '@polkadot/types/interfaces'
import { keyring } from '@polkadot/ui-keyring'
import { KeyringAddress } from '@polkadot/ui-keyring/types'
import { settings } from '@polkadot/ui-settings'
import { objectSpread, stringToHex } from '@polkadot/util'
import { defaults as addressDefaults } from '@polkadot/util-crypto/address/defaults'
import { getSignStatement } from '@utils'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import './add-wallet-modal.css'

interface Statics {
  api: ApiPromise
  registry: TypeRegistry
}

const statics = {
  api: undefined,
  registry: new TypeRegistry(),
} as unknown as Statics

interface InjectedAccountExt {
  address: string
  meta: {
    name: string
    source: string
    whenCreated: number
  }
}

interface ChainData {
  injectedAccounts: InjectedAccountExt[]
  properties: ChainProperties
  systemChain: string
  systemChainType: ChainType
  systemName: string
  systemVersion: string
}
const DISALLOW_EXTENSIONS: string[] = []
const DEFAULT_SS58 = statics.registry.createType('u32', addressDefaults.prefix)

export const DotAddWalletSelection = ({
  onComplete,
}: {
  onComplete: Function
}) => {
  const { user } = useAuth()
  const { data: project } = useGetProject()
  const { showErrorToast, showSuccessToast } = useToast()
  const [accounts, setAccounts] = useState<KeyringAddress[]>([])
  const [selectedAccount, setSelectedAccount] =
    useState<Maybe<KeyringAddress>>(null)
  const [error, setError] = useState<Maybe<any>>(null)
  const [properties, setProperties] = useState<Maybe<any>>(null)
  const [injectedAccounts, setInjectedAccounts] = useState<Maybe<any>>(null)
  const { mutate: createUserWallet, isPending: isLoadingCreateUserWallet } =
    useCreateUserPolkadotWallet()
  const [waitingForSignature, setWaitingForSignature] = useState<boolean>(false)
  const [isLoadingApi, setIsLoadingApi] = useState<boolean>(false)

  const issuer = project?.name || 'tokensoft'

  const handleWalletSelect = (account: any) => {
    setSelectedAccount(account)
  }

  const isKeyringLoaded = () => {
    try {
      return !!keyring.keyring
    } catch {
      return false
    }
  }

  const getInjectedAccounts = async (
    injectedPromise: Promise<InjectedExtension[]>,
  ): Promise<InjectedAccountExt[]> => {
    try {
      await injectedPromise

      const accounts = await web3Accounts()

      return accounts.map(
        ({ address, meta }, whenCreated): InjectedAccountExt => ({
          address,
          meta: objectSpread({}, meta, {
            name: `${meta.name || 'unknown'} (${
              meta.source === 'polkadot-js' ? 'extension' : meta.source
            })`,
            whenCreated,
          }),
        }),
      )
    } catch (error) {
      console.error('web3Accounts error', error)
      setError(error)

      return []
    }
  }

  const retrieve = async (
    api: ApiPromise,
    injectedPromise: Promise<InjectedExtension[]>,
  ): Promise<ChainData> => {
    const injectedAccounts = await getInjectedAccounts(injectedPromise)

    if (injectedAccounts.length === 0) {
      throw new Error('No Polkadot JS extension detected')
    }

    return {
      injectedAccounts: injectedAccounts.filter(
        ({ meta: { source } }) => !DISALLOW_EXTENSIONS.includes(source),
      ),
      // @ts-ignore
      properties: statics.registry.createType('ChainProperties', {
        // @ts-ignore
        isEthereum: api.registry.chainIsEthereum,
        ss58Format: api.registry.chainSS58,
        tokenDecimals: api.registry.chainDecimals,
        tokenSymbol: api.registry.chainTokens,
      }),
    }
  }

  const handleConfirm = async () => {
    setWaitingForSignature(true)
    // to be able to retrieve the signer interface from this account
    // we can use web3FromSource which will return an InjectedExtension type
    const injector = await web3FromSource(selectedAccount?.meta?.source ?? '')

    // this injector object has a signer and a signRaw method
    // to be able to sign raw bytes
    const signRaw = injector?.signer?.signRaw

    if (!!project && !!signRaw) {
      const documents = getProjectPrivacyDocumentsSync(`${project.id}`)
      const statement = getSignStatement(
        issuer,
        documents,
        window.location.host,
      )

      try {
        // after making sure that signRaw is defined
        // we can use it to sign our message
        const { signature } = await signRaw({
          address: selectedAccount?.address ?? '',
          data: stringToHex(statement),
          type: 'bytes',
        })

        setWaitingForSignature(false)

        createUserWallet(
          {
            userId: user.authId,
            walletAddress: selectedAccount?.address,
            signature: signature,
            message: statement,
            blockchainType: BLOCKCHAIN_KEY.POLKADOT,
          },
          {
            onSuccess: () => {
              showSuccessToast({
                description: 'Successfully added Polkadot wallet.',
              })
              onComplete && onComplete()
            },
            onError: (error) => {
              showErrorToast({
                description: 'Failed to create wallet.  Please try again.',
              })
            },
          },
        )
      } catch (e: any) {
        setWaitingForSignature(false)
        showErrorToast({ description: e.message })
      }
    }
  }

  const enabledContinue = (): boolean => {
    return isWeb3Injected && accounts.length > 0 && !!selectedAccount
  }

  useEffect(() => {
    if (!isLoadingApi && injectedAccounts?.length) {
      const chainSS58 = properties.ss58Format.unwrapOr(DEFAULT_SS58).toNumber()
      const ss58Format = settings.prefix === -1 ? chainSS58 : settings.prefix

      isKeyringLoaded() ||
        keyring.loadAll(
          {
            ss58Format,
          },
          injectedAccounts,
        )

      const _accounts = keyring.getAccounts()
      setAccounts(_accounts)
      setSelectedAccount(_accounts[0])
    }
  }, [isLoadingApi, injectedAccounts])

  const handleConnect = async () => {
    const injectedPromise = web3Enable(issuer)
    // Initialise the provider to connect to the local node - ('ws://127.0.0.1:9944');
    const provider = new WsProvider('wss://rpc.polkadot.io')

    try {
      setIsLoadingApi(true)
      // Create the API and wait until ready
      const api = await ApiPromise.create({ provider })
      const { injectedAccounts, properties: _properties } = await retrieve(
        api,
        injectedPromise,
      )
      setProperties(_properties)
      setInjectedAccounts(injectedAccounts)
    } catch (e: any) {
      setError(e.message)
    }

    setIsLoadingApi(false)
  }

  const getButtonText = () => {
    if (waitingForSignature) {
      return 'Waiting for signature...'
    }

    return isLoadingCreateUserWallet ? 'Adding...' : 'Confirm'
  }

  if (isLoadingApi) {
    return (
      <Flex place={'center'}>
        <LoadingIndicator text={'Loading Extension'} />
      </Flex>
    )
  }

  if (!accounts?.length) {
    return (
      <>
        {error ? (
          <Text>
            No Polkadot JS extension detected. Please install the Polkadot JS
            extension{' '}
            <a href='https://polkadot.js.org/extension' target='_blank'>
              here
            </a>{' '}
            and try again.
          </Text>
        ) : null}
        <Button className='w-full' onClick={handleConnect}>
          Connect to Polkadot Extension
        </Button>
      </>
    )
  }

  return (
    <>
      <Text>Please select one Polkadot account to add.</Text>
      <Col gap={2.5}>
        {accounts.map((account, i) => (
          <Row
            key={i}
            gap={4}
            nowrap
            className={classNames(
              'add-wallet-network-selection',
              selectedAccount?.address === account.address
                ? 'add-wallet-selected-network'
                : '',
            )}
            onClick={() => handleWalletSelect(account)}
          >
            <div>
              <PolkadotWalletIcon />
            </div>
            <div>
              <Text className='high-contrast'>{account.meta?.name}</Text>
              <Text className='high-contrast text-xs'>{account.address}</Text>
            </div>
          </Row>
        ))}
      </Col>
      <Button
        disabled={
          !enabledContinue() || isLoadingCreateUserWallet || waitingForSignature
        }
        className='w-full'
        onClick={() => handleConfirm()}
      >
        {getButtonText()}
      </Button>
    </>
  )
}
