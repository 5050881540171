import BigNumber from 'bignumber.js'

/**
 *
 * @param userBalances The user's wallet balances for each payment method
 * @param selectedPaymentMethodSymbol The currently selected payment method symbol
 * @param saleMinimumPurchaseQuantity The minimum purchase quantity for the sale
 * @param saleMaximumPurchaseQuantity The maximum purchase quantity for the sale
 * @param userWalletPurchaseQuantity The quantity the user has already purchased
 * @param pricePerLicense The price per license for the sale
 * @param convertCurrency A function to convert a currency value to another symbol
 * @returns A validator function for the amount input, which accepts an amount and
 * returns an error message or `true` for valid
 */
export const createAmountValidator = (
  userBalances: CurrencyValue[],
  selectedPaymentMethodSymbol: TokenSymbol,
  saleMinimumPurchaseQuantity: number,
  saleMaximumPurchaseQuantity: number,
  userWalletPurchaseQuantity: number,
  pricePerLicense: CurrencyValue,
  convertCurrency: (
    value: CurrencyValue,
    toSymbol: TokenSymbol,
  ) => Maybe<CurrencyValue>,
) => {
  return (value: string | undefined): string | true => {
    const parsedAmount = new BigNumber(value || 0)
    if (parsedAmount.isNaN() || !parsedAmount.gt(0)) {
      return 'Invalid amount.'
    }

    if (parsedAmount.gt(saleMaximumPurchaseQuantity)) {
      return `Amount is greater than the maximum purchase of ${saleMaximumPurchaseQuantity}
              license${saleMaximumPurchaseQuantity === 1 ? '' : 's'}.`
    }

    if (parsedAmount.lt(saleMinimumPurchaseQuantity)) {
      return `Amount is less than the minimum purchase of ${saleMinimumPurchaseQuantity} 
              license${saleMinimumPurchaseQuantity === 1 ? '' : 's'}.`
    }

    // If selected quantity exceeds sale maximum quantity - user purchased, return error
    const remainingPurchaseQuantityForUserWallet = Math.floor(
      saleMaximumPurchaseQuantity - userWalletPurchaseQuantity,
    )

    if (parsedAmount.gt(remainingPurchaseQuantityForUserWallet)) {
      return `Amount exceeds your remaining purchase limit of ${remainingPurchaseQuantityForUserWallet} 
              license${remainingPurchaseQuantityForUserWallet === 1 ? '' : 's'}.`
    }

    const purchaseCostInUSD = {
      value: parsedAmount.multipliedBy(pricePerLicense.value),
      symbol: 'USD',
    }

    // Validate sufficient balance
    if (userBalances && selectedPaymentMethodSymbol) {
      const balance = userBalances.find(
        (b) => b.symbol === selectedPaymentMethodSymbol,
      )

      const purchaseCostInSelectedCurrency = convertCurrency(
        purchaseCostInUSD,
        selectedPaymentMethodSymbol,
      )

      if (purchaseCostInSelectedCurrency === null) {
        return 'Invalid purchase conversion.'
      }

      if (!balance || purchaseCostInSelectedCurrency.value.gt(balance.value)) {
        return 'Insufficient wallet balance.'
      }
    }

    return true
  }
}
