import { useGetKycTierStatuses } from '@apiServices'
import { SumsubPanel } from '@components'
import { AccreditationPage, IdentityPage } from '@pages'
import { useEffect, useState } from 'react'
import { EligibilityItemStatus } from './eligibility-item-status'
import { EventModal } from './event-modal'

interface KycEligibilityStatusProps {
  kycRequirement: KycRestrictionType
  highlightColor: HexColorCode
  onMetRequirement: () => void
}

export const KycEligibilityStatus = ({
  kycRequirement,
  highlightColor,
  onMetRequirement,
}: KycEligibilityStatusProps) => {
  const requiresIdentityKyc = kycRequirement === 'kyc_required'
  const requiresAccreditedInvestor =
    kycRequirement === 'kyc_and_accredited_investor_required'

  const [
    userKycTierStatusRefetchIntervalMs,
    setUserKycTierStatusRefetchIntervalMs,
  ] = useState<number | false>(10000)

  const {
    data: userKycTierStatuses,
    isPending: isKycTierStatusesPending,
    isError: isKycTierStatusesError,
  } = useGetKycTierStatuses(userKycTierStatusRefetchIntervalMs)

  const hasMetIdentityKycRequirement = () => {
    if (!requiresIdentityKyc && !requiresAccreditedInvestor) {
      return true
    }

    // Tier 1 or tier 3 is required - identity is required.
    return (
      userKycTierStatuses?.find((status) => {
        return status.tier === 'identity' && status.status === 'completed'
      }) !== undefined
    )
  }

  const hasMetAccreditedInvestoryKycRequirement = () => {
    if (!requiresAccreditedInvestor) {
      return true
    }

    return (
      userKycTierStatuses?.find((status) => {
        return (
          status.tier === 'accredited_investor' && status.status === 'completed'
        )
      }) !== undefined
    )
  }

  const hasMetRequirements =
    hasMetIdentityKycRequirement() && hasMetAccreditedInvestoryKycRequirement()

  const [kycModalOpen, setKycModalOpen] = useState(false)
  const openKycModal = () => {
    setKycModalOpen(true)
  }

  const closeKycModal = () => {
    setKycModalOpen(false)
  }

  useEffect(() => {
    if (kycModalOpen) {
      // Increase pace a bit so if they finish, it
      // quickly picks it up.
      setUserKycTierStatusRefetchIntervalMs(4000)
    } else {
      // Reset to normal pace.
      setUserKycTierStatusRefetchIntervalMs(10000)
    }
  }, [kycModalOpen])

  const [accreditedInvestoryKycModalOpen, setAccreditedInvestoryKycModalOpen] =
    useState(false)
  const openAccreditedInvestoryKycModal = () => {
    setAccreditedInvestoryKycModalOpen(true)
  }

  const closeAccreditedInvestoryKycModal = () => {
    setAccreditedInvestoryKycModalOpen(false)
  }

  useEffect(() => {
    if (hasMetRequirements) {
      setUserKycTierStatusRefetchIntervalMs(false)
      setKycModalOpen(false)
      setSumsubOnlyModalOpen(false)
      onMetRequirement()
    }
  }, [hasMetRequirements])

  const [sumsubOnlyModalOpen, setSumsubOnlyModalOpen] = useState(false)

  const openSumsubOnlyModal = () => {
    setSumsubOnlyModalOpen(true)
  }

  const closeSumsubOnlyModal = () => {
    setSumsubOnlyModalOpen(false)
  }

  const onSumsubComplete = () => {
    closeSumsubOnlyModal()
    onMetRequirement()
  }

  const onSumsubFailed = () => {
    closeSumsubOnlyModal()
  }

  const onSumsubSubmitted = (idvApplicantId: any) => {
    console.log('Application id: ', idvApplicantId)
    closeSumsubOnlyModal()
  }

  const getProgressStatusForKycTier = (tier: string) => {
    const userKycTierStatus = userKycTierStatuses?.find(
      (status) => status.tier === tier,
    )

    if (!userKycTierStatus || userKycTierStatus.status === 'not-started') {
      return 'incomplete'
    }

    if (userKycTierStatus.status === 'completed') {
      return 'completed'
    }

    if (['started', 'pending'].includes(userKycTierStatus.status)) {
      return 'in-progress'
    }

    return 'failed'
  }

  const identityKycProgress = getProgressStatusForKycTier('identity')
  const accreditedInvestorKycProgress = getProgressStatusForKycTier(
    'accredited_investor',
  )

  const handleIdentityKycClick = () => {
    if (
      ['completed', 'failed', 'in-progress'].indexOf(identityKycProgress) > -1
    ) {
      openSumsubOnlyModal()
    } else {
      openKycModal()
    }
  }

  return (
    <>
      <EventModal
        isOpen={kycModalOpen}
        onRequestClose={closeKycModal}
        contentLabel='Identity KYC Modal'
      >
        <IdentityPage onCancel={closeKycModal} />
      </EventModal>
      <EventModal
        isOpen={accreditedInvestoryKycModalOpen}
        onRequestClose={closeAccreditedInvestoryKycModal}
        contentLabel='Accredited Investor KYC Modal'
      >
        <AccreditationPage />
      </EventModal>
      <EventModal
        isOpen={sumsubOnlyModalOpen}
        onRequestClose={closeSumsubOnlyModal}
        contentLabel='Sumsub Only Modal'
      >
        <SumsubPanel
          onComplete={onSumsubComplete}
          onFailed={onSumsubFailed}
          onSubmitted={onSumsubSubmitted}
        />
      </EventModal>
      <EligibilityItemStatus
        onClick={handleIdentityKycClick}
        title='Identity Verification'
        progressStatus={identityKycProgress}
        highlightColor={highlightColor}
      />
      {hasMetIdentityKycRequirement() && requiresAccreditedInvestor && (
        <EligibilityItemStatus
          onClick={openAccreditedInvestoryKycModal}
          title='Accredited Investor Verification'
          progressStatus={accreditedInvestorKycProgress}
          highlightColor={highlightColor}
        />
      )}
    </>
  )
}
