import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { Blockchain } from 'tokensoft-shared-types'

export const useGetChain = (chainId?: ID) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<Blockchain>({
    queryKey: ['chains', chainId],
    queryFn: async () => {
      return fetchWithClient(`chains/${chainId}`)
    },
    enabled: !!chainId,
  })
}
