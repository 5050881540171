import { useWebApiClient } from '@apiClients'
import { useMutation } from '@tanstack/react-query'

interface UserEligibilityStatus {
  isEligible: boolean
}

export const useRefreshEventUserEligibility = () => {
  const fetchWithClient = useWebApiClient()

  return useMutation<UserEligibilityStatus, Error, ID>({
    mutationFn: (eventId: ID) => {
      return fetchWithClient(`events/${eventId}/eligibility`, {
        method: 'PUT',
      })
    },
  })
}
