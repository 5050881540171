import {
  useGetEventDocuments,
  useGetEventUserDocumentsStatus,
} from '@apiServices'

export const useEventDocuments = (eventId: ID) => {
  const {
    data: eventDocuments,
    isPending: isPendingDocuments,
    error: documentsError,
  } = useGetEventDocuments(eventId)
  const {
    data: eventUserDocumentsStatus,
    isPending: isPendingUserDocumentsStatus,
    error: userDocumentsError,
  } = useGetEventUserDocumentsStatus(eventId)

  if (documentsError || userDocumentsError) {
    console.error(
      'Error fetching documents:',
      documentsError || userDocumentsError,
    )
  }

  return {
    data: {
      eventDocuments: eventDocuments ?? null,
      eventUserDocumentsStatus: eventUserDocumentsStatus ?? null,
    },
    isPending: isPendingDocuments || isPendingUserDocumentsStatus,
    error: documentsError || userDocumentsError,
  }
}
