import { IconProps } from './icon-props'

export const DropdownLargeIcon = ({
  color,
  className,
  size = 36,
}: IconProps) => {
  return (
    <div className={`${className && className}`}>
      <svg
        width={size}
        height={size}
        viewBox='0 0 36 36'
        fill='none'
        stroke={color !== undefined ? color : 'none'}
        xmlns='http://www.w3.org/2000/svg'
      >
        <rect width={size} height={size} rx={size / 2} fill='#EFF3FF' />
        <path
          d='M17.9928 20.6648C17.8373 20.6652 17.6867 20.6032 17.5669 20.4897L13.5739 16.6821C13.438 16.5529 13.3525 16.3671 13.3363 16.1658C13.3201 15.9644 13.3744 15.7639 13.4874 15.6084C13.6004 15.4529 13.7627 15.3551 13.9387 15.3365C14.1146 15.318 14.2898 15.3802 14.4258 15.5094L17.9928 18.921L21.5599 15.6313C21.628 15.568 21.7063 15.5208 21.7904 15.4923C21.8745 15.4638 21.9627 15.4545 22.0499 15.4652C22.1371 15.4758 22.2216 15.506 22.2985 15.5541C22.3755 15.6022 22.4434 15.6673 22.4983 15.7455C22.5592 15.8238 22.6054 15.9156 22.6339 16.0153C22.6624 16.115 22.6725 16.2203 22.6638 16.3247C22.655 16.429 22.6276 16.5302 22.583 16.6219C22.5385 16.7135 22.478 16.7937 22.4051 16.8573L18.4121 20.5354C18.2889 20.631 18.1413 20.6765 17.9928 20.6648Z'
          fill='black'
        />
      </svg>
    </div>
  )
}
