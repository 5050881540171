import { useOnClickOutside } from '@hooks'
import React, { HTMLAttributes, ReactElement, useRef, useState } from 'react'
import {
  Control,
  FieldValues,
  Path,
  RegisterOptions,
  useController,
} from 'react-hook-form'
import { FaChevronDown } from 'react-icons/fa'
import { twMerge } from 'tailwind-merge'

type SelectOptionProps = {
  value: string
} & HTMLAttributes<HTMLElement>

type SelectOption = {
  value: string
  label: string
}

interface SelectInputProps<T extends FieldValues, K extends Path<T>>
  extends Omit<BaseInputProps<T, K>, 'register'> {
  options: SelectOption[] | ReactElement<SelectOptionProps>[]
  control: Control<T>
  onValueChanged: (newValue: string) => void
  rules?: Partial<RegisterOptions<T, K>>
  placeholder?: string
  children?: React.ReactNode
}

export const SelectInput = <T extends FieldValues, K extends Path<T>>({
  id,
  className,
  label,
  options,
  control,
  onValueChanged,
  rules,
  placeholder = 'Select an option',
  children,
}: SelectInputProps<T, K>) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef<Maybe<HTMLDivElement>>(null)

  const {
    field,
    fieldState: { error },
  } = useController({ name: id, control, rules })

  useOnClickOutside(dropdownRef, () => setIsOpen(false))

  const handleToggle = () => setIsOpen(!isOpen)

  const handleSelect = (option: string) => {
    field.onChange(option)
    onValueChanged(option)
    setIsOpen(false)
  }

  const getSelectOptionProps = (value: string, index: number) => ({
    key: index,
    role: 'option',
    onClick: () => handleSelect(value),
    onKeyDown: () => handleSelect(value),
    'aria-selected': field.value === value,
  })

  const isReactElementArray = (
    options: SelectOption[] | ReactElement<SelectOptionProps>[],
  ): options is ReactElement<SelectOptionProps>[] => {
    return (
      Array.isArray(options) &&
      options.length > 0 &&
      React.isValidElement(options[0])
    )
  }

  const optionsAreElements = isReactElementArray(options)
  const optionClasses =
    'select-none cursor-pointer transition-colors hover:bg-slate-200 pl-3 py-2'

  return (
    <div className='w-full h-full'>
      {label && (
        <label
          className='block mb-2 text-md font-semibold'
          htmlFor={id as string}
        >
          {label}
        </label>
      )}
      <div className='relative w-full h-full' ref={dropdownRef}>
        <div
          className={twMerge(
            'flex justify-between items-center w-full h-full p-4 border rounded cursor-pointer',
            className,
          )}
          role='combobox'
          onClick={handleToggle}
          onKeyDown={handleToggle}
          tabIndex={0}
          aria-controls='options'
          aria-haspopup='listbox'
          aria-expanded={isOpen}
        >
          {optionsAreElements
            ? options.find(
                (element: ReactElement<SelectOptionProps>) =>
                  element.props.value === field.value,
              )
            : (options.find((option) => option.value === field.value)
                ?.label ?? (
                <span className='text-gray-400'>{placeholder}</span>
              ))}
          <FaChevronDown className='ml-2' size={12} />
        </div>
        {isOpen && (
          <ul
            className='absolute z-10 w-full bg-white border rounded max-h-80 overflow-auto'
            role='listbox'
          >
            {optionsAreElements
              ? options
                  // Exclude select item from list
                  .filter((element) => element.props.value !== field.value)
                  .map(
                    (
                      element: ReactElement<SelectOptionProps>,
                      index: number,
                    ) => {
                      const optionProps = getSelectOptionProps(
                        element.props.value,
                        index,
                      )

                      return React.cloneElement(element, {
                        ...optionProps,
                        className: twMerge(
                          element.props.className,
                          'box-border',
                          optionClasses,
                        ),
                      })
                    },
                  )
              : options.map((option, index) => (
                  <li
                    className={optionClasses}
                    {...getSelectOptionProps(option.value, index)}
                  >
                    {option.label}
                  </li>
                ))}
          </ul>
        )}
        {children && (
          <div className='absolute right-0 inset-y-0 flex items-center gap-2'>
            {children}
          </div>
        )}
      </div>
      <div className='relative'>
        {error && (
          <p className='absolute my-1 ml-1 text-red-500 text-xs'>
            {error.message}
          </p>
        )}
      </div>
    </div>
  )
}
