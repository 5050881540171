import { getResourceUri } from '@utils'
import React from 'react'

interface AnyImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {}

export const AnyImage = ({ ...props }: AnyImageProps) => {
  let src = props.src

  if (src && src.startsWith('ipfs://')) {
    src = getResourceUri(src)
  }

  return <img {...props} src={src} />
}
