import { Card, RadioInput } from '@components'
import { AccountDetails } from '@customTypes'
import { FC } from 'react'

type ApplicationTypePanelProps = {
  accountDetails: AccountDetails
  onChange: (details: AccountDetails) => void
}

export const ApplicationTypePanel: FC<ApplicationTypePanelProps> = ({
  accountDetails,
  onChange,
}) => {
  return (
    <div id='application-type'>
      <Card title='Application Type'>
        <p className='pb-4'>
          Are you registering for yourself or on behalf of an entity?
        </p>

        <div className='pb-4'>
          <RadioInput
            name='applicationType'
            checked={accountDetails?.investorType === 'myself'}
            onClick={() =>
              onChange({
                ...accountDetails,
                investorType: 'myself',
              })
            }
          >
            I am registering an individual account
          </RadioInput>
          <RadioInput
            name='applicationType'
            checked={accountDetails?.investorType === 'entity'}
            onClick={() =>
              onChange({
                ...accountDetails,
                investorType: 'entity',
              })
            }
          >
            I am an authorized signer registering on behalf of an entity
          </RadioInput>
        </div>
      </Card>
    </div>
  )
}
