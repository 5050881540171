import { useWebApiClient } from '@apiClients'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const usePostSignedOrReviewedDocuments = (eventId: ID) => {
  const client = useWebApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (data: { eventDocumentIds: ID[]; eventId: ID }) => {
      const { eventDocumentIds, eventId } = data
      await client(`events/${eventId}/documents/signed-or-reviewed`, {
        method: 'POST',
        data: { eventDocumentIds },
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries({
        queryKey: ['event', eventId, 'documents-status'],
      })
    },
  })
}
