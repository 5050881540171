import { useAccountApiClient } from '@apiClients'
import { useMutation } from '@tanstack/react-query'
import { AccountDocumentCreate } from 'tokensoft-shared-types'

/* Add previously uploaded docs to account. This hook is used
by useUploadKycDocs, which also handles the prerequisite file upload. */
export const useAddAccountDocuments = () => {
  const fetchWithClient = useAccountApiClient()

  return useMutation({
    mutationFn: async (data: {
      documents: AccountDocumentCreate[]
      kycTier: KycTier
      // Optional message that user can send to document reviewer
      message?: string
    }) => {
      const response = await fetchWithClient(`accounts/documents`, {
        method: 'PUT',
        data: data,
      })
      return response
    },
  })
}
