import { CountriesData } from '@customTypes'
import { COUNTRY_LIST } from '../../constants'

class CountryList {
  public data
  private labelMap
  private valueMap

  constructor() {
    this.data = COUNTRY_LIST
    this.labelMap = {} as Record<string, any>
    this.valueMap = {} as Record<string, any>

    this.data.forEach((country) => {
      this.labelMap[country.label.toLowerCase()] = country.value
      this.valueMap[country.value.toLowerCase()] = country.label
    })
  }

  getValue(label: any) {
    return this.labelMap[label.toLowerCase()]
  }

  getLabel(value: any) {
    return this.valueMap[value.toLowerCase()]
  }

  getLabels() {
    return this.data.map((country) => country.label)
  }

  getValues(): string[] {
    return this.data.map((country) => country.value)
  }

  getLabelList() {
    return this.labelMap
  }

  getValueList() {
    return this.valueMap
  }

  getData() {
    return this.data
  }

  getInverseList(input: CountriesData[]): CountriesData[] {
    if (!input || !input.length) {
      return this.data as unknown as CountriesData[]
    }
    return this.data.filter(
      ({ value: id1 }) => !input.some(({ value: id2 }) => id2 === id1),
    )
  }

  setLabel(value: string, label: string) {
    this.data.forEach((country) => {
      if (country.value === value) {
        ;(country as any).label = label
        this.valueMap[country.value.toLowerCase()] = country.label
      }
    })

    return this
  }

  setEmpty(label: any) {
    ;(this.data as any).unshift({
      value: '',
      label: label,
    })
    this.valueMap[''] = label
    this.labelMap[label] = ''

    return this
  }
}

export const countryList = () => {
  return new CountryList()
}
