import { viewDocumentVersion, viewEventUserDocumentVersion } from '@apiServices'
import { useAuth } from '@contexts'

interface DocumentProps {
  document: any
}

export const DocumentLink = ({ document }: DocumentProps) => {
  const {
    user: { token },
  } = useAuth()

  if (!document) {
    return null
  }

  const isEventUserDocumentVersion = !!document?.eventDocumentVersion?.id
  const documentName = isEventUserDocumentVersion
    ? document.eventDocumentVersion.documentVersion?.document?.name
    : document.documentVersion?.document?.name

  return (
    <a
      className='tracking-wide high-contrast-link font-semibold'
      onClick={() => {
        if (isEventUserDocumentVersion) {
          viewEventUserDocumentVersion(document.id, token)
        } else {
          viewDocumentVersion(document, token)
        }
      }}
      target='_blank'
      rel='noreferrer'
    >
      {documentName}
    </a>
  )
}
