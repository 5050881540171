export const SettingsIcon = ({}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
    >
      <path
        d='M15.8353 10.4C15.8812 10.1302 15.8812 9.81509 15.8812 9.5C15.8812 9.18491 15.8353 8.91508 15.8353 8.6L17.768 7.11508C17.9523 6.98016 17.9982 6.7552 17.8602 6.53016L16.0191 3.42497C15.927 3.2448 15.651 3.15519 15.4667 3.2448L13.1653 4.14484C12.705 3.78493 12.1531 3.4698 11.6007 3.2448L11.2785 0.859915C11.2326 0.680174 11.0483 0.5 10.8182 0.5H7.13601C6.90591 0.5 6.72162 0.680174 6.67576 0.859915L6.30762 3.2448C5.7552 3.4698 5.24907 3.78489 4.74252 4.14484L2.44119 3.2448C2.21104 3.15519 1.98089 3.2448 1.88877 3.42497L0.047698 6.5302C-0.0439899 6.7099 0.00178921 6.98016 0.139819 7.11512L2.11901 8.6C2.11901 8.91508 2.07314 9.18491 2.07314 9.5C2.07314 9.81509 2.11901 10.0849 2.11901 10.4L0.186247 11.8849C0.00191893 12.0198 -0.04386 12.2448 0.094083 12.4698L1.93515 15.575C2.02732 15.7552 2.30329 15.8448 2.48758 15.7552L4.78895 14.8552C5.2492 15.2151 5.80119 15.5302 6.35357 15.7552L6.72171 18.1401C6.76796 18.3651 6.95181 18.5 7.18196 18.5H10.8641C11.0943 18.5 11.2785 18.3198 11.3244 18.1401L11.693 15.7552C12.245 15.5302 12.7515 15.2151 13.2576 14.8552L15.559 15.7552C15.7891 15.8448 16.0192 15.7552 16.1114 15.575L17.9525 12.4698C18.0446 12.2901 17.9983 12.0198 17.8603 11.8849L15.8353 10.4ZM8.97713 12.65C7.18192 12.65 5.75528 11.2552 5.75528 9.5C5.75528 7.74478 7.18192 6.34999 8.97713 6.34999C10.7723 6.34999 12.199 7.74478 12.199 9.5C12.199 11.2552 10.7723 12.65 8.97713 12.65Z'
        fill='#0350D7'
      />
    </svg>
  )
}
