import { Stretched } from '@components'

type StretchedProps = React.ComponentProps<typeof Stretched>

type CardHeaderProps = StretchedProps & {}

export const CardHeader: React.FC<CardHeaderProps> = ({
  children,
  ...restProps
}) => {
  return (
    <Stretched className='justify-between' {...restProps}>
      {children}
    </Stretched>
  )
}
