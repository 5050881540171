export const EditIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
    >
      <path
        d='M2 16.4852H3.41421L12.7279 7.17151L11.3137 5.75729L2 15.071V16.4852ZM18 18.4852H0V14.2426L13.435 0.807546C13.8256 0.417016 14.4587 0.417016 14.8492 0.807546L17.6777 3.63597C18.0682 4.0265 18.0682 4.65966 17.6777 5.05019L6.24264 16.4852H18V18.4852ZM12.7279 4.34308L14.1421 5.75729L15.5563 4.34308L14.1421 2.92887L12.7279 4.34308Z'
        fill='currentColor'
      />
    </svg>
  )
}
