import { createContext, useContext } from 'react'

interface DeploymentContextProps {
  deployments?: any[]
  loading?: boolean
}

export const DeploymentContext =
  createContext<Maybe<DeploymentContextProps>>(null)
DeploymentContext.displayName = 'DeploymentContext'

export const useDeployment = (): DeploymentContextProps => {
  const context = useContext(DeploymentContext)

  if (!context) {
    throw new Error(
      'DeploymentContext context is undefined, please verify you are calling useDeployment() as child of a <DeploymentContext> component.',
    )
  }

  return context
}
