interface InnerHtmlWrapper {
  className?: string
  content: string
}

const styles = `
    #innerHtmlWrapper a:link {
      color: (internal value);
      text-decoration: underline;
      cursor: auto; 
    }

    #innerHtmlWrapper p {
      display: block;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
    }

    #innerHtmlWrapper blockquote {
      display: block;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 40px;
      margin-right: 40px;
    }
    
    #innerHtmlWrapper center {
      display: block;
      /* special centering to be able to emulate the html4/netscape behaviour */
      text-align: -webkit-center
    }
    
    #innerHtmlWrapper hr {
      display: block;
      overflow: hidden;
      unicode-bidi: isolate;
      margin-block-start: 0.5em;
      margin-block-end: 0.5em;
      margin-inline-start: auto;
      margin-inline-end: auto;
      border-style: inset;
      border-width: 1px
    }
    
    /* heading elements */
    
    #innerHtmlWrapper h1 {
      display: block;
      font-size: 2em;
      margin-top: 0.67em;
      margin-bottom: 0.67em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }

    #innerHtmlWrapper h2 {
      display: block;
      font-size: 1.5em;
      margin-top: 0.83em;
      margin-bottom: 0.83em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    
    #innerHtmlWrapper h3 {
      display: block;
      font-size: 1.17em;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    
    #innerHtmlWrapper h4 {
      display: block;
      margin-top: 1.33em;
      margin-bottom: 1.33em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    
    #innerHtmlWrapper h5 {
      display: block;
      font-size: .83em;
      margin-top: 1.67em;
      margin-bottom: 1.67em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    
    #innerHtmlWrapper h6 {
      display: block;
      font-size: .67em;
      margin-top: 2.33em;
      margin-bottom: 2.33em;
      margin-left: 0;
      margin-right: 0;
      font-weight: bold;
    }
    
    /* lists */
    
    #innerHtmlWrapper ul {
      display: block;
      list-style-type: disc;
      margin-top: 1em;
      margin-bottom: 1 em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
    }

    #innerHtmlWrapper ol {
      display: block;
      list-style-type: decimal;
      margin-top: 1em;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      padding-left: 40px;
    }
    
    #innerHtmlWrapper  li {
      display: list-item;
      text-align: -webkit-match-parent;
    }
    
    #innerHtmlWrapper ul ul, ol ul {
      list-style-type: circle
    }
    
    #innerHtmlWrapper ol ol ul, ol ul ul, ul ol ul, ul ul ul {
      list-style-type: square
    }
    
    #innerHtmlWrapper ol ul, ul ol, ul ul, ol ol {
      margin-block-start: 0;
      margin-block-end: 0
    }
    
    /* inline elements */
    
    #innerHtmlWrapper u, ins {
      text-decoration: underline
    }
    
    #innerHtmlWrapper strong, b {
      font-weight: bold
    }
    
    #innerHtmlWrapper i, cite, em, var, address, dfn {
      font-style: italic
    }
    
    #innerHtmlWrapper tt, code, kbd, samp {
      font-family: monospace
    }
      
    #innerHtmlWrapper big {
      font-size: larger
    }
      
    #innerHtmlWrapper small {
      font-size: smaller
    }
      
    #innerHtmlWrapper s {
      text-decoration: line-through
    }

    #innerHtmlWrapper em {
      font-style: italic;	
    }
`

const cssAppliedContent = (body: any) => `
  <div id="innerHtmlWrapper" data-testid='inner-html-content'> 
    <style scoped>
      ${styles}
    </style>
    ${body}
  <div>
`

export const InnerHtmlWrapper = ({ className, content }: InnerHtmlWrapper) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: cssAppliedContent(content) }}
    />
  )
}
