import {
  ProjectTheme,
  ProjectWelcomeContent,
  SEOContent,
} from 'tokensoft-shared-types'
import { ProjectThemeConfiguration } from './project-theme-configuration'
import { SEOConfiguration } from './seo-configuration'

interface ProjectConfigurationProps {
  projectTheme: ProjectTheme
  projectWelcomeContent: ProjectWelcomeContent
  seoContent: SEOContent
}

export const ProjectConfiguration = ({
  projectTheme,
  projectWelcomeContent,
  seoContent,
}: ProjectConfigurationProps) => {
  return (
    <>
      <SEOConfiguration seoContent={seoContent} projectTheme={projectTheme} />
      <ProjectThemeConfiguration
        projectTheme={projectTheme}
        welcomeContent={projectWelcomeContent}
      />
    </>
  )
}
