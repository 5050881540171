import { Flex } from '@components'

type FlexProps = React.ComponentProps<typeof Flex>

type ColProps = FlexProps & {}

export const Col: React.FC<ColProps> = ({ children, ...restProps }) => {
  return (
    <Flex direction={'col'} {...restProps}>
      {children}
    </Flex>
  )
}
