import { Button, Text } from '@components'
import { SelectInput } from '@newComponents'
import { useMemo } from 'react'
import {
  Control,
  FieldErrors,
  UseFormRegister,
  UseFormTrigger,
} from 'react-hook-form'
import { twMerge } from 'tailwind-merge'
import { NodePurchaseFormInputs } from './node-purchase-flow'

export interface PurchaseFormPaymentMethod {
  symbol: string
  icon: JSX.Element
}

interface NodePurchaseFormProps {
  errors: FieldErrors<NodePurchaseFormInputs>
  onMaxClick: () => void
  onMinClick?: () => void
  paymentMethods: PurchaseFormPaymentMethod[]
  register: UseFormRegister<NodePurchaseFormInputs>
  trigger: UseFormTrigger<NodePurchaseFormInputs>
  control: Control<NodePurchaseFormInputs>
  validators: FormValidators<NodePurchaseFormInputs>
  /** Optionally provide discrete amount options from which the user must select */
  discreteAmountOptions: number[]
}

export const NodePurchaseForm = ({
  onMaxClick,
  onMinClick,
  paymentMethods,
  register,
  trigger,
  control,
  validators,
  discreteAmountOptions,
}: NodePurchaseFormProps) => {
  const paymentMethodOptions = useMemo(() => {
    return paymentMethods.map((method) => (
      <PaymentMethodOption
        icon={method.icon}
        label={method.symbol}
        value={method.symbol}
      />
    ))
  }, [paymentMethods])

  return (
    <div>
      <SelectInput
        id='amount'
        label='Amount'
        options={discreteAmountOptions.map((amount) => ({
          value: `${amount}`,
          label: `${amount} License${amount > 1 ? 's' : ''}`,
        }))}
        control={control}
        onValueChanged={() => {
          trigger('amount')
        }}
        rules={{
          required: 'Amount is required',
          validate: validators.amount,
        }}
      >
        <SelectInput
          id='paymentMethod'
          className='bg-gray-200 border-none rounded-sm'
          label=''
          options={paymentMethodOptions}
          control={control}
          onValueChanged={() => trigger('paymentMethod')}
          rules={{
            required: 'Payment method is required',
            validate: validators.paymentMethod,
          }}
          disabled={paymentMethods.length === 1}
        />
      </SelectInput>
      <div className='flex justify-end items-center gap-2 mt-2'>
        {onMinClick && (
          <Button size='xs' className='rounded-none' onClick={onMinClick}>
            Min
          </Button>
        )}
        <Button size='xs' className='rounded-none' onClick={onMaxClick}>
          Max
        </Button>
      </div>
    </div>
  )
}

interface PaymentMethodOptionProps extends React.HTMLAttributes<HTMLElement> {
  icon: JSX.Element
  label: string
  value: string
}

const PaymentMethodOption = ({
  value,
  icon,
  label,
  className,
  ...htmlProps
}: PaymentMethodOptionProps) => {
  return (
    <div
      className={twMerge('flex items-center gap-2 p-2', className)}
      {...htmlProps}
    >
      {icon}
      <Text className='font-semibold text-lg'>{label ?? value}</Text>
    </div>
  )
}
