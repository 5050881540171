import classNames from 'classnames'
import { ChangeEventHandler, FC, ReactNode } from 'react'
import './checkbox-input.css'

type CheckboxInputProps = {
  testId?: string
  children?: ReactNode
  name: string
  checked?: boolean
  disabled?: boolean
  className?: string
  /** Intentionally using ChangeEventHandler here */
  onClick?: ChangeEventHandler<HTMLInputElement>
}

export const CheckboxInput: FC<CheckboxInputProps> = ({
  testId,
  children,
  name,
  checked,
  disabled,
  className,
  onClick,
}) => {
  return (
    <label
      htmlFor={name}
      className={classNames(
        'flex pb-2 w-auto',
        { 'cursor-pointer': !disabled },
        className,
      )}
    >
      <div className='mr-3'>
        {/* onChange must be set to silence warnings; we're using onClick from the Label, as for="id" doesn't work. */}
        <input
          data-testid={testId}
          className={classNames(
            'form-check-input',
            disabled ? 'cursor-default' : 'cursor-pointer',
          )}
          type='checkbox'
          id={name}
          name={name}
          checked={checked}
          disabled={disabled}
          onChange={onClick}
        />
      </div>
      <div>{children}</div>
    </label>
  )
}
