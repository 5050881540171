import { Text } from '@components'
import React from 'react'
import { EventDocument, EventUserDocumentStatus } from 'tokensoft-shared-types'

interface EventDocumentListProps {
  documents: EventDocument[]
  documentStatuses: EventUserDocumentStatus[]
}

export const EventDocumentList: React.FC<EventDocumentListProps> = ({
  documents,
  documentStatuses,
}) => {
  const formatDate = (date: Date | null) => {
    if (!date) return 'Not yet'
    return new Date(date).toLocaleString()
  }

  const getDocumentStatus = (documentId: number) => {
    return (
      documentStatuses.find(
        (status) => status.eventDocumentVersionId === documentId,
      ) || null
    )
  }

  const getStatusDate = (status: Maybe<EventUserDocumentStatus>) => {
    if (!status) return ''
    return status.signedAt
      ? formatDate(status.signedAt)
      : formatDate(status.acceptedAt)
  }

  return (
    <div className='container mx-auto p-4'>
      <Text className='text-2xl font-bold text-black mb-4'>
        Event Documents
      </Text>
      <div className='overflow-x-auto'>
        <table className='min-w-full bg-white'>
          <thead className='bg-gray-100'>
            <tr>
              <th className='py-2 px-4 text-left'>Name</th>
              <th className='py-2 px-4 text-left'>Signed/Accepted At</th>
              <th className='py-2 px-4 text-left'>Action</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc) => {
              const status = getDocumentStatus(doc.id)
              return (
                <tr key={doc.id} className='border-b'>
                  <td className='py-2 px-4'>{doc.name}</td>
                  <td className='py-2 px-4'>{getStatusDate(status)}</td>
                  <td className='py-2 px-4'>
                    <a
                      href={doc.uri}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='text-blue-500 hover:text-blue-700'
                    >
                      View
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
