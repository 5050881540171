import { removeSessionStorageKey } from '@apiClients'
import { AuthContext } from '@contexts'
import { User } from '@custom-types'
import { useSentryContext, useUserStore } from '@hooks'
import { usePrivy } from '@privy-io/react-auth'
import { FC, ReactNode } from 'react'

interface AuthProviderProps {
  children?: ReactNode
}

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useUserStore()
  const { authenticated } = usePrivy()

  // Persist user context for Sentry error tracking
  useSentryContext(user)

  const disconnect = () => {
    removeSessionStorageKey()
    setUser(null)
  }

  return (
    <>
      <AuthContext.Provider
        value={{
          // TODO: Remove the `as User` cast once the user store is properly typed
          user: user || ({} as User),
          disconnect,
          isAuthenticated: !!user && authenticated,
          authenticate: setUser,
          removeWalletAddress: (walletAddress: string) => {
            if (!user) return

            setUser({
              ...user,
              walletAddresses: user.walletAddresses.filter(
                (wa: any) => wa.walletAddress !== walletAddress,
              ),
            })
          },
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  )
}
