import {
  accountApiClient,
  eventApiClient,
  paymentApiClient,
  useEventApiClient,
} from '@apiClients'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getResourceUri } from '@utils'

export const downloadAccountDocument = async (
  accountDocumentId: number,
  token: any,
) => {
  const uri = `accounts/documents/${accountDocumentId}/download`
  const downloadResponse = await accountApiClient(uri, { token: token })

  if (downloadResponse.url) {
    window.open(downloadResponse.url, '_blank')
  }
}

export const viewDocumentVersion = async (document: any, token: any) => {
  // support for legacy documents that may not have a document version record in the db
  if (!document.documentVersion) {
    window.open(getResourceUri(document.uri), '_blank')
  }

  const uri = `document-versions/${document.documentVersion.id}/view`
  const downloadResponse = await paymentApiClient(uri, { token: token })

  if (downloadResponse.uri) {
    window.open(downloadResponse.uri, '_blank')
  }
}

export const viewComplianceDocumentVersion = async (document: any) => {
  if (!document.documentVersion) {
    window.open(getResourceUri(document.uri), '_blank')
  }

  const uri = `document-versions/${document.documentVersion.id}/compliance/view`
  const downloadResponse = await paymentApiClient(uri, {})

  if (downloadResponse.uri) {
    window.open(downloadResponse.uri, '_blank')
  }
}

export const viewLegacyDocumentVersion = async (id: any, token: any) => {
  if (!id) {
    throw new Error('id is required')
  }

  const uri = `purchases/${id}/viewLegacyAgreement`
  const downloadResponse = await paymentApiClient(uri, { token: token })

  if (downloadResponse.uri) {
    window.open(downloadResponse.uri, '_blank')
  }
}

export const viewEventUserDocumentVersion = async (id: any, token: any) => {
  const uri = `events/users/document-versions/${id}/view`
  const downloadResponse = await eventApiClient(uri, { token: token })

  if (downloadResponse.uri) {
    window.open(downloadResponse.uri, '_blank')
  }
}

export const useAcceptEventUserDocuments = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/users/document-versions/accept`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useSignEventUserDocuments = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/users/document-versions/sign`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useAcceptAndSignEventUserDocuments = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/users/document-versions/acceptAndSign`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useAcceptEventDocuments = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/document-versions/accept`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}

export const useSignEventDocuments = () => {
  const client = useEventApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: any) => {
      return client(`events/document-versions/sign`, {
        method: 'post',
        data,
      })
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['event'] }),
  })
}
