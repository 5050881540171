import BigNumber from 'bignumber.js'
import { Sale, SaleDetails, SaleRaw } from 'tokensoft-shared-types'

export function parseSale(saleRaw: SaleRaw): Sale {
  const {
    eventId,
    merkleProofUri,
    configUri,
    subgraphDetails,
    platformFeeInCents,
  } = saleRaw

  const feeBips: Maybe<BigNumber> =
    subgraphDetails.implementation.feeBips !== null
      ? new BigNumber(subgraphDetails.implementation.feeBips)
      : null

  // USD amounts are scaled by 10^8 when returned from subgraph,
  // so dividing by this factor yields the whole value in USD
  const usdScaleFactor = new BigNumber(10 ** 8)

  // If subgraphDetails or config is missing, we can't parse SaleDetails
  const partialDetails: Omit<
    SaleDetails,
    'minimumPurchaseQuantity' | 'maximumPurchaseQuantity'
  > = {
    merkleProofUriHistory: subgraphDetails.uris,
    token: {
      symbol: saleRaw.tokenSymbol,
      decimals: parseInt(saleRaw.tokenDecimals),
      /** Token price in USD */
      price: {
        value: new BigNumber(saleRaw.tokenPrice).dividedBy(usdScaleFactor),
        symbol: 'USD',
      },
      name: saleRaw.tokenName,
    },
    minimumPurchase: {
      value: new BigNumber(subgraphDetails.purchaseMinimum).dividedBy(
        usdScaleFactor,
      ),
      symbol: 'USD',
    },
    maximumPurchase: {
      value: new BigNumber(subgraphDetails.userMaximum).dividedBy(
        usdScaleFactor,
      ),
      symbol: 'USD',
    },
    saleCap: {
      value: new BigNumber(subgraphDetails.saleMaximum).dividedBy(
        usdScaleFactor,
      ),
      symbol: 'USD',
    },
    totalPurchases: {
      value: new BigNumber(subgraphDetails.purchaseTotal).dividedBy(
        usdScaleFactor,
      ),
      symbol: 'USD',
    },
    purchaseRecipientAddress: subgraphDetails.recipient.id,
    contract: {
      paymentMethods: subgraphDetails.paymentMethods,
      ownerAddress: subgraphDetails.owner.id,
      version: subgraphDetails.implementation.version,
    },
    feeBips,
    assetType: saleRaw.assetType,
  }

  const fullDetails: SaleDetails = {
    ...partialDetails,
    minimumPurchaseQuantity: partialDetails.minimumPurchase.value
      .div(partialDetails.token.price.value)
      .toNumber(),
    maximumPurchaseQuantity: partialDetails.maximumPurchase.value
      .div(partialDetails.token.price.value)
      .toNumber(),
  }

  const sale: Partial<Sale> = {
    id: saleRaw.id,
    chainId: saleRaw.chainId,
    eventId,
    merkleProofUri,
    configUri,
    platformFeeInUSD:
      platformFeeInCents !== null
        ? {
            value: new BigNumber(platformFeeInCents).dividedBy(100),
            symbol: 'USD',
          }
        : {
            // If platformFeeInCents is null, we default to 1 USD
            value: new BigNumber(1),
            symbol: 'USD',
          },
    ...fullDetails,
  }

  return sale as Sale
}
