import { Flex } from '@components'

type FlexProps = React.ComponentProps<typeof Flex>
type RowProps = FlexProps & {}

export const Row: React.FC<RowProps> = ({ children, ...restProps }) => {
  return (
    <Flex direction={'row'} {...restProps}>
      {children}
    </Flex>
  )
}
