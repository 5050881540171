import {
  useNotifications,
  useRemoveAllNotifications,
  useRemoveNotification,
} from '@apiServices'
import { Badge, NotificationListItem } from '@components'
import { useToast } from '@contexts'
import { BADGE } from '@enums'
import * as Popover from '@radix-ui/react-popover'
import { FaBell } from 'react-icons/fa'
import { GoBell } from 'react-icons/go'
import './notification-popover.css'

export const NotificationPopover = () => {
  const { notifications } = useNotifications()
  const { showErrorToast } = useToast()

  const { mutateAsync: removeNotification } = useRemoveNotification()
  const { mutateAsync: removeAllNotifications } = useRemoveAllNotifications()

  const deleteNotification = async (id: string) => {
    try {
      await removeNotification(id)
    } catch (e) {
      showErrorToast({ description: 'Error clearing notification' })
    }
  }

  const deleteAllNotifications = async () => {
    try {
      await removeAllNotifications()
    } catch (e) {
      showErrorToast({ description: 'Error clearing notifications' })
    }
  }

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <div className='nav-link'>
          <div className='rounded-full w-10 h-10 bg-gray-100 flex items-center justify-center'>
            {notifications && notifications.length > 0 ? (
              <Badge
                name={BADGE.VALUE}
                className='cursor-pointer'
                value={notifications.length}
              >
                <GoBell className='w-6 h-6 [&>path]:!fill-black' />
              </Badge>
            ) : (
              <GoBell className='w-6 h-6 [&>path]:!fill-black' />
            )}
          </div>
        </div>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content className='notification-popover-panel' sideOffset={5}>
          <div className='notification-popover-container'>
            <div className='notification-items-container'>
              {notifications?.length > 0 && (
                <div
                  className='notification-clear-all'
                  onClick={deleteAllNotifications}
                >
                  Clear All
                </div>
              )}
              {notifications?.length > 0 ? (
                notifications.map((notification: any, i: number) => (
                  <NotificationListItem
                    key={i}
                    notification={notification}
                    onDelete={() => deleteNotification(notification.id)}
                  />
                ))
              ) : (
                <>
                  <div className='notification-no-records-found-container'>
                    <div className='notification-no-records-found-icon'>
                      <FaBell size={36} />
                    </div>
                    <p className='notification-no-records-found-text'>
                      No new notifications
                    </p>
                  </div>
                </>
              )}
            </div>
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
