export const PolkadotIcon = () => {
  return (
    <svg
      height='20'
      width='20'
      version='1.1'
      id='Logo'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      viewBox='0 0 1326.1 1410.3'
    >
      <ellipse fill='#E6007A' cx='663' cy='147.9' rx='254.3' ry='147.9' />
      <ellipse fill='#E6007A' cx='663' cy='1262.3' rx='254.3' ry='147.9' />
      <ellipse
        fill='#E6007A'
        transform='matrix(0.5 -0.866 0.866 0.5 -279.1512 369.5916)'
        cx='180.5'
        cy='426.5'
        rx='254.3'
        ry='148'
      />
      <ellipse
        fill='#E6007A'
        transform='matrix(0.5 -0.866 0.866 0.5 -279.1552 1483.9517)'
        cx='1145.6'
        cy='983.7'
        rx='254.3'
        ry='147.9'
      />
      <ellipse
        fill='#E6007A'
        transform='matrix(0.866 -0.5 0.5 0.866 -467.6798 222.044)'
        cx='180.5'
        cy='983.7'
        rx='148'
        ry='254.3'
      />
      <ellipse
        fill='#E6007A'
        transform='matrix(0.866 -0.5 0.5 0.866 -59.8007 629.9254)'
        cx='1145.6'
        cy='426.6'
        rx='147.9'
        ry='254.3'
      />
    </svg>
  )
}
