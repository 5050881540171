import { twMerge } from 'tailwind-merge'

interface EventPropertyRowProps {
  className?: string
  label: string
  value: React.ReactNode
  separator?: string
}

export const EventPropertyRow = ({
  className = '',
  label,
  separator,
  value,
}: EventPropertyRowProps) => (
  <div
    className={twMerge(
      `flex justify-between items-center flex-wrap gap-x-4 gap-y-2`,
      className,
    )}
  >
    <p>{label}</p>
    {separator && <span>{separator}</span>}
    {['string', 'number'].indexOf(typeof value) !== -1 ? <p>{value}</p> : value}
  </div>
)
