import { useToast } from '@contexts'
import { VESTING_TYPE } from '@enums'
import {
  getContinuousVestingMerkleABI,
  getTrancheVestingMerkleABI,
} from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Address, Hex, TransactionExecutionError } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useUpdateDistributorMerkleRoot = () => {
  const { showErrorToast } = useToast()
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { executeContractWrite } = useContractWrite()

  const getABIForVestingType = (vestingType: VESTING_TYPE) => {
    switch (vestingType) {
      case VESTING_TYPE.CONTINUOUS:
      case VESTING_TYPE.NO_VESTING:
        return getContinuousVestingMerkleABI()
      case VESTING_TYPE.TRANCHE:
        return getTrancheVestingMerkleABI()
      default:
        throw new Error('Invalid vesting type')
    }
  }

  const write = async (
    vestingType: VESTING_TYPE,
    chainId: number,
    contractAddress: Address,
    args: unknown[],
  ) => {
    setSubmitting(true)

    const abi = getABIForVestingType(vestingType)

    try {
      const writeContractResponse = await executeContractWrite({
        address: contractAddress,
        abi: abi as Abi,
        functionName: 'setMerkleRoot',
        args,
      })

      setTransactionHash(writeContractResponse)
      return writeContractResponse
    } catch (e) {
      let msg: string
      if (e instanceof TransactionExecutionError) {
        msg = e.shortMessage
      } else if (e instanceof Error) {
        msg = e.message
      } else {
        msg = 'An error occurred while updating the distributor.'
      }

      if (msg === '') {
        msg = 'An error occurred while updating the distributor.'
      }

      showErrorToast({ description: msg })
      console.error(e)
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
