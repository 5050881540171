import { Stretched } from '@components'
import classNames from 'classnames'

type StretchedProps = Parameters<typeof Stretched>[0]

interface ButtonRowProps extends StretchedProps {}

export const ButtonRow = ({
  className,
  children,
  ...restProps
}: ButtonRowProps) => {
  return (
    <Stretched
      xgap={4}
      ygap={2.5}
      className={classNames('mt-4 button-row', className)}
      {...restProps}
    >
      {children}
    </Stretched>
  )
}
