import {
  initialAccountDetailsState,
  useCreateAccountKycTierEvent,
  useCreateIdentityKycTier,
  useSetAccountDetails,
  useUpdateAccountKycTier,
  useUploadKycDocuments,
} from '@apiServices'
import {
  AdditionalInformationPanel,
  ApplicationTypePanel,
  BasicInformationPanel,
  ButtonRow,
  EntityInformationPanel,
  EntityTypePanel,
  LoadingIndicator,
  PageHeader,
  ReviewPanel,
  SumsubPanel,
} from '@components'
import { useAccount, useAnalytics, useAuth, useToast } from '@contexts'
import type { AccountDetails } from '@customTypes'
import { KYC_TIER_EVENT, TIER_ID, TIER_NAME, VERIFICATION_STATUS } from '@enums'
import { KycDocumentUpload } from '@newComponents'
import { getKycTier } from '@utils'
import { useEffect, useState } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'

enum PageState {
  FORM = 'form',
  REVIEW = 'review',
  ENTITY_DOCS = 'entity docs',
  SUMSUB = 'sumsub',
}

interface IdentityFormProps {
  onCancel?: () => void
  onSubmitted?: () => void
}

export const IdentityForm = ({ onCancel, onSubmitted }: IdentityFormProps) => {
  const { showErrorToast } = useToast()

  const navigate = useNavigate()
  const {
    user: { authId },
    isAuthenticated,
  } = useAuth()
  const { account, tier1Status, refetch } = useAccount()

  const {
    mutate: saveAccountDetails,
    error: setAccountDetailsError,
    isPending: isLoadingSetAccountDetails,
    isSuccess: isSetAccountDetailsSuccess,
  } = useSetAccountDetails(authId, account?.id)

  const {
    mutate: createIdentityKycTier,
    isPending: isLoadingCreateIdentityKycTier,
    isSuccess: isCreateIdentityKycTierSuccess,
    error: createIdentityKycTierError,
  } = useCreateIdentityKycTier()

  const {
    mutate: createAccountKycTierEvent,
    error: createAccountKycTierEventError,
  } = useCreateAccountKycTierEvent()

  const {
    mutateAsync: uploadKycDocuments,
    isPending: isLoadingUploadKycDocuments,
    isSuccess: isUploadKycDocumentsSuccess,
    error: uploadKycDocumentsError,
  } = useUploadKycDocuments('identity')

  const {
    mutate: updateAccountKycTier,
    isPending: updateAccountKycTierLoading,
    isSuccess: isUpdateAccountKycTierSuccess,
  } = useUpdateAccountKycTier()

  const [accountDetails, setAccountDetails] =
    useState<Maybe<AccountDetails>>(null)
  const [formValid, setFormValid] = useState<boolean>(false)
  const [entityFormValid, setEntityFormValid] = useState<boolean>(false)
  const [pageState, setPageState] = useState(PageState.FORM)
  const [filesForUpload, setFilesForUpload] = useState<File[]>([])
  const [notes, setNotes] = useState<string>('')

  useAnalytics('/account/identity/' + pageState.toLowerCase())

  const { id: accountKycTierId } =
    account?.kyc?.tiers.find(
      (tier) => tier.kycTier.id === TIER_ID.IDENTITY_KYC_TIER_ID,
    ) || {}

  const canReview =
    pageState === PageState.FORM &&
    formValid &&
    (accountDetails?.investorType === 'myself' ||
      (accountDetails?.investorType === 'entity' && entityFormValid))

  const canSubmit =
    pageState === PageState.REVIEW &&
    !isLoadingSetAccountDetails &&
    !isLoadingCreateIdentityKycTier &&
    !isLoadingUploadKycDocuments

  const errorMessage =
    setAccountDetailsError ||
    createIdentityKycTierError ||
    createAccountKycTierEventError ||
    uploadKycDocumentsError

  const submitProfile = () => {
    if (!accountDetails) return
    // 1) Submit account details
    saveAccountDetails(accountDetails)
  }

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/account')
    } else if (account) {
      if (account.idvApplicantId) {
        setPageState(PageState.SUMSUB)
        return
      }

      // Prefill address form if information already exists on the user
      const existingAccountDetails: any = initialAccountDetailsState(account)
      if (pageState === PageState.FORM && existingAccountDetails) {
        setAccountDetails(existingAccountDetails)
      }
    }
  }, [account, isAuthenticated])

  useEffect(() => {
    // 2) Call set kyc after account details has finished submitting
    if (isSetAccountDetailsSuccess) {
      createIdentityKycTier()
    }
  }, [isSetAccountDetailsSuccess])

  useEffect(() => {
    // 3) Show documents when both calls have been successful
    if (isSetAccountDetailsSuccess && isCreateIdentityKycTierSuccess) {
      account.investorType === 'entity'
        ? setPageState(PageState.ENTITY_DOCS)
        : setPageState(PageState.SUMSUB)
    }
  }, [isSetAccountDetailsSuccess, isCreateIdentityKycTierSuccess])

  useEffect(() => {
    if (isUploadKycDocumentsSuccess && isUpdateAccountKycTierSuccess) {
      setPageState(PageState.SUMSUB)
    }
  }, [isUpdateAccountKycTierSuccess, isUploadKycDocumentsSuccess])

  const handleSumsubFailed = () => {
    refetch()
    navigate('failed')
  }

  const handleSumsubSubmitted = (idvApplicantId: string) => {
    if (account?.kyc?.tiers === undefined) {
      return
    }
    const kycTier = getKycTier(account?.kyc?.tiers, TIER_NAME.IDENTITY)
    if (!kycTier) {
      showErrorToast({ description: 'Account KYC tier not found' })
      return
    }
    createAccountKycTierEvent({
      tier: kycTier,
      event: KYC_TIER_EVENT.INITIAL_CHECK,
      idvApplicantId: idvApplicantId,
    })
    refetch()
    navigate('submitted')
  }

  const handleSumsubComplete = () => {
    refetch()
    if (tier1Status === VERIFICATION_STATUS.PENDING) {
      navigate('pending')
    } else if (tier1Status === VERIFICATION_STATUS.FAILED) {
      navigate('failed')
    } else if (tier1Status === VERIFICATION_STATUS.COMPLETED) {
      navigate('completed')
    }
  }

  const handleEntityDocsSubmit = async () => {
    const documentIds = await uploadKycDocuments({
      files: filesForUpload,
      message: notes || 'Entity documentation submitted for review',
    })

    await updateAccountKycTier({
      accountKycTierId,
      data: {
        account: {
          id: account.id,
          entity_type: accountDetails?.entityType,
        },
        documents: documentIds.map((id) => ({ id })),
      },
    })
  }

  const renderPrimaryActions = () => {
    if (pageState === PageState.FORM && !canReview) {
      return (
        <button className='btn btn-primary' disabled={true}>
          Review
        </button>
      )
    } else if (pageState === PageState.FORM && canReview) {
      return (
        <button
          className='btn btn-primary'
          onClick={() => setPageState(PageState.REVIEW)}
        >
          Review
        </button>
      )
    } else if (pageState === PageState.REVIEW && canSubmit) {
      return (
        <button className='btn btn-primary' onClick={submitProfile}>
          Next
        </button>
      )
    } else if (pageState === PageState.REVIEW && !canSubmit) {
      return (
        <button className='btn btn-primary' disabled={true}>
          <div className='flex flex-row justify-center items-center'>
            <div className='animate-spin'>
              <AiOutlineLoading3Quarters size={16} />
            </div>
            <span className='pl-2'>Next</span>
          </div>
        </button>
      )
    } else if (pageState === PageState.ENTITY_DOCS) {
      return (
        <button
          className='btn btn-primary'
          onClick={handleEntityDocsSubmit}
          disabled={
            updateAccountKycTierLoading ||
            isLoadingUploadKycDocuments ||
            filesForUpload.length === 0
          }
        >
          {updateAccountKycTierLoading ? (
            <div className='flex flex-row justify-center items-center'>
              <div className='animate-spin'>
                <AiOutlineLoading3Quarters size={16} />
              </div>
              <span className='pl-2'>Submitting...</span>
            </div>
          ) : (
            <span className=''>Submit</span>
          )}
        </button>
      )
    }

    return <></>
  }

  const renderSecondaryActions = () => {
    if (pageState === PageState.FORM) {
      if (onCancel !== undefined) {
        return (
          <button className='btn btn-outline-dark' onClick={onCancel}>
            Cancel
          </button>
        )
      } else {
        return (
          <Link className='btn btn-outline-dark' to='/account'>
            Cancel
          </Link>
        )
      }
    } else if (pageState === PageState.REVIEW && accountDetails) {
      return (
        <button
          className='btn btn-outline-dark'
          onClick={() => setPageState(PageState.FORM)}
        >
          Edit Profile
        </button>
      )
    }

    return <></>
  }

  const renderContent = () => {
    if (pageState === PageState.FORM) {
      return (
        <>
          {accountDetails && (
            <>
              <BasicInformationPanel
                accountDetails={accountDetails}
                onChange={(a: AccountDetails) =>
                  setAccountDetails({ ...accountDetails, ...a })
                }
                onValidChange={(v: boolean) => setFormValid(v)}
              />
              <ApplicationTypePanel
                accountDetails={accountDetails}
                onChange={(a: AccountDetails) => {
                  setAccountDetails({ ...accountDetails, ...a })
                }}
              />
            </>
          )}
          {accountDetails?.investorType === 'entity' && (
            <>
              <EntityInformationPanel
                accountDetails={accountDetails}
                onChange={(a: AccountDetails) =>
                  setAccountDetails({ ...accountDetails, ...a })
                }
                onValidChange={(v: boolean) => setEntityFormValid(v)}
              />
            </>
          )}
        </>
      )
    } else if (pageState === PageState.REVIEW && accountDetails) {
      return <ReviewPanel accountDetails={{ ...accountDetails }} />
    } else if (pageState === PageState.SUMSUB) {
      return (
        <>
          <SumsubPanel
            onComplete={() => handleSumsubComplete()}
            onFailed={() => handleSumsubFailed()}
            onSubmitted={(idvApplicantId: any) =>
              handleSumsubSubmitted(idvApplicantId)
            }
          />
        </>
      )
    } else if (pageState === PageState.ENTITY_DOCS) {
      return (
        <>
          {accountDetails !== null && (
            <EntityTypePanel
              accountDetails={accountDetails}
              onChange={(a: AccountDetails) =>
                setAccountDetails({ ...accountDetails, ...a })
              }
            />
          )}
          <KycDocumentUpload
            type='entity'
            entityType={accountDetails?.entityType}
            files={filesForUpload}
            onFileChange={setFilesForUpload}
          />
          <AdditionalInformationPanel
            value={notes}
            onChange={(e: any) => setNotes(e.target.value)}
          />
        </>
      )
    }

    return <></>
  }

  return !account ? (
    <LoadingIndicator />
  ) : (
    <>
      <PageHeader title={'Identity Verification'} />

      <div data-test-id='profile' className='grid gap-6 grid-cols-1'>
        {renderContent()}

        {errorMessage && (
          <div className='pb-3'>
            <p className='text-danger-medium text-center'>
              {errorMessage.message}
            </p>
          </div>
        )}

        <ButtonRow place={'between'}>
          <div className='px-3 pb-2 md:pb-0'>{renderSecondaryActions()}</div>
          <div className='px-3 pb-2 md:pb-0'>{renderPrimaryActions()}</div>
        </ButtonRow>
      </div>
    </>
  )
}
