import { getDifference, subtractFromDate } from '@utils'

export const getTrancheStartDate = (
  cliffVestedFraction: any,
  endVestedFraction: any,
  cliffDate: any,
  endDate: any,
) => {
  const targetPercentage = 0
  const startPercentage = Number(cliffVestedFraction) / 100
  const endPercentage = Number(endVestedFraction) / 100

  // Calculate the rate of change per month
  const percentageChange = endPercentage - startPercentage
  const monthsBetween = getDifference(cliffDate, endDate, 'months')
  const rateOfChangePerMonth = percentageChange / monthsBetween

  // Calculate the number of months to reach the target percentage
  const monthsToTarget = Math.floor(
    (startPercentage - targetPercentage) / rateOfChangePerMonth,
  )

  // Calculate the date when the target percentage is reached
  const targetDate = subtractFromDate(
    cliffDate,
    monthsToTarget,
    'months',
  ).toISOString()
  return targetDate
}

export const convertVestingTypeStringToVestingType = (
  vestingTypeStr: string,
): VestingType => {
  switch (vestingTypeStr) {
    case 'CONTINUOUS':
      return 'continuous'
    case 'TRANCHE':
    case 'MONTHLY':
      return 'tranche'
    default:
      throw new Error(`Invalid vesting type: ${vestingTypeStr}`)
  }
}
