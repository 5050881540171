import {
  useGetProject,
  useGetProjectPrivacyDocuments,
  viewComplianceDocumentVersion,
} from '@apiServices'
import { Col, LoadingIndicator, Title } from '@components'
import { useHideSidebar } from '@hooks'
import { buildDocumentNames } from '@utils'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const ProjectPolicyPage = () => {
  const navigate = useNavigate()

  useHideSidebar()

  const { data: project } = useGetProject()
  const projectId = project?.id ? `${project.id}` : undefined
  const { data: documentsData, isLoading } =
    useGetProjectPrivacyDocuments(projectId)
  const documents = documentsData?.documents || []

  const issuer = project?.name

  useEffect(() => {
    if (!isLoading && !documents?.length) {
      // Redirect to home page
      navigate('/')
    }
  }, [isLoading])

  if (isLoading) {
    return <LoadingIndicator />
  }

  const documentNames = buildDocumentNames(documents)

  const handleViewDocument = (document: any) => {
    viewComplianceDocumentVersion(document)
  }

  return (
    <>
      <Title>Legal</Title>
      <div className='max-w-6xl high-contrast mx-auto'>
        <div className='mt-12'>
          <span className='text-4xl font-bold'>Legal</span>
        </div>
        <div className='mt-6'>
          <span className='text-md'>
            Before registering with {issuer}, we ask that you review our{' '}
            {documentNames}. Links to the current version of each of these
            Policies are listed below, and we urge you to review each Policy in
            its entirety. Feel free to contact us with any questions.
          </span>
        </div>
        <Col className='mt-6' place='center'>
          {documents.map((document: any) => {
            return (
              <a
                className='high-contrast-link'
                onClick={() => handleViewDocument(document)}
                target='_blank'
                rel='noreferrer'
              >
                <div className={'flex flex-row items-center'}>
                  {document.documentVersion.document.name}
                </div>
              </a>
            )
          })}
        </Col>
      </div>
    </>
  )
}
