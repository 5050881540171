export const TITLE_ICON_CENTERED = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 252 152'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='10' y='10' width='230' height='60' rx='5' fill='#E5E7EB' />
      <path
        d='M136.493 30H113.661C112.744 30 112 30.7435 112 31.6605V48.6813C112 49.5983 112.744 50.3418 113.661 50.3418H136.493C137.41 50.3418 138.154 49.5983 138.154 48.6813V31.6605C138.154 30.7435 137.41 30 136.493 30ZM136.493 31.6605V43.7373L133.221 40.762C132.729 40.3149 131.972 40.3328 131.502 40.8032L128.19 44.1147L121.66 36.3142C121.166 35.7247 120.262 35.7189 119.761 36.3014L113.661 43.3911V31.6605H136.493ZM129.436 36.0195C129.436 34.7583 130.458 33.7362 131.719 33.7362C132.98 33.7362 134.002 34.7583 134.002 36.0195C134.002 37.2807 132.98 38.3027 131.719 38.3027C130.458 38.3028 129.436 37.2807 129.436 36.0195Z'
        fill='#6B7280'
      />
      <rect x='10' y='75' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M129.351 94.9003C129.217 94.9003 129.089 94.8474 128.995 94.753C128.901 94.6587 128.848 94.5308 128.848 94.3974V89.9502C128.848 89.8168 128.901 89.6889 128.995 89.5946C129.089 89.5003 129.217 89.4473 129.351 89.4473C129.484 89.4473 129.612 89.5003 129.706 89.5946C129.801 89.6889 129.854 89.8168 129.854 89.9502V94.3974C129.854 94.5308 129.801 94.6587 129.706 94.753C129.612 94.8474 129.484 94.9003 129.351 94.9003Z'
        fill='#6B7280'
      />
      <path
        d='M131.573 92.6768H127.126C126.993 92.6768 126.865 92.6238 126.77 92.5295C126.676 92.4352 126.623 92.3072 126.623 92.1739C126.623 92.0405 126.676 91.9125 126.77 91.8182C126.865 91.7239 126.993 91.6709 127.126 91.6709H131.573C131.707 91.6709 131.834 91.7239 131.929 91.8182C132.023 91.9125 132.076 92.0405 132.076 92.1739C132.076 92.3072 132.023 92.4352 131.929 92.5295C131.834 92.6238 131.707 92.6768 131.573 92.6768Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 86.0059H119.503C119.37 86.0059 119.242 85.9529 119.147 85.8586C119.053 85.7643 119 85.6364 119 85.503C119 85.3696 119.053 85.2416 119.147 85.1473C119.242 85.053 119.37 85 119.503 85H129.986C130.119 85 130.247 85.053 130.341 85.1473C130.436 85.2416 130.489 85.3696 130.489 85.503C130.489 85.6364 130.436 85.7643 130.341 85.8586C130.247 85.9529 130.119 86.0059 129.986 86.0059Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 86.0059H119.503C119.37 86.0059 119.242 85.9529 119.147 85.8586C119.053 85.7643 119 85.6364 119 85.503C119 85.3696 119.053 85.2416 119.147 85.1473C119.242 85.053 119.37 85 119.503 85H129.986C130.119 85 130.247 85.053 130.341 85.1473C130.436 85.2416 130.489 85.3696 130.489 85.503C130.489 85.6364 130.436 85.7643 130.341 85.8586C130.247 85.9529 130.119 86.0059 129.986 86.0059Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 88.5469H119.503C119.37 88.5469 119.242 88.4939 119.147 88.3996C119.053 88.3053 119 88.1774 119 88.044C119 87.9106 119.053 87.7827 119.147 87.6883C119.242 87.594 119.37 87.541 119.503 87.541H129.986C130.119 87.541 130.247 87.594 130.341 87.6883C130.436 87.7827 130.489 87.9106 130.489 88.044C130.489 88.1774 130.436 88.3053 130.341 88.3996C130.247 88.4939 130.119 88.5469 129.986 88.5469Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 91.0884H119.503C119.37 91.0884 119.242 91.0354 119.147 90.9411C119.053 90.8468 119 90.7189 119 90.5855C119 90.4521 119.053 90.3242 119.147 90.2298C119.242 90.1355 119.37 90.0825 119.503 90.0825H124.903C125.037 90.0825 125.165 90.1355 125.259 90.2298C125.353 90.3242 125.406 90.4521 125.406 90.5855C125.406 90.7189 125.353 90.8468 125.259 90.9411C125.165 91.0354 125.037 91.0884 124.903 91.0884Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 93.6299H119.503C119.37 93.6299 119.242 93.577 119.147 93.4826C119.053 93.3883 119 93.2604 119 93.127C119 92.9936 119.053 92.8657 119.147 92.7713C119.242 92.677 119.37 92.624 119.503 92.624H124.903C125.037 92.624 125.165 92.677 125.259 92.7713C125.353 92.8657 125.406 92.9936 125.406 93.127C125.406 93.2604 125.353 93.3883 125.259 93.4826C125.165 93.577 125.037 93.6299 124.903 93.6299Z'
        fill='#6B7280'
      />
      <rect x='10' y='110' width='230' height='30' rx='5' fill='#E5E7EB' />
      <path
        d='M129.351 129.9C129.217 129.9 129.089 129.847 128.995 129.753C128.901 129.659 128.848 129.531 128.848 129.397V124.95C128.848 124.817 128.901 124.689 128.995 124.595C129.089 124.5 129.217 124.447 129.351 124.447C129.484 124.447 129.612 124.5 129.706 124.595C129.801 124.689 129.854 124.817 129.854 124.95V129.397C129.854 129.531 129.801 129.659 129.706 129.753C129.612 129.847 129.484 129.9 129.351 129.9Z'
        fill='#6B7280'
      />
      <path
        d='M131.573 127.677H127.126C126.993 127.677 126.865 127.624 126.77 127.53C126.676 127.435 126.623 127.307 126.623 127.174C126.623 127.04 126.676 126.913 126.77 126.818C126.865 126.724 126.993 126.671 127.126 126.671H131.573C131.707 126.671 131.834 126.724 131.929 126.818C132.023 126.913 132.076 127.04 132.076 127.174C132.076 127.307 132.023 127.435 131.929 127.53C131.834 127.624 131.707 127.677 131.573 127.677Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 121.006H119.503C119.37 121.006 119.242 120.953 119.147 120.859C119.053 120.764 119 120.636 119 120.503C119 120.37 119.053 120.242 119.147 120.147C119.242 120.053 119.37 120 119.503 120H129.986C130.119 120 130.247 120.053 130.341 120.147C130.436 120.242 130.489 120.37 130.489 120.503C130.489 120.636 130.436 120.764 130.341 120.859C130.247 120.953 130.119 121.006 129.986 121.006Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 121.006H119.503C119.37 121.006 119.242 120.953 119.147 120.859C119.053 120.764 119 120.636 119 120.503C119 120.37 119.053 120.242 119.147 120.147C119.242 120.053 119.37 120 119.503 120H129.986C130.119 120 130.247 120.053 130.341 120.147C130.436 120.242 130.489 120.37 130.489 120.503C130.489 120.636 130.436 120.764 130.341 120.859C130.247 120.953 130.119 121.006 129.986 121.006Z'
        fill='#6B7280'
      />
      <path
        d='M129.986 123.547H119.503C119.37 123.547 119.242 123.494 119.147 123.4C119.053 123.305 119 123.177 119 123.044C119 122.911 119.053 122.783 119.147 122.688C119.242 122.594 119.37 122.541 119.503 122.541H129.986C130.119 122.541 130.247 122.594 130.341 122.688C130.436 122.783 130.489 122.911 130.489 123.044C130.489 123.177 130.436 123.305 130.341 123.4C130.247 123.494 130.119 123.547 129.986 123.547Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 126.088H119.503C119.37 126.088 119.242 126.035 119.147 125.941C119.053 125.847 119 125.719 119 125.585C119 125.452 119.053 125.324 119.147 125.23C119.242 125.136 119.37 125.083 119.503 125.083H124.903C125.037 125.083 125.165 125.136 125.259 125.23C125.353 125.324 125.406 125.452 125.406 125.585C125.406 125.719 125.353 125.847 125.259 125.941C125.165 126.035 125.037 126.088 124.903 126.088Z'
        fill='#6B7280'
      />
      <path
        d='M124.903 128.63H119.503C119.37 128.63 119.242 128.577 119.147 128.483C119.053 128.388 119 128.26 119 128.127C119 127.994 119.053 127.866 119.147 127.771C119.242 127.677 119.37 127.624 119.503 127.624H124.903C125.037 127.624 125.165 127.677 125.259 127.771C125.353 127.866 125.406 127.994 125.406 128.127C125.406 128.26 125.353 128.388 125.259 128.483C125.165 128.577 125.037 128.63 124.903 128.63Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4322_2356'
          x='0'
          y='0'
          width='252'
          height='152'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4322_2356'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4322_2356'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
