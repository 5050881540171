import { usePrivy, useWallets } from '@privy-io/react-auth'
import { Hex } from 'viem'
import { useAccount, useChainId } from 'wagmi'

type TSAddressConfig = {
  address: Hex | undefined
  type: 'default' | 'email'
  chainId: number | undefined
}

export const useTSAddress = (): TSAddressConfig => {
  const { address: wagmiAddress } = useAccount()
  const { user: privyUser } = usePrivy()
  const { wallets } = useWallets()
  const wagmiChainId = useChainId()

  console.log(`INFO: wallets: ${JSON.stringify(wallets)}`)

  const config: TSAddressConfig = {
    address: undefined,
    type: 'default',
    chainId: undefined,
  }

  if (!privyUser?.email) {
    config.address = wagmiAddress
    config.chainId = wagmiChainId
  } else {
    config.address = privyUser.wallet?.address as Hex
    config.type = 'email'

    const chainId = wallets[0]?.chainId?.split(':')[1]
    config.chainId = Number(chainId)
  }
  return config
}
