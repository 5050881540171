import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { UserWalletAddress } from 'tokensoft-shared-types'

export const useGetMyWallets = () => {
  const fetchWithClient = useWebApiClient()

  return useQuery<UserWalletAddress[]>({
    queryKey: ['wallets'],
    queryFn: async () => {
      const rawWalletAddresses = (await fetchWithClient(
        'wallets',
      )) as WithSerializedDateFields<UserWalletAddress>[]

      return rawWalletAddresses.map((rawWallet) => ({
        ...rawWallet,
        createdAt: new Date(rawWallet.createdAt),
        updatedAt: new Date(rawWallet.updatedAt),
      }))
    },
  })
}
