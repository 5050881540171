import { RxCross1 } from 'react-icons/rx'
import './fail-icon.css'

export const FailIcon = () => {
  return (
    <div className='circle'>
      <RxCross1 size={36} />
    </div>
  )
}
