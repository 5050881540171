import { useGetUsersEvent } from '@apiServices'
import { Col, HelpText, LoadingIndicator, TableComponent } from '@components'
import { useModal, useProject, useToast } from '@contexts'
import { VERIFICATION_STATUS } from '@enums'
import { PaginationState } from '@tanstack/react-table'
import { countryList } from '@utils'
import { useEffect, useMemo, useState } from 'react'
import { EventUsersSearch } from './event-users-search'
import './event-users.css'

type Person = {
  eventUserId: string
  name: string
  address: string
  firstName: string
  email: string
  country: string
  accountType: string
  walletAddress: string
  referralCode: string
  kyc: string
  identity: string
  accreditation: string
  claims: string
  purchases: string
  consent: boolean
}

type UserEvents = {
  rows: Person[]
  pages: number
  totalRecords: number
}

export const EventUsers = ({
  eventUsersTableConfig,
  loadingDataCompleted,
  event,
  handleEventUserParameters,
  handleExposeData,
}: {
  eventUsersTableConfig: any
  loadingDataCompleted: Function
  event: any
  handleEventUserParameters: Function
  handleExposeData: Function
}) => {
  const { showErrorToast } = useToast()
  const [searchValue, setSearchValue] = useState<string>('')
  const [usersEvent, setUsersEvent] = useState<UserEvents>()
  const { showModal } = useModal()
  const { project } = useProject()

  const pageSizeList = [50, 100, 200]

  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: pageSizeList[0],
  })

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize],
  )

  const handleEventUsersData = eventUsersTableConfig.handleTableData

  const { mutate: getUsers, isPending: getUsersLoading } = useGetUsersEvent()

  const countryOptions = useMemo(
    () => [{ value: null, label: 'Select' }, ...countryList().getData()],
    [],
  )

  const filtersDataSet = {
    kycStatus: [
      {
        label: 'Select',
        value: null,
      },
      {
        label: 'Not Started',
        value: VERIFICATION_STATUS.NONE,
      },
      {
        label: 'Pending',
        value: VERIFICATION_STATUS.PENDING,
      },
      {
        label: 'Completed',
        value: VERIFICATION_STATUS.COMPLETED,
      },
      {
        label: 'Failed',
        value: VERIFICATION_STATUS.FAILED,
      },
    ],
    country: countryOptions,
  }

  const [filterBy, setFilterBy] = useState<Maybe<any>>(null)
  const [orderBy, setOrderBy] = useState<any>(null)
  const [firstLoadIsEmpty, setFirstLoadIsEmpty] = useState<Maybe<any>>(null)
  const [rowsCount, setRowsCount] = useState<Maybe<any>>(null)

  useEffect(() => {
    if (eventUsersTableConfig) {
      setFilterBy(eventUsersTableConfig.filterBy(filtersDataSet))
      setOrderBy(eventUsersTableConfig.orderBy())
    }
  }, [eventUsersTableConfig])

  // FUNCTIONS

  const handleFilterChange = (key: any, value: any) => {
    const temp = filterBy
    temp[key].searchTerm = value
    setFilterBy({ ...temp })
  }

  const handleOrderByChange = (key: any, value: any) => {
    const temp = orderBy
    if (temp[key].desc !== null) {
      temp[key].desc = value
    } else {
      Object.entries(temp).forEach(([keyObj, value]) => {
        temp[keyObj].desc = null
      })
      temp[key].desc = value
    }
    setOrderBy({ ...temp })
  }

  const handleUpdateUserTable = (page = pageIndex) => {
    loadingDataCompleted(false)
    setPagination({ pageIndex: page, pageSize: pageSize })
    getUsers(handleEventUserParameters(page * pageSize, pageSize), {
      onSuccess: (data) => {
        const rows = handleEventUsersData(data)
        setRowsCount(rows.length)
        if (firstLoadIsEmpty === null) {
          setFirstLoadIsEmpty(rows.length === 0)
        }
        setUsersEvent({
          rows,
          pages: Math.ceil(data.totalRecords / pageSize),
          totalRecords: data.totalRecords,
        })
        loadingDataCompleted(true)
      },
      onError: (error) => {
        setRowsCount(0)
        if (firstLoadIsEmpty === null) {
          setFirstLoadIsEmpty(true)
        }
        showErrorToast({ description: error.message })
        loadingDataCompleted(true)
      },
    })
  }

  // USE EFFECT

  useEffect(() => {
    if (pageIndex != null && pageSize != null && event) {
      handleUpdateUserTable()
    }
  }, [pageIndex, pageSize])

  useEffect(() => {
    if (orderBy) {
      handleUpdateUserTable()
    }
  }, [orderBy])

  useEffect(() => {
    handleExposeData({
      orderBy: orderBy ? orderBy : [],
      filterBy: filterBy ? filterBy : [],
      searchValue: searchValue ? searchValue : '',
      firstLoadIsEmpty: firstLoadIsEmpty !== null ? firstLoadIsEmpty : null,
      rowsCount: rowsCount ? rowsCount : 0,
    })
  }, [orderBy, filterBy, searchValue, firstLoadIsEmpty, rowsCount])

  const handleSetSearchValue = (value: any) => {
    setSearchValue(value)
  }

  const getDisclaimerText = () => {
    if (project?.domains[0].domainName) {
      return (
        <>
          * Once users register on{' '}
          <span className='text-primary-medium'>
            {project?.domains[0].domainName}
          </span>{' '}
          their information will be visible.
        </>
      )
    }
    return `* Once users register their information will be visible.`
  }

  return (
    <>
      {!usersEvent ? (
        <div className='flex justify-center items-center h-44'>
          <LoadingIndicator />
        </div>
      ) : (
        <Col nowrap={true}>
          <EventUsersSearch
            setSearchValue={handleSetSearchValue}
            updateUserTable={handleUpdateUserTable}
            filterChange={handleFilterChange}
            filterList={filterBy}
            disabled={firstLoadIsEmpty}
          />
          <div className='relative overflow-x-auto overflow-y-hidden table-container'>
            {getUsersLoading ? (
              <div className='table-loading-cover'>
                <LoadingIndicator className='loading-indicator' />
              </div>
            ) : null}
            <TableComponent
              pagination={pagination}
              config={usersEvent}
              columnsData={eventUsersTableConfig.columnsData(
                orderBy,
                handleOrderByChange,
                firstLoadIsEmpty,
              )}
              setPagination={setPagination}
            />
            <HelpText className='pt-5'>{getDisclaimerText()}</HelpText>
          </div>
        </Col>
      )}
    </>
  )
}
