import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { convertVestingTypeStringToVestingType } from '@utils'
import BigNumber from 'bignumber.js'
import { Distributor } from 'tokensoft-shared-types'

export const useGetDistributorByContractAddress = (
  address: EvmAddress,
  enabled: boolean = true,
) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<Distributor>({
    queryKey: ['distributor', address],
    queryFn: async () => {
      const distributorRaw = await fetchWithClient(`distributors/${address}`)

      const distributor: Distributor = {
        id: distributorRaw.id,
        chainId: distributorRaw.chainId,
        eventId: distributorRaw.eventId,
        intro: distributorRaw.intro,
        vestingDescription: distributorRaw.vestingDescription,
        merkleProofUri: distributorRaw.merkleProofUri,
        delegateUri: distributorRaw.delegateUri,
        configUri: distributorRaw.configUri,
        vestingType: convertVestingTypeStringToVestingType(
          distributorRaw.vestingType,
        ),
        totalAllocation: new BigNumber(distributorRaw.totalAllocation),
        fractionDenominator:
          distributorRaw.fractionDenominator !== null
            ? new BigNumber(distributorRaw.fractionDenominator)
            : null,
        platformFeeInCents: distributorRaw.platformFeeInCents,
        token: {
          address: distributorRaw.tokenAddress,
          symbol: distributorRaw.tokenSymbol,
          decimals: distributorRaw.tokenDecimals,
        },
        interfaces: distributorRaw.interfaces,
        vestingScheduleData: distributorRaw.vestingScheduleData,
      }

      return distributor
    },
    enabled: enabled,
  })
}
