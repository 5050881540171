import { Stretched } from '@components'
import { DEFAULT_EVENT_HEADER_BG_COLOR } from '@constants'
import { getIPFSLink } from '@utils'
import './event-header-wrapper.css'

interface EventHeaderWrapperProps {
  backgroundColor: any
  backgroundImage?: any
  children: any
}

export const EventHeaderWrapper = ({
  backgroundColor,
  children,
  backgroundImage,
}: EventHeaderWrapperProps) => {
  const style = {
    backgroundColor: backgroundColor || DEFAULT_EVENT_HEADER_BG_COLOR,
  } as any
  if (backgroundImage) {
    const backgroundImageUrl = backgroundImage.startsWith('ipfs://')
      ? getIPFSLink(backgroundImage)
      : backgroundImage

    style['backgroundImage'] = `url(${backgroundImageUrl})`
    style['backgroundSize'] = 'cover'
    style['backgroundRepeat'] = 'no-repeat'
    style['backgroundPosition'] = 'center'
    style['flex'] = 1
    style['maxWidth'] = 1800
  }

  return (
    <div className='flex justify-center'>
      <div
        data-testid={'event-banner'}
        className={'event-banner'}
        style={style}
      >
        <Stretched
          gap={10}
          place={'between-center'}
          className='event-header mx-auto max-w-6xl'
        >
          {children}
        </Stretched>
      </div>
    </div>
  )
}
