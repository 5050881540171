import { usePublicApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { PaymentMethod } from 'tokensoft-shared-types'

export const useGetAllChainPaymentMethods = () => {
  const fetchWithClient = usePublicApiClient()

  return useQuery<PaymentMethod[]>({
    queryKey: ['chainPaymentMethods'],
    queryFn: async () => {
      return fetchWithClient(`chains/payment-methods`)
    },
  })
}
