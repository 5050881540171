import { EventTimelineValues } from '../types/web/read'

export const hydrateISO8601String = (dateStr: ISO8601String): Date => {
  return new Date(dateStr)
}

export const convertLegacyEventTypeToEventType = (
  legacyType: LegacyEventType,
): EventType => {
  switch (legacyType) {
    case 'DISTRIBUTE':
      return 'distribution'
    case 'RAISE':
      return 'sale'
    case 'IDENTITY':
      return 'identity'
  }
}

export const convertApiKycRestrictionTypeToKycRestrictionType = (
  legacyType: KycRestrictionDatabaseType,
): KycRestrictionType => {
  switch (legacyType) {
    case '0':
      return 'none'
    case '1':
      return 'kyc_required'
    case '3':
      return 'kyc_and_accredited_investor_required'
  }
}

export const hydrateEventDates = (
  event: EventTimelineValues<ISO8601String>,
): EventTimelineValues<Date> => {
  return {
    registrationStartTime: hydrateISO8601String(event.registrationStartTime),
    registrationEndTime:
      event.registrationEndTime !== null
        ? hydrateISO8601String(event.registrationEndTime)
        : null,
    startTime:
      event.startTime !== null ? hydrateISO8601String(event.startTime) : null,
    endTime:
      event.endTime !== null ? hydrateISO8601String(event.endTime) : null,
  }
}

export const parsePositiveInteger = (param: string): Maybe<ID> => {
  // Don't allow leading zeros
  if (param.startsWith('0')) {
    return null
  }

  const paramInt = Number(param)

  if (!isPositiveInteger(paramInt)) {
    return null
  }

  return paramInt
}

export const isPositiveInteger = (num: unknown): boolean => {
  return typeof num === 'number' && Number.isInteger(num) && num > 0
}
