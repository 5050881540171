export const DownloadIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      style={{ minWidth: '18px', minHeight: '19px' }}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
    >
      <path
        d='M8.29688 4.98981L8.29688 12.7871C8.29688 12.9736 8.37095 13.1524 8.50282 13.2843C8.63468 13.4162 8.81352 13.4902 9 13.4902C9.18648 13.4902 9.36532 13.4162 9.49718 13.2843C9.62905 13.1524 9.70313 12.9736 9.70313 12.7871L9.70313 4.98981C9.70313 4.80333 9.62905 4.62448 9.49718 4.49262C9.36532 4.36076 9.18648 4.28668 9 4.28668C8.81352 4.28668 8.63468 4.36076 8.50282 4.49262C8.37095 4.62448 8.29688 4.80333 8.29688 4.98981Z'
        fill='currentColor'
      />
      <path
        d='M4.93249 10.8668C4.93224 10.9592 4.95028 11.0508 4.98558 11.1362C5.02088 11.2217 5.07273 11.2993 5.13816 11.3646L8.12644 14.3529C8.35828 14.5841 8.67232 14.7139 8.99972 14.7139C9.32712 14.7139 9.64116 14.5841 9.873 14.3529L12.8613 11.3646C12.9266 11.2993 12.9783 11.2218 13.0137 11.1365C13.049 11.0511 13.0672 10.9597 13.0671 10.8674C13.0671 10.775 13.0489 10.6836 13.0136 10.5983C12.9782 10.513 12.9264 10.4355 12.8611 10.3702C12.7958 10.3049 12.7183 10.2531 12.633 10.2178C12.5476 10.1825 12.4562 10.1643 12.3639 10.1643C12.2715 10.1644 12.1801 10.1826 12.0948 10.2179C12.0095 10.2533 11.932 10.3051 11.8667 10.3704L8.99972 13.2367L6.13273 10.3697C6.03439 10.2714 5.90912 10.2044 5.77275 10.1773C5.63637 10.1502 5.49502 10.1641 5.36656 10.2173C5.2381 10.2705 5.1283 10.3606 5.05104 10.4762C4.97377 10.5918 4.93252 10.7277 4.93249 10.8668Z'
        fill='currentColor'
      />
      <path
        d='M1.71886e-07 4.08594L4.27619e-08 14.9141C0.00111583 15.8648 0.379278 16.7762 1.05153 17.4485C1.72378 18.1207 2.63523 18.4989 3.58594 18.5L14.4141 18.5C15.3648 18.4989 16.2762 18.1207 16.9485 17.4485C17.6207 16.7762 17.9989 15.8648 18 14.9141L18 4.08594C17.9989 3.13523 17.6207 2.22378 16.9485 1.55153C16.2762 0.879278 15.3648 0.501116 14.4141 0.5L3.58594 0.5C2.63523 0.501116 1.72378 0.879278 1.05153 1.55153C0.379278 2.22378 0.00111598 3.13523 1.71886e-07 4.08594ZM16.5938 14.9141C16.5931 15.492 16.3632 16.046 15.9546 16.4546C15.546 16.8632 14.992 17.0931 14.4141 17.0937L3.58594 17.0937C3.00805 17.0931 2.45401 16.8632 2.04539 16.4546C1.63676 16.046 1.4069 15.492 1.40625 14.9141L1.40625 4.08594C1.4069 3.50805 1.63676 2.95401 2.04539 2.54539C2.45401 2.13675 3.00805 1.9069 3.58594 1.90625L14.4141 1.90625C14.992 1.9069 15.546 2.13675 15.9546 2.54539C16.3632 2.95401 16.5931 3.50805 16.5938 4.08594L16.5938 14.9141Z'
        fill='currentColor'
      />
    </svg>
  )
}
