import { useUploadKycDocuments } from '@apiServices'
import {
  AdditionalInformationPanel,
  ButtonRow,
  LoadingIndicator,
  PageHeader,
} from '@components'
import { useAccount, useAnalytics, useToast } from '@contexts'
import { KycDocumentUpload, LoaderButton } from '@newComponents'
import * as Sentry from '@sentry/react'
import { useState } from 'react'
import { Link } from 'react-router-dom'

export const AccreditationPending = () => {
  useAnalytics('/account/accreditation-pending')
  const { account, loading: isLoadingAccount } = useAccount()

  const { showSuccessToast, showErrorToast } = useToast()
  const [showForm, setShowForm] = useState<boolean>(false)
  const [filesForUpload, setFilesForUpload] = useState<File[]>([])
  const [messageText, setMessageText] = useState<Maybe<string>>(null)

  const kycTier: KycTier = 'accredited_investor'
  const {
    mutateAsync: uploadKycDocuments,
    isPending: isUploadingKycDocuments,
  } = useUploadKycDocuments(kycTier)

  if (isLoadingAccount) return <LoadingIndicator text='Loading account...' />

  const handleSubmitDocuments = async () => {
    try {
      await uploadKycDocuments({
        files: filesForUpload,
        message: messageText || 'Additional documentation submitted for review',
      })

      showSuccessToast({
        title: 'Documents successfully added.',
      })

      setShowForm(false)
    } catch (err) {
      Sentry.captureException(err)
      showErrorToast({
        description:
          'An error occurred while submitting your documentation. Please try again.',
      })
    }
  }

  return (
    <>
      <PageHeader title={'Application pending'} />

      {/* Account should have qualificationMethod if accreditation is pending */}
      {showForm && account.qualificationMethod !== null ? (
        <div className='flex flex-col gap-4 items-end justify-start'>
          <KycDocumentUpload
            files={filesForUpload}
            onFileChange={setFilesForUpload}
            qualificationMethod={account.qualificationMethod}
            type='accreditation'
            entityType={account.entityType}
          />
          <AdditionalInformationPanel
            value={messageText ?? ''}
            onChange={(e: any) => setMessageText(e.target.value)}
          />
          <LoaderButton
            onClick={handleSubmitDocuments}
            isLoading={isUploadingKycDocuments}
            text='Submit Documents'
            loadingText='Uploading documents...'
          />
        </div>
      ) : (
        <>
          <p className='text-md md:text-base mb-8 text-center'>
            Your accreditation check is underway. We will notify you once your
            documents have been reviewed. This process typically takes 3-5
            business days.
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>
            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>

            <button
              className='btn btn-primary'
              onClick={() => setShowForm(true)}
            >
              Submit More Documents
            </button>
          </ButtonRow>
        </>
      )}
    </>
  )
}
