import { useAuth } from '@contexts'
import axios, { AxiosRequestConfig } from 'axios'
import { useCallback } from 'react'

interface RequestConfig extends AxiosRequestConfig {
  token?: string
  signOut?: () => void
  shouldSignOutOnForbidden?: boolean
}

const authApiClient = (
  endpoint: string,
  {
    data,
    token,
    signOut,
    headers: customHeaders,
    shouldSignOutOnForbidden = true,
    ...customConfig
  }: RequestConfig,
) => {
  const config = {
    method: data ? 'post' : 'get',
    url: `${import.meta.env.VITE_API_URL}/auth/v1/${endpoint}`,
    data: data || undefined,
    headers: {
      ...(token ? { Authorization: `Bearer ${token}` } : {}),
      ...customHeaders,
    },
    ...customConfig,
  }

  return axios.request(config).then(
    (res) => res.data,
    (error) => {
      const { status: statusCode } = error.toJSON()
      if (statusCode === 403 && shouldSignOutOnForbidden) {
        if (signOut) {
          signOut()
        }
      } else if (statusCode === 500) {
        return Promise.reject({
          message: error.response.data.message,
        })
      }

      return Promise.reject(error)
    },
  )
}

const useAuthApiClient = () => {
  const {
    user: { token },
    disconnect: signOut,
  } = useAuth()
  return useCallback(
    (endpoint: string, config: Omit<RequestConfig, 'token' | 'signOut'> = {}) =>
      authApiClient(endpoint, { ...config, token, signOut }),
    [token, signOut],
  )
}

export { authApiClient, useAuthApiClient }
