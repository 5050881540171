import { useGetChainPaymentMethods } from '@apiServices'
import {
  CheckboxInput,
  Col,
  InputGroup,
  NetworkSelect,
  Section,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components'
import {
  DEFAULT_BASE_CURRENCY,
  DEFAULT_BASE_CURRENCY_DECIMALS,
} from '@constants'
import { useAccount, useNetworks } from '@contexts'
import { RadioInput } from '@newComponents'
import {
  getMaxEndDate,
  getMinEndDate,
  getTimezoneAbbreviation,
  validateEndDate,
  validateStartDate,
} from '@utils'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { PaymentMethod } from 'tokensoft-shared-types'

interface SalePaymentConfigProps {
  context: any
  setContext: Function
}

const defaultNativeBaseCurrency = {
  symbol: DEFAULT_BASE_CURRENCY,
  decimals: DEFAULT_BASE_CURRENCY_DECIMALS,
}

export const SalePaymentConfig = ({
  context,
  setContext,
}: SalePaymentConfigProps) => {
  const { account } = useAccount()
  const {
    getNativeBaseCurrencyPriceOracle,
    supportedNetworks,
    getNetworkDetails,
  } = useNetworks()
  const [networkId, setNetworkId] = useState(context?.networkId)
  const [startTime, setStartTime] = useState(context?.startTime || null)
  const [endTime, setEndTime] = useState(context?.endTime || null)
  const [selectedPaymentMethods, setSelectedPaymentMethods] = useState<
    PaymentMethod[]
  >(context?.paymentMethods || [])
  const [nativePaymentsEnabled, setNativePaymentsEnabled] = useState(
    context?.nativePaymentsEnabled || false,
  )

  const {
    data: paymentMethods,
    isPending: isPaymentMethodsPending,
    isError: isPaymentMethodsError,
  } = useGetChainPaymentMethods(context?.networkId)

  const {
    symbol: nativeSymbol,
    nativePriceOracleAddress,
    decimals: nativeDecimals,
    nativePriceOracleHeartbeat,
  } = getNetworkDetails(context?.networkId)
  const nativeBaseCurrencyEnabled = !!paymentMethods?.find(
    (method) => method.native,
  )?.nativePriceOracleAddress
  const [nativeBaseCurrency, setNativeBaseCurrency] = useState(
    context?.nativeBaseCurrency || defaultNativeBaseCurrency,
  )

  const deploySaleNetworks = supportedNetworks?.filter(
    (network) =>
      network.saleFactoryAddress !== null ||
      network.saleFactoryV3Address !== null,
  )

  const formattedPaymentMethods = paymentMethods
    ?.filter((method) => {
      // remove payment methods BLU (id = 52) for all projects except for project id 2
      if (method.id === 52 && context.projectId !== '2') {
        return null
      }
      return (
        (nativePaymentsEnabled && method.native) ||
        selectedPaymentMethods?.includes(method)
      )
    })
    .map((method) => {
      {
        return method.native ? nativeSymbol : method.symbol
      }
    })
    .join(',')

  const startTimeError = validateStartDate(
    startTime,
    endTime,
    account?.timezone,
  )
  const validStartTime = startTimeError == null

  const endTimeError = validateEndDate(startTime, endTime, account?.timezone)
  const validEndTime = endTimeError == null

  const formInvalid =
    (!nativePaymentsEnabled && selectedPaymentMethods?.length <= 0) ||
    !startTime ||
    !validStartTime ||
    !endTime ||
    !validEndTime ||
    !nativeBaseCurrency

  const handleNetworkChange = (id: any) => {
    setNetworkId(id)
    setSelectedPaymentMethods([])
    setNativePaymentsEnabled(false)
    setNativeBaseCurrency(null)
  }

  const handlePaymentMethodClick = (paymentMethod: PaymentMethod) => {
    const checked = selectedPaymentMethods?.includes(paymentMethod)
    const newSelections = checked
      ? selectedPaymentMethods?.filter((item) => item !== paymentMethod)
      : [...selectedPaymentMethods, paymentMethod]
    setSelectedPaymentMethods(newSelections)
  }

  const handleStartTimeChange = (e: any) => {
    setStartTime(e.target.value)
  }

  const handleEndTimeChange = (e: any) => {
    setEndTime(e.target.value)
  }

  const getNativePaymentPriceOracle = () => {
    if (!nativeBaseCurrency) {
      return nativePriceOracleAddress
    }
    if (
      getNativeBaseCurrencyPriceOracle &&
      nativeBaseCurrency.symbol === nativeSymbol
    ) {
      return getNativeBaseCurrencyPriceOracle(networkId)
    }
    return nativePriceOracleAddress
  }

  const { register, watch } = useForm<{ assetType: SaleAssetType }>({
    defaultValues: {
      assetType: context?.assetType ?? 'token',
    },
  })

  const assetType = watch('assetType')

  useEffect(() => {
    setContext({
      ...context,
      paymentMethods: selectedPaymentMethods,
      nativePaymentsEnabled: nativePaymentsEnabled,
      nativePriceOracleAddress: getNativePaymentPriceOracle(),
      nativePriceOracleHeartbeat,
      networkId: networkId,
      startTime: startTime,
      endTime: endTime,
      nativeBaseCurrency: nativeBaseCurrency,
      onChainConfigFormValid: !formInvalid,
      assetType: assetType,
    })
  }, [
    formInvalid,
    nativePaymentsEnabled,
    selectedPaymentMethods,
    paymentMethods,
    startTime,
    endTime,
    nativeBaseCurrency,
    assetType,
  ])

  return (
    <Stacked data-testid='sale-onchain-config'>
      <Section place={'start-center'}>
        <SectionTitle>Network</SectionTitle>
        <NetworkSelect
          supportedNetworks={deploySaleNetworks}
          disabled={context?.saleId}
          direction={'row'}
          size={'sm'}
          selected={networkId}
          setSelected={handleNetworkChange}
          subtitle='Choose a network you would like your participants to purchase tokens on.'
        />
      </Section>

      <Section gap={5} place={'start-center'}>
        <Col>
          <SectionTitle>Sale Asset Type</SectionTitle>
          <Text>Select the type of asset you would like to sell.</Text>
          <RadioInput
            id='assetType'
            label=''
            options={[
              { label: 'Token', value: 'token' },
              { label: 'Node License', value: 'node' },
            ]}
            register={register}
          />
        </Col>
      </Section>

      <Section gap={5} place={'start-center'}>
        <Col>
          <SectionTitle>Base Currency</SectionTitle>
          <Text>
            Select what currency you would like your sale to be denominated in.
          </Text>
        </Col>
        <Col>
          {context?.saleId ? (
            <Text>{nativeBaseCurrency.symbol}</Text>
          ) : (
            <>
              <CheckboxInput
                name=''
                checked={nativeBaseCurrency?.symbol === DEFAULT_BASE_CURRENCY}
                disabled={context?.saleId}
                onClick={() => {
                  if (nativeBaseCurrency?.symbol === DEFAULT_BASE_CURRENCY) {
                    setNativeBaseCurrency(null)
                  } else {
                    setNativeBaseCurrency({
                      symbol: DEFAULT_BASE_CURRENCY,
                      decimals: DEFAULT_BASE_CURRENCY_DECIMALS,
                    })
                  }
                  setSelectedPaymentMethods([])
                }}
              >
                USD
              </CheckboxInput>
              {nativeBaseCurrencyEnabled ? (
                <CheckboxInput
                  name=''
                  checked={nativeBaseCurrency?.symbol === nativeSymbol}
                  disabled={context?.saleId}
                  onClick={() => {
                    if (nativeBaseCurrency?.symbol === nativeSymbol) {
                      setNativeBaseCurrency(null)
                    } else {
                      setNativeBaseCurrency({
                        symbol: nativeSymbol,
                        decimals: nativeDecimals,
                      })
                    }
                    setSelectedPaymentMethods([])
                  }}
                >
                  {nativeSymbol}
                </CheckboxInput>
              ) : null}
            </>
          )}
        </Col>
      </Section>

      <Section gap={5} place={'start-center'}>
        <Col>
          <SectionTitle textAlign={'left'}>Payment Methods</SectionTitle>
          <Text>
            Select all payment methods that you want to accept. Purchase value
            is recorded based on the{' '}
            {nativeBaseCurrency ? nativeBaseCurrency.symbol : ``} value of the
            payment token at the time of payment.
          </Text>
        </Col>
        <Col>
          {context?.saleId ? (
            <Text>{formattedPaymentMethods}</Text>
          ) : (
            <>
              <CheckboxInput
                name=''
                checked={nativePaymentsEnabled}
                disabled={context?.saleId}
                onClick={() => {
                  setNativePaymentsEnabled(!nativePaymentsEnabled)
                }}
              >
                {nativeSymbol}
              </CheckboxInput>
              {paymentMethods
                ?.filter((paymentMethod) => {
                  if (paymentMethod.id === 52 && context.projectId !== '2') {
                    return false
                  }
                  // if native base currency, check if existence of a native price oracle
                  if (nativeBaseCurrency === nativeSymbol) {
                    return (
                      !!paymentMethod.nativePriceOracleAddress &&
                      !!paymentMethod.address
                    )
                  }
                  return !!paymentMethod.address
                })
                .map((paymentMethod: PaymentMethod) => (
                  <CheckboxInput
                    name=''
                    key={paymentMethod.symbol}
                    disabled={context?.saleId}
                    checked={selectedPaymentMethods?.includes(paymentMethod)}
                    onClick={() => handlePaymentMethodClick(paymentMethod)}
                  >
                    {paymentMethod.symbol}
                  </CheckboxInput>
                ))}
            </>
          )}
        </Col>
      </Section>

      <Section>
        <SectionTitle textAlign={'left'}>Sale Schedule</SectionTitle>
        <Stretched xgap={5} ygap={0}>
          <InputGroup
            className='w-1/2'
            type='datetime-local'
            label={`Sale Start Date (${getTimezoneAbbreviation(
              account?.timezone,
            )})`}
            value={startTime}
            name='startTime'
            onChange={handleStartTimeChange}
            valid={validStartTime}
            error={startTimeError}
          />

          <InputGroup
            className='start w-1/2'
            type='datetime-local'
            label={`Sale End Date (${getTimezoneAbbreviation(
              account?.timezone,
            )})`}
            value={endTime}
            min={getMinEndDate(startTime)}
            max={getMaxEndDate(account?.timezone)}
            name='endTime'
            onChange={handleEndTimeChange}
            valid={validEndTime}
            error={endTimeError}
          />
        </Stretched>
      </Section>
    </Stacked>
  )
}
