import { getFlatPriceSaleABI } from '@utils'
import { useContractWrite } from '@utils/contract-interaction'
import { useState } from 'react'
import { Abi, Address, Hex } from 'viem'
import { useWaitForTransactionReceipt } from 'wagmi'

export const useSweepToken = () => {
  const [transactionHash, setTransactionHash] = useState<Hex | undefined>()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const { executeContractWrite } = useContractWrite()

  const write = async (
    chainId: number,
    token: Address,
    toAddress: Address,
    proof: string[],
  ) => {
    setSubmitting(true)
    console.log('preparing sweep Token', proof)

    try {
      const writeContractResponse = await executeContractWrite({
        address: toAddress,
        abi: getFlatPriceSaleABI() as Abi,
        functionName: 'sweepToken',
        args: [token],
      })

      setTransactionHash(writeContractResponse)
      console.log('sweep Token transaction response', writeContractResponse)
      return writeContractResponse
    } finally {
      setSubmitting(false)
    }
  }

  const waitForTransactionResponse = useWaitForTransactionReceipt({
    hash: transactionHash,
  })

  return {
    write,
    isSubmitting: submitting,
    ...waitForTransactionResponse,
  }
}
