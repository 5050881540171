import { createContext, useContext } from 'react'
import { CurrencyRegistry } from 'tokensoft-shared-types'

export interface CurrencyRegistryContextValue {
  convert: CurrencyRegistry['convert']
  registerPair: CurrencyRegistry['registerPair']
}

export const CurrencyRegistryContext =
  createContext<Maybe<CurrencyRegistryContextValue>>(null)

export const useCurrencyRegistry = (): CurrencyRegistryContextValue => {
  const context = useContext(CurrencyRegistryContext)
  if (!context) {
    throw new Error(
      'useCurrencyRegistry must be used within a CurrencyRegistryProvider',
    )
  }
  return context
}
