import { useGetProjectFeatures } from '@apiServices'
import { useAccount } from '@contexts'

export const useFeature = () => {
  const { data: projectFeatures, isPending: isProjectFeaturesPending } =
    useGetProjectFeatures()
  const { configuration: accountConfiguration, loading: accountLoading } =
    useAccount()

  const isFeatureEnabled = (name: string) => {
    if (projectFeatures === undefined) {
      return false
    }

    // first check the global configuration
    const feature = projectFeatures.find((f) => f.name === name)
    if (!feature?.enabled) {
      return false
    }

    // next check the project configuration
    const projectFeature = projectFeatures.find((f) => f.name === name)
    if (projectFeature) {
      return projectFeature.enabled
    }

    // finally, check the account specific configuration
    const accountFeature = accountConfiguration?.features?.find(
      (f: any) => f.name === name,
    )
    return accountFeature?.enabled
  }

  const getFeatureRestriction = (name: string) => {
    if (projectFeatures === undefined) {
      return false
    }

    // first check the global configuration
    const feature = projectFeatures.find((f) => f.name === name)
    if (feature?.restriction) {
      return feature.restriction
    }

    // next check the account specific configuration
    const accountFeature = accountConfiguration?.features?.find(
      (f: any) => f.name === name,
    )
    return accountFeature?.restriction
  }

  return {
    isFeatureEnabled: isFeatureEnabled,
    getFeatureRestriction: getFeatureRestriction,
    isLoading: isProjectFeaturesPending || accountLoading,
  }
}
