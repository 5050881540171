interface CsvTableProps {
  headers: string[]
  rows: string[][]
}

export const CsvTable = ({ headers, rows }: CsvTableProps) => {
  return (
    <div className='max-h-96 overflow-y-auto border border-gray-500 rounded-md'>
      <table className='w-full border-collapse'>
        <thead className='sticky top-0 bg-white border overflow-hidden rounded-md'>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className='p-2'>
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <td key={cellIndex} className='p-2'>
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
