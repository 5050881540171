export const ExpandIcon = ({ fill = 'var(--primary)', ...restProps }) => {
  return (
    <svg
      {...restProps}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d='M8 16C3.584 16 0 12.416 0 8C0 3.584 3.584 0 8 0C12.416 0 16 3.584 16 8C16 12.416 12.416 16 8 16ZM8 5.2L4.4 8.8L5.536 9.928L8 7.464L10.464 9.928L11.6 8.8L8 5.2Z'
        fill={fill}
      />
    </svg>
  )
}
