import { getFlatPriceSaleABI } from '@utils'
import BigNumber from 'bignumber.js'
import { getAddress } from 'viem'
import { useReadContract } from 'wagmi'

/** Fetch the total amount a given wallet has purchased on a sale contract */
export const useGetWalletPurchaseTotal = (
  chainId?: ChainId,
  saleId?: EvmAddress,
  walletAddress?: EvmAddress,
) => {
  const config = {
    address: saleId !== undefined ? getAddress(saleId) : '0x',
    abi: getFlatPriceSaleABI(),
    functionName: 'buyerTotal',
    chainId,
    args: [walletAddress],
  }

  let walletPurchaseTotal: CurrencyValue = {
    value: new BigNumber(0),
    symbol: 'USD',
  }

  const { data, isLoading, error } = useReadContract({
    ...config,
    query: {
      enabled:
        chainId !== undefined &&
        saleId !== undefined &&
        walletAddress !== undefined,
    },
  })

  if (data) {
    const buyerTotalNumberRaw = new BigNumber(data.toString())
    // User purchase total uses 8 decimals in USD
    const buyerTotal = buyerTotalNumberRaw.div(1e8)
    walletPurchaseTotal = {
      value: buyerTotal,
      symbol: 'USD',
    }
  }

  return {
    data: walletPurchaseTotal,
    isLoading,
    error,
  }
}
