import { useWebApiClient } from '@apiClients'
import { useMutation } from '@tanstack/react-query'

export const usePostForgotWalletsEmail = () => {
  const client = useWebApiClient()

  return useMutation({
    mutationFn: async (data: { email: EmailAddress }) => {
      const { email } = data
      await client('wallets/reminder-email', {
        method: 'POST',
        data: { email },
      })
    },
  })
}
