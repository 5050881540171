export const ContinuousIcon = ({ fill = 'var(--primary)' }) => {
  return (
    <svg
      data-testid={'continuous-icon'}
      width='56'
      height='56'
      viewBox='0 0 56 56'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M28 56C43.464 56 56 43.464 56 28C56 12.536 43.464 0 28 0C12.536 0 0 12.536 0 28C0 43.464 12.536 56 28 56ZM36.8667 33.0167C36.8667 32.6585 36.561 32.3762 36.1655 32.0775C35.651 31.6902 35.5472 30.9587 35.9345 30.4442C36.3218 29.9297 37.0533 29.8258 37.5678 30.2132C38.6645 31.038 39.2 32.025 39.2 33.0167C39.2 34.0107 38.6622 35 37.5608 35.826C35.6778 37.2377 31.9013 38.2667 27.5333 38.2667C23.1653 38.2667 19.3888 37.2377 17.5058 35.826C16.4045 35 15.8667 34.0107 15.8667 33.0167C15.8667 32.025 16.4022 31.038 17.4988 30.2132C18.0133 29.8258 18.7448 29.9297 19.1322 30.4442C19.5195 30.9587 19.4157 31.6902 18.9012 32.0775C18.5057 32.3762 18.2 32.6585 18.2 33.0167C18.2 33.2068 18.3085 33.3772 18.4555 33.5475C18.7215 33.859 19.1182 34.1378 19.607 34.3968C21.3943 35.3442 24.283 35.9333 27.5333 35.9333C30.7837 35.9333 33.6723 35.3442 35.4597 34.3968C35.9485 34.1378 36.3452 33.859 36.6112 33.5475C36.7582 33.3772 36.8667 33.2068 36.8667 33.0167ZM32.8195 30.653C31.6878 32.4847 29.687 33.6 27.5333 33.6C25.3797 33.6 23.3788 32.4847 22.2472 30.653C21.1155 28.8213 21.0117 26.5335 21.9753 24.6073C24.15 20.258 26.4903 15.5785 26.4903 15.5785C26.6875 15.183 27.0912 14.9333 27.5333 14.9333C27.9755 14.9333 28.3792 15.183 28.5763 15.5785C28.5763 15.5785 30.9167 20.258 33.0913 24.6073C34.055 26.5335 33.9512 28.8213 32.8195 30.653Z'
        fill={fill}
      />
    </svg>
  )
}
