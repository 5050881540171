export const LinkIcon = ({}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
    >
      <path
        d='M11.4824 7.01766C13.5829 9.12031 13.5541 12.4914 11.4951 14.5618C11.4912 14.566 11.4867 14.5706 11.4824 14.5748L9.11995 16.9373C7.03625 19.021 3.64617 19.0208 1.56278 16.9373C-0.520927 14.854 -0.520927 11.4635 1.56278 9.38015L2.86729 8.07565C3.21322 7.72971 3.80898 7.95963 3.82684 8.44851C3.84962 9.07155 3.96135 9.69751 4.1675 10.302C4.23732 10.5067 4.18744 10.7331 4.03451 10.886L3.57442 11.3461C2.58913 12.3314 2.55823 13.9357 3.53381 14.9306C4.51903 15.9353 6.13839 15.9413 7.13117 14.9485L9.49366 12.5864C10.4848 11.5953 10.4806 9.99338 9.49366 9.00644C9.36355 8.87658 9.23249 8.77568 9.13011 8.70519C9.05769 8.65546 8.99789 8.58948 8.95549 8.51254C8.91308 8.43559 8.88925 8.3498 8.88588 8.26201C8.87196 7.89052 9.00359 7.5077 9.29714 7.21415L10.0373 6.47393C10.2314 6.27983 10.5359 6.256 10.761 6.41308C11.0187 6.59306 11.2602 6.79537 11.4824 7.01766ZM16.4372 2.06267C14.3538 -0.020751 10.9637 -0.0210323 8.88001 2.06267L6.51752 4.42517C6.5133 4.42939 6.50873 4.43396 6.50486 4.43818C4.4459 6.5086 4.41704 9.87969 6.51752 11.9823C6.7398 12.2046 6.98121 12.4069 7.23896 12.5869C7.46403 12.744 7.76855 12.7201 7.96261 12.526L8.70279 11.7858C8.99635 11.4923 9.12797 11.1095 9.11405 10.738C9.11068 10.6502 9.08685 10.5644 9.04445 10.4874C9.00204 10.4105 8.94224 10.3445 8.86982 10.2948C8.76744 10.2243 8.63638 10.1234 8.50627 9.99352C7.51933 9.00658 7.51518 7.40466 8.50627 6.41357L10.8688 4.05142C11.8615 3.05865 13.4809 3.06463 14.4661 4.06935C15.4417 5.06427 15.4108 6.66859 14.4255 7.65388L13.9654 8.11397C13.8125 8.2669 13.7626 8.4933 13.8324 8.69798C14.0386 9.30246 14.1503 9.92841 14.1731 10.5515C14.191 11.0403 14.7867 11.2703 15.1326 10.9243L16.4372 9.61981C18.5209 7.53649 18.5209 4.14603 16.4372 2.06267Z'
        fill='currentColor'
      />
    </svg>
  )
}
