import { useNotificationApiClient } from '@apiClients'
import { useAuth } from '@contexts'
import { Notification } from '@customTypes'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

export const useNotifications = () => {
  const {
    isAuthenticated,
    user: { walletAddress },
  } = useAuth()
  const client = useNotificationApiClient()
  const result = useQuery({
    enabled: isAuthenticated,
    queryKey: ['notifications'],
    refetchOnWindowFocus: false,
    queryFn: () =>
      client(`notifications?cacheId=${walletAddress}`).then(
        (data) => data.notifications,
      ),
  })

  return isAuthenticated
    ? { ...result, notifications: result.data || [] }
    : { notifications: [] }
}

export const useRemoveNotification = () => {
  const {
    user: { authId },
  } = useAuth()
  const client = useNotificationApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (notificationId: string) =>
      client(`notifications/${notificationId}`, {
        method: 'delete',
        data: { authId },
      }),
    // Optimistically remove notification
    onMutate: async (notificationId) => {
      await queryClient.cancelQueries({ queryKey: ['notifications'] })
      const previousNotifications: any[] =
        queryClient.getQueryData(['notifications']) ?? []
      queryClient.setQueryData(['notifications'], (old: any[]) => {
        const filteredNotifications =
          old?.filter((n) => n.id !== notificationId) || []
        return [...filteredNotifications]
      })
      return [...previousNotifications]
    },
    // If the mutation fails, put notification back
    onError: (err, variables, context) => {
      queryClient.setQueryData(['notifications'], context)
    },
    onSettled: () => {},
  })
}

export const useRemoveAllNotifications = () => {
  const {
    user: { authId },
  } = useAuth()
  const client = useNotificationApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () =>
      client('notifications/all', {
        method: 'delete',
        data: { authId },
      }),
    mutationKey: ['notifications'],
    // Optimistically clear all notifications from cache
    onMutate: async () => {
      await queryClient.cancelQueries({ queryKey: ['notifications'] })
      const previousNotifications: any[] =
        queryClient.getQueryData(['notifications']) ?? []
      queryClient.setQueryData(['notifications'], () => {
        return []
      })
      return [...previousNotifications]
    },
    // If the mutation fails, use the context to roll back
    onError: (err, variables, context) => {
      queryClient.setQueryData(['notifications'], context)
    },
    onSettled: () => {},
  })
}

export const useBanners = () => {
  const { isAuthenticated } = useAuth()
  const client = useNotificationApiClient()
  const result = useQuery({
    enabled: isAuthenticated,
    queryKey: ['banners'],
    queryFn: () =>
      client('notifications/banners').then((data) => data.notifications),
  })

  return isAuthenticated
    ? { ...result, banners: result.data || [] }
    : { banners: [] }
}

export const useRemoveBanner = () => {
  const {
    user: { authId },
  } = useAuth()
  const client = useNotificationApiClient()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (notification: Notification) => {
      return client(`notifications/${notification.id}`, {
        method: 'delete',
        data: { authId },
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['banners'] })
    },
  })
}
