import {
  AdvancedDistributorDefinition,
  AggregatorV3,
  ChainlinkOracleDefinition,
  ContinuousVestingMerkleDefinition,
  ContinuousVestingMerkleDistributorFactoryDefinition,
  ContinuousVestingMerkleDistributorFactoryV4Definition,
  ContinuousVestingMerkleDistributorFactoryV5Definition,
  CrosschainDistributorDefinition,
  DistributorDefinition,
  DistributorV5Definition,
  Erc20Definition,
  FlatPriceSaleDefinition,
  FlatPriceSaleFactoryDefinition,
  FlatPriceSaleFactoryV3Definition,
  FlatPriceSaleFactoryV4Definition,
  FlatPriceSaleV3Definition,
  FlatPriceSaleV4Definition,
  GenericDistributorV4Definition,
  IPriceTierVestingDefinition,
  SatelliteDefinition,
  StakingDefinition,
  TrancheVestingDistributionDefinition,
  TrancheVestingMerkleDefinition,
  TrancheVestingMerkleDistributorFactoryDefinition,
  TrancheVestingMerkleDistributorFactoryV4Definition,
  TrancheVestingMerkleDistributorFactoryV5Definition,
  USDTDefinition,
} from '@contractABIs'
import { DISTRIBUTOR_TYPES } from '@enums'
import { Abi } from 'viem'

export const getSatelliteContractForChainId = (
  distributor: any,
  chainId: number,
) => {
  return distributor?.contracts?.find((contract: any) => {
    if (contract.networkId === chainId) {
      return contract
    }

    return null
  })
}

export const getConnextContracts = (distributor: any) => {
  return distributor.contracts?.filter((contract: any) =>
    isConnextContract(contract.interfaces),
  )
}

export const isDistributorDelegationEnabled = (distributor: any) => {
  const interfaces = getDistributorInterfaces(distributor)
  return isDelegationEnabled(interfaces)
}

export const getDistributorInterfaces = (distributor: any) => {
  const distributorContract = distributor.contracts?.find(
    (contract: any) =>
      contract.id === `${distributor.id}-${distributor.chainId}`,
  )
  return distributorContract ? distributorContract.interfaces : []
}

export const isDelegationEnabled = (interfaces: any) => {
  const isVoting = isVotingType(interfaces)
  const isVotingWithoutDelegation = isVotingWithoutDelegationType(interfaces)
  return isVoting && !isVotingWithoutDelegation
}

export const isVotingWithoutDelegationType = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.IVotingWithoutDelegation,
    ) >= 0
  )
}

export const isContinuousVestingType = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.IContinuousVesting,
    ) >= 0
  )
}

export const isAdvancedDistributorType = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.AdvancedDistributor,
    ) >= 0
  )
}

export const isTrancheVestingType = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.ITrancheVesting,
    ) >= 0
  )
}

export const isCrosschainDistributorType = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.CrosschainDistributor,
    ) >= 0
  )
}

export const isIPriceTierVesting = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.IPriceTierVesting,
    ) >= 0
  )
}

export const isSatelliteContract = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.Satellite,
    ) >= 0
  )
}

export const isConnextContract = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.IConnext,
    ) >= 0
  )
}

export const isVotingType = (interfaces: any) => {
  return (
    interfaces?.findIndex(
      (iface: any) => iface.name === DISTRIBUTOR_TYPES.IVoting,
    ) >= 0
  )
}

export const getAggregatorV3ABI = () => {
  return AggregatorV3
}

export const getDelegateABI = () => {
  // FIXME: This should use a voting abi and not the price tier vesting abi
  return IPriceTierVestingDefinition
}

export const getVotesABI = () => {
  return DistributorDefinition
}

export const getClaimableAmountABI = (version: 'legacy' | 'v5') => {
  switch (version) {
    case 'legacy':
      return DistributorDefinition as Abi

    case 'v5':
      return DistributorV5Definition as Abi
  }
}

export const getClaimABI = () => {
  // Continuous and Tranche vesting use the same ABI here
  // Would need to be updated if they diverge
  return ContinuousVestingMerkleDefinition
}

export const getExperimentalClaimABI = () => {
  return DistributorV5Definition
}

export const getPurchasedAmountABI = () => {
  return TrancheVestingDistributionDefinition
}

export const getFlatPriceSaleABI = (version?: string) => {
  if (version === 'v4.0') {
    return FlatPriceSaleV4Definition
  }

  if (version === 'v3.0') {
    return FlatPriceSaleV3Definition
  }

  return FlatPriceSaleDefinition
}

export const getFlatPriceSaleV3ABI = () => {
  return FlatPriceSaleV3Definition
}

export const getStakingABI = () => {
  return StakingDefinition
}

export const getAdvancedDistributorABI = () => {
  return AdvancedDistributorDefinition
}

export const getCrosschainDistributorABI = () => {
  return CrosschainDistributorDefinition
}

export const getSatelliteABI = () => {
  return SatelliteDefinition
}

export const getErc20ABI = (isUsdt: boolean = false) => {
  if (isUsdt) {
    return USDTDefinition
  }
  return Erc20Definition
}

export const getChainlinkOracleABI = () => {
  return ChainlinkOracleDefinition
}

export const getFlatPriceSaleFactoryABI = (version?: string) => {
  if (version === 'v4.0') {
    return FlatPriceSaleFactoryV4Definition
  }

  if (version === 'v3.0') {
    return FlatPriceSaleFactoryV3Definition
  }

  return FlatPriceSaleFactoryDefinition
}

export const getFlatPriceSaleFactoryV3ABI = () => {
  return FlatPriceSaleFactoryV3Definition
}

export const getContinuousVestingMerkleABI = () => {
  return ContinuousVestingMerkleDefinition
}

export const getTrancheVestingMerkleABI = () => {
  return TrancheVestingMerkleDefinition
}

export const getVestedFractionABI = () => {
  return AdvancedDistributorDefinition as Abi
}

export const getContinuousVestingMerkleDistributorFactoryABI = () => {
  return ContinuousVestingMerkleDistributorFactoryDefinition as unknown as Abi
}

export const getTrancheVestingMerkleDistributorFactoryABI = () => {
  return TrancheVestingMerkleDistributorFactoryDefinition as unknown as Abi
}

export const getContinuousVestingMerkleDistributorFactoryV4ABI = () => {
  return ContinuousVestingMerkleDistributorFactoryV4Definition as unknown as Abi
}

export const getContinuousVestingMerkleDistributorFactoryV5ABI = () => {
  return ContinuousVestingMerkleDistributorFactoryV5Definition as unknown as Abi
}

export const getTrancheVestingMerkleDistributorFactoryV4ABI = () => {
  return TrancheVestingMerkleDistributorFactoryV4Definition
}

export const getTrancheVestingMerkleDistributorFactoryV5ABI = () => {
  return TrancheVestingMerkleDistributorFactoryV5Definition as unknown as Abi
}

export const getGenericDistributorV4ABI = () => {
  return GenericDistributorV4Definition
}
