import { PDFIcon, Row, SectionTitle, Text } from '@components'
import { documentShowTypeToString } from '@contexts'
import { useEffect, useState } from 'react'
import { AiFillEye, AiOutlineEdit } from 'react-icons/ai'
import { BsTrash3 } from 'react-icons/bs'
import { FaPlusCircle } from 'react-icons/fa'
import { useWizard } from 'react-use-wizard'

export const DocumentsList = ({
  text,
  maxDocuments = 3,
  wizardState,
  setWizardState,
}: {
  text?: string
  maxDocuments?: number
  wizardState?: any
  setWizardState: (newWizardState: any) => void
}) => {
  const [showAddDocument, setShowAddDocument] = useState<boolean>(true)
  const { goToStep, nextStep, activeStep } = useWizard()

  useEffect(() => {
    setShowAddDocument(wizardState.documents?.length < maxDocuments)
    const clonedChanges = { ...wizardState }
    if (
      wizardState.eventConfig?.requireDocument &&
      wizardState.documents?.length === 0
    ) {
      clonedChanges.documentsFormValid = false
    } else {
      clonedChanges.documentsFormValid = true
    }
    setWizardState(clonedChanges)
  }, [wizardState.documents?.length])

  useEffect(() => {
    if (!wizardState.documents) {
      setWizardState({ ...wizardState, documents: [] })
    }
  }, [])

  const handleEditSection = (index: any) => {
    const clonedChanges = { ...wizardState }
    clonedChanges.isEditingDocuments = index
    setWizardState(clonedChanges)
    nextStep()
  }

  const handleDeleteSection = (i: any) => {
    const newDocumentsSection = wizardState.documents.filter(
      (a: any, index: any) => i !== index,
    )
    const clonedChanges = { ...wizardState }
    clonedChanges.documents = newDocumentsSection
    setWizardState(clonedChanges)
  }

  const handleOnAddSection = () => {
    goToStep(activeStep + 1)
  }

  return (
    <div>
      <SectionTitle>My Documents</SectionTitle>
      <Text>
        {text ||
          `Please upload documents for eligible users to accept or sign. ${
            wizardState.eventConfig?.requireDocument
              ? 'You must provide at least one document.'
              : ''
          }`}
      </Text>
      <div className='mt-4'>
        {wizardState.documents?.map((section: any, i: any) => {
          const formatDocTitle = () => {
            if (!section.uploadedFile?.filename) {
              return section.documentTitle
            }

            return `${section.documentTitle}`
          }

          return (
            <Row xalign={'between'} key={i} className='document-section'>
              <div className='flex flex-row justify-center items-center'>
                <PDFIcon />
                <span className='ml-2'>{formatDocTitle()}</span>
              </div>
              <div className='flex flex-row justify-center items-center mt-2 sm:mt-0'>
                <span className='mr-2'>
                  <AiFillEye fill='var(--primary)' size={22} />
                </span>
                <span className='mr-3'>
                  {documentShowTypeToString(section.showDocumentTo)}
                </span>
                <span
                  className='mr-2 cursor-pointer'
                  onClick={() => handleEditSection(i)}
                >
                  <AiOutlineEdit fill='var(--primary)' size={20} />
                </span>
                <span
                  className='cursor-pointer'
                  onClick={() => handleDeleteSection(i)}
                >
                  <BsTrash3 size={16} />
                </span>
              </div>
            </Row>
          )
        })}
      </div>
      {showAddDocument && (
        <div className='flex flex-row justify-start mt-4'>
          <button
            className='btn btn-outline-primary btn-sm'
            onClick={() => handleOnAddSection()}
            disabled={wizardState.documents?.length > 2}
          >
            <div className='flex flex-row items-center'>
              <FaPlusCircle className='mr-2 self-center' />
              <span>Add a Document</span>
            </div>
          </button>
        </div>
      )}
    </div>
  )
}
