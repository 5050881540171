import { joinArrayToFormattedString } from './string'

export const getSignStatement = (
  issuer?: string,
  isserDocuments: any[] = [],
  host?: string,
): string => {
  const documentNamesString = buildDocumentNames(isserDocuments)

  if (!issuer || !isserDocuments?.length || !host) {
    return 'This site is powered by Tokensoft. Sign this message to prove ownership of this wallet and accept our Terms of Service, Privacy Policy, and Cookie Policy: https://tokensoft.io/legal'
  }

  const privacyLink = `${host}/policy`

  return `This site is powered by Tokensoft. Sign this message to prove ownership of this wallet, accept our Terms of Service, Privacy Policy, and Cookie Policy (https://tokensoft.io/legal), to share your personal information with ${issuer}, and to accept their ${documentNamesString} which can be viewed at ${privacyLink}.`
}

export const buildDocumentNames = (documents: any[]) => {
  const documentNames = (documents || []).map(
    ({ documentVersion }) => documentVersion?.document?.name,
  )
  return joinArrayToFormattedString(documentNames)
}
