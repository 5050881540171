import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { Sale, SaleRaw } from 'tokensoft-shared-types'
import { parseSale } from './parsing'

export const useGetSaleByEventId = (eventId: ID) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<Sale>({
    queryKey: ['sale', eventId],
    queryFn: async () => {
      const saleRaw: SaleRaw = await fetchWithClient(`events/${eventId}/sale`)
      const sale: Sale = parseSale(saleRaw)
      return sale
    },
  })
}
