import { DocumentUploadContext } from '@contexts'
import { CreateDocument, EventWizardState } from '@customTypes'
import { DOCUMENT_SHOW_TO_TYPE } from '@enums'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'

interface DocumentUploadProviderProps {
  wizardState: EventWizardState
  setWizardState: Function
  hideStepFooter?: boolean
  children?: ReactNode
}

export const documentShowTypeToString = (
  documentType: DOCUMENT_SHOW_TO_TYPE,
): string => {
  switch (documentType) {
    case DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS:
      return 'All eligible persons'
    case DOCUMENT_SHOW_TO_TYPE.OUTSIDE_US:
      return 'Eligible persons outside the US'
    case DOCUMENT_SHOW_TO_TYPE.US_ONLY:
      return 'Eligible persons within the US'
  }
}

export const DocumentUploadProvider: FC<DocumentUploadProviderProps> = ({
  wizardState,
  setWizardState,
  hideStepFooter = false,
  children,
}) => {
  const [isUSRestricted, setIsUSRestricted] = useState<boolean>(false)
  const [eventType, setEventType] = useState<Maybe<any>>(null)

  const [documentData, setDocumentData] = useState<CreateDocument>({
    signatureRequired: false,
    company: 'COMPANY',
    companyName: undefined,
    signerName: undefined,
    signerTitle: undefined,
    showDocumentTo: DOCUMENT_SHOW_TO_TYPE.ALL_ELIGIBLE_PERSONS,
    documentTitle: undefined,
    uploadedFile: undefined,
    recipient: 'RECIPIENT',
    addendum: 'ADDENDUM',
    index: undefined,
  })

  useEffect(() => {
    if (wizardState) {
      const _isUSRestricted =
        wizardState.blockedCountryList?.find(
          (country) => country.value === 'US',
        ) !== undefined
      setIsUSRestricted(_isUSRestricted)
      setEventType(wizardState.eventType)
      if (wizardState.isEditingDocuments !== undefined) {
        setDocumentData({
          ...(wizardState.documents?.[wizardState.isEditingDocuments] ?? {}),
          index: wizardState.isEditingDocuments,
        })
      }
    }
  }, [wizardState])

  const handleOnSave = () => {
    let newDocumentsSection: CreateDocument[] = []
    if (
      wizardState.isEditingDocuments != null &&
      wizardState.isEditingDocuments >= 0
    ) {
      newDocumentsSection =
        wizardState.documents?.map((section, i) => {
          if (i === wizardState.isEditingDocuments) {
            return documentData
          }
          return section
        }) ?? []
    } else {
      newDocumentsSection = [...(wizardState.documents ?? []), documentData]
    }

    const clonedChanges = { ...wizardState }
    clonedChanges.documents = newDocumentsSection
    clonedChanges.isEditingDocuments = undefined
    setWizardState(clonedChanges)
  }

  const handleDeleteSection = (i: any) => {
    const newDocumentsSection = wizardState.documents?.filter(
      (a, index) => i !== index,
    )
    const clonedChanges = { ...wizardState }
    clonedChanges.documents = newDocumentsSection
    setWizardState(clonedChanges)
  }

  const value = useMemo(
    () => ({
      isUSRestricted,
      documentData,
      setDocumentData,
      handleOnSave,
      handleDeleteSection,
      hideStepFooter,
      eventType,
    }),
    [
      isUSRestricted,
      documentData,
      setDocumentData,
      handleOnSave,
      handleDeleteSection,
      hideStepFooter,
      eventType,
    ],
  )

  return (
    <DocumentUploadContext.Provider value={value}>
      {children}
    </DocumentUploadContext.Provider>
  )
}
