import { Card, RadioInput } from '@components'
import { Account } from '@customTypes'
import { isEntity } from '@utils'

export const AccreditationHistoryPanel = ({
  account,
  qualificationMethod,
  onClick,
}: {
  account: Account
  qualificationMethod: AccountAccreditationQualificationMethod
  onClick: (method: AccountAccreditationQualificationMethod) => void
}) => {
  const isAlreadyAccredited = qualificationMethod === 'letter'

  return (
    <Card title='Accreditation History'>
      <p className='pb-4'>
        Have you been certified as an accredited investor within the past 90
        days?
      </p>
      <div className='pb-4'>
        <RadioInput
          name='previousAccreditationStatus'
          className='flex'
          checked={!isAlreadyAccredited}
          onClick={() =>
            onClick(isEntity(account) ? 'entity-net-worth' : 'net-worth')
          }
        >
          <div className='pt-1 leading-tight'>No, I need to be certified.</div>
        </RadioInput>
        <RadioInput
          name='previousAccreditationStatus'
          className='flex'
          checked={isAlreadyAccredited}
          onClick={() => onClick('letter')}
        >
          <div className={'flex flex-col'}>
            <div className='pt-1 leading-tight'>
              Yes, and I have a dated letter from the entity that certified me
              stating so.
            </div>
            <div>
              <a
                className='underline text-xs'
                target='_blank'
                rel='noreferrer'
                href={
                  'https://drive.google.com/file/d/1LVWyvqvcJBDnqYIg8AwkVWIwtAyaJn8r/view?usp=drivesdk'
                }
              >
                Download sample letter
              </a>
            </div>
          </div>
        </RadioInput>
      </div>
    </Card>
  )
}
