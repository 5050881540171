import {
  EventPropertyRow,
  FormattedCurrency,
  FormattedNumber,
} from '@newComponents'
import BigNumber from 'bignumber.js'

type Props = {
  assetType: SaleAssetType
  pricePerAsset: BigNumber
  saleCap: CurrencyValue
}

const SaleTotal = ({ assetType, pricePerAsset, saleCap }: Props) => {
  const label = `Total ${assetType === 'node' ? 'Available' : 'Raise'}`

  const getPropertyValue = () => {
    const totalAvailable = saleCap.value.dividedBy(pricePerAsset)
    let propertyValue: React.ReactNode

    switch (assetType) {
      case 'node':
        propertyValue = (
          <FormattedNumber value={totalAvailable} displayDecimals={0} />
        )
        break
      case 'token':
        propertyValue = (
          <FormattedCurrency currencyValue={saleCap} displayDecimals={0} />
        )
        break
    }

    return propertyValue
  }

  return <EventPropertyRow label={label} value={getPropertyValue()} />
}

export default SaleTotal
