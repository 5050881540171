import { useCurrencyRegistry, useToast } from '@contexts'
import * as Sentry from '@sentry/react'
import BigNumber from 'bignumber.js'

export const useCalculateTokensPurchased = (
  enteredAmount: CurrencyValue,
  protocolFeeAmount: CurrencyValue,
  tokenSymbol: TokenSymbol,
): CurrencyValue => {
  const { showErrorToast } = useToast()
  const ZERO_TOKENS = {
    value: new BigNumber(0),
    symbol: tokenSymbol,
  }

  if (enteredAmount.symbol !== protocolFeeAmount.symbol) {
    return ZERO_TOKENS
  }

  const { convert } = useCurrencyRegistry()

  const netPurchaseAmount = {
    value: enteredAmount.value.minus(protocolFeeAmount.value),
    symbol: enteredAmount.symbol,
  }

  try {
    const tokensPurchased = convert(netPurchaseAmount, tokenSymbol)
    return tokensPurchased
  } catch (e) {
    console.error('Error converting payment method to token purchase:', e)
    showErrorToast({
      description: 'Error calculating tokens',
    })
    Sentry.captureException(e)
    return ZERO_TOKENS
  }
}
