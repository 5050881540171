import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'
import { EventUserDocumentVersion } from 'tokensoft-shared-types'

export const useGetMySignedDocuments = () => {
  const fetchWithClient = useWebApiClient()

  return useQuery<EventUserDocumentVersion[]>({
    queryKey: ['events', 'documents', 'signed'],
    queryFn: async () => {
      const documentsRaw = await fetchWithClient('events/documents/signed')

      const documents: EventUserDocumentVersion[] = documentsRaw.map(
        (docRaw: any) => {
          const document: EventUserDocumentVersion = {
            id: docRaw.id,
            eventUserId: docRaw.eventUserId,
            eventDocumentVersionId: docRaw.eventDocumentVersionId,
            // signedAt: docRaw.signedAt ? new Date(docRaw.signedAt) : null,
            // generatedAt: docRaw.generatedAt ? new Date(docRaw.generatedAt) : null,
            uri: docRaw.uri,
            createdAt: new Date(docRaw.createdAt),
            updatedAt: new Date(docRaw.updatedAt),
            transactionHash: docRaw.transactionHash,
            eventName: docRaw.eventName,
            // acceptedAt: docRaw.acceptedAt ? new Date(docRaw.acceptedAt) : null,
          }
          return document
        },
      )

      return documents
    },
  })
}
