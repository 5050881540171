import {
  AutonomysIcon,
  Button,
  CheckboxInput,
  PolkadotIcon,
  Text,
} from '@components'
import { LoaderButton, StatusBox } from '@new-components'
import { useState } from 'react'
import { usePolkadotWalletVerification } from './use-polkadot-wallet-verification'

interface PolkadotWalletVerificationProps {
  isAutonomys: boolean
  /** Optionally provide an SS58 wallet address which the user's
   * connected SS58 wallet address must match to pass verification */
  requiredWalletAddressMatch: Maybe<SS58Address>
  onVerifiedPolkadotWallet: () => void
}

/**
 * Manages verification flow of user connecting and verifying a polkadot wallet
 */
export const PolkadotWalletVerification = ({
  isAutonomys,
  requiredWalletAddressMatch,
  onVerifiedPolkadotWallet,
}: PolkadotWalletVerificationProps) => {
  const {
    connectPolkadotWallet,
    connectedAccount,
    isConnectingWallet,
    isDoingChallenge,
    isPendingVerificationResult,
    startChallenge,
    reset,
  } = usePolkadotWalletVerification({
    requiredWalletAddressMatch,
    onVerified: onVerifiedPolkadotWallet,
  })

  const [isReleaseChecked, setIsReleaseChecked] = useState(false)
  const [isOwnershipChecked, setIsOwnershipChecked] = useState(false)

  const handleReset = () => {
    reset()
    setIsReleaseChecked(false)
    setIsOwnershipChecked(false)
  }

  const verifiedWalletIcon = !isAutonomys ? <AutonomysIcon /> : <PolkadotIcon />

  const hasMatchingWallet = connectedAccount !== null

  return (
    <div className='px-1 flex flex-col items gap-8'>
      <Text className='self-start text-2xl text-gray-700'>
        {isAutonomys
          ? 'Connect Autonomys Wallet'
          : 'Verify Your Polkadot Wallet'}
      </Text>
      {connectedAccount === null && (
        <>
          <StatusBox
            status='info'
            text={`Please connect the ${isAutonomys ? 'Autonomys-compatible SS58' : 'SS58'} wallet you provided in the pre-registration process.`}
          />
          <LoaderButton
            className='w-full rounded-full'
            onClick={() => {
              void connectPolkadotWallet()
            }}
            isLoading={isConnectingWallet}
            loadingText='Connecting...'
            text='Connect Polkadot Wallet'
          />
        </>
      )}
      {connectedAccount !== null && (
        <>
          {hasMatchingWallet && (
            <StatusBox
              status='success'
              text={`${isAutonomys ? 'Autonomys' : 'SS58'} wallet connected successfully.`}
            />
          )}
          {!hasMatchingWallet && (
            <StatusBox
              status='error'
              text={`The connected ${isAutonomys ? 'Autonomy-compatible SS58' : 'SS58'} wallet is not the address that was provided in the pre-registration process.`}
            />
          )}
          <div className='flex gap-4 p-2 pl-4'>
            {verifiedWalletIcon}
            <Text className='flex-1 text-md leading-[20px] text-center text-black '>
              {requiredWalletAddressMatch}
            </Text>
          </div>
          <div className='flex flex-col gap-2'>
            {hasMatchingWallet && (
              <>
                <CheckboxInput
                  className='p-2'
                  name='release'
                  checked={isReleaseChecked}
                  onClick={() => setIsReleaseChecked(!isReleaseChecked)}
                >
                  I hereby attest to the accuracy and suitability of the wallet
                  address provided above and release the{' '}
                  {isAutonomys ? 'Autonomys Foundation' : 'Foundation'} from all
                  liability arising from any loss, delay, or other undesired
                  result resulting from inaccurate or incomplete address
                  information above.
                </CheckboxInput>
                <CheckboxInput
                  className='p-2'
                  name='ownership'
                  checked={isOwnershipChecked}
                  onClick={() => setIsOwnershipChecked(!isOwnershipChecked)}
                >
                  I hereby certify that I directly and wholly own the{' '}
                  {isAutonomys ? 'Autonomys' : 'SS58'}-compliant address listed
                  above.
                </CheckboxInput>
              </>
            )}
            <div className='flex p-6 gap-4 justify-between'>
              <LoaderButton
                className='w-full rounded-full'
                disabled={
                  !hasMatchingWallet || !isOwnershipChecked || !isReleaseChecked
                }
                onClick={() => {
                  void startChallenge()
                }}
                isLoading={isDoingChallenge || isPendingVerificationResult}
                loadingText='Confirming...'
                text='Confirm'
              />
              <Button
                className='w-full rounded-full'
                fill='outline'
                onClick={handleReset}
              >
                Change
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
