import { Row } from '@components'
import classNames from 'classnames'

type RowProps = React.ComponentProps<typeof Row>

interface TextLinkProps extends RowProps {
  className?: string
  children: React.ReactNode
}

export const TextLink: React.FC<TextLinkProps> = ({
  className,
  place = 'center',
  children,
  ...restProps
}) => {
  return (
    <Row
      nowrap
      gap={2.5}
      place={place}
      className={classNames('text-link whitespace-nowrap', className)}
      {...restProps}
    >
      {children}
    </Row>
  )
}
