import { usePostSignedOrReviewedDocuments } from '@apiServices'
import { Text } from '@components'
import { useAccount, useToast } from '@contexts'
import { CheckboxInput, LoaderButton, TextInput } from '@newComponents'
import * as Sentry from '@sentry/react'
import { useForm } from 'react-hook-form'
import { EventDocument, EventUserDocumentStatus } from 'tokensoft-shared-types'

interface DocumentSigningFormProps {
  eventId: ID
  eventDocuments: EventDocument[]
  eventUserDocumentStatuses: EventUserDocumentStatus[]
  onFinishedReviewingOrSigning: () => void
  isUserFinishedReviewingOrSigning: boolean
}

type DocumentSigningFormInputs = {
  signature: string
  documents: boolean[] // Array of booleans for document review checkboxes
}

export const DocumentSigningForm = ({
  eventId,
  eventDocuments,
  eventUserDocumentStatuses,
  onFinishedReviewingOrSigning,
  isUserFinishedReviewingOrSigning,
}: DocumentSigningFormProps) => {
  const { account } = useAccount()
  const userFullName = `${account?.firstName} ${account?.lastName}`

  const { showErrorToast } = useToast()

  const {
    mutateAsync: postDocumentsSignedOrReviewed,
    isPending: isPendingPostDocumentSignOrAgree,
  } = usePostSignedOrReviewedDocuments(eventId)

  const someDocRequiresSignature = eventDocuments.some(
    (doc) => doc.requiresSignature,
  )

  // Documents that need review or signature
  const documentsToReview = eventDocuments.filter((doc) => {
    const status = eventUserDocumentStatuses.find(
      (status) => status.eventDocumentVersionId === doc.id,
    )
    return !(status && (status.signedAt || status.acceptedAt))
  })

  const defaultCheckedState = documentsToReview.map((doc) => {
    const status = eventUserDocumentStatuses.find(
      (status) => status.eventDocumentVersionId === doc.id,
    )
    return !!(status && status.acceptedAt)
  })

  const {
    register,
    formState: { errors, isValid: isFormValid },
  } = useForm<DocumentSigningFormInputs>({
    mode: 'onTouched',
    defaultValues: {
      signature: '',
      documents: defaultCheckedState,
    },
  })

  const handleSubmit = async () => {
    if (!isFormValid) return

    try {
      const eventDocumentIds = documentsToReview.map((doc) => doc.id)
      await postDocumentsSignedOrReviewed({
        eventId,
        eventDocumentIds,
      })

      onFinishedReviewingOrSigning()
    } catch (e) {
      Sentry.captureException(e)
      showErrorToast({
        description: 'Error signing documents. Please try again.',
      })
    }
  }

  const getAgreementIntroText = () => {
    const docStr = documentsToReview.length === 1 ? 'document' : 'documents'

    if (isUserFinishedReviewingOrSigning) {
      const actionString = someDocRequiresSignature ? 'signed' : 'agreed to'
      return `Document terms have been met. You have ${actionString} the following ${docStr}:`
    }

    if (someDocRequiresSignature) {
      return `Please review and sign the following ${docStr}:`
    }

    return `Please review the following ${docStr}:`
  }

  const getLabelForDocumentCheckbox = (doc: EventDocument) => {
    return (
      <span>
        I have reviewed the{' '}
        <a href={doc.uri} target='_blank'>
          {doc.name}
        </a>
      </span>
    )
  }

  return (
    <div>
      <Text className='text-2xl font-bold text-black mb-4'>
        {someDocRequiresSignature ? 'Document Signing' : 'Document Review'}
      </Text>

      <div className='flex flex-col gap-4'>
        <Text>{getAgreementIntroText()}</Text>

        {/* Checkbox Inputs for Document Review */}
        {documentsToReview.map((doc, index) => (
          <CheckboxInput
            key={doc.id}
            id={`documents.${index}`}
            label={getLabelForDocumentCheckbox(doc)}
            register={register}
            validate={(value) =>
              value === true || `You must review ${doc.name}`
            }
            error={errors.documents?.[index]?.message}
          />
        ))}

        {/* Signature Input */}
        {someDocRequiresSignature && (
          <TextInput
            id='signature'
            label='Signature'
            placeholder='Enter your full name'
            register={register}
            validate={(value) =>
              value === userFullName
                ? true
                : `Your signature must match your full name: ${userFullName}`
            }
            error={errors.signature?.message}
          />
        )}
      </div>
      <LoaderButton
        className='mt-4'
        disabled={!isFormValid || isPendingPostDocumentSignOrAgree}
        onClick={() => handleSubmit()}
        isLoading={isPendingPostDocumentSignOrAgree}
        loadingText='Saving...'
        text={someDocRequiresSignature ? 'Sign Documents' : 'Agree to Terms'}
      />
    </div>
  )
}
