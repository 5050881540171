import { useWebApiClient } from '@apiClients'
import { useQuery } from '@tanstack/react-query'

export const useGetPolkadotWalletVerificationNonce = (
  polkadotAddress: Maybe<string>,
) => {
  const fetchWithClient = useWebApiClient()

  return useQuery({
    queryKey: ['polkadotVerificationNonce', polkadotAddress],
    queryFn: async () => {
      const result = (await fetchWithClient(
        `wallets/polkadot/verification-nonce?polkadotAddress=${polkadotAddress}`,
      )) as { nonce: Nonce }
      return result.nonce
    },
    enabled: !!polkadotAddress,
  })
}
