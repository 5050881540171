import { Section, VestingChart, generateTranches } from '@components'
import { DEPLOY_VESTING_TYPE_OPTIONS, EVENT_STATUS, VESTING_TYPE } from '@enums'
import {
  addToDate,
  convertBaseUnitsToDecimal,
  getDayOfMonth,
  isAfterOrSame,
  isBeforeOrSame,
  isValidDate,
} from '@utils'

export const renderVestingChart = (
  context: any,
  vestingType: any,
  unixStartTime: any,
  unixCliffTime: any,
  unixEndTime: any,
  unixEventEndTime: any,
  account: any,
) => {
  const distributorData = generateDistributorData(
    context,
    vestingType,
    unixStartTime,
    unixCliffTime,
    unixEndTime,
    unixEventEndTime,
    account,
  )

  return (
    <Section place={'center'}>
      <VestingChart
        className={'w-full'}
        distributorData={distributorData}
        claimsData={[]}
      />
    </Section>
  )
}

export const generateDistributorData = (
  context: any,
  vestingType: any,
  unixStartTime: any,
  unixCliffTime: any,
  unixEndTime: any,
  unixEventEndTime: any,
  account: any,
) => {
  const totalSupply = convertBaseUnitsToDecimal(
    context?.total,
    context?.tokenDecimals,
  )
  console.log(`totalSupply`, totalSupply)
  const distributorVestingType =
    vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY ||
    vestingType === VESTING_TYPE.TRANCHE
      ? VESTING_TYPE.TRANCHE
      : VESTING_TYPE.CONTINUOUS

  // if there is no event end date, make one up to make the chart more realistic
  const eventEndTime =
    unixEventEndTime && unixEventEndTime > 0
      ? unixEventEndTime
      : unixEndTime + 3600 * 24 * 30
  const eventStartTime = unixStartTime

  const tranches = generateTranches(
    eventStartTime,
    unixStartTime,
    unixCliffTime,
    unixEndTime,
    eventEndTime,
    'month',
    distributorVestingType,
  )

  return {
    account: {
      timezone: account.timezone,
    },
    event: {
      status: EVENT_STATUS.COMPLETED,
      startTime: unixStartTime,
      endTime: eventEndTime,
    },
    tokenSymbol: context?.tokenSymbol,
    tokenDecimals: context?.tokenDecimals,
    totalAllocations: totalSupply,
    vestingType: distributorVestingType,
    startTime: unixStartTime,
    cliffTime: unixCliffTime,
    endTime: unixEndTime,
    trancheVesting: {
      tranches: tranches,
    },
  }
}

export const validateForm = (
  vestingType: any,
  startTime: any,
  cliffIntervalLength: any,
  cliffTime: any,
  endIntervalLength: any,
  endTime: any,
) => {
  if (!vestingType) {
    return {
      formValid: false,
      errorMessage: 'Vesting type is required.',
    }
  }

  if (!startTime) {
    return {
      formValid: false,
      errorMessage: 'Start time is required.',
    }
  }

  if (!isValidDate(startTime)) {
    return {
      formValid: false,
      errorMessage: 'Invalid start time.',
    }
  }

  if (cliffIntervalLength === null) {
    return {
      formValid: false,
      errorMessage: 'Cliff period is required.',
    }
  }

  if (!cliffTime) {
    return {
      formValid: false,
      errorMessage: 'Invalid cliff period.',
    }
  }

  if (endIntervalLength === null) {
    return {
      formValid: false,
      errorMessage: 'Vesting period is required.',
    }
  }

  if (!endTime) {
    return {
      formValid: false,
      errorMessage: 'Invalid vesting period.',
    }
  }

  if (!isBeforeOrSame(startTime, endTime)) {
    return {
      formValid: false,
      errorMessage: 'Start time must be equal to or before end time.',
    }
  }

  if (!isAfterOrSame(cliffTime, startTime)) {
    return {
      formValid: false,
      errorMessage: 'Cliff period must be equal to or after start time.',
    }
  }

  if (!isBeforeOrSame(cliffTime, endTime)) {
    return {
      formValid: false,
      errorMessage: 'Cliff period must be equal to or before vesting period.',
    }
  }

  if (cliffIntervalLength > 999) {
    return {
      formValid: false,
      errorMessage: 'Cliff period must be less than 999.',
    }
  }

  if (endIntervalLength > 999) {
    return {
      formValid: false,
      errorMessage: 'Vesting period must be less than 999.',
    }
  }

  if (vestingType === DEPLOY_VESTING_TYPE_OPTIONS.MONTHLY) {
    const minEndTime = addToDate(startTime, 1, 'month')
    if (!isBeforeOrSame(minEndTime, endTime)) {
      return {
        formValid: false,
        errorMessage: 'Vesting period must be at least 1 month.',
      }
    }

    const dayOfMonth = getDayOfMonth(startTime)
    if (dayOfMonth > 28) {
      return {
        formValid: false,
        errorMessage:
          'Start time must be within the first 28 days of the month.',
      }
    }
  }

  return {
    formValid: true,
    errorMessage: null,
  }
}
