import { useUploadToS3 } from '@apiServices'
import { LoadingIndicator, Text } from '@components'
import { FileUploadResult } from '@custom-types'
import { CsvTable, FileSelector } from '@new-components'
import { useEffect, useState } from 'react'
import { PolkadotWalletsConfigurationFileData } from 'tokensoft-shared-types'

interface PolkadotWalletFileUploadProps {
  isPendingPolkdadotWalletFileValidation: boolean
  onFileRemoved: () => void
  onFileUploaded: (data: FileUploadResult) => void
  onIsPolkadotWalletRequiredToggled: () => void
  onPolkadotWalletUserIdentifierChanged: (
    newPolkadotWalletUserIdentifier: PolkadotWalletUserIdentifier,
  ) => void
  polkadotWalletUserIdentifier: PolkadotWalletUserIdentifier
  uploadedData: Maybe<PolkadotWalletsConfigurationFileData[]>
  uploadFileErrorMessage: Maybe<string>
}

export const PolkadotWalletFileUpload = ({
  isPendingPolkdadotWalletFileValidation,
  onFileRemoved,
  onFileUploaded,
  onPolkadotWalletUserIdentifierChanged,
  polkadotWalletUserIdentifier,
  uploadedData,
  uploadFileErrorMessage,
}: PolkadotWalletFileUploadProps) => {
  const [file, setFile] = useState<Maybe<File>>(null)
  const [isFileUploaded, setIsFileUploaded] = useState(false)

  const {
    mutateAsync: uploadFile,
    isPending: isUploadingFile,
    data: uploadedFileData,
  } = useUploadToS3()

  useEffect(() => {
    if (
      file === null ||
      isFileUploaded ||
      isUploadingFile ||
      !uploadedFileData
    ) {
      return
    }

    onFileUploaded({
      filename: file.name,
      contentType: file.type,
      httpsUrl: uploadedFileData.fileUrl,
      s3Uri: uploadedFileData.s3Uri,
      file: file,
    })
  }, [file, uploadedFileData, onFileUploaded, isUploadingFile, isFileUploaded])

  const handleFileChange = (files: File[]) => {
    if (files.length === 0) {
      setFile(null)
      onFileRemoved()
      return
    }

    setFile(files[0])
    setIsFileUploaded(false)

    void uploadFile({
      file: files[0],
      isPublicResource: false,
    })
  }

  const handleDownload = () => {
    let csvContent = 'data:text/csv;charset=utf-8,'
    if (polkadotWalletUserIdentifier === 'email') {
      csvContent += 'polkadotAddress,email\n'
    } else {
      csvContent += 'polkadotAddress,ethAddress\n'
    }

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'polkadot-wallet-template.csv')
    document.body.appendChild(link)

    link.click()
  }

  return (
    <div>
      <div className='border-dashed border rounded-md border-gray-500 p-4 w-full max-w-[800px] flex flex-col items-stretch'>
        <Text className='mb-4'>
          If you would like to require users to connect with specific polkadot
          wallets, you may upload a .csv file with the list of wallets and
          either emails or ethereum addresses. If no list is provided, users may
          connect any polkadot wallet to be eligible. Choose email or ethereum
          wallet below, and click{' '}
          <button
            className='inline color-primary underline'
            onClick={handleDownload}
          >
            here
          </button>{' '}
          to download a template.
        </Text>
        <div className='flex justify-center gap-16 items-center'>
          <div className='flex flex-col items-start'>
            <label>
              <input
                className='mr-2'
                type='radio'
                value='email'
                checked={polkadotWalletUserIdentifier === 'email'}
                onChange={() => onPolkadotWalletUserIdentifierChanged('email')}
              />
              Email
            </label>
            <label>
              <input
                className='mr-2'
                type='radio'
                value='eth-wallet'
                checked={polkadotWalletUserIdentifier === 'ethAddress'}
                onChange={() =>
                  onPolkadotWalletUserIdentifierChanged('ethAddress')
                }
              />
              Ethereum Wallet
            </label>
          </div>
        </div>
        <FileSelector
          className='mt-4 mx-auto'
          allowedFileExtensions={['csv']}
          maxFileSizeMB={10}
          onFileChange={handleFileChange}
          files={file ? [file] : []}
          uploadStyle='button'
          allowMultiple={false}
        />
      </div>

      {isPendingPolkdadotWalletFileValidation && (
        <div className='m-8'>
          <LoadingIndicator />
        </div>
      )}
      {/* Display CSV Data */}
      {uploadedData !== null && uploadedData.length > 0 && (
        <div className='mt-6'>
          <Text className='mb-2'>Rows: {uploadedData.length}</Text>
          <CsvTable
            headers={Object.keys(uploadedData[0])}
            rows={uploadedData.map((row) => Object.values(row) as string[])}
          />
        </div>
      )}

      {/* Display Error Message */}
      {uploadFileErrorMessage && (
        <div className='mt-4 text-center text-red-500'>
          {uploadFileErrorMessage}
        </div>
      )}
    </div>
  )
}
