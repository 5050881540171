import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay?: number) => {
  const savedCallback = useRef<(() => void) | null>(null)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback.current === null) {
        return
      }

      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
