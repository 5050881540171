import { createContext, useContext } from 'react'
import { Blockchain } from 'tokensoft-shared-types'

interface NetworkContextProps {
  supportedNetworks: Blockchain[]
  getNetworkDetails: (networkId: number) => Blockchain
  getNativeBaseCurrencyPriceOracle?: (networkId?: number) => Maybe<EvmAddress>
  loading?: boolean
}

export const NetworkContext = createContext<Maybe<NetworkContextProps>>(null)
NetworkContext.displayName = 'NetworkContext'

export const useNetworks = (): NetworkContextProps => {
  const context = useContext(NetworkContext)

  if (!context) {
    throw new Error(
      'NetworkContext context is undefined, please verify you are calling useNetworks() as child of a <NetworkContext> component.',
    )
  }

  return context
}
