import {
  useGetChain,
  useGetPaymentMethodOraclePrices,
  useGetSaleByEventId,
} from '@apiServices'
import { LoadingIndicator } from '@components'
import { useAccount, useCurrencyRegistry, useToast } from '@contexts'
import {
  useGetPaymentMethodBalances,
  useGetProtocolFeeInBasisPoints,
  useGetWalletPurchaseTotal,
} from '@hooks'
import { ReactNode, useEffect } from 'react'
import { parsePositiveInteger } from 'tokensoft-shared-types'
import { useAccount as useWagmiAccount } from 'wagmi'
import { PurchaseContext } from './purchase-inputs-provider'

interface PurchaseInputsLoaderProps {
  eventId: ID
  children: ReactNode
}

/**
 * Set up necessary data context for user purchases.
 */
export const PurchaseInputsLoader = ({
  eventId,
  children,
}: PurchaseInputsLoaderProps) => {
  const { account, loading: isLoadingAccount } = useAccount()

  const {
    data: sale,
    isLoading: isLoadingSale,
    error: saleError,
  } = useGetSaleByEventId(eventId)

  const { chain: wagmiChain = null, address: userWalletAddress = null } =
    useWagmiAccount()

  const accountId = parsePositiveInteger(account?.id)
  const authId = parsePositiveInteger(account?.authId)

  const { showErrorToast } = useToast()

  const {
    data: chain,
    isLoading: isLoadingChain,
    error: chainError,
  } = useGetChain(wagmiChain?.id)

  const {
    data: protocolFeeInBasisPoints,
    isLoading: isLoadingProtocolFee,
    error: protocolFeeError,
  } = useGetProtocolFeeInBasisPoints(chain?.stakingV4Address, userWalletAddress)

  const {
    balances: userPaymentMethodBalances,
    isLoading: isLoadingBalances,
    error: balancesError,
  } = useGetPaymentMethodBalances(sale?.contract.paymentMethods ?? [])

  const {
    data: userWalletPurchaseTotal,
    isLoading: isLoadingUserWalletPurchaseTotal,
    error: userWalletPurchaseTotalError,
  } = useGetWalletPurchaseTotal(chain?.id, sale?.id, userWalletAddress ?? '0x')

  // Render errors if needed
  useEffect(() => {
    if (saleError) {
      showErrorToast({
        title: 'Error loading sale',
        description: saleError.message,
      })
    }
    if (chainError) {
      showErrorToast({
        title: 'Error loading chain',
        description: chainError.message,
      })
    }
    if (protocolFeeError) {
      showErrorToast({
        title: 'Error loading protocol fee',
        description: protocolFeeError.message,
      })
    }
    if (balancesError) {
      showErrorToast({
        title: 'Error loading payment method balances',
        description: balancesError.message,
      })
    }
    if (userWalletPurchaseTotalError) {
      showErrorToast({
        title: 'Error loading buyer total',
        description: userWalletPurchaseTotalError.message,
      })
    }
  }, [saleError, chainError, protocolFeeError])

  const paymentMethodOracles: OracleConfig[] =
    sale?.contract.paymentMethods.map((paymentMethod) => {
      return {
        oracleAddress: paymentMethod.oracle,
        symbol: paymentMethod.symbol,
      }
    }) ?? []

  // The native chain token is required to calculate purchase fees, regardless of the
  // user's selected payment method. Typically, the chain native token is a payment method,
  // so it would be in the paymentMethodOracles list. But if it is not, pull the native
  // price oracle from the chain to make sure it gets added to the currency registry.
  if (
    paymentMethodOracles.findIndex(
      (oracle) => oracle.symbol === chain?.symbol,
    ) === -1 &&
    chain !== undefined &&
    chain.nativePriceOracleAddress !== null
  ) {
    paymentMethodOracles.push({
      oracleAddress: chain.nativePriceOracleAddress,
      symbol: chain.symbol,
    })
  }

  const oraclePrices = useGetPaymentMethodOraclePrices(
    chain?.id,
    paymentMethodOracles,
  )

  const { registerPair } = useCurrencyRegistry()

  useEffect(() => {
    if (oraclePrices !== null) {
      oraclePrices.forEach((oraclePrice) => {
        registerPair({
          base: oraclePrice.symbol,
          // All of our oracles are priced in USD
          quote: 'USD',
          rate: oraclePrice.value,
        })
      })
    }
  }, [oraclePrices])

  const isDoneLoadingPurchaseInputs =
    !!chain &&
    !!accountId &&
    !!authId &&
    !!userWalletAddress &&
    !!protocolFeeInBasisPoints &&
    !!sale &&
    !!userWalletPurchaseTotal &&
    !!userPaymentMethodBalances &&
    userPaymentMethodBalances.length > 0

  return (
    <>
      {(isLoadingAccount ||
        isLoadingChain ||
        isLoadingSale ||
        isLoadingBalances ||
        isLoadingUserWalletPurchaseTotal ||
        isLoadingProtocolFee) && (
        <LoadingIndicator text='Preparing data for purchase...' />
      )}

      {/* Final Render */}
      {isDoneLoadingPurchaseInputs && (
        <PurchaseContext.Provider
          value={{
            accountId,
            authId,
            chain: chain,
            protocolFeeInBasisPoints,
            sale,
            userPaymentMethodBalances,
            userWalletPurchaseTotal,
            walletAddress: userWalletAddress,
          }}
        >
          {children}
        </PurchaseContext.Provider>
      )}
    </>
  )
}
