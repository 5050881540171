import classNames from 'classnames'
import { useRef, useState } from 'react'
import { FaRegQuestionCircle } from 'react-icons/fa'
import { Popover, PopoverPosition } from 'react-tiny-popover'
import './help-popover.css'

type DivProps = React.HTMLProps<HTMLDivElement>

type HelpPopoverProps = DivProps & {
  className?: string
  divClassName?: string
  children: React.ReactNode
  button?: React.ReactNode
  positions?: PopoverPosition[]
}

export const HelpPopover: React.FC<HelpPopoverProps> = ({
  className,
  divClassName,
  children,
  button = <FaRegQuestionCircle size={18} />,
  positions,
  ...restProps
}) => {
  const ref = useRef<Maybe<any>>(null)
  const [open, setOpen] = useState<boolean>(false)

  const handleClickOutside = (e: any) => {
    if (ref?.current && ref.current.contains(e.target)) {
      setOpen(false)
    }
  }

  return (
    <Popover
      isOpen={open}
      positions={positions || ['top', 'bottom', 'left', 'right']} // preferred positions by priority
      content={
        <div
          className={classNames('help-popover-container', className)}
          onClick={handleClickOutside}
          {...restProps}
        >
          <div className='help-content-container'>{children}</div>
        </div>
      }
      onClickOutside={() => setOpen(false)}
    >
      <div onClick={() => setOpen(!open)} className={divClassName}>
        {button}
      </div>
    </Popover>
  )
}
