import { ButtonRow, PageHeader } from '@components'
import { useAnalytics } from '@contexts'
import { Link } from 'react-router-dom'

export const IdentitySubmitted = () => {
  useAnalytics('/account/identity-submitted')

  return (
    <PageHeader
      title={'Application submitted'}
      instructions={
        <>
          <p className='text-xs md:text-base mb-8 text-center'>
            Thank you for submitting your identification documentation. We will
            notify you once your documents have been reviewed.
          </p>

          <ButtonRow place={'center'}>
            <Link className='btn btn-primary' to='/account'>
              My Profile
            </Link>
            <Link className='btn btn-primary' to='/'>
              Dashboard
            </Link>
          </ButtonRow>
        </>
      }
    />
  )
}
