import axios, { ResponseType } from 'axios'

export const getValidIPFSHash = (hash: string): boolean => {
  // validate ipfs hash is prefixed with ipfs
  return !!hash && hash.startsWith('ipfs')
}

export const getIPFSResource = async (
  ipfsHash: string,
  reponseType: ResponseType = 'json',
): Promise<any> => {
  if (!ipfsHash || !getValidIPFSHash(ipfsHash)) {
    return null
  }

  const ipfsURL = getIPFSLink(ipfsHash)
  if (!ipfsURL) {
    return
  }

  try {
    const result = await axios.get(ipfsURL, { responseType: reponseType })
    return result.data
  } catch (e: any) {
    throw new Error(e.message)
  }
}

export const getResourceUri = (uri: string): HttpsUrl => {
  if (!uri || !getValidIPFSHash(uri)) {
    return uri as HttpsUrl
  }
  const hash = getIPFSHash(uri)
  return `${import.meta.env.VITE_IPFS_GATEWAY}/${hash}` as HttpsUrl
}

export const getIPFSHash = (ipfsAddress: string) => {
  if (!ipfsAddress || !getValidIPFSHash(ipfsAddress)) {
    return
  }
  const split = ipfsAddress.split('ipfs://')
  const hash = split.length > 1 ? split[1] : ipfsAddress
  return hash
}

export const getIPFSLink = (ipfsAddress: string) => {
  if (!getValidIPFSHash(ipfsAddress)) {
    return
  }
  const hash = getIPFSHash(ipfsAddress)
  return `${import.meta.env.VITE_IPFS_GATEWAY}/${hash}`
}

export const convertToIPFSLink = (ipfsAddress: string): string => {
  if (!ipfsAddress) {
    return ''
  }

  return 'ipfs://' + ipfsAddress
}
