import React from 'react'
import { twMerge } from 'tailwind-merge'

export type SocialIconProps = {
  icon: JSX.Element
  href: Url
  color?: HexColorCode
  className?: string
}

export const SocialIcon = ({
  icon,
  href,
  color,
  className = '',
}: SocialIconProps) => {
  const openInNewTab = (url: Url) => {
    const win = window.open(url, '_blank')
    win?.focus()
  }

  return (
    <div
      className={twMerge(
        'p-2 w-[36px] h-[36px] bg-[#EFF3FF] backdrop-blur-[15px] rounded-full cursor-pointer hover:bg-[#dee2ee] transition-colors',
        className,
      )}
      onClick={() => {
        openInNewTab(href)
      }}
    >
      {React.cloneElement(icon, {
        color: color !== undefined ? color : '#101828',
      })}
    </div>
  )
}
