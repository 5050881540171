export const TITLE_ICON_50_50_REVERSED = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 342 133'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        width='150.385'
        height='104.615'
        rx='5'
        transform='matrix(-1 0 0 1 163.462 13.0769)'
        fill='#E5E7EB'
      />
      <path
        d='M99.6856 54.9231H76.853C75.9359 54.9231 75.1924 55.6666 75.1924 56.5836V73.6044C75.1924 74.5214 75.9359 75.2649 76.853 75.2649H99.6856C100.603 75.2649 101.346 74.5214 101.346 73.6044V56.5836C101.346 55.6666 100.603 54.9231 99.6856 54.9231ZM99.6856 56.5836V68.6604L96.413 65.6851C95.9212 65.238 95.1648 65.2559 94.6948 65.7263L91.3828 69.0378L84.8523 61.2373C84.3587 60.6478 83.4545 60.642 82.9534 61.2245L76.853 68.3142V56.5836H99.6856ZM92.6283 60.9426C92.6283 59.6814 93.6503 58.6593 94.9115 58.6593C96.1727 58.6593 97.1948 59.6814 97.1948 60.9426C97.1948 62.2038 96.1728 63.2258 94.9115 63.2258C93.6503 63.2259 92.6283 62.2038 92.6283 60.9426Z'
        fill='#6B7280'
      />
      <rect
        width='150.385'
        height='45.7692'
        rx='5'
        transform='matrix(-1 0 0 1 326.923 13.0769)'
        fill='#E5E7EB'
      />
      <path
        d='M255.543 40.6311C255.41 40.6311 255.282 40.5781 255.187 40.4837C255.093 40.3894 255.04 40.2615 255.04 40.1281V35.6809C255.04 35.5475 255.093 35.4196 255.187 35.3253C255.282 35.231 255.41 35.178 255.543 35.178C255.676 35.178 255.804 35.231 255.899 35.3253C255.993 35.4196 256.046 35.5475 256.046 35.6809V40.1281C256.046 40.2615 255.993 40.3894 255.899 40.4837C255.804 40.5781 255.676 40.6311 255.543 40.6311Z'
        fill='#6B7280'
      />
      <path
        d='M257.767 38.4073H253.319C253.186 38.4073 253.058 38.3543 252.964 38.26C252.869 38.1656 252.816 38.0377 252.816 37.9043C252.816 37.7709 252.869 37.643 252.964 37.5487C253.058 37.4544 253.186 37.4014 253.319 37.4014H257.767C257.9 37.4014 258.028 37.4544 258.122 37.5487C258.216 37.643 258.269 37.7709 258.269 37.9043C258.269 38.0377 258.216 38.1656 258.122 38.26C258.028 38.3543 257.9 38.4073 257.767 38.4073Z'
        fill='#6B7280'
      />
      <path
        d='M256.178 31.7366H245.695C245.562 31.7366 245.434 31.6836 245.34 31.5893C245.245 31.495 245.192 31.3671 245.192 31.2337C245.192 31.1003 245.245 30.9723 245.34 30.878C245.434 30.7837 245.562 30.7307 245.695 30.7307H256.178C256.311 30.7307 256.439 30.7837 256.534 30.878C256.628 30.9723 256.681 31.1003 256.681 31.2337C256.681 31.3671 256.628 31.495 256.534 31.5893C256.439 31.6836 256.311 31.7366 256.178 31.7366Z'
        fill='#6B7280'
      />
      <path
        d='M256.178 31.7366H245.695C245.562 31.7366 245.434 31.6836 245.34 31.5893C245.245 31.495 245.192 31.3671 245.192 31.2337C245.192 31.1003 245.245 30.9723 245.34 30.878C245.434 30.7837 245.562 30.7307 245.695 30.7307H256.178C256.311 30.7307 256.439 30.7837 256.534 30.878C256.628 30.9723 256.681 31.1003 256.681 31.2337C256.681 31.3671 256.628 31.495 256.534 31.5893C256.439 31.6836 256.311 31.7366 256.178 31.7366Z'
        fill='#6B7280'
      />
      <path
        d='M256.178 34.2776H245.695C245.562 34.2776 245.434 34.2247 245.34 34.1303C245.245 34.036 245.192 33.9081 245.192 33.7747C245.192 33.6413 245.245 33.5134 245.34 33.419C245.434 33.3247 245.562 33.2717 245.695 33.2717H256.178C256.311 33.2717 256.439 33.3247 256.534 33.419C256.628 33.5134 256.681 33.6413 256.681 33.7747C256.681 33.9081 256.628 34.036 256.534 34.1303C256.439 34.2247 256.311 34.2776 256.178 34.2776Z'
        fill='#6B7280'
      />
      <path
        d='M251.096 36.8189H245.695C245.562 36.8189 245.434 36.7659 245.34 36.6716C245.245 36.5773 245.192 36.4493 245.192 36.3159C245.192 36.1826 245.245 36.0546 245.34 35.9603C245.434 35.866 245.562 35.813 245.695 35.813H251.096C251.229 35.813 251.357 35.866 251.451 35.9603C251.546 36.0546 251.599 36.1826 251.599 36.3159C251.599 36.4493 251.546 36.5773 251.451 36.6716C251.357 36.7659 251.229 36.8189 251.096 36.8189Z'
        fill='#6B7280'
      />
      <path
        d='M251.096 39.3604H245.695C245.562 39.3604 245.434 39.3074 245.34 39.2131C245.245 39.1188 245.192 38.9908 245.192 38.8575C245.192 38.7241 245.245 38.5961 245.34 38.5018C245.434 38.4075 245.562 38.3545 245.695 38.3545H251.096C251.229 38.3545 251.357 38.4075 251.451 38.5018C251.546 38.5961 251.599 38.7241 251.599 38.8575C251.599 38.9908 251.546 39.1188 251.451 39.2131C251.357 39.3074 251.229 39.3604 251.096 39.3604Z'
        fill='#6B7280'
      />
      <rect
        width='150.385'
        height='45.7692'
        rx='5'
        transform='matrix(-1 0 0 1 326.923 71.9231)'
        fill='#E5E7EB'
      />
      <path
        d='M255.543 99.4772C255.41 99.4772 255.282 99.4243 255.187 99.3299C255.093 99.2356 255.04 99.1077 255.04 98.9743V94.5271C255.04 94.3937 255.093 94.2658 255.187 94.1715C255.282 94.0772 255.41 94.0242 255.543 94.0242C255.676 94.0242 255.804 94.0772 255.899 94.1715C255.993 94.2658 256.046 94.3937 256.046 94.5271V98.9743C256.046 99.1077 255.993 99.2356 255.899 99.3299C255.804 99.4243 255.676 99.4772 255.543 99.4772Z'
        fill='#6B7280'
      />
      <path
        d='M257.767 97.2537H253.319C253.186 97.2537 253.058 97.2007 252.964 97.1064C252.869 97.0121 252.816 96.8842 252.816 96.7508C252.816 96.6174 252.869 96.4894 252.964 96.3951C253.058 96.3008 253.186 96.2478 253.319 96.2478H257.767C257.9 96.2478 258.028 96.3008 258.122 96.3951C258.216 96.4894 258.269 96.6174 258.269 96.7508C258.269 96.8842 258.216 97.0121 258.122 97.1064C258.028 97.2007 257.9 97.2537 257.767 97.2537Z'
        fill='#6B7280'
      />
      <path
        d='M256.178 90.5828H245.695C245.562 90.5828 245.434 90.5298 245.34 90.4355C245.245 90.3412 245.192 90.2133 245.192 90.0799C245.192 89.9465 245.245 89.8185 245.34 89.7242C245.434 89.6299 245.562 89.5769 245.695 89.5769H256.178C256.311 89.5769 256.439 89.6299 256.534 89.7242C256.628 89.8185 256.681 89.9465 256.681 90.0799C256.681 90.2133 256.628 90.3412 256.534 90.4355C256.439 90.5298 256.311 90.5828 256.178 90.5828Z'
        fill='#6B7280'
      />
      <path
        d='M256.178 90.5828H245.695C245.562 90.5828 245.434 90.5298 245.34 90.4355C245.245 90.3412 245.192 90.2133 245.192 90.0799C245.192 89.9465 245.245 89.8185 245.34 89.7242C245.434 89.6299 245.562 89.5769 245.695 89.5769H256.178C256.311 89.5769 256.439 89.6299 256.534 89.7242C256.628 89.8185 256.681 89.9465 256.681 90.0799C256.681 90.2133 256.628 90.3412 256.534 90.4355C256.439 90.5298 256.311 90.5828 256.178 90.5828Z'
        fill='#6B7280'
      />
      <path
        d='M256.178 93.1238H245.695C245.562 93.1238 245.434 93.0708 245.34 92.9765C245.245 92.8822 245.192 92.7543 245.192 92.6209C245.192 92.4875 245.245 92.3596 245.34 92.2652C245.434 92.1709 245.562 92.1179 245.695 92.1179H256.178C256.311 92.1179 256.439 92.1709 256.534 92.2652C256.628 92.3596 256.681 92.4875 256.681 92.6209C256.681 92.7543 256.628 92.8822 256.534 92.9765C256.439 93.0708 256.311 93.1238 256.178 93.1238Z'
        fill='#6B7280'
      />
      <path
        d='M251.096 95.6653H245.695C245.562 95.6653 245.434 95.6124 245.34 95.518C245.245 95.4237 245.192 95.2958 245.192 95.1624C245.192 95.029 245.245 94.9011 245.34 94.8067C245.434 94.7124 245.562 94.6594 245.695 94.6594H251.096C251.229 94.6594 251.357 94.7124 251.451 94.8067C251.546 94.9011 251.599 95.029 251.599 95.1624C251.599 95.2958 251.546 95.4237 251.451 95.518C251.357 95.6124 251.229 95.6653 251.096 95.6653Z'
        fill='#6B7280'
      />
      <path
        d='M251.096 98.2066H245.695C245.562 98.2066 245.434 98.1536 245.34 98.0593C245.245 97.965 245.192 97.837 245.192 97.7036C245.192 97.5702 245.245 97.4423 245.34 97.348C245.434 97.2537 245.562 97.2007 245.695 97.2007H251.096C251.229 97.2007 251.357 97.2537 251.451 97.348C251.546 97.4423 251.599 97.5702 251.599 97.7036C251.599 97.837 251.546 97.965 251.451 98.0593C251.357 98.1536 251.229 98.2066 251.096 98.2066Z'
        fill='#6B7280'
      />
      <defs>
        <filter
          id='filter0_d_4322_1062'
          x='0'
          y='0'
          width='342'
          height='132.769'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset dx='1' dy='1' />
          <feGaussianBlur stdDeviation='0.5' />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0 0.152941 0 0 0 0.06 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow_4322_1062'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow_4322_1062'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  )
}
