import { useWebApiClient } from '@api/clients'
import * as Sentry from '@sentry/react'
import { useQuery } from '@tanstack/react-query'

export const useGetMyPolkadotWalletRequirement = (eventId: ID) => {
  const fetchWithClient = useWebApiClient()

  return useQuery<{
    isSpecificWalletRequired: boolean
    requiredPolkadotAddress: Maybe<SS58Address>
  }>({
    queryKey: [
      'events',
      eventId,
      'eligibility',
      'my-polkadot-wallet-requirement',
    ],
    queryFn: async () => {
      try {
        const result = (await fetchWithClient(
          `events/${eventId}/eligibility/my-polkadot-wallet-requirement`,
        )) as {
          isSpecificWalletRequired: boolean
          requiredPolkadotAddress: Maybe<SS58Address>
        }

        return result
      } catch (error) {
        Sentry.captureException(error)
        throw error
      }
    },
  })
}
