import { Checkmark } from 'react-checkmark'

interface CheckmarkProps {
  color?: string
  size?: string
}

export const AnimatedCheckmark = ({
  color = 'var(--success)',
  size = 'xLarge',
}: CheckmarkProps) => {
  return <Checkmark color={color} size={size} />
}
