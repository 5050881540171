import { Button, Text } from '@components'
import classNames from 'classnames'
import { useWizard } from 'react-use-wizard'

type GoToStepButtonProps = {
  disabled?: boolean
  label?: string
  icon?: React.ReactNode
  className?: string
  stepToGo: number
} & React.ComponentProps<typeof Button>

export const GoToStepButton: React.FC<GoToStepButtonProps> = ({
  disabled = false,
  label = 'Continue',
  icon = <></>,
  className = '',
  stepToGo,
  ...restProps
}) => {
  const { goToStep, isLoading } = useWizard()

  return (
    <Button
      className={classNames('md:w-36 w-full', className)}
      onClick={(e) => goToStep(stepToGo)}
      disabled={disabled || isLoading}
      {...restProps}
    >
      <Text>{label}</Text>
      {icon}
    </Button>
  )
}
