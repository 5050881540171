import { useGetProject } from '@apiServices'
import {
  Button,
  Flex,
  LegacyTransactions,
  PageHeader,
  Row,
  SectionTitle,
  Stacked,
  Stretched,
  Text,
} from '@components'
import { useAccount, useAnalytics } from '@contexts'
import { FEATURE } from '@enums'
import { useFeature } from '@hooks'
import { useNavigate } from 'react-router-dom'

export const PurchasesPage = () => {
  useAnalytics('/purchases')
  const { data: project } = useGetProject()
  const { isFeatureEnabled } = useFeature()
  const { account } = useAccount()
  const navigate = useNavigate()

  const showLegacyPurchase = () => {
    const isLegacyPurchaseEnabled = isFeatureEnabled(FEATURE.LEGACY_PURCHASES)

    if (!isLegacyPurchaseEnabled || !project?.tenantId) {
      return null
    }

    if (!account.email || !account.emailVerified) {
      return (
        <>
          <PageHeader
            className={'mt-8 !mb-4'}
            title={'Legacy Purchase History'}
          />
          <div className='email-info-body p-4 mb-2'>
            <span className='text-xs'>
              You need to verify your email in order to view your legacy
              purchase history.
            </span>
          </div>
        </>
      )
    }

    return (
      <>
        <PageHeader
          className={'mt-8 !mb-4'}
          title={'Legacy Purchase History'}
        />
        <LegacyTransactions />
      </>
    )
  }

  return (
    <>
      <PageHeader title={'Dashboard'} />

      <Stacked>
        <Stretched xalign={'between'} ygap={5}>
          <Flex width={'full'} yalign={'center'}>
            <SectionTitle>Purchase History</SectionTitle>
          </Flex>

          <Row className='justify-start md:justify-end' gap={2.5}>
            <Button
              fill={'outline'}
              size={'sm'}
              onClick={() => {
                navigate(`/`)
              }}
            >
              <>
                <Text>Events</Text>
              </>
            </Button>

            <Button size={'sm'} onClick={() => {}}>
              <>
                <Text>Purchases</Text>
              </>
            </Button>
          </Row>
        </Stretched>

        {/* <Transactions /> */}
        {showLegacyPurchase()}
      </Stacked>
    </>
  )
}
