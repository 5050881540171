import {
  AccreditationCompleted,
  AccreditationFailed,
  AccreditationForm,
  AccreditationPending,
  LoadingIndicator,
} from '@components'
import { useAccount } from '@contexts'
import { VERIFICATION_STATUS } from '@enums'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const AccreditationPage = () => {
  const navigate = useNavigate()
  const { account, isTier3Eligible, tier3Status } = useAccount()

  useEffect(() => {
    // scroll to top of page
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (account && !isTier3Eligible) {
      navigate('/account')
    }
  }, [account])

  if (!account) return <LoadingIndicator />

  switch (tier3Status) {
    case VERIFICATION_STATUS.NONE:
    case VERIFICATION_STATUS.NEW:
    default:
      return <AccreditationForm />
    case VERIFICATION_STATUS.COMPLETED:
      return <AccreditationCompleted />
    case VERIFICATION_STATUS.FAILED:
      return <AccreditationFailed />
    case VERIFICATION_STATUS.PENDING:
      return <AccreditationPending />
  }
}
