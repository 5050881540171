export const AutonomysIcon = () => {
  return (
    <svg
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M0 9.84095C0 10.7892 0.132374 11.7071 0.376979 12.5762L7.48059 0.144531C3.17842 1.26396 0 5.18051 0 9.84095ZM19.623 12.5748L12.5194 0.144531C16.823 1.26396 20 5.18051 20 9.83951C20 10.7877 19.8691 11.7057 19.623 12.5748ZM9.99996 19.8568C12.8172 19.8568 15.3611 18.6884 17.1798 16.8122H2.82009C4.63736 18.6899 7.18269 19.8568 9.99996 19.8568ZM14.7526 12.559L10.0001 4.24245L5.249 12.559H14.7526Z'
        fill='#1A1927'
      />
    </svg>
  )
}
