import { CountryList } from '@customTypes'
import { useAsync } from '@hooks'
import axios from 'axios'
import { useEffect } from 'react'
import { CountryList as countries } from 'tokensoft-shared-types'

const getCountryCode = async (): Promise<string> => {
  const response = await axios('https://countrycode.tokensoft.io')
  return response?.data.country || ''
}

export const useCountryCode = () => {
  const { data: countryCode, isPending, run } = useAsync()

  useEffect(() => {
    run(getCountryCode())
  }, [run])

  return { countryCode, loading: isPending }
}

export const allowedRegionsToCountryList = (
  allowedRegions: CountryCode[],
): CountryList[] => {
  if (!allowedRegions?.length) {
    return []
  }

  return allowedRegions
    .map((value: CountryCode) => {
      return countries.find((country) => country.value === value)
    })
    .filter((c) => c !== undefined)
    .concat() as CountryList[]
}

export const blockedRegionsFromCountryList = (
  list: CountryList[],
): CountryList[] => {
  if (!list?.length) {
    return []
  }

  // Returns the countries that are not in the list
  return countries.filter(
    (country) => !list.find((c) => c.value === country.value),
  )
}
